import { useContext } from 'react';
import { Route, Redirect, useLocation } from 'react-router';

import PropTypes from 'prop-types';

import { AuthContext } from './actions/auth-context';

const PrivateRoute = ({ component: Component, ...rest }) => {
  const location = useLocation();
  const { isAuthenticated } = useContext(AuthContext);

  return (
    <Route
      {...rest}
      render={(props) =>
        isAuthenticated ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: '/login',
              state: { from: location },
            }}
          />
        )
      }
    />
  );
};

PrivateRoute.propTypes = {
  component: PropTypes.func.isRequired,
};

export default PrivateRoute;
