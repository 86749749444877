import React, { useState } from 'react';
import { FaArrowCircleUp } from 'react-icons/fa';

import styled from 'styled-components';

const ScrollToTopButton = () => {
  const [showScroll, setShowScroll] = useState(false);

  const checkScrollTop = () => {
    if (!showScroll && window.pageYOffset > 400) {
      setShowScroll(true);
    } else if (showScroll && window.pageYOffset <= 400) {
      setShowScroll(false);
    }
  };

  const scrollTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  window.addEventListener('scroll', checkScrollTop);

  return (
    <Button show={showScroll} onClick={scrollTop}>
      <FaArrowCircleUp color="#FFBA00" size={40} />
      <span>VOLTAR AO TOPO</span>
    </Button>
  );
};

export default ScrollToTopButton;

export const Button = styled.button`
  position: fixed;
  z-index: 999999999;
  bottom: 20px;
  right: 80px;
  display: ${(props) => (props.show ? 'flex' : 'none')};
  flex-direction: column;
  align-items: center;
  border: none;
  background-color: transparent;
  color: #ffba00;

  animation: fadeIn 0.3s;
  transition: opacity 0.4s;
  opacity: 0.5;

  :hover {
    opacity: 1;
    font-weight: bold;
  }

  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 0.5;
    }
  }
`;
