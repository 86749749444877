/* eslint-disable no-unused-vars */
import React, {
  useEffect,
  useRef,
  useCallback,
  useState,
  useContext,
} from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router';

import { Form } from '@unform/web';
import axios from 'axios';
import styled from 'styled-components';
import * as Yup from 'yup';

import { AuthContext } from '../../../../actions/auth-context';
import { update, show } from '../../../../actions/base-rest';
import {
  currencyToFloat,
  floatToCurrency,
} from '../../../../commons/currency-formatter';
import { dateToString } from '../../../../commons/date';
import { indexEnum } from '../../../../commons/enum';
import stateOptions from '../../../../commons/StateOptions';
import { isValidCpf, isValidCnpj } from '../../../../commons/validate-cpf-cnpj';
import InputCurrencyLabel from '../../../../components/unform/input-currency-label';
import InputDateLabel from '../../../../components/unform/input-date-label';
import InputLabel from '../../../../components/unform/input-label';
import InputMaskLabel from '../../../../components/unform/input-mask-label';
import InputTextAreaLabel from '../../../../components/unform/input-text-area-label';
import RadioButtonLabel from '../../../../components/unform/radiobutton-label-string';
import SelectLabel from '../../../../components/unform/select-label';
import { setBreadcrumb } from '../../../../reducers/breadcrumb';
import { GroupData } from '../../../../styles/GroupData';

const URL = 'persons';

const EditPerson = () => {
  const { setLoading } = useContext(AuthContext);

  const dispatch = useDispatch();

  const formRef = useRef(null);

  const [typePerson, setTypePerson] = useState('Física');

  const [person, setPerson] = useState({});

  const [selectedState, setSelectedState] = useState({});

  const [selectedMaritalStatus, setSelectedMaritalStatus] = useState({});

  const [maritalStatusOptions, setMaritalStatusOptions] = useState([]);

  const history = useHistory();

  const params = useParams();

  const prepareGetPhones = useCallback((phones) => {
    if (phones.length > 0) {
      const prepared_phones = [];

      prepared_phones[0] = phones.find((item) => item.type === 'Casa') || {};
      prepared_phones[1] = phones.find((item) => item.type === 'Celular') || {};
      prepared_phones[2] =
        phones.find((item) => item.type === 'Trabalho') || {};

      return prepared_phones;
    }

    return phones;
  }, []);

  const prepareBirthDate = useCallback((string_date) => {
    if (string_date?.length > 0) return new Date(`${string_date}T00:00`);

    return '';
  }, []);

  const getPerson = useCallback(
    async (id) => {
      setLoading(true);

      const response = await show(URL, id);

      if (response.monthly_income)
        response.monthly_income = await floatToCurrency(
          response.monthly_income
        );

      response.phones = await prepareGetPhones(response.phones);

      response.birth_date = await prepareBirthDate(response.birth_date);

      setPerson(response);

      if (response.addresses.length > 0)
        setSelectedState(
          stateOptions.find(
            (item) => item.value === response.addresses[0].state
          )
        );

      if (response.marital_status)
        setSelectedMaritalStatus(
          maritalStatusOptions.find(
            (item) => item.value === response.marital_status
          )
        );

      formRef.current.setFieldValue('type', response.type);

      formRef.current.setFieldValue('monthly_income', response.monthly_income);

      setLoading(false);
    },
    [stateOptions, maritalStatusOptions]
  );

  const searchCep = useCallback(
    async (value) => {
      const cep = value.replace('_', '');

      const { data } = await axios.get(`https://viacep.com.br/ws/${cep}/json/`);
      if (data.cep) {
        formRef.current.setFieldValue('addresses[0].street', data.logradouro);
        formRef.current.setFieldValue('addresses[0].neighborhood', data.bairro);
        formRef.current.setFieldValue('addresses[0].city', data.localidade);
        setSelectedState(stateOptions.find((item) => item.value === data.uf));
      }
    },
    [stateOptions, formRef]
  );

  const changeFieldsInBankToNull = useCallback(async (object) => {
    for (const i in object) {
      if (Object.prototype.hasOwnProperty.call(object, i)) {
        if (object[i] === '') object[i] = null;
      }
    }

    return object;
  }, []);

  const prepareAddressesForSubmit = useCallback(async (address) => {
    const prepared_address = await changeFieldsInBankToNull(address);

    if (Object.entries(prepared_address).length > 0) {
      prepared_address.type = 'Casa';
      prepared_address.is_principal = true;

      return [prepared_address];
    }

    return null;
  }, []);

  const preparePhonesForSubmit = useCallback(async (phones) => {
    const prepared_phone = await changeFieldsInBankToNull(phones[0]);

    const prepared_cell_phone = await changeFieldsInBankToNull(phones[1]);

    const prepared_cell_phone_2 = await changeFieldsInBankToNull(phones[2]);

    const prepared_phones = [];

    if (Object.entries(prepared_phone).length > 0) {
      prepared_phone.type = 'Casa';

      prepared_phone.is_principal = true;
    }

    if (Object.entries(prepared_cell_phone).length > 0) {
      prepared_cell_phone.type = 'Celular';

      prepared_cell_phone.is_principal = false;
    }

    if (Object.entries(prepared_cell_phone_2).length > 0) {
      prepared_cell_phone_2.type = 'Trabalho';

      prepared_cell_phone_2.is_principal = false;
    }

    if (prepared_phone.phone_number) prepared_phones.push(prepared_phone);

    if (prepared_cell_phone.phone_number)
      prepared_phones.push(prepared_cell_phone);

    if (prepared_cell_phone_2.phone_number)
      prepared_phones.push(prepared_cell_phone_2);

    return prepared_phones;
  }, []);

  const prepareEmailsForSubmit = useCallback(async (email) => {
    const prepared_email = await changeFieldsInBankToNull(email);

    if (Object.entries(prepared_email).length > 0 && prepared_email.email) {
      prepared_email.type = 'Casa';

      prepared_email.is_principal = true;

      return [prepared_email];
    }

    return null;
  }, []);

  const loadOptionsCivilState = useCallback(async () => {
    const response = await indexEnum('marital-status');

    setMaritalStatusOptions(response);
  }, []);

  const submit = useCallback(
    async (data) => {
      if (selectedState) data.addresses[0].state = selectedState.value;

      if (data.monthly_income)
        data.monthly_income = await currencyToFloat(data.monthly_income);

      try {
        formRef.current.setErrors({});
        const schema = Yup.object().shape({
          type: Yup.string().required('Tipo pessoa é obrigatório'),
          troll_code: Yup.string(),
          full_name: Yup.string().min(3, 'Nome mínimo 3 caracteres'),
          cpf_cnpj: Yup.string()
            .test(
              'validateCpfCnpj',
              `${typePerson === 'Física' ? 'CPF' : 'CNPJ'} inválido`,
              (value) => {
                let result = false;

                if (value.length > 0) {
                  if (value.length === 14) {
                    result = isValidCpf(value);
                  } else {
                    result = isValidCnpj(value);
                  }
                }

                return result;
              }
            )
            .required(
              `${typePerson === 'Física' ? 'CPF' : 'CNPJ'} é obrigatório`
            ),
          rg_ie: Yup.string(),
          emails: Yup.array().of(
            Yup.object().shape({
              type: Yup.string(),
              email: Yup.string().email('Digite um e-mail válido'),
              is_principal: Yup.bool(),
              email_verified: Yup.bool(),
            })
          ),
          phones: Yup.array().of(
            Yup.object().shape({
              type: Yup.string(),
              phone_number: Yup.string()
                .test('validPhone', 'Número inválido', (value) => {
                  if (value.includes('_')) return false;
                  return true;
                })
                .nullable(),
              is_principal: Yup.bool(),
            })
          ),
          note: Yup.string(),
          naturalness: Yup.string(),
          mother_name: Yup.string(),
          father_name: Yup.string(),
          birth_date: Yup.date().nullable(),
          marital_status: Yup.string(),
          addresses: Yup.array().of(
            Yup.object().shape({
              type: Yup.string(),
              cep: Yup.string()
                .test('cep', 'CEP inválido, min 9 dígitos', (value) => {
                  if (value.includes('_')) return false;
                  return true;
                })
                .nullable(),
              street: Yup.string(),
              number: Yup.string(),
              complement: Yup.string(),
              neighborhood: Yup.string(),
              city: Yup.string(),
              state: Yup.string(),
              is_principal: Yup.bool(),
            })
          ),
          workplace: Yup.string(),
          profession: Yup.string(),
          monthly_income: Yup.string().nullable(),
          work_address: Yup.string(),
          work_phone: Yup.string()
            .test('validPhone', 'Celular inválido', (value) => {
              if (value.includes('_')) return false;
              return true;
            })
            .nullable(),
        });

        await schema.validate(data, { abortEarly: false });

        setLoading(true);

        const processed_data = await changeFieldsInBankToNull(data);

        processed_data.addresses = await prepareAddressesForSubmit(
          processed_data.addresses[0]
        );

        processed_data.emails = await prepareEmailsForSubmit(
          processed_data.emails[0]
        );

        processed_data.phones = await preparePhonesForSubmit(
          processed_data.phones
        );

        processed_data.birth_date = await dateToString(
          processed_data.birth_date
        );

        const response = await update(URL, processed_data);

        if (!response.error) {
          response.phones = await prepareGetPhones(response.phones);

          response.birth_date = await prepareBirthDate(response.birth_date);

          setPerson({});

          setPerson(response);
        } else if (
          response.error.message ===
          `ID Troll duplicado. Já existe uma pessoa com esse Id TROLL cadastrado no sistema.`
        )
          formRef.current.setFieldValue('troll_code', null);
      } catch (error) {
        const errorMessages = {};
        if (error instanceof Yup.ValidationError) {
          error.inner.forEach((err) => {
            errorMessages[err.path] = err.message;
          });
          formRef.current.setErrors(errorMessages);
        }
      }

      setLoading(false);
    },
    [typePerson, getPerson, selectedState, formRef]
  );

  useEffect(() => {
    const { id } = params;

    getPerson(id);

    dispatch(
      setBreadcrumb([
        { page: 'pessoas', url: '/persons' },
        { page: 'editar', url: null },
      ])
    );

    document.documentElement.style.overflow = 'auto';

    document.body.scroll = 'yes';
  }, [getPerson, dispatch]);

  useEffect(() => {
    loadOptionsCivilState();
  }, []);

  return (
    <Container className="container ">
      <div className="row mt-3 px-1 justify-content-center">
        <div className="col-12 text-center my-3">
          <h4>EDITAR {person.name}</h4>
        </div>
      </div>

      <div className="row justify-content-center mb-5">
        <Form
          ref={formRef}
          onSubmit={submit}
          initialData={person}
          noValidate
          className="col-12 col-lg-8"
        >
          <GroupData>
            <span>DADOS PESSOAIS</span>

            <div className="col-1 d-none">
              <InputLabel name="id" label="ID" />
            </div>

            <div className="row">
              <div className="col-10 px-1">
                <RadioButtonLabel
                  name="type"
                  label="Tipo pessoa"
                  onChange={(e) => setTypePerson(e.target.value)}
                  options={[
                    { label: 'física', value: 'Física' },
                    { label: 'jurídica', value: 'Jurídica' },
                  ]}
                />
              </div>

              <div className="col-7 col-md-2 mt-2 px-1">
                <InputLabel name="troll_code" label="codigo troll" />
              </div>
            </div>

            <div className="row">
              <div className="col-12 col-md-6 mt-3 px-1">
                <InputLabel name="full_name" label="nome" />
              </div>

              <div className="col-7 col-md-4 mt-3 px-1">
                <InputMaskLabel
                  name="cpf_cnpj"
                  label={typePerson === 'Física' ? 'cpf' : 'cnpj'}
                  mask={
                    typePerson === 'Física'
                      ? '999.999.999-99'
                      : '99.999.999/9999-99'
                  }
                />
              </div>

              <div className="col-5 col-md-2 mt-3 px-1">
                <InputLabel
                  name="rg_ie"
                  label={typePerson === 'Física' ? 'rg' : 'ie'}
                />
              </div>
            </div>

            <div className="row">
              <div className="col-12 col-md-6 mt-3 px-1">
                <InputLabel name="father_name" label="nome do pai" />
              </div>

              <div className="col-12 col-md-6 mt-3 px-1">
                <InputLabel name="mother_name" label="nome mãe  " />
              </div>
            </div>

            <div className="row">
              <div className="col-12 col-md-5 mt-3 px-1">
                <InputLabel name="emails[0].email" label="e-mail" />
              </div>

              <div className="col-6 col-md-2 mt-3 px-1">
                <InputLabel name="naturalness" label="naturalidade" />
              </div>

              <div className="col-6 col-lg-2 mt-3 px-1">
                <InputDateLabel
                  name="birth_date"
                  label="data nascimento"
                  defaultValue={person.birth_date || null}
                />
              </div>

              <div className="col-6 col-lg-3 mt-3 px-1">
                <SelectLabel
                  name="marital_status"
                  label="estado civil"
                  placeholder="selecione"
                  options={maritalStatusOptions}
                  value={selectedMaritalStatus}
                  onChange={(e) => setSelectedMaritalStatus(e)}
                />
              </div>
            </div>

            <div className="row">
              <div className="col-6 col-md-4 mt-3 px-1">
                <InputMaskLabel
                  name="phones[0].phone_number"
                  label="telefone"
                  mask="(99) 9999-9999"
                />
              </div>

              <div className="col-6 col-md-4 mt-3 px-1">
                <InputMaskLabel
                  name="phones[1].phone_number"
                  label="celular (Principal)"
                  mask="(99) 99999-9999"
                />
              </div>

              <div className="col-6 col-md-4 mt-3 px-1">
                <InputMaskLabel
                  name="phones[2].phone_number"
                  label="celular (Recados)"
                  mask="(99) 99999-9999"
                />
              </div>
            </div>

            <div className="row">
              <div className="col-sm mt-2 px-1">
                <InputTextAreaLabel
                  name="note"
                  label="Observações / Telefones de recado"
                  cols={20}
                  rows={3}
                />
              </div>
            </div>
          </GroupData>

          <GroupData>
            <span>ENDEREÇO</span>

            <div className="row">
              <div className="col-12 col-md-4 mt-3 px-1">
                <InputMaskLabel
                  name="addresses[0].cep"
                  label="cep"
                  mask="99999-999"
                  onBlur={(e) => searchCep(e.target.value)}
                />
              </div>

              <div className="col-12 col-md-6 mt-3 px-1">
                <InputLabel name="addresses[0].street" label="rua" />
              </div>

              <div className="col-4 col-md-2 mt-3 px-1">
                <InputLabel name="addresses[0].number" label="número" />
              </div>

              <div className="col-8 col-md-4 mt-3 px-1">
                <InputLabel name="addresses[0].neighborhood" label="bairro" />
              </div>

              <div className="col-6 col-md-4 mt-3 px-1">
                <InputLabel name="addresses[0].city" label="cidade" />
              </div>

              <div className="col-6 col-md-4 mt-3 px-1">
                <SelectLabel
                  name="addresses[0].state"
                  label="estado"
                  placeholder="selecione"
                  options={stateOptions}
                  value={selectedState}
                  onChange={(e) => setSelectedState(e)}
                />
              </div>

              <div className="col-12 mt-3 px-1">
                <InputLabel
                  name="addresses[0].complement"
                  label="complemento"
                />
              </div>
            </div>
          </GroupData>

          <GroupData>
            <span>DADOS PROFISSIONAIS</span>
            <div className="row">
              <div className="col-12 col-md-5 mt-3 px-1">
                <InputLabel name="workplace" label="local de trabalho" />
              </div>

              <div className="col-12 col-md-5 mt-3 px-1">
                <InputLabel name="profession" label="profissão" />
              </div>

              <div className="col-12 col-md-2 mt-3 px-1">
                <InputCurrencyLabel
                  name="monthly_income"
                  label="renda mensal"
                />
              </div>
            </div>

            <div className="row">
              <div className="col-12 col-md-8 mt-3 px-1">
                <InputLabel name="work_address" label="endereço trabalho" />
              </div>

              <div className="col-12 col-md-4 mt-3 px-1">
                <InputMaskLabel
                  name="work_phone"
                  label="telefone empresa"
                  mask="(99) 9999-9999"
                />
              </div>
            </div>
          </GroupData>

          <div className="row justify-content-center mt-4">
            <div className="col-6 col-md-4 col-xl-3">
              <button
                className="btn btn-outline-secondary btn-block col-12"
                type="button"
                onClick={() => history.goBack()}
              >
                voltar
              </button>
            </div>
            <div className="col-6 col-md-4 col-xl-3">
              <button
                className="btn btn-primary btn-block col-12"
                type="submit"
              >
                atualizar
              </button>
            </div>
          </div>
        </Form>
      </div>
    </Container>
  );
};

export default EditPerson;

export const Container = styled.div`
  /* text-transform: uppercase; */

  .react-select__single-value {
    top: 50% !important;
  }
`;
