import api from '../actions/api';
import { handleError } from '../actions/handle-error';

export const indexEnum = async (type) => {
  const result = new Promise((resolve) => {
    api
      .get(`enums/${type}`)
      .then((response) => {
        const options = response.data.map((item) => ({
          // label: `${item.toUpperCase()}`,
          label: `${item}`,
          value: item,
        }));

        resolve(options);
      })
      .catch((error) => {
        handleError(error);
        resolve(error);
      });
  });
  return result;
};
