import { useEffect, useCallback, useState } from 'react';
import { BsSquareFill } from 'react-icons/bs';
import { MdClose } from 'react-icons/md';
import { useHistory } from 'react-router';

import PropTypes from 'prop-types';
import styled from 'styled-components';

import { index } from '../../../actions/base-rest';

const OrdersInProductionLocationAndCityModal = ({
  order_location,
  unit_city,
}) => {
  const history = useHistory();

  const [orders, setOrders] = useState([]);

  const getOrdersInLocation = useCallback(async () => {
    const params = {
      page: 1,
      per_page: 500,
      order_location,
      unit_city,
      sorts: 'delivery_date:asc',
    };

    const response = await index('orders', params);

    setOrders([]);

    setOrders(response.rows);
  }, [order_location, unit_city]);

  useEffect(() => {
    if (order_location) getOrdersInLocation();
  }, [order_location, unit_city]);

  return (
    <ModalContainer>
      <div
        className="modal fade"
        tabIndex="-1"
        id="modal-orders-production-location-city"
      >
        <div className="modal-dialog  modal-dialog-centered modal-xl">
          <div className="modal-content ">
            <div className="modal-header">
              <h5 className="modal-title col-11 text-center text-uppercase">
                Pedidos no setor {order_location} de {unit_city}
              </h5>
              <MdClose
                id="close"
                size={30}
                data-bs-dismiss="modal"
                type="button"
                onClick={() => {}}
              />
            </div>
            <div className="bg-modal">
              <div className="modal-body">
                <div className="table-responsive">
                  <Legend className="row  justify-content-center">
                    <div className="col text-center">
                      <BsSquareFill color="#d11515" /> Entrega Atrasada
                    </div>

                    <div className="col text-center">
                      <BsSquareFill color="#c7a11e" /> menos 7 dias entrega
                    </div>

                    <div className="col text-center">
                      <BsSquareFill color="#E67E22" /> Retrabalho
                    </div>
                  </Legend>

                  <table className="table table-hover text-center table-striped">
                    <thead>
                      <tr>
                        <th scope="col" className="text-start">
                          ENTREGA
                        </th>
                        <th scope="col" className="text-start">
                          INCLUSÃO
                        </th>
                        <th scope="col" className="text-start">
                          CLIENTE
                        </th>
                        <th scope="col" className="text-start">
                          CIDADE
                        </th>
                        <th scope="col" className="text-start">
                          EDITOR
                        </th>
                        {/* <th scope="col" className="text-start">
                          Nº SIGI
                        </th> */}
                        <th scope="col" className="text-start">
                          STATUS
                        </th>
                      </tr>
                    </thead>

                    <tbody>
                      {orders?.map((item) => (
                        <OrderTr
                          order_later={item.is_order_later}
                          order_urgent={item.is_order_urgent}
                          order_status={item.order_status}
                          is_rework={item.is_rework}
                          has_editor={item.editor_id}
                          key={item.id}
                          data-bs-dismiss="modal"
                          onClick={(e) => {
                            if (e.target.innerText !== undefined) {
                              history.push(`orders/${item.id}`);
                            }
                          }}
                        >
                          <td
                            className="text-start"
                            style={{ fontWeight: 'bold' }}
                          >
                            {item.delivery_date_format}
                          </td>
                          <td className="text-start">
                            {item.order_date_format}
                          </td>
                          <td className="text-start">
                            {item.person_full_name}
                          </td>
                          <td className="text-start">{item.unit_city}</td>
                          <td className="text-start">
                            {item.editor_full_name || 'Não definido'}
                          </td>
                          {/* <td className="text-start">{item.sig_number}</td> */}
                          <td className="text-start">{item.order_status}</td>
                        </OrderTr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>

              <div className="row justify-content-end">
                <div className="col-6 col-md-2 m-3">
                  <button
                    type="button"
                    data-bs-dismiss="modal"
                    className="btn btn-outline-secondary btn-block col-12"
                  >
                    Cancelar
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ModalContainer>
  );
};

OrdersInProductionLocationAndCityModal.propTypes = {
  order_location: PropTypes.string.isRequired,
  unit_city: PropTypes.string.isRequired,
};

export default OrdersInProductionLocationAndCityModal;

export const ModalContainer = styled.div`
  .bg-modal {
    background-color: #f5f5f5;
    margin: 7px;
  }

  .modal-header {
    border: none;
    background-color: #4f4f4f;
    color: #fff;
    justify-content: center !important;
  }

  .modal-body {
    text-transform: uppercase;
  }

  .modal-footer {
    justify-content: center !important;
    border: none;
  }

  svg {
    :hover {
      cursor: pointer;
      color: #ffba00;
    }
  }
`;

export const OrderTr = styled.tr`
  color: ${(props) => {
    if (props.order_later) return '#d11515';

    if (props.order_urgent) return '#c7a11e';

    if (props.is_rework) return '#E67E22';
    return '#464646';
  }} !important;

  :hover {
    cursor: pointer;
  }
`;

export const Legend = styled.div`
  font-size: 12px;
  text-transform: capitalize;
`;
