// import { ImSortAlphaAsc, ImSortAlphaDesc } from 'react-icons/im';
import { TiArrowSortedDown, TiArrowSortedUp } from 'react-icons/ti';
import ReactTooltip from 'react-tooltip';

import PropTypes from 'prop-types';
import styled from 'styled-components';

const SortButton = ({ label, ascFunction, descFunction, params }) => {
  const isOrderAsc = params.sorts.includes('ASC');

  return (
    <Container>
      {label}

      {isOrderAsc && (
        <TiArrowSortedUp
          size={20}
          data-tip="Ordenar de Z-A"
          onClick={descFunction}
        />
      )}

      {!isOrderAsc && (
        <TiArrowSortedDown
          size={20}
          data-tip="Ordenar de A-Z"
          onClick={ascFunction}
        />
      )}

      <ReactTooltip />
    </Container>
  );
};

SortButton.propTypes = {
  label: PropTypes.string.isRequired,
  ascFunction: PropTypes.func.isRequired,
  descFunction: PropTypes.func.isRequired,
  params: PropTypes.shape({
    sorts: PropTypes.string,
  }).isRequired,
};

export default SortButton;

export const Container = styled.div`
  svg {
    margin-left: 8px;
    margin-top: -1px;
    :hover {
      color: #ffba00;
    }
  }
`;
