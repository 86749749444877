import { useEffect, useCallback, useState, useContext, useRef } from 'react';
import { FcOk, FcInfo, FcCancel } from 'react-icons/fc';
import { FiSquare } from 'react-icons/fi';
// import { TiCancel } from 'react-icons/ti';
import ReactTooltip from 'react-tooltip';

import { Form } from '@unform/web';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { AuthContext } from '../../../../actions/auth-context';
import {
  getLocalBlobThumbnailApi,
  deletePhotosOfOrder,
} from '../../../../actions/order.service';
import DeleteModal from '../../../../components/delete-modal';
import Checkbox from '../../../../components/unform/checkbox';
import ViewImageModal from './ViewImageModal';

const EditedPhotos = ({ order, setOrder, getOrder }) => {
  const { setLoading, hasPermissions } = useContext(AuthContext);

  const formRef = useRef(null);

  const [thumbnails, setThumbnails] = useState([]);

  const [firstPhotoToView, setFirstPhotoToView] = useState({});

  const [isSelectedAllPhotos, setIsSelectedAllPhotos] = useState(false);

  const [photosToBeRemoved, setPhotosToBeRemoved] = useState([]);

  const validPhotos =
    order.photos.filter((item) => item.is_cover_photo === true).length > 0;

  const getLocalBlobThumbnail = useCallback(
    async (photo) => {
      const result = await getLocalBlobThumbnailApi(order.id, photo.id);

      return {
        id: photo.id,
        blob: result,
        photo_status: photo.photo_status,
        is_cover_photo: photo.is_cover_photo,
        note: photo.note,
      };
    },
    [order]
  );

  const prepareThumbnails = useCallback(async () => {
    if (order.photos.length > 0) {
      setLoading(true);

      const list = [];

      for (let i = 0; i < order.photos.length; i++) {
        const photo = order.photos[i];

        list.push(getLocalBlobThumbnail(photo));
      }

      const result = await Promise.all(list);

      result.sort((a, b) => {
        const fa = a.id;
        const fb = b.id;

        if (fa < fb) {
          return -1;
        }
        if (fa > fb) {
          return 1;
        }
        return 0;
      });

      setThumbnails(result);

      setLoading(false);
    }
  }, [order]);

  const addAndRemovePhotoToDelete = useCallback(
    (photo_id) => {
      if (photosToBeRemoved.includes(photo_id)) {
        setPhotosToBeRemoved(
          photosToBeRemoved.filter((item) => item !== photo_id)
        );
      } else {
        setPhotosToBeRemoved((state) => [...state, photo_id]);
      }
    },
    [photosToBeRemoved]
  );

  const removePhotos = useCallback(async () => {
    setLoading(true);

    const response = await deletePhotosOfOrder(order.id, photosToBeRemoved);

    if (!response.error) {
      response.delivery_date = new Date(response.delivery_date);

      if (response.payment_first_date) {
        response.payment_first_date = new Date(
          response.payment_first_date.replace(/-/g, ',')
        );
      }

      setOrder(response);

      setIsSelectedAllPhotos(false);

      setPhotosToBeRemoved([]);
    }

    setLoading(false);
  }, [order, photosToBeRemoved]);

  const viewRemovePhotosButton = useCallback(
    () => isSelectedAllPhotos || photosToBeRemoved.length > 0,
    [isSelectedAllPhotos, photosToBeRemoved]
  );

  useEffect(() => {
    prepareThumbnails();
  }, [order]);

  useEffect(() => {
    const elements = document.querySelectorAll("input[type='checkbox']");

    if (isSelectedAllPhotos) {
      for (let i = 0; i < elements.length; i++) {
        elements[i].checked = true;
      }

      setPhotosToBeRemoved(order.photos.map((photo) => photo.id));
    } else {
      for (let i = 0; i < elements.length; i++) {
        elements[i].checked = false;
      }

      setPhotosToBeRemoved([]);
    }
  }, [isSelectedAllPhotos, order]);

  return (
    <>
      {order.photos?.length > 0 &&
        hasPermissions(['edit_photos', 'edit_order']) && (
          <Container className="mt-5">
            <div className="text-center bg-dark">
              <h5 className="mt-2">FOTOS EDITADAS </h5>
            </div>

            {!validPhotos && (
              <div className="row justify-content-center">
                <div className="col-sm-6 col-md-6 mt-2">
                  <div className="alert alert-danger text-center" role="alert">
                    <b>ATENÇÃO!</b> Pedido sem uma foto principal.
                  </div>
                </div>
              </div>
            )}
            <div className="container mt-2">
              <div className="row">
                <div className="col-6">
                  <Form ref={formRef} noValidate>
                    <Checkbox
                      name="select_all_photos"
                      value=""
                      label="Selecionar todas as fotos"
                      defaultChecked={isSelectedAllPhotos}
                      onChange={() =>
                        setIsSelectedAllPhotos(!isSelectedAllPhotos)
                      }
                    />
                  </Form>
                </div>

                <div className="col-6 text-end total-fotos">
                  <p>
                    Total de fotos{' '}
                    <span className="badge bg-secondary">
                      {order.photos?.length}
                    </span>
                  </p>
                </div>
              </div>

              <div className="row justify-content-center">
                {viewRemovePhotosButton() && (
                  <div className="col-sm col-md-4">
                    <button
                      type="button"
                      className="btn btn-danger col-12"
                      data-bs-toggle="modal"
                      data-bs-target="#modal-remove-photo"
                    >
                      Remover fotos selecionadas
                    </button>
                  </div>
                )}
              </div>

              <ViewImageModal
                order={order}
                setOrder={setOrder}
                getOrder={getOrder}
                first_photo_to_view={firstPhotoToView}
              />

              <div className="row">
                <Legend>
                  <div>
                    <FcInfo size={15} />
                    <span>Aguarda inspeção</span>
                  </div>

                  <div>
                    <FcOk size={15} />
                    <span>Aprovado</span>
                  </div>

                  <div>
                    <FcCancel size={15} />
                    <span>Reprovado</span>
                  </div>

                  <div>
                    <FiSquare color="#d11515" size={15} />
                    <span>Foto de capa</span>
                  </div>
                </Legend>

                {thumbnails.map((thumbnail, i) => (
                  <Photo
                    key={thumbnail.id}
                    className="col-6 col-md-2"
                    is_cover_photo={thumbnail.is_cover_photo}
                    onClick={() =>
                      setFirstPhotoToView({
                        id: thumbnail.id,
                        photo_status: thumbnail.photo_status,
                        note: thumbnail.note,
                        is_cover_photo: thumbnail.is_cover_photo,
                        index: i,
                      })
                    }
                  >
                    <div className="card mt-2">
                      <img
                        src={thumbnail.blob}
                        alt="foto editada"
                        data-bs-toggle="modal"
                        data-bs-target="#modal-view-image"
                      />
                      <div className="bg-check">
                        <Form ref={formRef} noValidate>
                          <Checkbox
                            name={thumbnail?.id}
                            value={thumbnail.id}
                            label="remover"
                            defaultChecked={isSelectedAllPhotos}
                            onChange={(e) =>
                              addAndRemovePhotoToDelete(e.target.value)
                            }
                          />
                          <div className="status">
                            {thumbnail.photo_status === 'Aguarda inspeção' && (
                              <FcInfo size={30} data-tip="Aguarda inspeção" />
                            )}

                            {thumbnail.photo_status === 'Aprovada' && (
                              <FcOk size={30} data-tip="Foto aprovada" />
                            )}

                            {thumbnail.photo_status === 'Reprovada' && (
                              <FcCancel
                                className="reproved"
                                size={30}
                                color="red"
                                data-tip={thumbnail.note}
                              />
                            )}
                          </div>

                          <div className="index">
                            <span>{i + 1}</span>
                          </div>
                        </Form>
                      </div>
                    </div>
                    <ReactTooltip />
                  </Photo>
                ))}
              </div>
            </div>
          </Container>
        )}

      <DeleteModal
        id_modal="modal-remove-photo"
        item_delete={{ description: 'fotos selecionadas' }}
        deleteItem={() => removePhotos()}
      />
    </>
  );
};

EditedPhotos.propTypes = {
  order: PropTypes.shape({
    id: PropTypes.string,
    photos: PropTypes.arrayOf(Object),
  }).isRequired,
  setOrder: PropTypes.func.isRequired,
  getOrder: PropTypes.func.isRequired,
};

export default EditedPhotos;

export const Container = styled.section`
  text-transform: uppercase;
  margin-bottom: 50px;

  h6 {
    font-size: 12px;
  }

  .badge {
    background-color: #464646 !important;
  }

  .bg-dark {
    height: 40px;
    background-color: #464646 !important;
    display: flex;
    flex-direction: row;
    justify-content: center;

    h5 {
      color: white;
    }
  }

  .table {
    font-size: 13px;
  }

  .table-hover {
    cursor: pointer;
  }

  .action {
    color: #d11515;

    :hover {
      cursor: pointer;
      color: #ec4646;
    }
  }

  @media screen and (max-width: 768px) {
    .table {
      width: 900px !important;
    }

    .total-fotos {
      font-size: 12px;
    }
  }
`;

export const OrderField = styled.div`
  svg {
    margin-left: 8px;
    margin-top: -1px;
    :hover {
      color: #ffba00;
    }
  }
`;

export const Photo = styled.div`
  img {
    width: 100%;
    height: 150px;
    object-fit: cover;

    border: ${(props) => (props.is_cover_photo ? '5px solid #d11515' : '')};

    :hover {
      cursor: pointer;

      box-shadow: 0 0 10px #464646;
    }
  }

  .bg-check {
    margin-top: -30px;
    padding-left: 5px;
    background: rgba(0, 0, 0, 0.5);

    .form-check-label {
      color: #fff;
    }

    .index {
      top: 0;
      background-color: #ffba00;
      color: #464646;
      opacity: 0.7;
      width: 30px;
      height: 30px;
      border-radius: 50%;
      display: flex;
      font-weight: bold;
      justify-content: center;
      align-items: center;
      position: absolute;
      z-index: 1;
      right: 0;
      margin: 2px;
    }

    .status {
      top: 0;
      display: flex;
      font-weight: bold;
      position: absolute;
      z-index: 1;
      left: 0;
      margin: 2px;
    }
  }
`;

export const Legend = styled.div`
  display: flex;
  flex-direction: row;
  font-size: 11px;
  text-transform: none;
  align-items: center;

  svg {
    margin-top: -2px;
  }

  span {
    margin: 0 10px 0 5px;
  }

  @media screen and (max-width: 768px) {
    justify-content: center;
  }
`;
