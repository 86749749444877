import { useContext } from 'react';

import PropTypes from 'prop-types';

import { AuthContext } from '../../../../../actions/auth-context';

const FinishPlotterOrderButton = ({ order }) => {
  const { hasPermissions } = useContext(AuthContext);

  return (
    <>
      {order.order_status === 'Em plotter' &&
        hasPermissions(['plotter_photos']) && (
          <div className="col-sm col-md-4 col-xl-3 mt-2">
            <button
              className="btn btn-success btn-block col-12"
              type="button"
              data-bs-toggle="modal"
              data-bs-target="#modal-finish-plotter-order"
            >
              finalizar plotter
            </button>
          </div>
        )}
    </>
  );
};

FinishPlotterOrderButton.propTypes = {
  order: PropTypes.shape({
    id: PropTypes.string,
    order_status: PropTypes.string,
  }).isRequired,
};

export default FinishPlotterOrderButton;
