/* eslint-disable no-unused-vars */
import React, { useRef, useCallback, useEffect, useState } from 'react';
import { MdClose } from 'react-icons/md';

import { Form } from '@unform/web';
import { Modal } from 'bootstrap';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import * as Yup from 'yup';

import InputLabel from '../../../../../components/unform/input-label';
import InputTextAreaLabel from '../../../../../components/unform/input-text-area-label';

function ActionModal({ title, action, modal_id, order }) {
  const formRefModal = useRef(null);

  const modalRef = useRef(null);

  const [modal, setModal] = useState(null);

  const resetFormRefModal = useCallback(() => {
    formRefModal.current.reset();

    document.documentElement.style.overflow = 'auto';

    document.body.scroll = 'yes';

    modal.hide();
  }, [modal]);

  useEffect(() => {
    setModal(new Modal(modalRef.current));
  }, []);

  const submit = useCallback(
    async (data) => {
      try {
        formRefModal.current.setErrors({});
        const schema = Yup.object().shape({
          action_note: Yup.string(),

          ms_number: Yup.number().typeError('Valor inválido').nullable(),

          sig_number_edited: Yup.number()
            .typeError('Valor inválido')
            .nullable(),
        });

        await schema.validate(data, { abortEarly: false });

        data.order_id = order.id;

        await action(data);

        resetFormRefModal();
      } catch (error) {
        const errorMessages = {};
        if (error instanceof Yup.ValidationError) {
          error.inner.forEach((err) => {
            errorMessages[err.path] = err.message;
          });
          formRefModal.current.setErrors(errorMessages);
        }
      }
    },
    [order, modal]
  );

  return (
    <ModalContainer>
      <div
        className="modal"
        tabIndex="-1"
        id={modal_id}
        ref={modalRef}
        data-bs-backdrop="false"
      >
        <div className="modal-dialog  modal-dialog-centered">
          <div className="modal-content ">
            <div className="modal-header">
              <h5 className="modal-title col-11 text-center text-uppercase">
                {title}
              </h5>
              <MdClose
                id="close"
                size={30}
                data-bs-dismiss="modal"
                type="button"
                onClick={() => {}}
              />
            </div>
            <div className="bg-modal">
              <div className="modal-body">
                <Form
                  ref={formRefModal}
                  onSubmit={submit}
                  initialData={order}
                  noValidate
                >
                  <div className="row">
                    {order.order_status === 'Em edição' && (
                      <div className="col-sm col-md-6 mt-2">
                        <InputLabel
                          name="sig_number_edited"
                          label="Nº SIGI editadas"
                        />
                      </div>
                    )}

                    {order.order_status === 'Em impressão' && (
                      <div className="col-sm col-md-6 mt-2">
                        <InputLabel name="ms_number" label="Nº MS" />
                      </div>
                    )}

                    <div className="col-12 mt-2">
                      <InputTextAreaLabel
                        name="action_note"
                        label="Observações"
                        cols={20}
                        rows={2}
                      />
                    </div>
                  </div>

                  <div className="row justify-content-center mt-4">
                    <button
                      type="button"
                      className="btn btn-secondary col-4 m-1"
                      onClick={() => resetFormRefModal()}
                    >
                      CANCELAR
                    </button>
                    <button type="submit" className="btn btn-primary col-4 m-1">
                      SALVAR
                    </button>
                  </div>
                </Form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ModalContainer>
  );
}

ActionModal.propTypes = {
  title: PropTypes.string.isRequired,
  action: PropTypes.func.isRequired,
  modal_id: PropTypes.string.isRequired,
  order: PropTypes.shape({
    id: PropTypes.string,
    order_status: PropTypes.string,
  }).isRequired,
};

export default ActionModal;

export const ModalContainer = styled.div`
  .bg-modal {
    background-color: #f5f5f5;
    margin: 7px;
  }

  .modal-header {
    border: none;
    background-color: #4f4f4f;
    color: #fff;
    justify-content: center !important;
  }

  .modal-body {
    text-transform: uppercase;
  }

  .modal-footer {
    justify-content: center !important;
    border: none;
  }

  svg {
    :hover {
      cursor: pointer;
      color: #ffba00;
    }
  }
`;
