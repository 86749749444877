import React, { useEffect, useRef, useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';

import { Form } from '@unform/web';
import styled from 'styled-components';
import * as Yup from 'yup';

import { store } from '../../../../actions/base-rest';
import stateOptions from '../../../../commons/StateOptions';
import InputLabel from '../../../../components/unform/input-label';
import SelectLabel from '../../../../components/unform/select-label';
import { setBreadcrumb } from '../../../../reducers/breadcrumb';
import { GroupData } from '../../../../styles/GroupData';

const URL = 'units';

const NewUnit = () => {
  const dispatch = useDispatch();

  const history = useHistory();

  const formRef = useRef(null);

  const [selectedState, setSelectedState] = useState({});

  const submit = useCallback(async (data) => {
    try {
      formRef.current.setErrors({});
      const schema = Yup.object().shape({
        name: Yup.string().min(3, 'Descrição mínimo 3 caracteres'),
        state: Yup.string().required('Estado é obrigatório'),
        city: Yup.string().required('Cidade é obrigatório'),
      });

      await schema.validate(data, { abortEarly: false });

      store(URL, data).then((response) => {
        history.push(`/${URL}/${response.id}`);
      });
    } catch (error) {
      const errorMessages = {};
      if (error instanceof Yup.ValidationError) {
        error.inner.forEach((err) => {
          errorMessages[err.path] = err.message;
        });
        formRef.current.setErrors(errorMessages);
      }
    }
  }, []);

  useEffect(() => {
    dispatch(
      setBreadcrumb([
        { page: 'unidades', url: '/units' },
        { page: 'novo', url: null },
      ])
    );
  }, [dispatch]);

  return (
    <Container className="container ">
      <div className="row mt-3 px-1 justify-content-center">
        <div className="col-12 text-center my-3">
          <h4>NOVA UNIDADE</h4>
        </div>
      </div>

      <div className="row justify-content-center mb-5">
        <Form
          ref={formRef}
          onSubmit={submit}
          noValidate
          className="col-12 col-lg-8 "
        >
          <GroupData>
            <div className="row justify-content-center">
              <div className="col-12 col-md-8 mt-5 px-1">
                <InputLabel name="name" label="nome" />
              </div>
            </div>

            <div className="row justify-content-center">
              <div className="col-12 col-md-4 mt-3 px-1">
                <InputLabel name="city" label="cidade" />
              </div>

              <div className="col-6 col-md-4 mt-3 px-1">
                <SelectLabel
                  name="state"
                  label="estado"
                  placeholder="selecione"
                  options={stateOptions}
                  value={selectedState}
                  onChange={(e) => setSelectedState(e)}
                />
              </div>
            </div>
          </GroupData>

          <div className="row justify-content-center mt-4">
            <div className="col-6 col-md-4 col-xl-3">
              <button
                className="btn btn-outline-secondary btn-block col-12"
                type="button"
                onClick={() => history.push('/units')}
              >
                cancelar
              </button>
            </div>
            <div className="col-6 col-md-4 col-xl-3">
              <button
                className="btn btn-primary btn-block col-12"
                type="submit"
              >
                salvar
              </button>
            </div>
          </div>
        </Form>
      </div>
    </Container>
  );
};

export default NewUnit;

export const Container = styled.div`
  height: 100vh;
`;
