/* eslint-disable prettier/prettier */
/* eslint-disable arrow-body-style */
import { useContext, useCallback } from 'react';

import PropTypes from 'prop-types';

import { AuthContext } from '../../../../../actions/auth-context';
import {} from '../../../../../actions/order.service';

const StartEditOrderButton = ({ order }) => {
  const { hasPermissions, hasRoles } = useContext(AuthContext);

  const hasViewButton = useCallback(() => {
    return (
      order.order_status === 'Financeiro aprovado' &&
      hasPermissions(['edit_order', 'edit_photos']) &&
      order.sig_number &&
      !hasRoles(['printer', 'fitter', 'packer', 'plotter'])
    );
  }, [order]);

  return (
    <>
      {hasViewButton() && (
        <div className="col-sm col-md-4 col-xl-3 mt-2">
          <button
            className="btn btn-success btn-block col-12"
            type="button"
            data-bs-toggle="modal"
            data-bs-target="#modal-get-edit-order"
          >
            editar pedido
          </button>
        </div>
      )}

      {!order.sig_number && order.order_status === 'Financeiro aprovado' && (
        <div className="alert alert-danger text-center mt-2" role="alert">
          <b>Atenção! </b> Impossível editar pedido sem o nº SIGI!
        </div>
      )}
    </>
  );
};

StartEditOrderButton.propTypes = {
  order: PropTypes.shape({
    id: PropTypes.string,
    order_status: PropTypes.string,
    sig_number: PropTypes.number,
  }).isRequired,
};

export default StartEditOrderButton;
