/* eslint-disable no-unused-vars */
import { useState, useCallback, useContext } from 'react';
import { FaFileDownload } from 'react-icons/fa';
import { IoIosTrash } from 'react-icons/io';
import ReactTooltip from 'react-tooltip';

import PropTypes from 'prop-types';
import styled from 'styled-components';

import { AuthContext } from '../../../../actions/auth-context';
import {
  deleteFileFromOrder,
  downloadSpcFileFromOrder,
} from '../../../../actions/order.service';
import DeleteModal from '../../../../components/delete-modal';

const FilesOrder = ({ order, setOrder }) => {
  const { setLoading, hasPermissions, hasRole } = useContext(AuthContext);

  const [itemDelete, setItemDelete] = useState({});

  const removeFileFromOrder = useCallback(async () => {
    setLoading(true);

    const response = await deleteFileFromOrder(order.id, itemDelete.id);

    if (!response.error) {
      response.delivery_date = new Date(response.delivery_date);

      if (response.payment_first_date) {
        response.payment_first_date = new Date(
          response.payment_first_date.replace(/-/g, ',')
        );
      }

      setOrder(response);
    }

    setLoading(false);
  }, [itemDelete, order]);

  const downloadFile = useCallback(
    async (item) => {
      setLoading(true);

      const response = await downloadSpcFileFromOrder(order.id, item.id);

      setLoading(false);

      if (!response.error) {
        const link = document.createElement('a');

        link.href = response;

        link.setAttribute('download', item.file_name);

        document.body.appendChild(link);

        link.click();

        link.parentNode.removeChild(link);
      }
    },
    [order]
  );

  const hasRemoveSpcFile = useCallback(() => {
    const has_remove = false;

    if (hasPermissions(['add_order']) && order.order_status === 'Em inclusão')
      return true;

    if (hasPermissions(['add_order']) && hasRole('master')) return true;

    if (hasPermissions(['add_order']) && hasRole('administrator')) return true;

    return has_remove;
  }, [order]);

  return (
    <>
      {hasPermissions(['financial_analysis', 'add_order']) && (
        <Container className="row justify-content-center mt-3">
          <div className="col-sm col-md-6">
            <div className="table-responsive">
              <table className="table text-center table-borderless table-striped">
                <thead>
                  <tr>
                    <th scope="col" className="text-start">
                      ARQUIVO
                    </th>
                    <th scope="col" className="text-end">
                      AÇÃO
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <>
                    {order.spcfiles?.map((item) => (
                      <tr key={item.id}>
                        <td className="text-start">{item.file_name}</td>
                        <td className="text-center ">
                          {hasRemoveSpcFile() && (
                            <IoIosTrash
                              size={25}
                              className="mb-1  action-remove"
                              data-tip="Remover arquivo"
                              onClick={() =>
                                setItemDelete({
                                  id: item.id,
                                  description: item.file_name,
                                })
                              }
                              data-bs-toggle="modal"
                              data-bs-target="#modal-remove-file"
                            />
                          )}

                          {hasPermissions([
                            'financial_analysis',
                            'add_order',
                            'edit_order',
                          ]) && (
                            <FaFileDownload
                              size={20}
                              className="mb-1 action-download"
                              data-tip="Baixar arquivo"
                              onClick={() => downloadFile(item)}
                            />
                          )}
                          <ReactTooltip />
                        </td>
                      </tr>
                    ))}

                    {order.spcfiles?.length === 0 && (
                      <tr>
                        <td colSpan="2">
                          <div
                            className="alert alert-danger text-center mt-2"
                            role="alert"
                          >
                            <b>Atenção!</b> Nenhum arquivo anexado a este
                            pedido!
                          </div>
                        </td>
                      </tr>
                    )}
                  </>
                </tbody>
              </table>
            </div>
          </div>

          <DeleteModal
            id_modal="modal-remove-file"
            item_delete={itemDelete}
            deleteItem={() => removeFileFromOrder()}
          />
        </Container>
      )}
    </>
  );
};

FilesOrder.propTypes = {
  order: PropTypes.shape({
    id: PropTypes.string,
    spcfiles: PropTypes.arrayOf(Object),
    order_status: PropTypes.string,
  }).isRequired,
  setOrder: PropTypes.func.isRequired,
};

export default FilesOrder;

export const Container = styled.div`
  .action-remove {
    color: #d11515;

    :hover {
      cursor: pointer;
      color: #ec4646;
    }
  }

  .action-download {
    color: #4f4f4f;

    :hover {
      cursor: pointer;
      color: #ffba00;
    }
  }
`;
