import { useCallback, useContext } from 'react';

import PropTypes from 'prop-types';

import { AuthContext } from '../../../../../actions/auth-context';
import {
  finishEditingTheOrder,
  getOrderToEdit,
} from '../../../../../actions/order.service';
import ActionModal from './ActionModal';

const EditModal = ({ order, setOrder }) => {
  const { setLoading } = useContext(AuthContext);

  const uploadOrder = useCallback((response) => {
    if (!response.error) {
      response.delivery_date = new Date(response.delivery_date);

      if (response.payment_first_date) {
        response.payment_first_date = new Date(
          response.payment_first_date.replace(/-/g, ',')
        );
      }

      setOrder(response);
    }
  }, []);

  const startEditOrder = useCallback(async (data) => {
    setLoading(true);
    const response = await getOrderToEdit(data.order_id, data);

    uploadOrder(response);

    setLoading(false);
  }, []);

  const finishEditingOrder = useCallback(async (data) => {
    setLoading(true);

    const response = await finishEditingTheOrder(data.order_id, data);

    uploadOrder(response);

    setLoading(false);
  }, []);

  return (
    <>
      <ActionModal
        modal_id="modal-get-edit-order"
        title="editar pedido?"
        action={startEditOrder}
        order={order}
      />

      <ActionModal
        modal_id="modal-finish-edit-order"
        title="Finalizar edição do pedido?"
        action={finishEditingOrder}
        order={order}
      />
    </>
  );
};

EditModal.propTypes = {
  order: PropTypes.shape({
    id: PropTypes.string,
    order_status: PropTypes.string,
  }).isRequired,
  setOrder: PropTypes.func.isRequired,
};

export default EditModal;
