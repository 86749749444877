import axios from 'axios';

const api = axios.create({
  baseURL: `${process.env.REACT_APP_API_BASE_URL}`,
});

api.interceptors.request.use((response) => {
  response.headers.Authorization = `Bearer ${JSON.parse(
    localStorage.getItem('vo_access_token')
  )}`;

  return response;
});

export default api;
