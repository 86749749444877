import { useCallback, useContext } from 'react';
import { VscInspect } from 'react-icons/vsc';
import { useHistory } from 'react-router';

import PropTypes from 'prop-types';

import { AuthContext } from '../../../../actions/auth-context';
import { startApproveEditionTheOrder } from '../../../../actions/order.service';

const ApproveEditionOrderButton = ({ order }) => {
  const { hasPermissions } = useContext(AuthContext);

  const history = useHistory();

  const getApproveEditionOrder = useCallback(async (data) => {
    const response = await startApproveEditionTheOrder(data.id, {
      action_note: null,
    });

    if (!response.error) {
      history.push(`/orders/${data.id}`);
    }
  }, []);

  return (
    <>
      {order.order_status === 'Editado' &&
        hasPermissions(['edit_inspection']) && (
          <VscInspect
            size={25}
            className="mb-1 action"
            data-tip="Analizar Edição do Pedido"
            onClick={() => getApproveEditionOrder(order)}
          />
        )}
    </>
  );
};

ApproveEditionOrderButton.propTypes = {
  order: PropTypes.shape({
    order_status: PropTypes.string,
  }).isRequired,
};

export default ApproveEditionOrderButton;
