/* eslint-disable react/require-default-props */
import { useCallback, useContext } from 'react';
import { TiPrinter } from 'react-icons/ti';
import ReactTooltip from 'react-tooltip';

import PropTypes from 'prop-types';

import { AuthContext } from '../../../../actions/auth-context';
import { finishPlotterTheOrder } from '../../../../actions/order.service';

const FinishPlotterOrderButton = ({ order, getOrders }) => {
  const { hasPermissions } = useContext(AuthContext);

  const finishPlotterOrder = useCallback(async (data) => {
    finishPlotterTheOrder(data.id, {
      action_note: null,
    }).then(() => {
      getOrders();
    });
  }, []);

  const hasViewButton = useCallback(
    () =>
      hasPermissions(['edit_order', 'plotter_photos']) &&
      order.order_status === 'Em plotter',
    [order]
  );

  return (
    <>
      {hasViewButton() && (
        <TiPrinter
          size={25}
          className="mb-1 action-finished"
          data-tip="Finalizar plotter"
          onClick={() => finishPlotterOrder(order)}
        />
      )}

      <ReactTooltip />
    </>
  );
};

FinishPlotterOrderButton.propTypes = {
  order: PropTypes.shape({
    id: PropTypes.string,
    order_status: PropTypes.string,
    products: PropTypes.arrayOf(Object),
  }).isRequired,
  getOrders: PropTypes.func,
};

export default FinishPlotterOrderButton;
