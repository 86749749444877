import React, {
  useRef,
  useCallback,
  useEffect,
  useState,
  useContext,
} from 'react';
import { MdClose } from 'react-icons/md';

import { Form } from '@unform/web';
import { Modal } from 'bootstrap';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import * as Yup from 'yup';

import { AuthContext } from '../../../../actions/auth-context';
import { index } from '../../../../actions/base-rest';
import { addProductToOrder } from '../../../../actions/order.service';
import {
  floatToCurrency,
  currencyToFloat,
} from '../../../../commons/currency-formatter';
import InputCurrencyLabel from '../../../../components/unform/input-currency-label';
import InputLabel from '../../../../components/unform/input-label';
import SelectLabel from '../../../../components/unform/select-label';

function AddProductModal({ order, setOrder }) {
  const { setLoading, hasPermissions } = useContext(AuthContext);

  const formRefModal = useRef(null);

  const modalRef = useRef(null);

  const [modal, setModal] = useState(null);

  const [selectedProduct, setSelectedProduct] = useState({});

  const [productsOptions, setProductOptions] = useState([]);

  const getProductsOptions = useCallback(async () => {
    const params = { current_page: 1, per_page: 100 };

    if (hasPermissions(['add_order'])) {
      const products = await index('products', params);

      if (products) {
        const options = await products.rows.map((item) => ({
          label: item.name,
          value: item.id,
          price: item.price,
        }));

        setProductOptions(options);
      }
    }
  }, []);

  const resetFormRefModal = useCallback(() => {
    formRefModal.current.reset();

    setSelectedProduct({});

    modal.hide();

    document.documentElement.style.overflow = 'auto';

    document.body.scroll = 'yes';
  }, [modal]);

  useEffect(() => {
    getProductsOptions();
  }, []);

  useEffect(() => {
    setModal(new Modal(modalRef.current));
  }, []);

  useEffect(() => {
    formRefModal.current.setFieldValue('discount', 0);
  }, [selectedProduct]);

  const submit = useCallback(
    async (data) => {
      if (selectedProduct) data.product_id = selectedProduct.value;

      if (data.discount) {
        data.discount = await currencyToFloat(data.discount);
      } else {
        data.discount = 0;
      }

      if (data.unit_price)
        data.unit_price = await currencyToFloat(data.unit_price);

      try {
        formRefModal.current.setErrors({});
        const schema = Yup.object().shape({
          product_id: Yup.string().required('Produto é obrigatório'),

          quantity: Yup.number().typeError('Valor inválido'),

          unit_price: Yup.number().typeError('Valor inválido'),

          discount: Yup.number().typeError('Valor inválido').nullable(),
        });

        await schema.validate(data, { abortEarly: false });

        setLoading(true);

        const response = await addProductToOrder(order.id, data);

        if (!response.error) {
          response.delivery_date = new Date(response.delivery_date);

          if (response.payment_first_date) {
            response.payment_first_date = new Date(
              response.payment_first_date.replace(/-/g, ',')
            );
          }

          setOrder(response);
        }

        resetFormRefModal();

        setLoading(false);
      } catch (error) {
        const errorMessages = {};
        if (error instanceof Yup.ValidationError) {
          error.inner.forEach((err) => {
            errorMessages[err.path] = err.message;
          });
          formRefModal.current.setErrors(errorMessages);
        }
      }
    },
    [order, selectedProduct]
  );

  return (
    <ModalContainer>
      <div
        className="modal"
        tabIndex="-1"
        id="modal-add-product"
        ref={modalRef}
        data-bs-backdrop="false"
      >
        <div className="modal-dialog  modal-dialog-centered">
          <div className="modal-content ">
            <div className="modal-header">
              <h5 className="modal-title col-11 text-center">
                ADICIONAR PRODUTO
              </h5>
              <MdClose
                id="close"
                size={30}
                data-bs-dismiss="modal"
                type="button"
                onClick={() => {}}
              />
            </div>
            <div className="bg-modal">
              <div className="modal-body">
                <Form ref={formRefModal} onSubmit={submit} noValidate>
                  <div className="col-12 mt-3">
                    <SelectLabel
                      name="product_id"
                      label="produto"
                      placeholder="selecione"
                      options={productsOptions}
                      value={selectedProduct}
                      onChange={(e) => {
                        setSelectedProduct(e);

                        formRefModal.current.setFieldValue(
                          'unit_price',
                          floatToCurrency(e.price)
                        );
                      }}
                    />
                  </div>

                  <div className="row">
                    <div className="col-sm col-md-4 mt-2">
                      <InputLabel name="unit_price" label="preço" disabled />
                    </div>

                    <div className="col-sm col-md-4 mt-2">
                      <InputLabel name="quantity" label="quantidade" />
                    </div>

                    <div className="col-sm col-md-4 mt-2">
                      <InputCurrencyLabel name="discount" label="desconto" />
                    </div>
                  </div>

                  <div className="row justify-content-center mt-4">
                    <button
                      type="button"
                      className="btn btn-secondary col-4 m-1"
                      onClick={() => resetFormRefModal()}
                    >
                      CANCELAR
                    </button>
                    <button type="submit" className="btn btn-primary col-4 m-1">
                      incluir
                    </button>
                  </div>
                </Form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ModalContainer>
  );
}

AddProductModal.propTypes = {
  order: PropTypes.shape({
    id: PropTypes.string,
  }).isRequired,
  setOrder: PropTypes.func.isRequired,
};

export default AddProductModal;

export const ModalContainer = styled.div`
  .bg-modal {
    background-color: #f5f5f5;
    margin: 7px;
  }

  .modal-header {
    border: none;
    background-color: #4f4f4f;
    color: #fff;
    justify-content: center !important;
  }

  .modal-body {
    text-transform: uppercase;
  }

  .modal-footer {
    justify-content: center !important;
    border: none;
  }

  svg {
    :hover {
      cursor: pointer;
      color: #ffba00;
    }
  }
`;
