/* eslint-disable react/no-array-index-key */
/* eslint-disable react/prop-types */
import { useEffect, useCallback, useState } from 'react';
import { BsChevronCompactDown } from 'react-icons/bs';

import {
  BarChart,
  Bar,
  XAxis,
  Tooltip,
  PieChart,
  Pie,
  LabelList,
} from 'recharts';
import styled from 'styled-components';
import 'animate.css';

import {
  getReworksByUsersApi,
  getReworksBySectorsApi,
  getReworksDetailsApi,
} from '../../../actions/dashbords.service';

const Reworks = ({ params }) => {
  const [dataUser, setDataUser] = useState({});

  const [dataSector, setDataSector] = useState({});

  const [dataDetail, setDataDetail] = useState({});

  const [openDetail, setOpenDetail] = useState(false);

  const CustomTooltipUser = ({ active, payload }) => {
    if (!active) return null;

    for (const bar of payload) {
      if (bar.dataKey === 'quantity') bar.name = 'Quantidade';

      return (
        <TooltipReworkUser>
          <span>{bar.payload.full_name}</span>

          <div className="row justify-content-center">
            <b>{bar.value}</b>
            <p>Quantidade</p>
          </div>
        </TooltipReworkUser>
      );
    }

    return null;
  };

  const getReworksSector = useCallback(async (filter) => {
    const result = await getReworksBySectorsApi(filter);

    setDataSector(result);
  }, []);

  const getReworksUser = useCallback(async (filter) => {
    const result = await getReworksByUsersApi(filter);

    setDataUser(result);
  }, []);

  const getReworksDetail = useCallback(async (filter) => {
    const result = await getReworksDetailsApi(filter);

    setDataDetail(result);
  }, []);

  useEffect(() => {
    getReworksUser(params);
    getReworksSector(params);
    getReworksDetail(params);
  }, [params]);

  return (
    <Container className="row">
      {dataUser && (
        <div className="col-sm col-md-6">
          <ContainerReport>
            <h5 className="animate__animated animate__pulse text-center">
              RETRABALHO POR USUÁRIO
            </h5>

            <Report>
              <div className="report">
                <BarChart
                  width={550}
                  height={300}
                  data={dataUser}
                  margin={{
                    top: 5,
                    right: 30,
                    left: 20,
                    bottom: 5,
                  }}
                >
                  <XAxis dataKey="username" />
                  <Tooltip content={<CustomTooltipUser />} />
                  <Bar dataKey="quantity" fill="#D47129">
                    <LabelList dataKey="quantity" a />
                  </Bar>
                </BarChart>
              </div>
            </Report>
          </ContainerReport>
        </div>
      )}

      {dataSector && (
        <div className="col-sm col-md-6">
          <ContainerReport>
            <h5 className="animate__animated animate__pulse text-center">
              RETRABALHO POR SETOR
            </h5>

            <Report>
              <div className="report row justify-content-center">
                <PieChart width={420} height={318}>
                  <Pie
                    dataKey="quantity"
                    isAnimationActive={false}
                    data={dataSector}
                    cx="50%"
                    cy="50%"
                    innerRadius={60}
                    outerRadius={100}
                    fill="#8884d8"
                    label={({
                      cx,
                      cy,
                      midAngle,
                      innerRadius,
                      outerRadius,
                      value,
                      index,
                    }) => {
                      const RADIAN = Math.PI / 180;
                      const radius =
                        25 + innerRadius + (outerRadius - innerRadius);
                      const x = cx + radius * Math.cos(-midAngle * RADIAN);
                      const y = cy + radius * Math.sin(-midAngle * RADIAN);

                      return (
                        <text
                          x={x}
                          y={y}
                          fill="#8884d8"
                          textAnchor={x > cx ? 'start' : 'end'}
                          dominantBaseline="central"
                        >
                          {dataSector[index].sector} ({value})
                        </text>
                      );
                    }}
                  />
                </PieChart>
              </div>
            </Report>
          </ContainerReport>
        </div>
      )}

      {!openDetail && (
        <div
          className="detalhes"
          role="button"
          tabIndex="0"
          onKeyPress={() => {}}
          onClick={() => setOpenDetail(true)}
        >
          <span>Relatório detalhado</span>

          <div>
            <BsChevronCompactDown />
          </div>
        </div>
      )}

      <>
        {openDetail && (
          <Detail>
            <div className="row justify-content-end">
              <button
                type="button"
                className="btn btn-no-border-primary"
                onClick={() => setOpenDetail(false)}
              >
                fechar relatório
              </button>
            </div>

            <div className="table-responsive">
              <table className="table table-striped">
                <thead>
                  <tr className="bg-dark">
                    <th scope="col" colSpan={2} className="text-center">
                      ORIGEM
                    </th>
                    <th scope="col" colSpan={2} className="text-center">
                      DESTINO
                    </th>
                  </tr>

                  <tr>
                    <th scope="col" className="bg-secondary">
                      SETOR
                    </th>
                    <th scope="col" className="bg-secondary">
                      USUÁRIO
                    </th>
                    <th scope="col" className="bg-secondary">
                      SETOR
                    </th>
                    <th scope="col" className="bg-secondary">
                      USUÁRIO
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {dataDetail?.map((item) => (
                    <tr key={item.id}>
                      <td className="name">
                        <b>{item.of_sector}</b>
                      </td>

                      <td className="name">
                        <b>{item.of_full_name}</b>
                      </td>

                      <td className="name">
                        <b>{item.to_sector}</b>
                      </td>

                      <td className="name">
                        <b>{item.to_full_name}</b>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </Detail>
        )}
      </>
    </Container>
  );
};

export default Reworks;

export const Container = styled.div`
  .detalhes {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    font-size: 11px;

    :hover {
      color: #fab702;
      cursor: pointer;

      svg {
        animation: animate__pulse;
        animation-duration: 2s;
      }
    }
  }
`;

export const ContainerReport = styled.div`
  text-align: center;
  color: #7d7d7d;
  margin-top: 20px;

  .detalhes {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    font-size: 11px;

    :hover {
      color: #fab702;
      cursor: pointer;

      svg {
        animation: animate__pulse;
        animation-duration: 2s;
      }
    }
  }
`;

export const Report = styled.div`
  text-align: center;
  overflow: auto;
  background: #f5f5f5;

  .report {
    width: 100%;
    text-align: center;
  }

  .recharts-xAxis {
    transform: translate(0, 0);
  }
`;

export const Detail = styled.div`
  margin-top: 10px;

  .btn-no-border-primary {
    width: 200px;
  }

  .bg-secondary {
    background-color: #464646 !important;
    color: #fff !important;
  }

  .bg-dark {
    background-color: #fab702 !important;

    th {
      color: #fff !important;
    }
  }

  .table {
    .name {
      color: #7d7d7d !important;
    }
  }
`;

export const TooltipReworkUser = styled.div`
  background-color: #fff;
  border-radius: 3px;
  border: 1px solid #7d7d7d;
  padding: 5px;
  display: flex;
  justify-content: center;
  flex-direction: column;

  span {
    font-weight: bold;
    font-size: 14px;
    color: #fab702;
  }

  p {
    font-size: 10px;
    margin-top: -5px;
  }

  b {
    font-size: 18px;
  }
`;
