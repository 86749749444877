/* eslint-disable react/require-default-props */
import { useCallback, useContext } from 'react';
import { FaBox } from 'react-icons/fa';

import PropTypes from 'prop-types';

import { AuthContext } from '../../../../actions/auth-context';
import { startPackingTheOrder } from '../../../../actions/order.service';

const StartPackOrderButton = ({ order, getOrders }) => {
  const { hasPermissions } = useContext(AuthContext);

  const getPackingOrder = useCallback(async (data) => {
    startPackingTheOrder(data.id, {
      action_note: null,
    }).then(() => {
      getOrders();
    });
  }, []);

  return (
    <>
      {order.order_status === 'Montado' &&
        hasPermissions(['edit_order', 'pack_order']) && (
          <FaBox
            size={19}
            className="mb-1 action"
            data-tip="Embalar Pedido"
            onClick={() => getPackingOrder(order)}
          />
        )}
    </>
  );
};

StartPackOrderButton.propTypes = {
  order: PropTypes.shape({
    order_status: PropTypes.string,
  }).isRequired,
  getOrders: PropTypes.func,
};

export default StartPackOrderButton;
