import React, {
  useRef,
  useCallback,
  useContext,
  useState,
  useEffect,
} from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';

import { Form } from '@unform/web';
import styled from 'styled-components';
import * as Yup from 'yup';

import { AuthContext } from '../../actions/auth-context';
import Input from '../../components/unform/input';

const Login = () => {
  const formRef = useRef(null);
  const [error, setError] = useState({});
  const [viewPage, setViewPage] = useState(false);
  const history = useHistory();
  const location = useLocation();

  const { signIn, isAuthenticated } = useContext(AuthContext);

  const submit = useCallback(async (data) => {
    data.username = data.username.toLowerCase();

    try {
      formRef.current.setErrors({});
      const schema = Yup.object().shape({
        username: Yup.string()
          .email('Digite um e-mail válido')
          .required('E-mail é obrigatório'),
        password: Yup.string().required('Senha é obrigatório'),
      });

      await schema.validate(data, { abortEarly: false });

      const response = await signIn(data);

      if (response.error) {
        setError(response.error);
      } else {
        history.push('/');
      }
    } catch (erro) {
      const errorMessages = {};
      if (erro instanceof Yup.ValidationError) {
        erro.inner.forEach((err) => {
          errorMessages[err.path] = err.message;
        });
        formRef.current.setErrors(errorMessages);
      }
    }
  });

  useEffect(() => {
    if (isAuthenticated) {
      const { from } = location.state || { from: { pathname: '/' } };
      history.replace(from);
    }
  }, [isAuthenticated]);

  useEffect(() => {
    if (!viewPage) {
      setTimeout(() => {
        setViewPage(true);
      }, 300);
    }

    return () => {
      setViewPage(false);
    };
  }, []);

  return (
    <Container>
      {viewPage && (
        <>
          <div className="form col col-md-6 col-lg-4 text-center p-4 m-2">
            <h1>Login</h1>

            <Form ref={formRef} onSubmit={submit} noValidate>
              <div className="mt-5">
                <Input name="username" placeholder="e-mail" />
              </div>

              <div className="mt-3">
                <Input name="password" placeholder="senha" type="password" />
              </div>

              {error.message && (
                <div className="mt-1">
                  <div
                    className="alert alert-warning alert-dismissible fade show"
                    role="alert"
                  >
                    <div>
                      <strong>{error.title}</strong>{' '}
                    </div>
                    {error.message}
                    <button
                      type="button"
                      className="btn-close"
                      data-bs-dismiss="alert"
                      aria-label="Close"
                    />
                  </div>
                </div>
              )}

              <ForgotPassword>
                <Link to="/forgot-password">Esqueci a senha</Link>
              </ForgotPassword>

              <button
                className="btn btn-secondary btn-block col-12 mt-5"
                type="submit"
              >
                enviar
              </button>
            </Form>
          </div>
        </>
      )}
    </Container>
  );
};

export default Login;

export const Container = styled.div`
  margin-top: -80px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 100vh;

  h1 {
    color: #464646;
    text-transform: uppercase;
  }

  .form {
    background-color: #ffcf4c;
  }
`;
export const ForgotPassword = styled.div`
  display: flex;
  flex-direction: column;
  text-align: right;
  font-weight: 500;
  font-size: 14px;
  margin-top: 20px;
  text-transform: uppercase;
  a {
    color: #464646;
    text-decoration: none;

    :hover {
      color: #6b6b6b;
      cursor: pointer;
    }
  }
`;
