import React, { useCallback, useEffect, useState } from 'react';
import { IoIosSearch } from 'react-icons/io';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';

import styled from 'styled-components';

import { index, remove } from '../../../actions/base-rest';
import DeleteModal from '../../../components/delete-modal';
import { setBreadcrumb } from '../../../reducers/breadcrumb';
import ProductsFilter from './ProductsFilter';
import ProductsTable from './ProductsTable';

const URL = 'products';

const default_params = {
  current_page: 1,
  per_page: 10,
  sorts: 'name:ASC',
};

const Products = () => {
  const dispatch = useDispatch();
  const [page, setPage] = useState({});
  const [products, setProducts] = useState([]);
  const [isFilter, setIsFilter] = useState(false);
  const [itemDelete, setItemDelete] = useState({});
  const [params, setParams] = useState(default_params);
  const [loading, setLoading] = useState(false);
  const [scrolling, setScrolling] = useState(false);

  const getProducts = useCallback(
    async (filter) => {
      setLoading(true);

      const response = await index(URL, filter);

      if (scrolling) {
        const new_products = (data) => [...data, ...response.rows];
        setProducts(new_products);
        setScrolling(false);
      } else {
        setProducts(response.rows);
      }

      setLoading(false);
      setPage(response.page);
    },
    [scrolling]
  );

  const deleteItem = useCallback(async () => {
    const response = await remove(URL, itemDelete.id);

    if (response) {
      setProducts([]);
      getProducts();
    }
  }, [itemDelete]);

  useEffect(() => {
    getProducts(params);
  }, [params]);

  useEffect(() => {
    dispatch(setBreadcrumb([{ page: 'produtos', url: null }]));
  }, []);

  return (
    <Container className="container">
      <div className="row mt-3 justify-content-center">
        <div className="col-12 text-center my-3">
          <h4>PRODUTOS</h4>
        </div>

        {!isFilter && (
          <div className="col-6 col-md-4">
            <Link to={`/${URL}/new`}>
              <button
                type="button"
                className="btn btn-primary col-12 btn-product"
              >
                novo produto
              </button>
            </Link>
          </div>
        )}
        <div className={isFilter ? 'col-12 col-md-10' : 'col-6 col-md-4'}>
          <button
            type="button"
            className="btn btn-secondary col-12 btn-product"
            onClick={() => setIsFilter(true)}
          >
            <IoIosSearch size={25} className="mb-1 icon-button" />
            localizar
          </button>

          <ProductsFilter
            isFilter={isFilter}
            setIsFilter={setIsFilter}
            setParams={setParams}
            default_params={default_params}
          />
        </div>
      </div>

      <ProductsTable
        products={products}
        page={page}
        setItemDelete={setItemDelete}
        setParams={setParams}
        params={params}
        loading={loading}
        setScrolling={setScrolling}
      />

      <DeleteModal
        id_modal="modal-product"
        item_delete={itemDelete}
        deleteItem={() => deleteItem()}
      />
    </Container>
  );
};

export default Products;

export const Container = styled.div`
  .btn-product {
    height: 45px;
  }

  @media screen and (max-width: 360px) {
    .btn-product {
      height: 55px;
    }

    .icon-button {
      display: none;
    }
  }
`;
