import { useContext } from 'react';

import PropTypes from 'prop-types';

import { AuthContext } from '../../../../../actions/auth-context';

const ReopenOrderButton = ({ order }) => {
  const { hasPermissions } = useContext(AuthContext);

  return (
    <>
      {order.order_status === 'Arquivado' && hasPermissions(['edit_order']) && (
        <div className="col-6 col-md-2 mt-2">
          <button
            className="btn btn-success btn-block col-12"
            type="button"
            data-bs-toggle="modal"
            data-bs-target="#modal-reopen-order"
          >
            reabrir
          </button>
        </div>
      )}
    </>
  );
};

ReopenOrderButton.propTypes = {
  order: PropTypes.shape({
    id: PropTypes.string,
    order_status: PropTypes.string,
  }).isRequired,
};

export default ReopenOrderButton;
