import { useContext } from 'react';
import { AiOutlineUser, AiOutlineLock, AiOutlineLogout } from 'react-icons/ai';
import { FaUserCircle } from 'react-icons/fa';
import { Link } from 'react-router-dom';

import styled from 'styled-components';

import { AuthContext } from '../../actions/auth-context';

const Profile = () => {
  const { user, signOut, handleChangingPassword, thumbnailProfile } =
    useContext(AuthContext);

  return (
    <>
      {user?.id && (
        <Container>
          {thumbnailProfile && (
            <div className="profile">
              <img src={thumbnailProfile} alt="Profile thumbnail" />
            </div>
          )}

          {!thumbnailProfile && <FaUserCircle className="user" size={50} />}
          {/* <FaUserCircle className="user" size={50} /> */}

          <DropDownUser className="dropdown-content">
            <div className="col p-3">
              {user?.full_name} <span>({user.roles[0].name})</span>
            </div>

            <Link to="/profile-me">
              <AiOutlineUser size={25} /> meus dados
            </Link>

            <Link
              to="/"
              data-bs-toggle="modal"
              data-bs-target="#modal-confirm-change-password"
              onClick={() => handleChangingPassword(true)}
            >
              <AiOutlineLock size={25} /> alterar senha
            </Link>

            <Link to="/" onClick={signOut}>
              <AiOutlineLogout size={25} /> sair
            </Link>
          </DropDownUser>
        </Container>
      )}
    </>
  );
};

export default Profile;

export const Container = styled.div`
  margin-left: -100px;
  background: #fff;
  border-radius: 50%;
  display: none;
  text-transform: uppercase;

  :hover {
    cursor: pointer;
    .user {
      background: #fab702 !important;
      border-radius: 50%;
    }

    .dropdown-content {
      display: block;
    }
  }

  img {
    border: 2px solid #fff;
    border-radius: 50%;
    width: 55px;

    :hover {
      border-color: #fab702;
    }
  }

  @media screen and (min-width: 769px) {
    display: block;
  }
`;
export const DropDownUser = styled.div`
  display: none;
  z-index: 5 !important;
  position: absolute;
  background-color: #464646;
  min-width: 280px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  margin-left: -180px;
  color: #fff;

  span {
    font-size: 12px;
  }

  svg {
    margin-right: 5px;
  }

  a {
    color: #fff;
    font-size: 14px;
    display: flex;
    align-items: center;
    padding: 0 0 0 15px;
    text-decoration: none;
    text-transform: uppercase;
    height: 50px;

    :hover {
      color: #fab702;
      background-color: #757575;
    }
  }
`;
