import { FaUserCircle } from 'react-icons/fa';
import { IoIosTrash } from 'react-icons/io';
import ReactTooltip from 'react-tooltip';

import PropTypes from 'prop-types';
import styled from 'styled-components';

const SellersTable = ({ data, setItemDelete }) => (
  <Container>
    {data.length > 0 && (
      <div className="row mt-3 justify-content-center">
        <div className="col-12 col-md-8">
          <table className="table table-hover text-center">
            <thead>
              <tr>
                <th scope="col" className="text-start">
                  VENDEDOR
                </th>
                <th scope="col" className="text-end">
                  AÇÃO
                </th>
              </tr>
            </thead>

            <tbody>
              {data.map((item) => (
                <tr key={item.id}>
                  <td className="text-start">
                    <FaUserCircle size={25} />
                    {item.full_name}
                  </td>

                  <td className="text-end action">
                    <IoIosTrash
                      size={25}
                      className="mb-1"
                      data-tip="Excluir"
                      onClick={() =>
                        setItemDelete({
                          id: item.id,
                          description: item.full_name,
                        })
                      }
                      data-bs-toggle="modal"
                      data-bs-target="#modal-remove-seller"
                    />
                    <ReactTooltip />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    )}
  </Container>
);

SellersTable.propTypes = {
  data: PropTypes.arrayOf(Object).isRequired,
  setItemDelete: PropTypes.func.isRequired,
};

export default SellersTable;

export const Container = styled.section`
  text-transform: uppercase;

  h6 {
    font-size: 12px;
  }

  img {
    border-radius: 50%;
    border: 1px solid #cecece;
    padding: 2px;
    width: 40px;
    height: 40px;
    object-fit: cover;
    margin-right: 20px;
  }

  svg {
    margin-right: 10px;
    margin-bottom: 4px;
  }

  .table-hover {
    cursor: pointer;
  }

  .action {
    color: #d11515;

    :hover {
      cursor: pointer;
      color: #ec4646;
    }
  }

  @media screen and (max-width: 768px) {
    .table {
      width: 900px !important;
    }
  }
`;
