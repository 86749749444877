import { useContext, useCallback } from 'react';

import PropTypes from 'prop-types';

import { AuthContext } from '../../../../../actions/auth-context';
import {} from '../../../../../actions/order.service';

const FinishEditOrderButton = ({ order }) => {
  const { hasPermissions } = useContext(AuthContext);

  const validPhotos =
    order.photos.filter((item) => item.is_cover_photo === true).length > 0;

  const viewButton = useCallback(() => {
    if (order.order_status === 'Em edição' && order.photos.length > 0) {
      if (
        hasPermissions(['edit_photos']) &&
        validPhotos
        // &&
        // !hasRoles(['printer', 'fitter', 'packer', 'plotter'])
      )
        return true;

      // if (hasRoles(['master', 'administrator'])) return true;
    }

    return false;
  }, [order]);

  return (
    <>
      {viewButton() && (
        <div className="col-sm col-md-4 col-xl-3 mt-2">
          <button
            className="btn btn-success btn-block col-12"
            type="button"
            data-bs-toggle="modal"
            data-bs-target="#modal-finish-edit-order"
          >
            finalizar edição
          </button>
        </div>
      )}
    </>
  );
};

FinishEditOrderButton.propTypes = {
  order: PropTypes.shape({
    id: PropTypes.string,
    order_status: PropTypes.string,
    photos: PropTypes.arrayOf(Object),
  }).isRequired,
};

export default FinishEditOrderButton;
