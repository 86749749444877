/* eslint-disable react/require-default-props */
import { useCallback, useContext } from 'react';
import { FaBox } from 'react-icons/fa';

import PropTypes from 'prop-types';

import { AuthContext } from '../../../../actions/auth-context';
import { finishPackingTheOrder } from '../../../../actions/order.service';

const FinishPackOrderButton = ({ order, getOrders }) => {
  const { hasPermissions } = useContext(AuthContext);

  const finishPackingOrder = useCallback(async (data) => {
    finishPackingTheOrder(data.id, {
      action_note: null,
    }).then(() => {
      getOrders();
    });
  }, []);

  return (
    <>
      {order.order_status === 'Em embalagem' &&
        hasPermissions(['edit_order', 'pack_order']) && (
          <FaBox
            size={19}
            className="mb-1 action-finished"
            data-tip="Finalizar embalagem"
            onClick={() => finishPackingOrder(order)}
          />
        )}
    </>
  );
};

FinishPackOrderButton.propTypes = {
  order: PropTypes.shape({
    order_status: PropTypes.string,
  }).isRequired,
  getOrders: PropTypes.func,
};

export default FinishPackOrderButton;
