import React, { useContext, useCallback, useState, useEffect } from 'react';
import { MdClose } from 'react-icons/md';

import styled from 'styled-components';

import { AuthContext } from '../../actions/auth-context';
import { forgotPassword } from '../../actions/authenticate';
import { toastSuccess } from '../../actions/toast';

const ConfirmChangePassword = () => {
  const { user, signOut, handleChangingPassword, changingPassword } =
    useContext(AuthContext);
  const [emailSent, setEmailSent] = useState(false);

  const submit = useCallback(async () => {
    const response = await forgotPassword(user.email);
    if (!response.error) {
      toastSuccess(
        'Sucesso!',
        'As informações para troca de senha foram enviadas para seu e-mail.'
      );
      setEmailSent(true);
      handleChangingPassword(false);
    }
  }, [user]);

  useEffect(() => {
    if (changingPassword) {
      setEmailSent(false);
    }
  }, [changingPassword]);

  return (
    <Modal>
      <div className="modal" tabIndex="-1" id="modal-confirm-change-password">
        {!emailSent && (
          <div className="modal-dialog">
            <div className="modal-content text-center">
              <div className="modal-header">
                <h5 className="modal-title col-11">ALTERAR SENHA</h5>
                <MdClose size={30} data-bs-dismiss="modal" type="button" />
              </div>
              <div className="bg-modal">
                <div className="modal-body">
                  <p>
                    <b>{user?.full_name}</b> Deseja alterar sua senha?
                  </p>
                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-secondary col-4"
                    data-bs-dismiss="modal"
                  >
                    {' '}
                    NÃO{' '}
                  </button>
                  <button
                    type="button"
                    className="btn btn-primary col-4"
                    onClick={() => submit()}
                  >
                    SIM
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}

        {emailSent && (
          <div className="modal-dialog success">
            <div className="modal-content text-center">
              <div className="modal-header">
                <h5 className="modal-title col-11">SOLICITAÇÃO REALIZADA</h5>
                <MdClose size={30} data-bs-dismiss="modal" type="button" />
              </div>
              <div className="bg-modal">
                <div className="modal-body">
                  <p>
                    As informações para alterar sua senha foram enviadas em seu
                    e-mail.
                  </p>

                  <p>
                    Faça o <strong>LOGOFF</strong> e confira seu e-mail!
                  </p>
                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-secondary col-6"
                    data-bs-dismiss="modal"
                    onClick={() => {
                      signOut();
                      setEmailSent(false);
                      handleChangingPassword(false);
                    }}
                  >
                    FAZER O LOGOFF
                  </button>

                  <ResetAgain
                    className="col-12 mt-3"
                    data-bs-dismiss="modal"
                    onClick={() => {
                      signOut();
                      setEmailSent(false);
                    }}
                  >
                    <p>Não recebeu o e-mail?</p>
                    <span>enviar novamente</span>
                  </ResetAgain>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </Modal>
  );
};

export default ConfirmChangePassword;

export const Modal = styled.div`
  .bg-modal {
    background-color: #f5f5f5;
    margin: 7px;
  }

  .modal-header {
    border: none;
    background-color: #4f4f4f;
    color: #fff;
    justify-content: center !important;
  }

  .modal-body {
    text-transform: uppercase;
  }

  .modal-footer {
    justify-content: center !important;
    border: none;
  }

  svg {
    :hover {
      cursor: pointer;
      color: #ffba00;
    }
  }

  .success {
    .bg-modal {
      /* background-color: #ffba00 !important; */
    }

    .btn-secondary {
      height: 50px;
    }
  }
`;

export const ResetAgain = styled.div`
  p {
    line-height: 1;
    margin: 0;
    padding: 0;
    color: #6b6b6b;
    font-size: 11px;
  }

  span {
    text-transform: uppercase;

    :hover {
      cursor: pointer;
      color: #ffba00;
    }
  }
`;
