import { useContext } from 'react';

import PropTypes from 'prop-types';
import styled from 'styled-components';

import { AuthContext } from '../../../../actions/auth-context';
import { floatToCurrency } from '../../../../commons/currency-formatter';

const ResumeOrder = ({ order }) => {
  const { hasPermissions } = useContext(AuthContext);

  return (
    <>
      {hasPermissions(['add_order', 'financial_analysis']) && (
        <Container className="container mt-5">
          <div className="row justify-content-end">
            <div className="col-sm col-md-9 col-xl-6">
              <div className="table-responsive">
                <table className="table text-center table-borderless table-striped">
                  <tbody>
                    <tr>
                      <td className="text-start p-0">TOTAL PRODUTOS</td>
                      <td className="text-end p-1">
                        {floatToCurrency(order?.total_order)}
                      </td>
                    </tr>

                    <tr>
                      <td className="text-start p-0">
                        TOTAL DESCONTO PRODUTOS
                      </td>
                      <td className="text-end p-1">
                        {floatToCurrency(order?.products_discount)}
                      </td>
                    </tr>

                    <tr>
                      <td className="text-start p-0">TOTAL DESCONTO PEDIDO</td>
                      <td className="text-end p-1">
                        {floatToCurrency(order?.discount)}
                      </td>
                    </tr>

                    <tr>
                      <td className="text-start p-0">TOTAL DESCONTOS</td>
                      <td className="text-end p-1">
                        {floatToCurrency(order?.total_discount)}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>

          <div className="row justify-content-end mb-5">
            <div className="col-sm col-md-2 text-end">
              <p>TOTAL A PAGAR</p>
              <h4>{floatToCurrency(order?.total_discounted)}</h4>
            </div>
          </div>
        </Container>
      )}
    </>
  );
};

ResumeOrder.propTypes = {
  order: PropTypes.shape({
    total_order: PropTypes.number,
    products_discount: PropTypes.number,
    discount: PropTypes.number,
    total_discounted: PropTypes.number,
    total_discount: PropTypes.number,
  }).isRequired,
};

export default ResumeOrder;

export const Container = styled.div`
  text-transform: uppercase;

  .table {
    font-size: 12px;
  }

  p {
    font-weight: bold;
    font-size: 13px;
    line-height: 1px;
    margin-top: 20px;
  }

  h4 {
    color: #4f4f4f;
    font-weight: bold;
  }
`;
