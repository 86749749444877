/* eslint-disable no-unused-vars */
import { useCallback, useState, useContext } from 'react';
import { IoIosTrash } from 'react-icons/io';
import ReactTooltip from 'react-tooltip';

import PropTypes from 'prop-types';
import styled from 'styled-components';

import { AuthContext } from '../../../../actions/auth-context';
import { deleteProductFromOrder } from '../../../../actions/order.service';
import { floatToCurrency } from '../../../../commons/currency-formatter';
import DeleteModal from '../../../../components/delete-modal';

const ProductsTable = ({ order, products, setOrder }) => {
  const { hasPermissions, hasRole } = useContext(AuthContext);

  const [productDelete, setProductDelete] = useState({});

  const removeProductFromOrder = useCallback(async () => {
    const response = await deleteProductFromOrder(order.id, productDelete.id);

    if (!response.error) {
      response.delivery_date = new Date(response.delivery_date);

      if (response.payment_first_date) {
        response.payment_first_date = new Date(
          response.payment_first_date.replace(/-/g, ',')
        );
      }

      setOrder(response);
    }
  }, [order, productDelete]);

  const hasRemoveProduct = useCallback(() => {
    let has_remove = false;

    if (hasPermissions(['add_order']) && order.order_status === 'Em inclusão')
      has_remove = true;

    if (hasPermissions(['add_order']) && hasRole('master')) has_remove = true;

    if (hasPermissions(['add_order']) && hasRole('administrator'))
      has_remove = true;

    return has_remove;
  }, [order]);

  return (
    <Container>
      <div className="row text-center bg-dark mt-3">
        <h5 className="mt-2">PRODUTOS</h5>
      </div>

      <div className="container mt-2">
        <div className="row">
          <div className="table-responsive">
            <table className="table table-hover text-center">
              <thead>
                <tr>
                  <th scope="col" className="text-start p-1">
                    #
                  </th>

                  <th scope="col" className="text-start p-1">
                    DESCRIÇÃO
                  </th>

                  <th scope="col" className="text-start p-1">
                    QTDE
                  </th>

                  {hasPermissions(['add_order', 'financial_analysis']) && (
                    <>
                      <th scope="col" className="text-start p-1">
                        VALOR
                      </th>

                      <th scope="col" className="text-start p-1">
                        DESCONTO
                      </th>

                      <th
                        scope="col"
                        className={
                          order.order_status === 'Em inclusão'
                            ? `text-start p-1`
                            : `text-end p-1`
                        }
                      >
                        TOTAL
                      </th>
                    </>
                  )}

                  {hasRemoveProduct() && (
                    <th scope="col" className="text-end p-0">
                      AÇÃO
                    </th>
                  )}
                </tr>
              </thead>

              <tbody>
                {products?.map((item, index) => (
                  <tr key={item.id}>
                    <td className="text-start p-1">{index + 1}</td>

                    <td className="text-start p-1">{item.product_name}</td>

                    <td className="text-start p-1">{item.quantity}</td>

                    {hasPermissions(['add_order', 'financial_analysis']) && (
                      <>
                        <td className="text-start p-1">
                          {floatToCurrency(item.unit_price)}
                        </td>

                        <td className="text-start p-1">
                          {floatToCurrency(item.discount)}
                        </td>

                        <td
                          className={
                            order.order_status === 'Em inclusão'
                              ? `text-start p-1`
                              : `text-end p-1`
                          }
                        >
                          {floatToCurrency(item.total_discounted)}
                        </td>
                      </>
                    )}

                    {hasRemoveProduct() && (
                      <td className="text-end action p-1">
                        <IoIosTrash
                          size={20}
                          className="mb-1"
                          data-tip="Excluir"
                          onClick={() =>
                            setProductDelete({
                              description: item.product_name,
                              id: item.product_id,
                            })
                          }
                          data-bs-toggle="modal"
                          data-bs-target="#modal-remove-product"
                        />
                        <ReactTooltip />
                      </td>
                    )}
                  </tr>
                ))}

                {products?.length === 0 && (
                  <tr className="alert alert-danger text-center mt-2">
                    <td colSpan="7">
                      <b>Atenção!</b> Nenhum produto adicionado a este pedido!
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>

        {hasPermissions(['add_order', 'financial_analysis']) &&
          order.order_status === 'Em inclusão' && (
            <div className="row mt-2 justify-content-end">
              <div className="col-sm col-md-4 col-xl-3 mt-2">
                <button
                  type="button"
                  className="btn btn-primary btn-block col-12"
                  data-bs-toggle="modal"
                  data-bs-target="#modal-add-product"
                >
                  adicionar produto
                </button>
              </div>

              <div className="col-sm col-md-5 col-xl-3 mt-2">
                <button
                  type="button"
                  className="btn btn-secondary btn-block col-12"
                  data-bs-toggle="modal"
                  data-bs-target="#modal-apply-discount"
                >
                  adicionar desconto
                </button>
              </div>
            </div>
          )}
      </div>

      <DeleteModal
        id_modal="modal-remove-product"
        item_delete={productDelete}
        deleteItem={() => removeProductFromOrder()}
      />
    </Container>
  );
};

ProductsTable.propTypes = {
  products: PropTypes.arrayOf(Object).isRequired,
  order: PropTypes.shape({
    id: PropTypes.string,
    order_status: PropTypes.string,
  }).isRequired,
  setOrder: PropTypes.func.isRequired,
};

export default ProductsTable;

export const Container = styled.section`
  text-transform: uppercase;

  h6 {
    font-size: 12px;
  }

  .bg-dark {
    height: 40px;
    background-color: #464646 !important;

    h5 {
      color: white;
    }
  }

  .table {
    font-size: 13px;
  }

  .table-hover {
    cursor: pointer;
  }

  .action {
    color: #d11515;

    :hover {
      cursor: pointer;
      color: #ec4646;
    }
  }

  @media screen and (max-width: 768px) {
    .table {
      width: 900px !important;
    }
  }
`;
