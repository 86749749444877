import { useCallback, useContext } from 'react';

import PropTypes from 'prop-types';

import { AuthContext } from '../../../../../actions/auth-context';
import {
  sendOrderToFinanceAnalysis,
  getOrderToFinanceAnalysis,
  orderFinancialApproved,
  orderFinancialDisapproved,
  shelveOrderById,
  reopenOrderById,
} from '../../../../../actions/order.service';
import ActionModal from './ActionModal';

const FinancialAnalysisModal = ({ order, setOrder }) => {
  const { setLoading } = useContext(AuthContext);

  const uploadOrder = useCallback((response) => {
    if (response && !response.error) {
      response.delivery_date = new Date(response.delivery_date);

      if (response.payment_first_date) {
        response.payment_first_date = new Date(
          response.payment_first_date.replace(/-/g, ',')
        );
      }

      setOrder(response);
    }
  }, []);

  const sendFinanceAnalysis = useCallback(async (data) => {
    setLoading(true);

    const response = await sendOrderToFinanceAnalysis(data.order_id, data);

    uploadOrder(response);

    setLoading(false);
  }, []);

  const startFinancialAnalysis = useCallback(async (data) => {
    setLoading(true);

    const response = await getOrderToFinanceAnalysis(data.order_id, data);

    uploadOrder(response);

    setLoading(false);
  }, []);

  const financialApproved = useCallback(async (data) => {
    setLoading(true);

    const response = await orderFinancialApproved(data.order_id, {
      action_note: data.action_note,
    });

    uploadOrder(response);

    setLoading(false);
  }, []);

  const financialDisapproved = useCallback(async (data) => {
    setLoading(true);

    const response = await orderFinancialDisapproved(data.order_id, {
      action_note: data.action_note,
    });

    uploadOrder(response);

    setLoading(false);
  }, []);

  const shelveOrder = useCallback(async (data) => {
    setLoading(true);

    const response = await shelveOrderById(data.order_id, data);

    uploadOrder(response);

    setLoading(false);
  }, []);

  const reopenOrder = useCallback(async (data) => {
    setLoading(true);

    const response = await reopenOrderById(data.order_id, data);

    uploadOrder(response);

    setLoading(false);
  }, []);

  return (
    <>
      <ActionModal
        modal_id="modal-send-finance"
        title="enviar pedido para análise?"
        action={sendFinanceAnalysis}
        order={order}
      />

      <ActionModal
        modal_id="modal-get-finance-analysis"
        title="analizar pedido?"
        action={startFinancialAnalysis}
        order={order}
      />

      <ActionModal
        modal_id="modal-financial-approved"
        title="aprovar pedido?"
        action={financialApproved}
        order={order}
      />

      <ActionModal
        modal_id="modal-financial-disapproved"
        title="reprovar pedido?"
        action={financialDisapproved}
        order={order}
      />

      <ActionModal
        modal_id="modal-shelve-order"
        title="arquivar pedido?"
        action={shelveOrder}
        order={order}
      />

      <ActionModal
        modal_id="modal-reopen-order"
        title="reabrir pedido?"
        action={reopenOrder}
        order={order}
      />
    </>
  );
};

FinancialAnalysisModal.propTypes = {
  order: PropTypes.shape({
    id: PropTypes.string,
    order_status: PropTypes.string,
  }).isRequired,
  setOrder: PropTypes.func.isRequired,
};

export default FinancialAnalysisModal;
