import api from './api';
import { handleError } from './handle-error';
import { toastSuccess } from './toast';

export const addSellerToUnit = async (unit_id, seller_id) => {
  const result = new Promise((resolve) => {
    api
      .post(`units/${unit_id}/sellers/${seller_id}`)
      .then((response) => {
        toastSuccess('Novo vendedor incluido com sucesso!');
        resolve(response.data);
      })
      .catch(async (error) => {
        const response = await handleError(error);
        resolve(response);
      });
  });
  return result;
};

export const removeSellerFromUnit = async (unit_id, seller_id) => {
  const result = new Promise((resolve) => {
    api
      .delete(`units/${unit_id}/sellers/${seller_id}`)
      .then((response) => {
        toastSuccess('Vendedor removido com sucesso!');
        resolve(response.data);
      })
      .catch(async (error) => {
        const response = await handleError(error);
        resolve(response);
      });
  });
  return result;
};
