import React, { useEffect, useCallback, useState, useContext } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router';

import styled from 'styled-components';

import { AuthContext } from '../../../../actions/auth-context';
import { show } from '../../../../actions/base-rest';
import { setBreadcrumb } from '../../../../reducers/breadcrumb';
import ConfirmResetPassword from './ConfirmResetPassword';
import UserData from './UserData';
import UserPermissions from './UserPermissions';
import UserRoles from './UserRoles';

const URL = 'users';

const EditUser = () => {
  const { setLoading } = useContext(AuthContext);

  const dispatch = useDispatch();

  const params = useParams();

  const [user, setUser] = useState({});

  const getUser = useCallback(
    async (id) => {
      setLoading(true);

      const response = await show(URL, id);

      setUser(response);

      dispatch(
        setBreadcrumb([
          { page: 'usuários', url: '/users' },
          { page: 'editar', url: null },
          { page: response.full_name, url: null },
        ])
      );

      setLoading(false);
    },
    [dispatch]
  );

  useEffect(() => {
    const { id } = params;

    getUser(id);
  }, []);

  return (
    <Container className="container ">
      <div className="row mt-3 px-1 justify-content-center">
        <div className="col-12 text-center my-3">
          <h4>EDITAR {user.full_name}</h4>
        </div>
      </div>

      {user.id && (
        <div className="row justify-content-center mb-5">
          <UserData user={user} setUser={setUser} />

          <UserRoles user={user} setUser={setUser} />

          <UserPermissions user={user} setUser={setUser} />
        </div>
      )}

      <ConfirmResetPassword user={user} />
    </Container>
  );
};

export default EditUser;

export const Container = styled.div`
  text-transform: uppercase;

  .label {
    font-style: normal;
    font-weight: 300;
    font-size: 10px;
    line-height: 14px;
    color: #4d4d4d;
    margin: 0;
    text-transform: uppercase;
  }

  .error {
    color: red;
    font-size: 12px;
    margin: 0;
    font-weight: 500;
  }
`;
