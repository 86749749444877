import { useContext } from 'react';

import PropTypes from 'prop-types';

import { AuthContext } from '../../../../../actions/auth-context';

const FinishPackingOrderButton = ({ order }) => {
  const { hasPermissions } = useContext(AuthContext);

  return (
    <>
      {order.order_status === 'Em embalagem' && hasPermissions(['pack_order']) && (
        <div className="col-sm col-md-5 col-xl-3 mt-2">
          <button
            className="btn btn-success btn-block col-12"
            type="button"
            data-bs-toggle="modal"
            data-bs-target="#modal-finish-packing-order"
          >
            finalizar embalagem
          </button>
        </div>
      )}
    </>
  );
};

FinishPackingOrderButton.propTypes = {
  order: PropTypes.shape({
    id: PropTypes.string,
    order_status: PropTypes.string,
  }).isRequired,
};

export default FinishPackingOrderButton;
