import { useEffect, useState, useCallback, useRef } from 'react';

import { Form } from '@unform/web';
import PropTypes from 'prop-types';

import { index } from '../../../../actions/base-rest';
import { addRole, removeRole } from '../../../../actions/user.service';
import Checkbox from '../../../../components/unform/checkbox';
import { GroupData } from '../../../../styles/GroupData';

const UserRoles = ({ user, setUser }) => {
  const [rolesOptions, setRolesOptions] = useState([]);
  const formRef = useRef(null);

  const loadRoles = useCallback(async () => {
    const response = await index('roles');
    response.sort((a, b) => (a.nome > b.nome ? 1 : b.nome > a.nome ? -1 : 0));

    setRolesOptions(response);
  }, []);

  const disabledRole = useCallback(
    (slug) => {
      const checked = user.roles.findIndex((item) => item.slug === slug) > -1;
      return checked && user.roles.length === 1;
    },
    [user]
  );

  const checkedRole = useCallback(
    (slug) => {
      const checked = user.roles.findIndex((item) => item.slug === slug) > -1;
      return checked;
    },
    [user]
  );

  const changeRoles = useCallback(
    async (role_id) => {
      const hasRole = user.roles.findIndex((item) => item.id === role_id) > -1;

      let response;
      const data = {
        id: user.id,
      };

      if (hasRole) {
        data.role = role_id;
        response = await removeRole(data);
      } else {
        data.roles = [role_id];
        response = await addRole(data);
      }

      setUser({});
      const upadated_user = !response.error ? response : user;
      setUser(upadated_user);
    },
    [user]
  );

  useEffect(() => {
    loadRoles();
  }, []);

  return (
    <Form ref={formRef} noValidate className="col-12 col-lg-10">
      <GroupData>
        <div className="row px-1">
          <div className="col-12 mt-3">
            <label className="label" htmlFor="ip">
              PERFIL
              <input type="text" className="d-none" id="ip" />
            </label>
          </div>

          {rolesOptions.map((item) => (
            <div className="col-4 mt-2" key={item.id}>
              {user.id && (
                <Checkbox
                  name={item.slug}
                  value={item.id}
                  label={item.name}
                  disabled={disabledRole(item.slug)}
                  defaultChecked={checkedRole(item.slug)}
                  onChange={(e) => changeRoles(e.target.value)}
                />
              )}
            </div>
          ))}
        </div>
      </GroupData>
    </Form>
  );
};

UserRoles.propTypes = {
  user: PropTypes.shape({
    id: PropTypes.string,
    roles: PropTypes.arrayOf(PropTypes.shape()),
  }).isRequired,
  setUser: PropTypes.func.isRequired,
};

export default UserRoles;
