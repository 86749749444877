import React, { useCallback, useEffect, useState } from 'react';
import { IoIosSearch } from 'react-icons/io';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';

import styled from 'styled-components';

import { index, remove } from '../../../actions/base-rest';
import DeleteModal from '../../../components/delete-modal';
import { setBreadcrumb } from '../../../reducers/breadcrumb';
import UsersFilter from './UsersFilter';
import UsersTable from './UsersTable';

const URL = 'users';
const default_params = {
  current_page: 1,
  per_page: 50,
  sorts: 'full_name:ASC',
};

const Users = () => {
  const dispatch = useDispatch();
  const [users, setUsers] = useState([]);
  const [page, setPage] = useState({});
  const [params, setParams] = useState(default_params);
  const [isFilter, setIsFilter] = useState(false);
  const [itemDelete, setItemDelete] = useState({});

  const getUsers = useCallback(async (filter) => {
    const response = await index(URL, filter);
    setUsers(response.rows);
    setPage(response.page);
  }, []);

  const deleteItem = useCallback(async () => {
    const response = await remove(URL, itemDelete.id);

    if (response) {
      setItemDelete({});
      setUsers([]);
      getUsers();
    }
  }, [itemDelete]);

  useEffect(() => {
    getUsers(params);
  }, [params]);

  useEffect(() => {
    dispatch(setBreadcrumb([{ page: 'usuários', url: null }]));
  }, []);

  return (
    <Container className="container">
      <div className="row mt-3 justify-content-center">
        <div className="col-12 text-center my-3">
          <h4>USUÁRIOS</h4>
        </div>

        {!isFilter && (
          <div className="col-6 col-md-4">
            <Link to="/users/new">
              <button type="button" className="btn btn-primary col-12">
                novo usuário
              </button>
            </Link>
          </div>
        )}
        <div className={isFilter ? 'col-12 col-md-10' : 'col-6 col-md-4'}>
          <button
            type="button"
            className="btn btn-secondary col-12"
            onClick={() => setIsFilter(true)}
          >
            <IoIosSearch size={25} className="mb-1" /> localizar
          </button>

          <UsersFilter
            isFilter={isFilter}
            setUsers={setUsers}
            setIsFilter={setIsFilter}
            setParams={setParams}
            params={params}
            default_params={default_params}
          />
        </div>
      </div>

      <UsersTable
        users={users}
        page={page}
        setItemDelete={setItemDelete}
        setParams={setParams}
        params={params}
      />

      <DeleteModal
        id_modal="modal-remove-user"
        item_delete={itemDelete}
        deleteItem={() => deleteItem()}
      />
    </Container>
  );
};

export default Users;

export const Container = styled.div`
  .btn {
    height: 45px;
  }

  @media screen and (max-width: 370px) {
    .btn {
      height: 70px !important;
    }
  }
`;
