import { Switch, Route } from 'react-router-dom';

import ChangePassword from './pages/change-password';
import ClientOrder from './pages/client-order/ClientOrder';
import ForgotPassword from './pages/forgot-password';
import Home from './pages/home';
import Login from './pages/login';
import Logout from './pages/logout';
import Order from './pages/orders/form';
import Orders from './pages/orders/list';
import PageNotFound from './pages/page-not-found';
import PageUnauthorized from './pages/page-unauthorized';
import EditPerson from './pages/persons/form/edit';
import NewPerson from './pages/persons/form/new';
import People from './pages/persons/list';
import Product from './pages/products/form';
import Products from './pages/products/list';
import ProfileMe from './pages/profile-me';
import EditUnit from './pages/units/form/edit';
import NewUnit from './pages/units/form/new';
import Units from './pages/units/list';
import EditUser from './pages/users/form/edit';
import NewUser from './pages/users/form/new';
import Users from './pages/users/list';
import PrivateRoute from './PrivateRoute';

const Routes = () => (
  <Switch>
    <Route path="/login">
      <Login />
    </Route>
    <Route path="/logout">
      <Logout />
    </Route>

    <Route path="/forgot-password" component={ForgotPassword} />

    <Route path="/profile/reset-password/:token" component={ChangePassword} />

    <Route path="/orders/access-order/:token" component={ClientOrder} />

    <PrivateRoute exact path="/" component={Home} />

    <PrivateRoute exact path="/persons" component={People} />

    <PrivateRoute exact path="/persons/new" component={NewPerson} />

    <PrivateRoute exact path="/persons/:id" component={EditPerson} />

    <PrivateRoute exact path="/products" component={Products} />

    <PrivateRoute exact path="/products/new" component={Product} />

    <PrivateRoute exact path="/products/:id" component={Product} />

    <PrivateRoute exact path="/units" component={Units} />

    <PrivateRoute exact path="/units/new" component={NewUnit} />

    <PrivateRoute exact path="/units/:id" component={EditUnit} />

    <PrivateRoute exact path="/orders" component={Orders} />

    <PrivateRoute exact path="/orders/new" component={Order} />

    <PrivateRoute exact path="/orders/:id" component={Order} />

    <PrivateRoute exact path="/users" component={Users} />

    <PrivateRoute exact path="/users/new" component={NewUser} />

    <PrivateRoute exact path="/users/:id" component={EditUser} />

    <PrivateRoute exact path="/profile-me" component={ProfileMe} />

    {/* <PrivateRoute path="/server-error" component={ServerError} /> */}

    <PrivateRoute exact path="/unauthorized" component={PageUnauthorized} />

    <PrivateRoute path="*" component={PageNotFound} />
  </Switch>
);

export default Routes;
