import React, { useRef, useCallback } from 'react';
import { AiOutlineClear } from 'react-icons/ai';
import { MdClose } from 'react-icons/md';
import ReactTooltip from 'react-tooltip';

import { Form } from '@unform/web';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import Input from '../../../components/unform/input';

const UnitsFilter = ({ isFilter, setIsFilter, setParams, default_params }) => {
  const formRef = useRef(null);

  const clearFieldsSearch = useCallback(() => {
    formRef.current.setFieldValue('name', '');
    formRef.current.setFieldValue('city', '');
    setParams(default_params);
  }, []);

  const filterUnitsByName = useCallback(async (value) => {
    if (value.length >= 3) {
      setParams((prevState) => ({
        ...prevState,
        name: value,
      }));
    } else if (value.length === 0) {
      setParams(default_params);
    }
  }, []);

  const filterUnitsByCity = useCallback(async (value) => {
    if (value.length >= 3) {
      setParams((prevState) => ({
        ...prevState,
        city: value,
      }));
    } else if (value.length === 0) {
      setParams(default_params);
    }
  }, []);

  return (
    <>
      {isFilter && (
        <>
          <CloseSearch
            className="btn btn-outline-primary text-center"
            onClick={() => {
              setIsFilter(false);
              clearFieldsSearch();
            }}
          >
            <MdClose size={25} data-tip="Fechar" />
            <ReactTooltip />
          </CloseSearch>

          <Search>
            <Form ref={formRef} noValidate className="col-sm col-lg-10 p-4">
              <div className="row justify-content-center">
                <div className="col-sm mt-2">
                  <Input
                    name="name"
                    placeholder="Por nome"
                    onChange={(e) => filterUnitsByName(e.target.value)}
                  />
                </div>

                <div className="col-sm mt-2">
                  <Input
                    name="city"
                    placeholder="Por cidade"
                    onChange={(e) => filterUnitsByCity(e.target.value)}
                  />
                </div>

                <div className="col-1 d-none d-md-block">
                  <ClearSearch onClick={clearFieldsSearch}>
                    <AiOutlineClear size={30} data-tip="Limpar campos" />
                    <ReactTooltip />
                  </ClearSearch>
                </div>
              </div>

              <div className="row justify-content-end mt-3 d-sm-block d-md-none ">
                <div className="col">
                  <button
                    className="btn btn-outline-secondary col-sm btn-block col-12"
                    type="button"
                    onClick={clearFieldsSearch}
                  >
                    Limpar campos
                  </button>
                </div>
              </div>
            </Form>
          </Search>
        </>
      )}
    </>
  );
};

UnitsFilter.propTypes = {
  isFilter: PropTypes.bool.isRequired,
  setIsFilter: PropTypes.func.isRequired,
  setParams: PropTypes.func.isRequired,
  default_params: PropTypes.shape().isRequired,
};

export default UnitsFilter;

export const Search = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  background-color: #f5f5f5;
`;
export const ClearSearch = styled.div`
  color: #d11515;

  svg {
    margin-top: 12px;
    margin-left: -8px;
  }

  :hover {
    cursor: pointer;
    color: #ec4646;
  }
`;
export const CloseSearch = styled.button`
  position: absolute;
  margin-left: -70px;
  margin-top: 6px;
  height: 35px !important;
`;
