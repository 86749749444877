import { useState, useRef, useCallback, useContext } from 'react';
import Cropper from 'react-cropper';
// eslint-disable-next-line import/no-extraneous-dependencies
import 'cropperjs/dist/cropper.css';
import { FaUpload, FaCheck } from 'react-icons/fa';

import PropTypes from 'prop-types';
import styled from 'styled-components';

import { AuthContext } from '../../actions/auth-context';
import { storeThumbnail } from '../../actions/user.service';
import { GroupData } from '../../styles/GroupData';

const CropThumbnail = ({ isCropping, setIsCropping }) => {
  const { changeThumbnailProfile, setLoading } = useContext(AuthContext);
  const [image, setImage] = useState('');
  const imageRef = useRef(null);
  const [cropper, setCropper] = useState();

  const onChangeImage = useCallback((e) => {
    e.preventDefault();
    let files;

    if (e.dataTransfer) {
      files = e.dataTransfer.files;
    } else if (e.target) {
      files = e.target.files;
    }

    const reader = new FileReader();
    reader.onload = () => {
      setImage(reader.result);
    };
    reader.readAsDataURL(files[0]);
  }, []);

  const getCropData = useCallback(async () => {
    if (typeof cropper !== 'undefined') {
      setLoading(true);

      const data = cropper.getCroppedCanvas().toDataURL();

      const i = data.indexOf('base64,');

      const buffer = Buffer.from(data.slice(i + 7), 'base64');

      const file = new File([buffer], 'teste.png', {
        type: 'image/png',
      });

      const formData = new FormData();
      formData.append('file', file);

      const response = await storeThumbnail(formData);

      if (response.id) {
        changeThumbnailProfile();
        setImage('');
        setIsCropping(false);
      }
    }
  }, [cropper]);

  return (
    <>
      {isCropping && (
        <GroupData>
          <div className="row mt-3 px-1 justify-content-center">
            <div className="col-12 text-center my-3">
              <h4>ALTERAR IMAGEM DE PERFIL</h4>
            </div>
          </div>

          <div className="row justify-content-center pt-3">
            <div className="col-6 col-md-4">
              <button
                type="button"
                className="btn btn-secondary btn-block col-12"
                onClick={() => {
                  setIsCropping(false);
                  setImage('');
                }}
              >
                cancelar
              </button>
            </div>

            {!image && (
              <div className="col-6 col-md-4">
                <InputFile
                  type="button"
                  className="btn btn-lg btn-primary btn-block col-12"
                >
                  Escolher imagem
                  <FaUpload size={20} />
                  <input
                    type="file"
                    name="thumbnail"
                    id="thumbnail"
                    onChange={onChangeImage}
                    accept="image/*"
                    data-tip=""
                    data-for="get"
                  />
                </InputFile>
              </div>
            )}

            {image && (
              <div className="col-6 col-md-4">
                <InputFile
                  type="button"
                  className="btn btn-lg btn-primary btn-block col-12"
                  onClick={getCropData}
                >
                  salvar imagem
                  <FaCheck size={20} />
                </InputFile>
              </div>
            )}
          </div>

          {image && (
            <div className="row justify-content-center mt-3">
              <div className="col-sm col-md-8">
                <Cropper
                  style={{ height: 400, width: '100%' }}
                  aspectRatio={4 / 4}
                  preview=".img-preview"
                  guides
                  src={image}
                  ref={imageRef}
                  dragMode="move"
                  checkOrientation
                  onInitialized={(instance) => {
                    setCropper(instance);
                  }}
                />
              </div>

              <Preview className="box col">
                <h6>Preview</h6>
                <div className="img-preview img-thumbnail" />
              </Preview>
            </div>
          )}
        </GroupData>
      )}
    </>
  );
};

CropThumbnail.propTypes = {
  isCropping: PropTypes.bool.isRequired,
  setIsCropping: PropTypes.func.isRequired,
};

export default CropThumbnail;

export const InputFile = styled.button`
  position: relative;
  overflow: hidden;

  font-size: 16px;

  svg {
    margin-left: 10px;
    margin-bottom: 5px;
  }

  input[type='file'] {
    position: absolute;
    top: 0;
    right: 0;
    min-width: 100%;
    min-height: 100%;
    font-size: 100px;
    text-align: right;
    filter: alpha(opacity=0);
    opacity: 0;
    outline: none;
    cursor: inherit;
    display: block;
  }
`;

export const Preview = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
  width: 50%;

  .img-preview {
    width: 100%;
    height: 150px;
    overflow: hidden;
  }

  .loader {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    line-height: 50px;
  }
`;
