import React, {
  useEffect,
  useRef,
  useCallback,
  useState,
  useContext,
} from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router';

import { Form } from '@unform/web';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import * as Yup from 'yup';

import { AuthContext } from '../../../actions/auth-context';
import { update, show, store } from '../../../actions/base-rest';
import {
  currencyToFloat,
  floatToCurrency,
} from '../../../commons/currency-formatter';
import { indexEnum } from '../../../commons/enum';
import InputCurrencyLabel from '../../../components/unform/input-currency-label';
import InputLabel from '../../../components/unform/input-label';
import SelectLabel from '../../../components/unform/select-label';
import { setBreadcrumb } from '../../../reducers/breadcrumb';
import { GroupData } from '../../../styles/GroupData';

const URL = 'products';

const Product = () => {
  const { setLoading } = useContext(AuthContext);

  const dispatch = useDispatch();

  const history = useHistory();

  const params = useParams();

  const formRef = useRef(null);

  const [product, setProduct] = useState({});

  const [optionsUnit, setOptionsUnit] = useState([]);

  const [optionsType, setOptionsType] = useState([]);

  const [selectedUnitType, setSelectedUnitType] = useState([]);

  const [selectedType, setSelectedType] = useState([]);

  const getProduct = useCallback(
    async (id) => {
      setLoading(true);

      const response = await show(URL, id);

      response.price = await floatToCurrency(response.price);

      setSelectedType({
        label: response.type,
        value: response.type,
      });

      setSelectedUnitType({
        label: response.unit.toUpperCase(),
        value: response.unit,
      });

      formRef.current.setFieldValue('price', response.price);

      setProduct(response);

      setLoading(false);
    },
    [optionsUnit, formRef]
  );

  const submit = useCallback(
    async (data) => {
      if (selectedUnitType.value) data.unit = selectedUnitType.value;

      if (selectedType.value) data.type = selectedType.value;

      try {
        formRef.current.setErrors({});

        const schema = Yup.object().shape({
          name: Yup.string().min(3, 'Nome mínimo 3 caracteres'),

          finish_type: Yup.string().min(3, 'Descrição mínimo 3 caracteres'),

          unit: Yup.string().required('Un. é obrigatório'),

          type: Yup.string().required('Tipo é obrigatório'),

          price: Yup.string().required('Preço é obrigatório'),
        });

        await schema.validate(data, { abortEarly: false });

        setLoading(true);

        data.price = await currencyToFloat(data.price);

        if (product.id) {
          const response = await update(URL, data);

          if (!response.error) setProduct(response);
        } else {
          const response = await store(URL, data);

          if (!response.error) history.push(`/${URL}/${response.id}`);
        }

        setLoading(false);
      } catch (error) {
        const errorMessages = {};

        if (error instanceof Yup.ValidationError) {
          error.inner.forEach((err) => {
            errorMessages[err.path] = err.message;
          });
          formRef.current.setErrors(errorMessages);
        }
      }
    },
    [getProduct, product, selectedUnitType, selectedType]
  );

  const loadOptionsUnitType = useCallback(async () => {
    const response = await indexEnum('unit-types');

    setOptionsUnit(response);
  }, []);

  const loadOptionsType = useCallback(async () => {
    const response = await indexEnum('product-types');

    setOptionsType(response);
  }, []);

  useEffect(() => {
    const { id } = params;

    if (id) getProduct(id);

    loadOptionsUnitType();

    loadOptionsType();

    dispatch(
      setBreadcrumb([
        { page: 'produtos', url: '/products' },
        { page: id ? 'editar' : 'novo', url: null },
      ])
    );
  }, [params]);

  return (
    <Container className="container ">
      <div className="row mt-3 px-1 justify-content-center">
        <div className="col-12 text-center my-3">
          <h4>{product.id ? `EDITAR ${product.name}` : 'NOVO PRODUTO'}</h4>
        </div>
      </div>

      <div className="row justify-content-center mb-5">
        <Form
          ref={formRef}
          onSubmit={submit}
          initialData={product}
          noValidate
          className="col-12 col-lg-8 "
        >
          <GroupData>
            <div className="col-1 d-none">
              <InputLabel name="id" label="ID" />
            </div>

            <div className="row justify-content-center">
              <div className="col-12 col-md-8 mt-5 px-1">
                <InputLabel name="name" label="nome" />
              </div>
            </div>

            <div className="row justify-content-center">
              <div className="col-12 col-md-8 mt-3 px-1">
                <InputLabel name="finish_type" label="Descrição" />
              </div>
            </div>

            <div className="row justify-content-center">
              <div className="col-6 col-md-4 mt-3 px-1">
                <SelectLabel
                  name="type"
                  label="tipo"
                  options={optionsType}
                  placeholder=""
                  value={selectedType}
                  onChange={(e) => setSelectedType(e)}
                />
              </div>

              <div className="col-6 col-md-2 mt-3 px-1">
                <SelectLabel
                  name="unit"
                  label="unidade"
                  options={optionsUnit}
                  placeholder=""
                  value={selectedUnitType}
                  onChange={(e) => setSelectedUnitType(e)}
                />
              </div>

              <div className="col-sm col-md-2 mt-3 px-1">
                <InputCurrencyLabel name="price" label="preço" />
              </div>
            </div>
          </GroupData>

          <div className="row justify-content-center mt-4">
            <div className="col-6 col-md-4 col-xl-3">
              <button
                className="btn btn-outline-secondary btn-block col-12"
                type="button"
                onClick={() => history.push('/products')}
              >
                {product.id ? 'voltar' : 'cancelar'}
              </button>
            </div>

            <div className="col-6 col-md-4 col-xl-3">
              <button
                className="btn btn-primary btn-block col-12"
                type="submit"
              >
                {product.id ? 'atualizar' : 'salvar'}
              </button>
            </div>
          </div>
        </Form>
      </div>
    </Container>
  );
};

Product.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string,
    }),
  }).isRequired,
};

export default Product;

export const Container = styled.div`
  /* text-transform: uppercase; */

  height: 100vh;
`;
