import { useCallback, useContext } from 'react';

import PropTypes from 'prop-types';

import { AuthContext } from '../../../../../actions/auth-context';
import {
  startPrintTheOrder,
  finishPrintTheOrder,
} from '../../../../../actions/order.service';
import ActionModal from './ActionModal';

const PrintModal = ({ order, setOrder }) => {
  const { setLoading } = useContext(AuthContext);

  const uploadOrder = useCallback((response) => {
    if (!response.error) {
      response.delivery_date = new Date(response.delivery_date);

      if (response.payment_first_date) {
        response.payment_first_date = new Date(
          response.payment_first_date.replace(/-/g, ',')
        );
      }

      setOrder(response);
    }
  }, []);

  const getPrintOrder = useCallback(async (data) => {
    setLoading(true);

    const response = await startPrintTheOrder(data.order_id, data);

    uploadOrder(response);

    setLoading(false);
  }, []);

  const finishPrintOrder = useCallback(async (data) => {
    setLoading(true);

    const response = await finishPrintTheOrder(data.order_id, data);

    uploadOrder(response);

    setLoading(false);
  }, []);

  return (
    <>
      <ActionModal
        modal_id="modal-start-print-order"
        title="iniciar impressão do pedido?"
        action={getPrintOrder}
        order={order}
      />

      <ActionModal
        modal_id="modal-finish-print-order"
        title="finalizar impressão do pedido?"
        action={finishPrintOrder}
        order={order}
      />
    </>
  );
};

PrintModal.propTypes = {
  order: PropTypes.shape({
    id: PropTypes.string,
    order_status: PropTypes.string,
  }).isRequired,
  setOrder: PropTypes.func.isRequired,
};

export default PrintModal;
