import { BrowserRouter } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';

import AuthProvider from './actions/auth-context';
import Breadcrumb from './components/breadcrumb';
import Footer from './components/footer-app';
import Loading from './components/loading/Loading';
import Navbar from './components/navbar';
import ScrollToTopButton from './components/scroll-to-top-button';
import ConfirmChangePassword from './pages/confirm-change-password/ConfirmChangePassword';
import Routes from './Routes';
import GlobalStyle from './styles/GlobalStyles';

function App() {
  return (
    <AuthProvider>
      <BrowserRouter>
        <GlobalStyle />
        <ScrollToTopButton />
        <Loading />
        <ToastContainer autoClose={3500} />
        <Navbar />
        <Breadcrumb />
        <ConfirmChangePassword />
        <Routes />
        <Footer />
      </BrowserRouter>
    </AuthProvider>
  );
}

export default App;
