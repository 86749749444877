/* eslint-disable no-unused-vars */
import { useContext } from 'react';
import { BiPhotoAlbum } from 'react-icons/bi';
import { FaWpforms, FaCity, FaUsers } from 'react-icons/fa';
import { IoIosPeople } from 'react-icons/io';
import { Link } from 'react-router-dom';

import styled from 'styled-components';

import { AuthContext } from '../../actions/auth-context';

const MenuItems = () => {
  const { hasPermissions } = useContext(AuthContext);

  const items = [
    {
      id: 1,
      title: 'PEDIDOS',
      link: '/orders',
      icon: <FaWpforms size={50} />,
      isVisible: hasPermissions([
        'delete_order',
        'add_order',
        'update_order',
        'financial_analysis',
        'edit_photos',
        'print_photos',
        'plotter_photos',
        'assemble_order',
        'pack_order',
      ]),
    },
    {
      id: 2,
      title: 'PESSOAS',
      link: '/persons',
      icon: <IoIosPeople size={50} />,
      isVisible: hasPermissions(['edit_person']),
    },
    {
      id: 3,
      title: 'PRODUTOS',
      link: '/products',
      icon: <BiPhotoAlbum size={50} />,
      isVisible: hasPermissions(['edit_product']),
    },
    {
      id: 4,
      title: 'UNIDADES',
      link: '/units',
      icon: <FaCity size={50} />,
      isVisible: hasPermissions(['edit_unit']),
    },
    {
      id: 5,
      title: 'USUÁRIOS',
      link: '/users',
      icon: <FaUsers size={50} />,
      isVisible: hasPermissions(['edit_user']),
    },
  ];
  return (
    <>
      {items.map((item) => (
        <div
          key={item.id}
          className={
            item.isVisible
              ? 'col-6 col-sm-4 col-md-4 col-lg-2 col-xl-3 col-xxl-2 mb-4'
              : 'd-none'
          }
        >
          <Container className="shadow text-center p-1">
            <Link to={item.link}>
              <div className="mt-3">{item.icon}</div>
              <p className="mt-2">{item.title}</p>
            </Link>
          </Container>
        </div>
      ))}
    </>
  );
};

export default MenuItems;

export const Container = styled.div`
  background-color: #d3d8d9;
  color: #4f4f4f;
  cursor: pointer;
  height: 120px;

  a {
    color: #4f4f4f;
    text-decoration: none;
  }

  :hover {
    background-color: #ffba00;

    a {
      color: #fff;
    }
  }
`;
