import { useContext } from 'react';

import PropTypes from 'prop-types';

import { AuthContext } from '../../../../../actions/auth-context';

const SendFinancialAnalysisButton = ({ order }) => {
  const { hasPermissions } = useContext(AuthContext);

  const valid_spcfiles = order.spcfiles && order.spcfiles.length > 0;

  const valid_order_products = order.products && order.products.length > 0;

  const valid_order_status =
    order.order_status === 'Em inclusão' ||
    order.order_status === 'Financeiro negado';

  const valid_permissions = hasPermissions(['add_order']);

  return (
    <>
      {valid_spcfiles &&
        valid_order_products &&
        valid_order_status &&
        valid_permissions && (
          <div className="col-sm col-md-5 col-xl-3 mt-2">
            <button
              className="btn btn-success btn-block col-12"
              type="button"
              data-bs-toggle="modal"
              data-bs-target="#modal-send-finance"
            >
              enviar para análise
            </button>
          </div>
        )}
    </>
  );
};

SendFinancialAnalysisButton.propTypes = {
  order: PropTypes.shape({
    id: PropTypes.string,
    order_status: PropTypes.string,
    spcfiles: PropTypes.arrayOf(Object),
    products: PropTypes.arrayOf(Object),
  }).isRequired,
};

export default SendFinancialAnalysisButton;
