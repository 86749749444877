import React, { useEffect, useCallback, useState } from 'react';
import { AiFillPrinter, AiOutlineDollarCircle } from 'react-icons/ai';
import { BsFillPuzzleFill } from 'react-icons/bs';
import { FaBoxOpen, FaBoxes, FaTruck, FaStore } from 'react-icons/fa';
import { IoBagCheck } from 'react-icons/io5';
import { MdOutlinePhotoCamera } from 'react-icons/md';
import { RiImageEditFill } from 'react-icons/ri';
import { TiPrinter } from 'react-icons/ti';

import PropTypes from 'prop-types';
import styled from 'styled-components';

import { getOrderPositionOfOrderById } from '../../../../actions/order.service';
import { formatDeltaTime } from '../../../../commons/date';

const OrderStatus = ({ order, has_plotter }) => {
  const [comercial, setComercial] = useState({});

  const [financeiro, setFinanceiro] = useState({});

  const [edicao, setEdicao] = useState({});

  const [impressao, setImpressao] = useState({});

  const [plotter, setPlotter] = useState({});

  const [montagem, setMontagem] = useState({});

  const [embalagem, setEmbalagem] = useState({});

  const [expedicao, setExpedicao] = useState({});

  const [enviado, setEnviado] = useState({});

  const [recebido, setRecebido] = useState({});

  const [entregue, setEntregue] = useState({});

  const getOrderPosition = useCallback(async (id) => {
    const response = await getOrderPositionOfOrderById(id);

    const data_format = response.map((item) => ({
      timeStart: formatDeltaTime(item.time_from_the_start),
      timeProcess: formatDeltaTime(item.time_of_process),
      has_reworks: item.has_reworks,
      section: item.section,
    }));

    setComercial(data_format.filter((item) => item.section === 'Comercial')[0]);

    setFinanceiro(
      data_format.filter((item) => item.section === 'Financeiro')[0]
    );

    setEdicao(data_format.filter((item) => item.section === 'Edição')[0]);

    setImpressao(data_format.filter((item) => item.section === 'Impressão')[0]);

    setPlotter(data_format.filter((item) => item.section === 'Plotter')[0]);

    setMontagem(data_format.filter((item) => item.section === 'Montagem')[0]);

    setEmbalagem(data_format.filter((item) => item.section === 'Embalagem')[0]);

    setExpedicao(data_format.filter((item) => item.section === 'Expedição')[0]);

    setEnviado(data_format.filter((item) => item.section === 'Enviado')[0]);

    setRecebido(data_format.filter((item) => item.section === 'Recebido')[0]);

    setEntregue(data_format.filter((item) => item.section === 'Entregue')[0]);
  }, []);

  useEffect(() => {
    getOrderPosition(order.id);
  }, [order]);
  return (
    <Container>
      <ContainerPosition>
        <ul className="p-0">
          <Card className="col-1 m-1" data={comercial}>
            <div className="card">
              <div className="icon">
                <MdOutlinePhotoCamera size={25} />
              </div>

              <p className="text">Comercial</p>

              <p className="day">{comercial?.timeProcess?.days || 0}d</p>

              <p className="process">
                {`${comercial?.timeProcess?.hours || 0}:${
                  comercial?.timeProcess?.minutes || 0
                }:${comercial?.timeProcess?.seconds || 0}`}
              </p>

              <p className="start">{`${comercial?.timeStart?.days || 0}d ${
                comercial?.timeStart?.hours || 0
              }:${comercial?.timeStart?.minutes || 0}:${
                comercial?.timeStart?.seconds || 0
              }`}</p>
            </div>
          </Card>
          <Card className="col-1 m-1" data={financeiro}>
            <div className="card">
              <div className="icon">
                <AiOutlineDollarCircle size={25} />
              </div>

              <p className="text"> Financeiro</p>

              <p className="day">{financeiro?.timeProcess?.days || 0}d</p>

              <p className="process">
                {`${financeiro?.timeProcess?.hours || 0}:${
                  financeiro?.timeProcess?.minutes || 0
                }:${financeiro?.timeProcess?.seconds || 0}`}
              </p>

              <p className="start">{`${financeiro?.timeStart?.days || 0}d ${
                financeiro?.timeStart?.hours || 0
              }:${financeiro?.timeStart?.minutes || 0}:${
                financeiro?.timeStart?.seconds || 0
              }`}</p>
            </div>
          </Card>
          <Card className="col-1 m-1" data={edicao}>
            <div className="card">
              <div className="icon">
                <RiImageEditFill size={25} />
              </div>

              <p className="text">Edição</p>

              <p className="day">{edicao?.timeProcess?.days || 0}d</p>

              <p className="process">
                {`${edicao?.timeProcess?.hours || 0}:${
                  edicao?.timeProcess?.minutes || 0
                }:${edicao?.timeProcess?.seconds || 0}`}
              </p>

              <p className="start">{`${edicao?.timeStart?.days || 0}d ${
                edicao?.timeStart?.hours || 0
              }:${edicao?.timeStart?.minutes || 0}:${
                edicao?.timeStart?.seconds || 0
              }`}</p>
            </div>
          </Card>
          <Card className="col-1 m-1" data={impressao}>
            <div className="card">
              <div className="icon">
                <AiFillPrinter size={25} />
              </div>

              <p className="text">Impressão</p>

              <p className="day">{impressao?.timeProcess?.days || 0}d</p>

              <p className="process">
                {`${impressao?.timeProcess?.hours || 0}:${
                  impressao?.timeProcess?.minutes || 0
                }:${impressao?.timeProcess?.seconds || 0}`}
              </p>

              <p className="start">{`${impressao?.timeStart?.days || 0}d ${
                impressao?.timeStart?.hours || 0
              }:${impressao?.timeStart?.minutes || 0}:${
                impressao?.timeStart?.seconds || 0
              }`}</p>
            </div>
          </Card>

          {has_plotter && (
            <Card className="col-1 m-1" data={plotter}>
              <div className="card">
                <div className="icon">
                  <TiPrinter size={25} />
                </div>

                <p className="text">Plotter</p>

                <p className="day">{plotter?.timeProcess?.days || 0}d</p>

                <p className="process">
                  {`${plotter?.timeProcess?.hours || 0}:${
                    plotter?.timeProcess?.minutes || 0
                  }:${plotter?.timeProcess?.seconds || 0}`}
                </p>

                <p className="start">{`${plotter?.timeStart?.days || 0}d ${
                  plotter?.timeStart?.hours || 0
                }:${plotter?.timeStart?.minutes || 0}:${
                  plotter?.timeStart?.seconds || 0
                }`}</p>
              </div>
            </Card>
          )}

          <Card className="col-1 m-1" data={montagem}>
            <div className="card">
              <div className="icon">
                <BsFillPuzzleFill size={25} />
              </div>

              <p className="text">Montagem</p>

              <p className="day">{montagem?.timeProcess?.days || 0}d</p>

              <p className="process">
                {`${montagem?.timeProcess?.hours || 0}:${
                  montagem?.timeProcess?.minutes || 0
                }:${montagem?.timeProcess?.seconds || 0}`}
              </p>

              <p className="start">{`${montagem?.timeStart?.days || 0}d ${
                montagem?.timeStart?.hours || 0
              }:${montagem?.timeStart?.minutes || 0}:${
                montagem?.timeStart?.seconds || 0
              }`}</p>
            </div>
          </Card>
          <Card className="col-1 m-1" data={embalagem}>
            <div className="card">
              <div className="icon">
                <FaBoxOpen size={25} />
              </div>

              <p className="text">Embalagem</p>

              <p className="day">{embalagem?.timeProcess?.days || 0}d</p>

              <p className="process">
                {`${embalagem?.timeProcess?.hours || 0}:${
                  embalagem?.timeProcess?.minutes || 0
                }:${embalagem?.timeProcess?.seconds || 0}`}
              </p>

              <p className="start">{`${embalagem?.timeStart?.days || 0}d ${
                embalagem?.timeStart?.hours || 0
              }:${embalagem?.timeStart?.minutes || 0}:${
                embalagem?.timeStart?.seconds || 0
              }`}</p>
            </div>
          </Card>
          <Card className="col-1 m-1" data={expedicao}>
            <div className="card">
              <div className="icon">
                <FaBoxes size={25} />
              </div>

              <p className="text">Expedição</p>

              <p className="day">{expedicao?.timeProcess?.days || 0}d</p>

              <p className="process">
                {`${expedicao?.timeProcess?.hours || 0}:${
                  expedicao?.timeProcess?.minutes || 0
                }:${expedicao?.timeProcess?.seconds || 0}`}
              </p>

              <p className="start">{`${expedicao?.timeStart?.days || 0}d ${
                expedicao?.timeStart?.hours || 0
              }:${expedicao?.timeStart?.minutes || 0}:${
                expedicao?.timeStart?.seconds || 0
              }`}</p>
            </div>
          </Card>
          <Card className="col-1 m-1" data={enviado}>
            <div className="card">
              <div className="icon">
                <FaTruck size={25} />
              </div>

              <p className="text">Enviado</p>

              <p className="day">{enviado?.timeProcess?.days || 0}d</p>

              <p className="process">
                {`${enviado?.timeProcess?.hours || 0}:${
                  enviado?.timeProcess?.minutes || 0
                }:${enviado?.timeProcess?.seconds || 0}`}
              </p>

              <p className="start">{`${enviado?.timeStart?.days || 0}d ${
                enviado?.timeStart?.hours || 0
              }:${enviado?.timeStart?.minutes || 0}:${
                enviado?.timeStart?.seconds || 0
              }`}</p>
            </div>
          </Card>
          <Card className="col-1 m-1" data={recebido}>
            <div className="card">
              <div className="icon">
                <FaStore size={25} />
              </div>

              <p className="text">Na unidade</p>

              <p className="day">{recebido?.timeProcess?.days || 0}d</p>

              <p className="process">
                {`${recebido?.timeProcess?.hours || 0}:${
                  recebido?.timeProcess?.minutes || 0
                }:${recebido?.timeProcess?.seconds || 0}`}
              </p>

              <p className="start">{`${recebido?.timeStart?.days || 0}d ${
                recebido?.timeStart?.hours || 0
              }:${recebido?.timeStart?.minutes || 0}:${
                recebido?.timeStart?.seconds || 0
              }`}</p>
            </div>
          </Card>
          <Card className="col-1 m-1" data={entregue}>
            <div className="card">
              <div className="icon">
                <IoBagCheck size={25} />
              </div>

              <p className="text">Entregue</p>

              <p className="day">{entregue?.timeProcess?.days || 0}d</p>

              <p className="process">
                {`${entregue?.timeProcess?.hours || 0}:${
                  entregue?.timeProcess?.minutes || 0
                }:${entregue?.timeProcess?.seconds || 0}`}
              </p>

              <p className="start">{`${entregue?.timeStart?.days || 0}d ${
                entregue?.timeStart?.hours || 0
              }:${entregue?.timeStart?.minutes || 0}:${
                entregue?.timeStart?.seconds || 0
              }`}</p>
            </div>
          </Card>
        </ul>
      </ContainerPosition>
    </Container>
  );
};

OrderStatus.propTypes = {
  order: PropTypes.shape({
    id: PropTypes.string,
  }).isRequired,
  has_plotter: PropTypes.bool.isRequired,
};

export default OrderStatus;

export const Container = styled.div`
  height: 130px;
`;

export const ContainerPosition = styled.div`
  overflow: auto;
  width: 100%;

  ul {
    display: flex;
    justify-content: center;
    font-size: 8px;
    margin-top: 20px;
    counter-reset: step;
    display: flex;
    flex-direction: row;
    width: 100%;
  }

  @media screen and (max-width: 667px) {
    ul {
      width: 800px;
      justify-content: initial !important;
    }
  }
`;

export const Card = styled.li`
  list-style-type: none;
  position: relative;
  text-align: center;
  width: 90px;

  .icon {
    margin-top: -25px;
    color: #fff;
    z-index: 2;
    position: absolute;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    background-color: ${(props) => {
      if (props.data) {
        return !props.data.has_reworks ? '#55b776' : '#EC4646';
      }

      return '#c9c9c9';
    }};
    padding: 8px;
  }

  .time {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 80px;
    color: #7d7d7d;
    padding: 5px;

    p {
      font-size: 10px;
      margin-top: 1px;
    }
  }

  .card {
    border: ${(props) => {
      if (props.data) {
        return !props.data.has_reworks
          ? '1px solid #55b776'
          : '1px solid #EC4646';
      }

      return '1px solid #c9c9c9';
    }};
    width: 80px;
    height: 80px;
    z-index: 2;
    display: flex;
    align-items: center;
  }

  .text {
    background-color: ${(props) => {
      if (props.data) {
        return !props.data.has_reworks ? '#55b776' : '#EC4646';
      }

      return '#c9c9c9';
    }};
    padding: 1px;
    width: 70px;
    border-radius: 3px;
    color: #fff;
    margin-top: 8px;
    z-index: 3;

    font-size: 10px;
  }

  .day {
    font-size: 22px;
    font-weight: bold;
    color: ${(props) => {
      if (props.data) {
        return !props.data.has_reworks ? '#55b776' : '#EC4646';
      }

      return '#c9c9c9';
    }};
    margin-top: -20px;
  }

  .process {
    font-size: 9px;
    margin-top: -22px;
    color: ${(props) => {
      if (props.data) {
        return !props.data.has_reworks ? '#55b776' : '#EC4646';
      }

      return '#bcbcbc';
    }};
  }

  .start {
    font-size: 10px;
    color: #7d7d7d;
    margin-top: -15px;
    font-weight: bold;
  }

  :after {
    width: 100px;
    height: 2px;
    content: '';
    position: absolute;
    background-color: ${(props) => (props.data ? '#55b776' : '#c9c9c9')};
    top: 42px;
    left: -30px;
    z-index: 1;
    transition: all 0.8s;
  }

  :first-child:after {
    content: none;
  }
`;
