import { useEffect, useState, useCallback, useRef } from 'react';

import { Form } from '@unform/web';
import PropTypes from 'prop-types';

import { index } from '../../../../actions/base-rest';
import {
  addPermission,
  removePermission,
} from '../../../../actions/user.service';
import Checkbox from '../../../../components/unform/checkbox';
import { GroupData } from '../../../../styles/GroupData';

const UserPermissions = ({ user, setUser }) => {
  const [permissionsOptions, setPermissionsOptions] = useState([]);
  const formRef = useRef(null);

  const loadPermissions = useCallback(async () => {
    const response = await index('permissions');
    response.sort((a, b) => (a.nome > b.nome ? 1 : b.nome > a.nome ? -1 : 0));
    setPermissionsOptions(response);
  }, []);

  const changePermissions = useCallback(
    async (permission_id) => {
      const hasPermission =
        user.permissions.findIndex((item) => item.id === permission_id) > -1;

      let hasExtra_permission = false;
      if (user.extra_permissions && user.extra_permissions.length > 0) {
        hasExtra_permission =
          user.extra_permissions.findIndex(
            (item) => item.id === permission_id
          ) > -1;
      }

      let response;
      const data = {
        id: user.id,
      };

      if (hasPermission || hasExtra_permission) {
        data.permission = permission_id;
        response = await removePermission(data);
      } else {
        data.permissions = [permission_id];
        response = await addPermission(data);
      }

      setUser({});
      const upadated_user = !response.error ? response : user;
      setUser(upadated_user);
    },
    [user]
  );

  const checkedPermission = useCallback(
    (slug) => {
      let extra_checked = false;
      const checked =
        user.permissions.findIndex((item) => item.slug === slug) > -1;

      if (user.extra_permissions && user.extra_permissions.length > 0) {
        extra_checked =
          user.extra_permissions.findIndex((item) => item.slug === slug) > -1;
      }

      return checked || extra_checked;
    },
    [user]
  );

  const prepareLabel = useCallback(
    (permission) => {
      let isExtraPermission = false;

      if (user.extra_permissions && user.extra_permissions.length > 0) {
        isExtraPermission =
          user.extra_permissions.findIndex(
            (item) => item.slug === permission.slug
          ) > -1;
      }

      if (isExtraPermission) {
        return `${permission.name} *`;
      }
      return permission.name;
    },
    [user]
  );

  useEffect(() => {
    loadPermissions();
  }, []);

  return (
    <Form ref={formRef} noValidate className="col-12 col-lg-10">
      <GroupData>
        <div className="row px-1">
          <div className="col-12 mt-3">
            <label className="label" htmlFor="i">
              PERMISSÕES DO PERFIL
              <input type="text" className="d-none" id="i" />
            </label>
          </div>

          {permissionsOptions.map((item) => (
            <div className="col-4 mt-2" key={item.id}>
              {user.id && (
                <Checkbox
                  name={item.slug}
                  value={item.id}
                  label={prepareLabel(item)}
                  defaultChecked={checkedPermission(item.slug)}
                  onChange={(e) => changePermissions(e.target.value)}
                />
              )}
            </div>
          ))}
        </div>
      </GroupData>
    </Form>
  );
};

UserPermissions.propTypes = {
  user: PropTypes.shape({
    id: PropTypes.string,
    permissions: PropTypes.arrayOf(PropTypes.shape()),
    extra_permissions: PropTypes.arrayOf(PropTypes.shape()),
  }).isRequired,
  setUser: PropTypes.func.isRequired,
};

export default UserPermissions;
