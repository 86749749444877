import { useContext } from 'react';

import PropTypes from 'prop-types';

import { AuthContext } from '../../../../../actions/auth-context';

const StartFinancialAnalysisButton = ({ order }) => {
  const { hasPermissions, hasRoles } = useContext(AuthContext);

  return (
    <>
      {order.order_status === 'Incluído' &&
        (hasPermissions(['financial_analysis']) ||
          hasRoles(['administrator', 'master'])) && (
          <div className="col-sm col-md-4 col-xl-3 mt-2">
            <button
              className="btn btn-success btn-block col-12"
              type="button"
              data-bs-toggle="modal"
              data-bs-target="#modal-get-finance-analysis"
            >
              analizar pedido
            </button>
          </div>
        )}
    </>
  );
};

StartFinancialAnalysisButton.propTypes = {
  order: PropTypes.shape({
    id: PropTypes.string,
    order_status: PropTypes.string,
  }).isRequired,
};

export default StartFinancialAnalysisButton;
