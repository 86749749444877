/* eslint-disable react/no-array-index-key */
/* eslint-disable react/prop-types */
import { useEffect, useCallback, useState } from 'react';
import { BsChevronCompactDown } from 'react-icons/bs';

import { BarChart, Bar, XAxis, Tooltip, LabelList } from 'recharts';
import styled from 'styled-components';
import 'animate.css';

import { getUsersProductionApi } from '../../../actions/dashbords.service';

const UsersProduction = ({ params }) => {
  const [data, setData] = useState({});

  const [openDetail, setOpenDetail] = useState(false);

  const getUsersProduction = useCallback(async (filter) => {
    const result = await getUsersProductionApi(filter);

    setData(result);
  }, []);

  const CustomTooltip = ({ active, payload }) => {
    if (!active) return null;

    if (payload) {
      for (const bar of payload) {
        return (
          <TooltipReport>
            <span>{bar?.payload.full_name}</span>

            <div className="row justify-content-center">
              <b>{bar.value}</b>
              <p>Quantidade</p>
            </div>

            <div className="row justify-content-center separator">
              <b>{bar.payload.avg_quantity}</b>
              <p>Média dia</p>
            </div>
          </TooltipReport>
        );
      }
    }

    return null;
  };

  useEffect(() => {
    getUsersProduction(params);
  }, [params]);

  return (
    <>
      {data && (
        <Container>
          <h5 className="animate__animated animate__pulse text-center">
            QUANTIDADE FOTOS POR EDITOR
          </h5>

          <Report>
            <div className="row justify-content-center report">
              <BarChart
                width={900}
                height={300}
                data={data}
                margin={{
                  top: 5,
                  right: 30,
                  left: 20,
                  bottom: 5,
                }}
              >
                <XAxis dataKey="username" />
                <Tooltip content={<CustomTooltip />} />
                <Bar dataKey="quantity" fill="#fab702">
                  <LabelList dataKey="quantity" />
                </Bar>
              </BarChart>
            </div>
          </Report>

          {!openDetail && (
            <div
              className="detalhes"
              role="button"
              tabIndex="0"
              onKeyPress={() => {}}
              onClick={() => setOpenDetail(true)}
            >
              <span>Relatório detalhado</span>

              <div>
                <BsChevronCompactDown />
              </div>
            </div>
          )}

          <>
            {openDetail && (
              <Detail>
                <div className="row justify-content-end">
                  <button
                    type="button"
                    className="btn btn-no-border-primary"
                    onClick={() => setOpenDetail(false)}
                  >
                    fechar relatório
                  </button>
                </div>

                <div className="table-responsive">
                  <table className="table table-striped">
                    <thead>
                      <tr>
                        <th scope="col">NOME</th>
                        <th scope="col">QUANTIDADE</th>
                        <th scope="col">MÉDIA POR DIA</th>
                      </tr>
                    </thead>
                    <tbody>
                      {data?.map((item) => (
                        <tr key={item.id}>
                          <td className="text-start name">
                            <b>{item.full_name}</b>
                          </td>

                          <td>{item.quantity}</td>

                          <td>{item.avg_quantity}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </Detail>
            )}
          </>
        </Container>
      )}
    </>
  );
};

export default UsersProduction;

export const Container = styled.div`
  text-align: center;
  color: #7d7d7d;
  margin-top: 40px;

  .detalhes {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    font-size: 11px;

    :hover {
      color: #fab702;
      cursor: pointer;

      svg {
        animation: animate__pulse;
        animation-duration: 2s;
      }
    }
  }
`;

export const Report = styled.div`
  text-align: center;
  overflow: auto;
  background: #f5f5f5;

  .report {
    width: 100%;
    text-align: center;
  }
`;

export const Detail = styled.div`
  margin-top: 10px;

  .btn-no-border-primary {
    width: 200px;
  }

  .bg-secondary {
    background-color: #e8e8e8 !important;
  }

  .table {
    th {
      color: #7d7d7d !important;
    }

    .name {
      color: #7d7d7d !important;
    }
  }
`;

export const TooltipReport = styled.div`
  background-color: #fff;
  border-radius: 3px;
  border: 1px solid #7d7d7d;
  padding: 5px;
  display: flex;
  justify-content: center;
  flex-direction: column;

  .separator {
    margin-top: -10px !important;
  }

  span {
    font-weight: bold;
    font-size: 14px;
    color: #fab702;
  }

  p {
    font-size: 10px;
    margin-top: -5px;
  }

  b {
    font-size: 18px;
  }
`;
