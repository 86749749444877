// import { logoff } from './auth'
import { toastError } from './toast';

export const handleError = (error) => {
  const erro = error.response;

  // eslint-disable-next-line no-console
  console.log(erro);

  if (erro === undefined) {
    toastError(
      'Atenção!',
      'Servidor não encontrado! Entre em contato com o suporte!'
    );
    return {
      error: {
        title: 'Atenção! ',
        message: 'Servidor não encontrado! Entre em contato com o suporte!',
      },
    };
  }

  switch (erro.status) {
    case 400:
      if (
        erro.data.messages &&
        erro.data.messages[0].message ===
          'value_error.missing: That email is already taken. Please try register with another one.'
      ) {
        toastError('Atenção!', 'E-mail inserido já está em uso.');
        return {
          error: {
            title: 'Atenção! ',
            message: 'E-mail inserido já está em uso.',
          },
        };
      }

      if (
        erro.data.message &&
        erro.data.message ===
          'duplicate key value violates unique constraint "orders_tenant_id_and_sig_number_ukey"'
      ) {
        toastError(
          'Atenção!',
          'Pedido duplicado. Pedido já lançado no sistema.'
        );
        return {
          error: {
            title: 'Atenção! ',
            message: 'Pedido duplicado. Pedido já lançado no sistema.',
          },
        };
      }

      if (
        erro.data.message &&
        erro.data.message ===
          'duplicate key value violates unique constraint "persons_tenant_id_and_troll_code_ukey"'
      ) {
        toastError(
          'Atenção!',
          'ID Troll duplicado. Já existe uma pessoa com esse Id TROLL cadastrado no sistema.'
        );
        return {
          error: {
            title: 'Atenção! ',
            message: `ID Troll duplicado. Já existe uma pessoa com esse Id TROLL cadastrado no sistema.`,
          },
        };
      }

      if (
        erro.data.message &&
        erro.data.message ===
          'update or delete on table "persons" violates foreign key constraint "orders_person_id_fkey" on table "orders"'
      ) {
        toastError('Atenção!', 'Não é possível excluir/alterar esse registro.');
        return {
          error: {
            title: 'Atenção! ',
            message: 'Não é possível excluir/alterar esse registro.',
          },
        };
      }

      if (
        erro.data.messages &&
        erro.data.messages[0].message ===
          'value_error.missing: That username is already taken. Please try another one.'
      ) {
        toastError('Atenção!', 'Username inserido já está em uso.');
        return {
          error: {
            title: 'Atenção! ',
            message: 'Username inserido já está em uso.',
          },
        };
      }

      if (
        erro.data.messages &&
        erro.data.messages[0].message ===
          'value_error.missing: That username is already taken. Please try another one.'
      ) {
        toastError('Atenção!', 'Username inserido já está em uso.');
        return {
          error: {
            title: 'Atenção! ',
            message: 'Username inserido já está em uso.',
          },
        };
      }

      if (
        erro.data &&
        erro.data.message ===
          'duplicate key value violates unique constraint "products_tenant_id_and_name_index"'
      ) {
        toastError('Atenção!', 'Este produto já está cadastrado.');
        return {
          error: {
            title: 'Atenção! ',
            message: 'Este produto já está cadastrado.',
          },
        };
      }

      if (
        erro.data &&
        erro.data.message ===
          'duplicate key value violates unique constraint "unit_sellers_unit_id_and_user_id_ukey"'
      ) {
        toastError('Atenção!', 'Este vendedor já esta inserido nessa unidade.');
        return {
          error: {
            title: 'Atenção! ',
            message: 'Este vendedor já esta inserido nessa unidade.',
          },
        };
      }

      if (
        erro.data &&
        erro.data.message ===
          'duplicate key value violates unique constraint "orders_unit_id_and_person_id_and_sig_number_ukey"'
      ) {
        toastError('Atenção!', 'Número SIGI já cadastrado em outro pedido.');
        return {
          error: {
            title: 'Atenção! ',
            message: 'Número SIGI já cadastrado em outro pedido.',
          },
        };
      }

      if (
        erro.data.detail &&
        erro.data.detail[0].message ===
          'duplicate key value violates unique constraint "roles_users_role_id_and_user_id_ukey"'
      ) {
        toastError('Atenção!', 'Perfil já inserido para este usuário.');
        return {
          error: {
            title: 'Atenção! ',
            message: 'Perfil já inserido para este usuário.',
          },
        };
      }

      if (
        erro.data.detail &&
        erro.data.detail[0].message ===
          'value_error.missing: That cpf_cnpj is already taken. Please try register with another one.'
      ) {
        toastError('Atenção!', 'CPF/CNPJ já cadastrado na base de dados.');
      }

      if (
        erro.data &&
        erro.data.message ===
          'update or delete on table "orders" violates foreign key constraint "orders_spcfiles_order_id_fkey" on table "orders_spcfiles"'
      ) {
        toastError('Erro!', 'Pedido não pode ser excluído.');
      }

      if (
        erro.data &&
        erro.data.message ===
          'duplicate key value violates unique constraint "orders_products_order_id_and_product_id_ukey"'
      ) {
        toastError('Erro!', 'Produto já incluído no pedido.');
        return {
          error: {
            title: 'Erro! ',
            message: 'Produto já incluído no pedido.',
          },
        };
      }

      break;
    case 401:
      if (
        erro.data.messages &&
        erro.data.messages[0].message ===
          'value_error.missing: Could not validate token credentials.'
      ) {
        toastError(
          'Atenção!',
          'Não foi possível validar as credenciais do token.'
        );
        return {
          error: {
            title: 'Não Autorizado! ',
            message: 'Não foi possível validar as credenciais do token.',
          },
        };
      }

      if (
        erro.data.messages &&
        erro.data.messages[0].message ===
          'value_error.missing: Authentication was unsuccessful.'
      ) {
        toastError('Não Autorizado!', 'E-mail ou senha inválido.');
        return {
          error: {
            title: 'Não Autorizado! ',
            message: 'E-mail ou senha inválido.',
          },
        };
      }

      if (
        erro.data.messages &&
        erro.data.messages[0].message ===
          'value_error.missing: Not an active user.'
      ) {
        return {
          error: {
            title: 'Não Autorizado! ',
            message: 'Seu usuário está inativado.',
          },
        };
      }

      if (
        erro.data.messages &&
        erro.data.messages[0].message ===
          'value_error.missing: Host not authorized.'
      ) {
        toastError('Não Autorizado!', 'Host não autorizado.');
        return {
          error: {
            title: 'Não Autorizado! ',
            message: 'Host não autorizado.',
          },
        };
      }

      if (
        (erro.data.messages &&
          erro.data.messages[0].message ===
            'value_error.missing: Not authenticated') ||
        (erro.data.messages &&
          erro.data.messages[0].message ===
            'value_error.missing: No authenticated user.') ||
        (erro.data.messages &&
          erro.data.messages[0].message ===
            'value_error.missing: Could not validate token credentials.')
      ) {
        toastError(
          'Não Autorizado!',
          'Não foi possível validar as credenciais do token.'
        );

        localStorage.removeItem('vo_access_token');

        // setTimeout(() => {
        //   window.location.href = '/login';
        // }, 3000);

        return {
          error: {
            title: 'Não Autorizado! ',
            message: 'Usuário não autorizado.',
          },
        };
      }

      if (
        erro.data.messages &&
        erro.data.messages[0].message ===
          'value_error.missing: Host not authorized.'
      ) {
        toastError('Não Autorizado!', 'Host não autorizado.');
      }

      if (
        erro.data.messages &&
        erro.data.messages[0].message ===
          'value_error.missing: Unauthorized user.'
      ) {
        toastError('Não Autorizado!', 'Usuário não autorizado.');

        return {
          error: {
            title: 'Não Autorizado! ',
            message: 'Usuário não autorizado.',
          },
        };
        // window.location.href = '/unauthorized';
      }

      break;

    case 403:
      if (
        erro.data.messages &&
        erro.data.messages[0].message ===
          'value_error.missing: The seller_id informed does not correspond to a valid seller.'
      ) {
        toastError(
          'Atenção!',
          'O vendedor informado não pertence a unidade informada!'
        );
      }

      break;

    case 404:
      if (erro.data.detail === 'Not Found') {
        toastError('Nenhum registro encontrado.');
      }

      break;

    case 422:
      if (
        erro.data.messages &&
        erro.data.messages[0].message ===
          'value_error.email: email is value is not a valid email address'
      ) {
        toastError('E-mail inválido', 'E-mail inserido é inválido.');
        return {
          error: {
            title: 'E-mail inválido! ',
            message: 'E-mail inserido é inválido.',
          },
        };
      }

      if (
        erro.data.messages &&
        erro.data.messages[0].message ===
          'type_error.none.not_allowed: addresses is none is not an allowed value'
      ) {
        toastError('Endereço inválido', 'Endereço inserido é inválido.');
        return {
          error: {
            title: 'Endereço inválido! ',
            message: 'Endereço inserido é inválido.',
          },
        };
      }

      if (
        erro.data.messages &&
        erro.data.messages[0].message ===
          'value_error.missing: Order must be status in editing to delete photo files.'
      ) {
        toastError(
          'Atenção!',
          'Só é permitido remover fotos com o pedido em EDIÇÃO!'
        );
        return {
          error: {
            title: 'Atenção!',
            message: 'Só é permitido remover fotos com o pedido em EDIÇÃO!',
          },
        };
      }

      if (
        erro.data.messages &&
        erro.data.messages[0].message ===
          'value_error: payment_first_date is payment_first_date must be equal or later than the current date'
      ) {
        toastError(
          'Atenção',
          'Data de vencimento não pode ser inferior a data atual.'
        );
        return {
          error: {
            title: 'Atenção! ',
            message: 'Data de vencimento não pode ser inferior a data atual.',
          },
        };
      }

      if (
        erro.data.messages &&
        erro.data.messages[0].message ===
          'value_error.missing: This order has a plotter product, it is necessary to send it for plotting.'
      ) {
        toastError(
          'ERRO',
          'Este pedido contém um produto do tipo PLOTTER. Necessário enviar para Plotagem.'
        );
        return {
          error: {
            title: 'ERRO! ',
            message:
              'Este pedido contém um produto do tipo PLOTTER. Necessário enviar para Plotagem.',
          },
        };
      }

      if (
        erro.data.messages &&
        erro.data.messages[0].message === 'assertion_error: phones is '
      ) {
        toastError('Erro', 'Telefone/Celular inválido.');
        return {
          error: {
            title: 'Erro! ',
            message: 'Telefone/Celular inválido.',
          },
        };
      }

      if (
        erro.data.messages &&
        erro.data.messages[0].message ===
          'value_error.missing: file type must be JPG, PNG or PDF type.'
      ) {
        toastError(
          'Atenção',
          'Tipo de imagem inválida, use apenas (.png, .jpg).'
        );
        return {
          error: {
            title: 'Atenção! ',
            message: 'Tipo de imagem inválida, use apenas (.png, .jpg).',
          },
        };
      }

      if (
        erro.data.messages &&
        erro.data.messages[0].message ===
          'value_error.missing: sig_number_edited is field required'
      ) {
        toastError('Atenção', 'Nº SIGI das fotos editadas é obrigatório.');
        return {
          error: {
            title: 'Atenção! ',
            message: 'Nº SIGI das fotos editadas é obrigatório.',
          },
        };
      }

      if (
        erro.data.messages &&
        erro.data.messages[0].message ===
          'value_error.missing: Order must be status in inclusion to add spc files.'
      ) {
        toastError(
          'Erro',
          'O pedido deve ter status de inclusão para adicionar arquivos.'
        );
        return {
          error: {
            title: 'Erro! ',
            message:
              'O pedido deve ter status de inclusão para adicionar arquivos.',
          },
        };
      }

      if (
        erro.data.messages &&
        erro.data.messages[0].message ===
          'value_error.missing: Order needs at least one product.'
      ) {
        toastError('Erro', 'O pedido deve ter no mínimo um produto.');
        return {
          error: {
            title: 'Erro! ',
            message: 'O pedido deve ter no mínimo um produto.',
          },
        };
      }

      if (
        erro.data.messages &&
        erro.data.messages[0].message ===
          'value_error.missing: Order needs at least one SPC file.'
      ) {
        toastError('Erro', 'O pedido deve ter no mínimo um arquivo SPC.');
        return {
          error: {
            title: 'Erro! ',
            message: 'O pedido deve ter no mínimo um arquivo SPC.',
          },
        };
      }

      if (
        erro.data.messages &&
        erro.data.messages[0].message ===
          'value_error.missing: Order must be status in edition to add photos.'
      ) {
        toastError(
          'Erro',
          'O pedido deve ter status de edição para adicionar fotos.'
        );
        return {
          error: {
            title: 'Erro! ',
            message: 'O pedido deve ter status de edição para adicionar fotos.',
          },
        };
      }

      if (
        erro.data.messages &&
        erro.data.messages[0].message ===
          'value_error.missing: Order must be status in inclusion to delete spc files.'
      ) {
        toastError(
          'Erro',
          'O pedido deve ter status de inclusão para remover arquivos spc.'
        );
        return {
          error: {
            title: 'Erro! ',
            message:
              'O pedido deve ter status de inclusão para remover arquivos spc.',
          },
        };
      }

      if (
        erro.data.messages &&
        erro.data.messages[0].message ===
          'value_error.missing: order_status must be to subsequent to current status.'
      ) {
        toastError('Erro!', 'Erro ao alterar status do pedido');
        return {
          error: {
            title: 'Erro!! ',
            message: 'Erro ao alterar status do pedido',
          },
        };
      }

      if (
        erro.data.messages &&
        erro.data.messages[0].message ===
          'value_error.missing: The order must have the photos added and to finish editing.'
      ) {
        toastError('Erro!', 'Pedido sem fotos editadas');
        return {
          error: {
            title: 'Erro!! ',
            message: 'Pedido sem fotos editadas',
          },
        };
      }

      if (
        erro.data.messages &&
        erro.data.messages[0].message ===
          'value_error: delivery_date is delivery_date must be greater than the current date'
      ) {
        toastError(
          'Data inválida',
          'Data de entrega deve ser diferente da data atual.'
        );
      }

      if (
        erro.data.messages &&
        erro.data.messages[0].message ===
          'value_error.missing: Order must be status in inclusion to add products.'
      ) {
        toastError(
          'Atenção!',
          'O pedido deve ter status de inclusão para adicionar produtos.'
        );
        return {
          error: {
            title: 'Atenção!',
            message:
              'O pedido deve ter status de inclusão para adicionar produtos.',
          },
        };
      }

      if (
        erro.data.messages &&
        erro.data.messages[0].message ===
          'value_error.str.regex: username is string does not match regex "^[a-zA-Z0-9_-]+$"'
      ) {
        toastError('E-mail inserido já está em uso.');
      }

      if (
        erro.data.messages &&
        erro.data.messages[0].message ===
          'type_error.integer: troll_code is value is not a valid integer'
      ) {
        toastError('Código TROLL!', 'Número inválido.');
      }

      if (
        erro.data.messages &&
        erro.data.messages[0].message === 'assertion_error: phones is '
      ) {
        toastError('Telefone inválido', 'Formato de telefone ou nº inválido.');
      }

      if (
        erro.data.messages &&
        erro.data.messages[0].message ===
          'value_error.number.not_gt: price is ensure this value is greater than 0'
      ) {
        toastError(
          'Preço inválido',
          'Preço do produto precisa ser maior que ZERO.'
        );
      }

      if (erro.data.detail && erro.data.detail[0].msg === 'field required') {
        toastError('Erro', 'Campo inválido.');
        return {
          error: {
            title: 'Erro! ',
            message: 'Campo inválido.',
          },
        };
      }

      break;

    default:
      break;
  }
  return null;
};
