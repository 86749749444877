import { createContext, useState, useEffect, useCallback } from 'react';

import PropTypes from 'prop-types';

import { authenticate } from './authenticate';
import { index } from './base-rest';
import { getProfileMeFull, getThumbnailSW3Url } from './user.service';

export const AuthContext = createContext({});

const AuthProvider = ({ children }) => {
  const [user, setUser] = useState();
  const [thumbnailProfile, setThumbnailProfile] = useState(null);
  const isAuthenticated = !!user;
  const [changingPassword, setChangingPassword] = useState(false);
  const [isLoading, setLoading] = useState(false);

  const changeThumbnailProfile = useCallback(async () => {
    const response_thumbnail = await getThumbnailSW3Url();

    if (!response_thumbnail?.error) setThumbnailProfile(response_thumbnail);

    setLoading(false);
  }, []);

  const prepareCityProfile = useCallback(async (profile) => {
    const response_units = await index('units', { per_page: 500 });

    for (let i = 0; i < response_units.rows.length; i++) {
      const unit = response_units.rows[i];

      // eslint-disable-next-line no-await-in-loop
      const sellers = await index(`units/${unit.id}/sellers`);

      for (let x = 0; x < sellers?.length; x++) {
        const seller = sellers[x];

        if (seller.username === profile.username) {
          profile.city = unit.city;
        }
      }
    }

    return profile;
  }, []);

  const signIn = async ({ username, password }) => {
    const response = await authenticate(username, password);

    if (!response.error) {
      localStorage.setItem(
        'vo_access_token',
        JSON.stringify(response.access_token)
      );

      changeThumbnailProfile();

      let profile = await getProfileMeFull();

      if (profile.roles.findIndex((item) => item.slug === 'seller') > -1) {
        profile = await prepareCityProfile(profile);
      }

      setUser(profile);
    }

    return response;
  };

  const signOut = useCallback(() => {
    setUser(null);

    URL.revokeObjectURL(thumbnailProfile);

    setThumbnailProfile(null);

    localStorage.removeItem('vo_access_token');
  }, [thumbnailProfile]);

  const reloadUser = useCallback(async () => {
    setLoading(true);

    let profile = await getProfileMeFull();

    if (!profile.error) {
      if (profile.roles.findIndex((item) => item.slug === 'seller') > -1) {
        profile = await prepareCityProfile(profile);
      }

      setUser(profile);

      changeThumbnailProfile();
    }

    setLoading(false);
  }, []);

  const handleChangingPassword = useCallback((value) => {
    setChangingPassword(value);
  }, []);

  const hasRole = useCallback(
    (value) => user.roles.findIndex((item) => item.slug === value) > -1,
    [user]
  );

  const hasRoles = useCallback(
    (values) => {
      const have_roles = user.roles.filter((item) =>
        values.includes(item.slug)
      );

      return have_roles.length >= 1;
    },
    [user]
  );

  const hasPermissions = useCallback(
    (values) => {
      const all_permissions = user.permissions.concat(user.extra_permissions);

      const have_permissions = all_permissions.filter((item) =>
        values.includes(item.slug)
      );

      return have_permissions.length >= 1;
    },
    [user]
  );

  useEffect(() => {
    const access_token = JSON.parse(localStorage.getItem('vo_access_token'));
    if (access_token) reloadUser();
  }, []);

  return (
    <AuthContext.Provider
      value={{
        isAuthenticated,
        user,
        isLoading,
        changingPassword,
        thumbnailProfile,
        signIn,
        signOut,
        handleChangingPassword,
        hasRole,
        setLoading,
        changeThumbnailProfile,
        hasPermissions,
        hasRoles,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default AuthProvider;
