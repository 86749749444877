import React, { useRef, useCallback } from 'react';
import { AiOutlineClear } from 'react-icons/ai';
import { MdClose } from 'react-icons/md';
import ReactTooltip from 'react-tooltip';

import { Form } from '@unform/web';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import Input from '../../../components/unform/input';

const UsersFilter = ({
  isFilter,
  setIsFilter,
  setParams,
  params,
  default_params,
}) => {
  const formRef = useRef(null);

  const clearFieldsSearch = useCallback(() => {
    formRef.current.setFieldValue('full_name', '');
    setParams(default_params);
  }, []);

  const filterData = useCallback(async (value) => {
    if (value.length >= 3) {
      setParams((prevState) => ({
        ...prevState,
        full_name: value,
      }));
    } else if (value.length === 0) {
      setParams(default_params);
    }
  }, []);

  return (
    <>
      {isFilter && (
        <>
          <CloseSearch
            className="btn btn-outline-primary text-center"
            onClick={() => {
              setIsFilter(false);
              clearFieldsSearch();
            }}
          >
            <MdClose size={25} data-tip="Fechar" />
            <ReactTooltip />
          </CloseSearch>

          <Search>
            <Form ref={formRef} noValidate className="col col-lg-8 p-4">
              <div className="row justify-content-center">
                <div className="col-8 mt-2 d-flex">
                  <Input
                    name="full_name"
                    placeholder="Por nome"
                    onChange={(e) => filterData(e.target.value)}
                  />

                  {params.full_name && (
                    <ClearSearch onClick={clearFieldsSearch}>
                      <AiOutlineClear size={30} data-tip="Limpar campos" />
                      <ReactTooltip />
                    </ClearSearch>
                  )}
                </div>
              </div>
            </Form>
          </Search>
        </>
      )}
    </>
  );
};

UsersFilter.propTypes = {
  isFilter: PropTypes.bool.isRequired,
  setIsFilter: PropTypes.func.isRequired,
  setParams: PropTypes.func.isRequired,
  params: PropTypes.shape().isRequired,
  default_params: PropTypes.shape().isRequired,
};

export default UsersFilter;

export const Search = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  background-color: #f5f5f5;
`;

export const ClearSearch = styled.div`
  color: #d11515;

  svg {
    margin-top: 5px;
    margin-left: 5px;
  }

  :hover {
    cursor: pointer;
    color: #ec4646;
  }
`;

export const CloseSearch = styled.button`
  position: absolute;
  margin-left: -70px;
  margin-top: 6px;
  height: 35px !important;
`;
