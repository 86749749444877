import { Link } from 'react-router-dom';

import styled from 'styled-components';

const PageNotFound = () => (
  <Container className="row p-2 mt-2 justify-content-center">
    <div className="col-12 col-md-9 card-error text-center">
      <h1 className="mt-5">404</h1>
      <h3 className=" mt-5">Página não encontrada</h3>
      <p>
        Parece que a página que você estava tentando acessar não foi localizada.
      </p>
      <p>
        <b>Confira com o administrador do sistema!</b>
      </p>
      <Link to="/">
        <button className="btn btn-secondary m-5 col-5" type="button">
          Retorne a página inicial
        </button>
      </Link>
    </div>
  </Container>
);

export default PageNotFound;

export const Container = styled.div`
  height: 100vh;
  h1 {
    font-size: 128px;
    text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.5);
    margin-bottom: 20px;
    color: #ffcf4c;
  }

  h3 {
    text-transform: uppercase;
    text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.5);
    font-size: 50px;
    margin-bottom: 40px;
    color: #ffcf4c;
  }

  .btn-secondary {
    height: 60px;
  }
`;
