import { useContext } from 'react';
import { IoMdHome } from 'react-icons/io';
import { useSelector } from 'react-redux';
import { Link, withRouter, useHistory } from 'react-router-dom';

import styled from 'styled-components';

import { AuthContext } from '../../actions/auth-context';

const Breadcrumb = () => {
  const history = useHistory();

  const data = useSelector((state) => state.data);

  const { isAuthenticated } = useContext(AuthContext);

  const { pathname } = history.location;

  return (
    <>
      {isAuthenticated &&
        pathname !== '/unauthorized' &&
        pathname !== '/page-not-found' && (
          <Container className="container">
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  {data.length === 0 ? (
                    <>
                      <IoMdHome size={18} /> {data.length === 0 ? 'HOME' : ''}
                    </>
                  ) : (
                    <Link to="/">
                      <IoMdHome size={18} /> {data.length === 0 ? 'HOME' : ''}
                    </Link>
                  )}
                </li>

                {data.map((item) => (
                  <li className="breadcrumb-item " key={item.page}>
                    {item.url ? (
                      <Link to={item.url}>{item.page}</Link>
                    ) : (
                      item.page
                    )}
                  </li>
                ))}
              </ol>
            </nav>
          </Container>
        )}
    </>
  );
};

export default withRouter(Breadcrumb);

export const Container = styled.div`
  height: 30px;

  svg {
    margin-top: -5px;
  }

  ul {
    list-style-type: none;
  }

  li {
    font-size: 12px;
    text-transform: uppercase;
    margin-top: 5px;
  }

  a {
    text-decoration: none;
    color: #4f4f4f;

    :hover {
      color: #ffba00;
    }
  }
`;
