import { useContext, useCallback } from 'react';
import { IoIosTrash } from 'react-icons/io';
import { useHistory } from 'react-router';
import ReactTooltip from 'react-tooltip';

import PropTypes from 'prop-types';
import styled from 'styled-components';

import { AuthContext } from '../../../actions/auth-context';
import SortButton from '../../../components/sort-desc/SortButton';

const UsersTable = ({ users, page, setItemDelete, params, setParams }) => {
  const history = useHistory();

  const { user } = useContext(AuthContext);

  const sortUsers = useCallback(
    (field, sort) => {
      setParams((prevState) => ({
        ...prevState,
        sorts: `${field}:${sort}`,
        per_page: users.length,
      }));
    },
    [users]
  );

  return (
    <Container>
      <div className="col-12 text-center mt-5">
        <h6>
          TOTAL DE REGISTROS - <b>{page?.total}</b>
        </h6>
      </div>

      <div className="row mt-3">
        <div className="col">
          <div className="table-responsive">
            <table className="table table-hover text-center">
              <thead>
                <tr>
                  <th scope="col" className="text-start">
                    <SortButton
                      label="NOME"
                      params={params}
                      ascFunction={() => sortUsers('full_name', 'ASC')}
                      descFunction={() => sortUsers('full_name', 'DESC')}
                    />
                  </th>
                  <th scope="col" className="text-start">
                    USERNAME
                  </th>
                  <th scope="col" className="text-start">
                    E-MAIL
                  </th>
                  <th scope="col">TELEFONE</th>
                  <th scope="col" className="text-end">
                    AÇÃO
                  </th>
                </tr>
              </thead>

              <tbody>
                {users.map((item) => (
                  <tr
                    key={item.id}
                    onClick={(e) => {
                      if (e.target.innerText !== undefined) {
                        history.push(`users/${item.id}`);
                      }
                    }}
                  >
                    <td className="text-start">{item.full_name}</td>
                    <td className="text-start">{item.username}</td>
                    <td className="text-start">{item.email}</td>
                    <td>{item.cell_phone}</td>

                    <td className="text-end">
                      <RemoveItem
                        disabled={
                          item.username === 'master' || item.id === user.id
                        }
                      >
                        {item.username !== 'master' ? (
                          <IoIosTrash
                            size={25}
                            className="mb-1"
                            data-tip="Excluir"
                            onClick={() =>
                              setItemDelete({
                                id: item.id,
                                description: item.full_name,
                              })
                            }
                            data-bs-toggle="modal"
                            data-bs-target="#modal-remove-user"
                          />
                        ) : (
                          <IoIosTrash
                            size={25}
                            className="mb-1"
                            data-tip="Excluir"
                          />
                        )}
                        <ReactTooltip />
                      </RemoveItem>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </Container>
  );
};

UsersTable.propTypes = {
  users: PropTypes.arrayOf(Object).isRequired,
  page: PropTypes.shape().isRequired,
  setItemDelete: PropTypes.func.isRequired,
  setParams: PropTypes.func.isRequired,
  params: PropTypes.shape().isRequired,
};

export default UsersTable;

export const Container = styled.section`
  /* text-transform: uppercase; */

  h6 {
    font-size: 12px;
  }

  .table-hover {
    cursor: pointer;
  }

  @media screen and (max-width: 768px) {
    .table {
      width: 900px !important;
    }
  }
`;

export const RemoveItem = styled.div`
  color: ${(props) => (props.disabled ? '#4F4F4F' : '#d11515')};

  :hover {
    cursor: ${(props) => (props.disabled ? 'default' : 'pointer')};
    color: ${(props) => (props.disabled ? '#4F4F4F' : '#ec4646')};
  }
`;
