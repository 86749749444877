/* eslint-disable no-unused-vars */
import { useEffect, useCallback, useState, useRef } from 'react';
import { BsChevronCompactDown } from 'react-icons/bs';
import { FaFilter } from 'react-icons/fa';
import ReactTooltip from 'react-tooltip';

import 'animate.css';

import { Form } from '@unform/web';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import * as Yup from 'yup';

import { getUsersProductionByEditorApi } from '../../actions/dashbords.service';
import { dateToStringWithTime } from '../../commons/date';
import InputDateLabel from '../../components/unform/input-date-label';

const EditorProductionById = ({ user }) => {
  const formRef = useRef(null);

  const [value, setValue] = useState({});

  const [params, setParams] = useState({});

  const [openDateForm, setOpenDateForm] = useState(false);

  const getUsersProductionByEditor = useCallback(async () => {
    const result = await getUsersProductionByEditorApi(user.id, params);
    if (result) setValue(result[0]);
  }, [user, params]);

  const submit = useCallback(
    async (data) => {
      try {
        formRef.current.setErrors({});
        const schema = Yup.object().shape({
          start_date: Yup.date().nullable(),
          end_date: Yup.date().nullable(),
        });

        await schema.validate(data, { abortEarly: false });

        data.start_date = await dateToStringWithTime(data.start_date);

        data.end_date = await dateToStringWithTime(data.end_date);

        setParams(data);
      } catch (error) {
        const errorMessages = {};
        if (error instanceof Yup.ValidationError) {
          error.inner.forEach((err) => {
            errorMessages[err.path] = err.message;
          });
          formRef.current.setErrors(errorMessages);
        }
      }
    },
    [formRef]
  );

  useEffect(() => {
    getUsersProductionByEditor(params);
  }, [params]);

  return (
    <Container>
      <h5 className="animate__animated animate__pulse text-center">
        QUANTIDADE FOTOS (30 dias)
      </h5>

      {!openDateForm && (
        <div
          className="detalhes"
          role="button"
          tabIndex="0"
          onKeyPress={() => {}}
          onClick={() => setOpenDateForm(true)}
        >
          <span>Filtrar por data</span>

          <div>
            <BsChevronCompactDown />
          </div>
        </div>
      )}

      {openDateForm && (
        <Form ref={formRef} onSubmit={submit} noValidate>
          <ContainerForm>
            <Close
              onClick={() => {
                setParams({});

                setOpenDateForm(false);
              }}
            >
              X
            </Close>

            <div className="row justify-content-center">
              <div className="col text-center mb-2">
                <span>FILTRAR POR DATA</span>
              </div>
            </div>

            <div className="row justify-content-center">
              <div className="col-sm-6 col-md-2">
                <InputDateLabel name="start_date" label="data inicial" />
              </div>

              <div className="col-sm-6 col-md-2">
                <InputDateLabel name="end_date" label="data final" />
              </div>

              <Button type="submit" className="col-1 d-none d-md-block">
                <FaFilter data-tip="Filtrar" size={20} />
                <ReactTooltip />
              </Button>

              <div className="row d-sm-block d-md-none mt-2">
                <div className="col-sm p-0">
                  <button
                    type="submit"
                    className="btn btn-primary btn-block col-12"
                  >
                    Filtrar
                  </button>
                </div>
              </div>
            </div>
          </ContainerForm>
        </Form>
      )}

      <Detail>
        <div className="table-responsive">
          <table className="table table-striped">
            <thead>
              <tr>
                <th scope="col">QUANTIDADE</th>
                <th scope="col">MÉDIA POR DIA</th>
              </tr>
            </thead>
            <tbody>
              <tr key={value?.id}>
                <td>{value?.quantity || 0}</td>

                <td>{value?.avg_quantity || 0}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </Detail>
    </Container>
  );
};

EditorProductionById.propTypes = {
  user: PropTypes.shape({
    id: PropTypes.string,
  }).isRequired,
};

export default EditorProductionById;

export const Container = styled.div`
  text-align: center;
  color: #7d7d7d;
  margin-top: 40px;

  .detalhes {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    font-size: 11px;

    :hover {
      color: #fab702;
      cursor: pointer;

      svg {
        animation: animate__pulse;
        animation-duration: 2s;
      }
    }
  }
`;

export const Report = styled.div`
  text-align: center;
  overflow: auto;
  background: #f5f5f5;

  .report {
    width: 100%;
    text-align: center;
  }
`;

export const Detail = styled.div`
  margin-top: 10px;

  .btn-no-border-primary {
    width: 200px;
  }

  .bg-secondary {
    background-color: #e8e8e8 !important;
  }

  .table {
    th {
      color: #7d7d7d !important;
    }

    .name {
      color: #7d7d7d !important;
    }
  }
`;

export const TooltipReport = styled.div`
  background-color: #fff;
  border-radius: 3px;
  border: 1px solid #7d7d7d;
  padding: 5px;
  display: flex;
  justify-content: center;
  flex-direction: column;

  .separator {
    margin-top: -10px !important;
  }

  span {
    font-weight: bold;
    font-size: 14px;
    color: #fab702;
  }

  p {
    font-size: 10px;
    margin-top: -5px;
  }

  b {
    font-size: 18px;
  }
`;

export const Button = styled.button`
  background-color: #fab702;
  padding: 10px;
  border-radius: 3px;
  color: #fff;
  height: 40px;
  margin-top: 14px;
  width: 50px;
  border: 0;

  :hover {
    cursor: pointer;
    background-color: #ffcf4c;
  }
`;

export const ContainerForm = styled.div`
  margin-top: 10px;
  background-color: #f5f5f5;
  padding: 5px 20px 20px 20px;
  align-items: center;
  font-weight: bold;

  span {
    font-size: 10px;
    font-weight: bold;
  }

  .close {
    width: 40px;
    border: 1px solid #fab702;
  }
`;

export const Close = styled.button`
  background-color: #d11515;
  border-color: #d11515;
  border-radius: 3px;
  color: #fff;
  height: 30px;
  margin-top: 5px;
  width: 30px;
  border: 0;
  float: right;

  :hover {
    cursor: pointer;
    background: #ec4646 !important;
    border-color: #d11515 !important;
  }
`;
