import { useContext } from 'react';
import { AiOutlineUser, AiOutlineLock, AiOutlineLogout } from 'react-icons/ai';
import { Link } from 'react-router-dom';

import styled from 'styled-components';

import { AuthContext } from '../../actions/auth-context';
import Profile from '../profile';

const Navbar = () => {
  const { user, signOut, handleChangingPassword } = useContext(AuthContext);

  return (
    <Container user_id={user?.id}>
      <nav className="navbar navbar-expand-lg navbar-dark bg-dark">
        <div className="container-fluid ">
          {user?.id && (
            <button
              className="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarTogglerDemo01"
              aria-controls="navbarTogglerDemo01"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon" />
            </button>
          )}

          <div className="col col-lg-12 text-center">
            {window.location.pathname.includes('access-order') ? (
              <div to="/" className="navbar-brand">
                <img
                  src="/img/vic-os-logo2.png"
                  width="150"
                  alt="Logo VIC-OS"
                />
              </div>
            ) : (
              <Link to="/" className="navbar-brand">
                <img src="/img/vic-os-logo.png" width="150" alt="Logo VIC-OS" />
              </Link>
            )}
          </div>

          <Profile />

          {user?.id && (
            <div className="collapse navbar-collapse " id="navbarTogglerDemo01">
              <ul className="navbar-nav me-auto mb-2 mb-lg-0 d-lg-none">
                <li className="nav-item text-white ">{user?.full_name}</li>

                <li className="nav-item mt-3">
                  <Link className="nav-link d-flex p-0" to="/profile-me">
                    <AiOutlineUser size={16} /> <p> meus dados</p>
                  </Link>
                </li>

                <li className="nav-item">
                  <Link
                    className="nav-link d-flex p-0"
                    to="/"
                    data-bs-toggle="modal"
                    data-bs-target="#modal-confirm-change-password"
                    onClick={() => handleChangingPassword(true)}
                  >
                    <AiOutlineLock size={16} /> <p> alterar senha</p>
                  </Link>
                </li>

                <li className="nav-item">
                  <Link
                    className="nav-link d-flex p-0"
                    to="/"
                    onClick={signOut}
                  >
                    <AiOutlineLogout size={16} /> <p> sair</p>
                  </Link>
                </li>
              </ul>
            </div>
          )}
        </div>
      </nav>

      <div className="line" />
    </Container>
  );
};

export default Navbar;

export const Container = styled.div`
  p {
    font-size: 12px;
    text-transform: uppercase;
    margin-left: 5px;
  }

  .navbar-dark {
    background-color: #464646 !important;
    background-color: ${() => {
      if (window.location.pathname.includes('access-order')) return '#ffff';

      return '#464646';
    }} !important;
  }

  .line {
    height: 6px;
    background-color: #fab702;
    margin-top: 5px;
    box-shadow: 0 2px 2px 1px rgba(0, 0, 0, 0.2);
  }

  .navbar-toggler {
    /* border-color: #fff; */
  }
  .navbar-toggler-icon {
    border-color: #fff !important;
  }
`;
