let strCpf = null;
let soma = 0;
let resto = 0;
let strCnpj = null;
let strNumeros = null;
let tamanho = 0;
let strDigitos = null;
let pos = 0;
let resultado = 0;

export function isValidCpf(cpf) {
  if (!cpf) {
    return true;
  }

  strCpf = null;

  strCpf = cpf.replace(/[^\d]+/g, '');

  if (
    strCpf.length !== 11 ||
    strCpf === '00000000000' ||
    strCpf === '11111111111' ||
    strCpf === '22222222222' ||
    strCpf === '33333333333' ||
    strCpf === '44444444444' ||
    strCpf === '55555555555' ||
    strCpf === '66666666666' ||
    strCpf === '77777777777' ||
    strCpf === '88888888888' ||
    strCpf === '99999999999'
  ) {
    return false;
  }

  soma = 0;

  for (let i = 1; i <= 9; i += 1) {
    soma += parseInt(strCpf.substring(i - 1, i), 10) * (11 - i);
  }

  resto = (soma * 10) % 11;

  if (resto === 10 || resto === 11) {
    resto = 0;
  }
  if (resto !== parseInt(strCpf.substring(9, 10), 10)) {
    return false;
  }

  soma = 0;

  for (let i = 1; i <= 10; i += 1) {
    soma += parseInt(strCpf.substring(i - 1, i), 10) * (12 - i);
  }
  resto = (soma * 10) % 11;

  if (resto === 10 || resto === 11) {
    resto = 0;
  }
  if (resto !== parseInt(strCpf.substring(10, 11), 10)) {
    return false;
  }

  return true;
}

export function isValidCnpj(cnpj) {
  if (!cnpj) {
    return true;
  }

  strCnpj = null;

  strCnpj = cnpj.replace(/[^\d]+/g, '');

  if (
    strCnpj.length !== 14 ||
    strCnpj === '00000000000000' ||
    strCnpj === '11111111111111' ||
    strCnpj === '22222222222222' ||
    strCnpj === '33333333333333' ||
    strCnpj === '44444444444444' ||
    strCnpj === '55555555555555' ||
    strCnpj === '66666666666666' ||
    strCnpj === '77777777777777' ||
    strCnpj === '88888888888888' ||
    strCnpj === '99999999999999'
  ) {
    return false;
  }

  tamanho = strCnpj.length - 2;
  strNumeros = strCnpj.substring(0, tamanho);
  strDigitos = strCnpj.substring(tamanho);
  soma = 0;
  pos = tamanho - 7;

  for (let i = tamanho; i >= 1; i -= 1) {
    // eslint-disable-next-line no-plusplus
    soma += parseInt(strNumeros.charAt(tamanho - i), 10) * pos--;
    if (pos < 2) {
      pos = 9;
    }
  }

  resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11);
  const digito = parseInt(strDigitos.charAt(0), 10);
  if (resultado !== digito) {
    return false;
  }

  tamanho += 1;
  strNumeros = strCnpj.substring(0, tamanho);
  soma = 0;
  pos = tamanho - 7;
  for (let i = tamanho; i >= 1; i -= 1) {
    // eslint-disable-next-line no-plusplus
    soma += parseInt(strNumeros.charAt(tamanho - i), 10) * pos--;
    if (pos < 2) {
      pos = 9;
    }
  }

  resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11);
  if (resultado !== parseInt(strDigitos.charAt(1), 10)) {
    return false;
  }

  return true;
}

export default { isValidCpf, isValidCnpj };
