/* eslint-disable no-unused-vars */
import React, { useEffect, useCallback, useState, useContext } from 'react';
import { AiFillPrinter } from 'react-icons/ai';
import { BsPuzzleFill } from 'react-icons/bs';
import {
  FaBoxOpen,
  FaBoxes,
  FaTruck,
  FaStoreAlt,
  FaUserCheck,
} from 'react-icons/fa';
import { FcStackOfPhotos } from 'react-icons/fc';
import { FiCheck } from 'react-icons/fi';
import { MdMonetizationOn } from 'react-icons/md';
import { RiImageEditFill } from 'react-icons/ri';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router';
import ImageViewer from 'react-simple-image-viewer';

import styled from 'styled-components';

import { AuthContext } from '../../actions/auth-context';
import {
  getSW3UrlThumbnailPhotos,
  getOrderbyToken,
  downloadPhotoFromOrder,
  downloadAllPhotosFromOrder,
} from '../../actions/order.service';
import { dateToStringDDMMYYY } from '../../commons/date';
import { setBreadcrumb } from '../../reducers/breadcrumb';

const ClientOrder = () => {
  const { setLoading } = useContext(AuthContext);

  const dispatch = useDispatch();

  const params = useParams();

  const [order, setOrder] = useState({});

  const [photos, setPhotos] = useState([]);

  const [images, setImages] = useState([]);

  const [currentImage, setCurrentImage] = useState(0);

  const [isViewerOpen, setIsViewerOpen] = useState(false);

  const [viewCover, setViewCover] = useState(false);

  const status = [
    {
      label: 'Em inclusão',
      value: 0,
    },
    {
      label: 'Incluído',
      value: 0,
    },
    {
      label: 'Análise financeira',
      value: 1,
    },
    {
      label: 'Financeiro negado',
      value: 1,
    },
    {
      label: 'Financeiro aprovado',
      value: 1,
    },
    {
      label: 'Em edição',
      value: 2,
    },
    {
      label: 'Editado',
      value: 2,
    },
    {
      label: 'Em impressão',
      value: 3,
    },
    {
      label: 'Impresso',
      value: 3,
    },
    {
      label: 'Em plotter',
      value: 3,
    },
    {
      label: 'Plotado',
      value: 3,
    },
    {
      label: 'Em montagem',
      value: 4,
    },
    {
      label: 'Montado',
      value: 4,
    },
    {
      label: 'Em embalagem',
      value: 5,
    },
    {
      label: 'Embalado',
      value: 5,
    },
    {
      label: 'Em expedição',
      value: 6,
    },
    {
      label: 'Enviado',
      value: 7,
    },
    {
      label: 'Recebido na unidade',
      value: 8,
    },
    {
      label: 'Entregue',
      value: 9,
    },
  ];

  const isActive = useCallback(
    (value) => {
      const index = status.filter((item) => item.label === order.order_status);

      if (index[0]?.value >= value) return 'active';

      return '';
    },
    [order, status]
  );

  const getUrl = useCallback(
    (photo) =>
      new Promise((resolve) => {
        const url = getSW3UrlThumbnailPhotos(order.id, photo.id);

        photo.url = url;

        resolve(url);
      }),
    [order]
  );

  const preparePhotos = useCallback(async () => {
    if (order.photos) {
      setLoading(true);

      const list = [];

      for (let i = 0; i < order.photos.length; i++) {
        const photo = order.photos[i];

        list.push(getUrl(photo));
      }

      const result = await Promise.all(list);

      for (let i = 0; i < result.length; i++) {
        const item = result[i];

        order.photos[i].url = item;
      }

      setPhotos(order.photos);

      setImages(order.photos.map((item) => item.url));

      setLoading(false);
    }
  }, [order]);

  const getOrderToken = useCallback(async (token) => {
    const response = await getOrderbyToken(token);
    if (!response.error) setOrder(response);
  }, []);

  const downloadPhoto = useCallback(
    async (photo) => {
      setLoading(true);

      const response = await downloadPhotoFromOrder(order.id, photo.id);

      setLoading(false);

      if (!response.error) {
        const link = document.createElement('a');

        link.href = response;

        link.setAttribute('download', photo.file_name);

        document.body.appendChild(link);

        link.click();

        link.parentNode.removeChild(link);
      }
    },
    [order]
  );

  const downloadAllPhotos = useCallback(async () => {
    setLoading(true);

    await downloadAllPhotosFromOrder(order.id, order.person.full_name);

    setLoading(false);
  }, [order]);

  const hasViewButton = useCallback(() => {
    const index = status.filter((item) => item.label === order.order_status);

    if (index[0]?.value === 9) return true;

    return false;
  }, [order, status]);

  const openImageViewer = useCallback(
    (index) => {
      setCurrentImage(index);
      setIsViewerOpen(true);
    },
    [images]
  );

  const closeImageViewer = () => {
    setCurrentImage(0);
    setIsViewerOpen(false);
  };

  useEffect(() => {
    const { token } = params;

    if (token) getOrderToken(token);

    dispatch(setBreadcrumb([{ page: 'meu pedido', url: null }]));
  }, []);

  useEffect(() => {
    if (order.photos?.length > 0) preparePhotos();
  }, [order]);

  return (
    <>
      <Container>
        <div className="container">
          <div className="row mt-4 justify-content-center">
            <div className="col-sm text-center">
              <h5>BEM VINDO!</h5>
              <h4>{order.person?.full_name}</h4>
            </div>
          </div>

          <div className="row mt-5 justify-content-center">
            <div className="col-sm col-md-4 table-responsive">
              <table className="table text-center table-borderless">
                <tbody>
                  <tr>
                    <td className="text-start p-0">Pedido</td>
                    <td className="text-end p-1">
                      <b>{order.sig_number}</b>
                    </td>
                  </tr>
                  <tr>
                    <td className="text-start p-0">Data entrega prevista</td>
                    <td className="text-end p-1">
                      <b>{dateToStringDDMMYYY(order.delivery_date)}</b>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>

        <div className="text-center mt-5 mb-2">
          <span>Confira as etapas de seu pedido</span>
        </div>

        <OrderStatus>
          <ul id="progress-bar" className="progressbar p-0">
            <li className={isActive(0)}>
              <FiCheck size={20} />
              <p> Incluído</p>
            </li>
            <li className={isActive(1)}>
              <MdMonetizationOn size={20} />
              <p> Financeiro</p>
            </li>
            <li className={isActive(2)}>
              <RiImageEditFill size={20} />
              <p>Edição</p>
            </li>
            <li className={isActive(3)}>
              <AiFillPrinter size={20} />
              <p>Impressão</p>
            </li>
            <li className={isActive(4)}>
              <BsPuzzleFill size={20} />
              <p>Montagem</p>
            </li>
            <li className={isActive(5)}>
              <FaBoxOpen size={20} />
              <p>Embalagem</p>
            </li>
            <li className={isActive(6)}>
              <FaBoxes size={20} />
              <p>Expedição</p>
            </li>
            <li className={isActive(7)}>
              <FaTruck size={20} />
              <p>Enviado</p>
            </li>
            <li className={isActive(8)}>
              <FaStoreAlt size={20} />
              <p>Recebido</p>
            </li>
            <li className={isActive(9)}>
              <FaUserCheck size={20} />
              <p>Entregue</p>
            </li>
          </ul>
        </OrderStatus>

        {!viewCover && (
          <div className="row justify-content-center mt-3">
            <BookAccess className="col-sm col-md-5 p-0">
              {hasViewButton() && (
                <img
                  src={photos[0]?.url}
                  alt="fotobook"
                  className="img-fluid"
                />
              )}

              {!hasViewButton() && <FcStackOfPhotos />}

              {hasViewButton() && (
                <button
                  type="button"
                  className="btn btn-primary btn-block col-10 col-md-6"
                  onClick={() => setViewCover(true)}
                >
                  VISUALIZAR ENSAIO
                </button>
              )}

              {!hasViewButton() && (
                <div className="col bg-button text-center">
                  Suas fotos estão sendo preparadas, aguarde!
                </div>
              )}

              <div className="mask " />
            </BookAccess>
          </div>
        )}

        {viewCover && (
          <div className="container mt-3 mb-5">
            <div className="row">
              <div className="col text-center">
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={() => downloadAllPhotos()}
                >
                  baixar todas as fotos
                </button>
              </div>
            </div>

            <div className="row">
              {photos?.map((photo, i) => (
                <Photo key={photo.id} className="col-6 col-md-2 p-2">
                  <div className="card mt-2">
                    <img
                      src={photo.url}
                      alt="foto editada"
                      onClick={() => openImageViewer(i)}
                      role="presentation"
                    />

                    <button
                      type="button"
                      className="btn btn-secondary"
                      onClick={() => downloadPhoto(photo)}
                    >
                      baixar
                    </button>
                  </div>
                </Photo>
              ))}
            </div>
          </div>
        )}
      </Container>

      <ViewImage className="container">
        {isViewerOpen && (
          <ImageViewer
            src={images}
            currentIndex={currentImage}
            disableScroll={false}
            closeOnClickOutside
            onClose={closeImageViewer}
          />
        )}
      </ViewImage>
    </>
  );
};

export default ClientOrder;

export const Container = styled.div`
  h4 {
    font-weight: bold;
    text-transform: uppercase;
    color: #464646;
  }

  span {
    font-size: 12px;
    font-weight: bold;
    color: #464646;
  }

  .table td {
    color: #464646;
  }
`;

export const OrderStatus = styled.div`
  background: #f5f5f5;

  p {
    font-size: 8px;
    margin-top: 10px;
  }

  svg {
    margin-top: -25px;
    margin-left: -10px;
    color: #fff;
    z-index: 2;
    position: absolute;
  }

  overflow: auto;

  .progressbar {
    font-size: 8px;
    margin-top: 30px;
    counter-reset: step;
    display: flex;
    flex-direction: row;
    width: 100%;
  }

  .progressbar li {
    list-style-type: none;
    width: 50%;
    float: left;
    position: relative;
    text-align: center;
    text-transform: uppercase;
    color: #7d7d7d;
  }

  .progressbar li:before {
    width: 30px;
    height: 30px;
    content: '';
    line-height: 50px;
    background-color: #7d7d7d;
    display: block;
    text-align: center;
    margin: 0 auto 0 auto;
    border-radius: 50%;
    transition: all 0.8s;
    z-index: 1;
    position: relative;
  }

  .progressbar li:after {
    width: 100%;
    height: 2px;
    content: '';
    position: absolute;
    background-color: #7d7d7d;
    top: 15px;
    left: -50%;
    z-index: 1;
    transition: all 0.8s;
  }

  .progressbar li:first-child:after {
    content: none;
  }

  .progressbar li.active:before {
    border-color: #55b776;
    background-color: #55b776;
    transition: all 0.8s;
    z-index: 2;
  }

  .progressbar li.active:after {
    background-color: #55b776;
    transition: all 0.8s;
  }

  @media screen and (max-width: 667px) {
    .progressbar {
      width: 800px;
    }
  }
`;

export const BookAccess = styled.div`
  background-color: #cecece;
  display: flex;
  flex-direction: column;
  align-items: center;

  height: 300px;
  width: 500px;
  margin-bottom: 100px;

  img {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }

  svg {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }

  button {
    margin-top: -110px;
    z-index: 5;
    position: relative;
  }

  .mask {
    height: 300px;
    width: 500px;
    background-color: #ffcf4c;
    opacity: 0.4;
    z-index: 3;
    position: absolute;
  }

  .bg-button {
    background: #ffcf4c;
    padding: 10px;
    position: absolute;
    z-index: 4;
    height: 40px;
    margin-top: 200px;
  }

  @media screen and (max-width: 667px) {
    .mask {
      height: 300px;
      width: 100%;
    }

    .bg-button {
      height: 60px;
    }
  }
`;

export const Photo = styled.div`
  img {
    width: 100%;
    height: 150px;
    object-fit: cover;
  }

  button {
    border-radius: 0 !important;
    height: 30px;
    font-size: 12px;
  }
`;

export const ViewImage = styled.div`
  z-index: 3;
  position: absolute;
`;
