import React, { useCallback, useEffect, useState } from 'react';
import { IoIosSearch } from 'react-icons/io';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';

import { index, remove } from '../../../actions/base-rest';
import DeleteModal from '../../../components/delete-modal';
import { setBreadcrumb } from '../../../reducers/breadcrumb';
import PersonsFilter from './PersonsFilter';
import PersonsTable from './PersonsTable';

const URL = 'persons';

const default_params = {
  current_page: 1,
  per_page: 10,
  sorts: 'full_name:ASC',
};

const Persons = () => {
  const dispatch = useDispatch();

  const [page, setPage] = useState({});

  const [persons, setPersons] = useState([]);

  const [isFilter, setIsFilter] = useState(false);

  const [itemDelete, setItemDelete] = useState({});

  const [params, setParams] = useState(default_params);

  const [loading, setLoading] = useState(false);

  const [scrolling, setScrolling] = useState(false);

  const getPersons = useCallback(
    async (filter) => {
      setLoading(true);

      const response = await index(URL, filter);

      if (scrolling) {
        const new_persons = (data) => [...data, ...response.rows];

        setPersons(new_persons);

        setScrolling(false);
      } else {
        setPersons(response.rows);
      }

      setLoading(false);

      setPage(response.page);
    },
    [scrolling]
  );

  const deleteItem = useCallback(async () => {
    const response = await remove(URL, itemDelete.id);

    if (response) {
      setPersons([]);

      getPersons();
    }
  }, [itemDelete]);

  useEffect(() => {
    getPersons(params);
  }, [params]);

  useEffect(() => {
    dispatch(setBreadcrumb([{ page: 'pessoas', url: null }]));
  }, []);

  return (
    <div className="container">
      <div className="row mt-3 justify-content-center">
        <div className="col-12 text-center my-3">
          <h4>PESSOAS</h4>
        </div>

        {!isFilter && (
          <div className="col-6 col-md-4">
            <Link to={`/${URL}/new`}>
              <button
                type="button"
                className="btn btn-primary col-12"
                style={{ height: '45px' }}
              >
                nova pessoa
              </button>
            </Link>
          </div>
        )}
        <div className={isFilter ? 'col-12 col-md-10' : 'col-6 col-md-4'}>
          <button
            type="button"
            className="btn btn-secondary col-12"
            onClick={() => setIsFilter(true)}
            style={{ height: '45px' }}
          >
            <IoIosSearch size={25} className="mb-1" /> localizar
          </button>

          <PersonsFilter
            isFilter={isFilter}
            setIsFilter={setIsFilter}
            setParams={setParams}
            default_params={default_params}
          />
        </div>
      </div>

      <PersonsTable
        persons={persons}
        page={page}
        setItemDelete={setItemDelete}
        setParams={setParams}
        params={params}
        loading={loading}
        setScrolling={setScrolling}
      />

      <DeleteModal
        id_modal="modal-pessoa"
        item_delete={itemDelete}
        deleteItem={() => deleteItem()}
      />
    </div>
  );
};

export default Persons;
