import { useCallback } from 'react';
import { IoIosTrash } from 'react-icons/io';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useHistory } from 'react-router';
import SyncLoader from 'react-spinners/SyncLoader';
import ReactTooltip from 'react-tooltip';

import PropTypes from 'prop-types';
import styled from 'styled-components';

import SortButton from '../../../components/sort-desc/SortButton';

const UnitsTable = ({
  units,
  page,
  setItemDelete,
  params,
  setParams,
  loading,
  setScrolling,
}) => {
  const history = useHistory();

  const changePage = useCallback(() => {
    setScrolling(true);
    setParams((prevState) => ({
      ...prevState,
      current_page: page.current_page + 1,
    }));
  }, [page]);

  const sortUnits = useCallback(
    (field, sort) => {
      setParams((prevState) => ({
        ...prevState,
        sorts: `${field}:${sort}`,
        per_page: units.length,
      }));
    },
    [units]
  );

  return (
    <Container>
      <div className="col-12 text-center mt-5">
        <h6>
          TOTAL DE REGISTROS - {units.length} de <b>{page?.total}</b>
        </h6>
      </div>

      <div className="row mt-3">
        <div className="col">
          <InfiniteScroll
            dataLength={units.length}
            next={changePage}
            hasMore={page.has_next_page}
            loader={
              <div className="col text-center">
                {loading && (
                  <SyncLoader color="#F7931E" size={15} margin={10} />
                )}
              </div>
            }
            endMessage={
              <div className="col text-center">
                <h6>
                  TOTAL DE REGISTROS - {units.length} de <b>{page?.total}</b>
                </h6>
              </div>
            }
          >
            <div className="table-responsive">
              <table className="table table-hover text-center">
                <thead>
                  <tr>
                    <th scope="col" className="text-start">
                      <SortButton
                        label="NOME"
                        params={params}
                        ascFunction={() => sortUnits('name', 'ASC')}
                        descFunction={() => sortUnits('name', 'DESC')}
                      />
                    </th>
                    <th scope="col" className="text-start">
                      <SortButton
                        label="CIDADE"
                        params={params}
                        ascFunction={() => sortUnits('city', 'ASC')}
                        descFunction={() => sortUnits('city', 'DESC')}
                      />
                    </th>
                    <th scope="col">
                      <SortButton
                        label="UF"
                        params={params}
                        ascFunction={() => sortUnits('state', 'ASC')}
                        descFunction={() => sortUnits('state', 'DESC')}
                      />
                    </th>
                    <th scope="col" className="text-end">
                      AÇÃO
                    </th>
                  </tr>
                </thead>

                <tbody>
                  {units.map((item) => (
                    <tr
                      key={item.id}
                      onClick={(e) => {
                        if (e.target.innerText !== undefined) {
                          history.push(`units/${item.id}`);
                        }
                      }}
                    >
                      <td className="text-start">{item.name}</td>
                      <td className="text-start">{item.city}</td>
                      <td>{item.state}</td>

                      <td className="text-end action">
                        <IoIosTrash
                          size={25}
                          className="mb-1"
                          data-tip="Excluir"
                          onClick={() =>
                            setItemDelete({
                              id: item.id,
                              description: item.name,
                            })
                          }
                          data-bs-toggle="modal"
                          data-bs-target="#modal-unit"
                        />
                        <ReactTooltip />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </InfiniteScroll>
        </div>
      </div>
    </Container>
  );
};

UnitsTable.propTypes = {
  units: PropTypes.arrayOf(Object).isRequired,
  page: PropTypes.shape().isRequired,
  setItemDelete: PropTypes.func.isRequired,
  setParams: PropTypes.func.isRequired,
  params: PropTypes.shape().isRequired,
  loading: PropTypes.bool.isRequired,
  setScrolling: PropTypes.func.isRequired,
};

export default UnitsTable;

export const Container = styled.section`
  /* text-transform: uppercase; */

  height: 100vh;

  h6 {
    font-size: 12px;
  }

  .table-hover {
    cursor: pointer;
  }

  .action {
    color: #d11515;

    :hover {
      cursor: pointer;
      color: #ec4646;
    }
  }

  @media screen and (max-width: 768px) {
    .table {
      width: 900px !important;
    }
  }
`;
