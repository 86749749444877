import React, { useEffect, useState, useCallback, useContext } from 'react';
import { GoAlert } from 'react-icons/go';
import { useDispatch } from 'react-redux';

import PropTypes from 'prop-types';
import styled from 'styled-components';

import { AuthContext } from '../../../../actions/auth-context';
import { setBreadcrumb } from '../../../../reducers/breadcrumb';
import PageUnauthorized from '../../../page-unauthorized/PageUnauthorized';
import AddPhotosButton from './action-buttons/AddPhotosButton';
import AddSpcFilesButton from './action-buttons/AddSpcFilesButton';
import ApproveFinAnalysisButton from './action-buttons/ApproveFinAnalysisButton';
import DeliveredOrderButton from './action-buttons/DeliveredOrderButton';
import DisapproveFinAnalysisButton from './action-buttons/DisapproveFinAnalysisButton';
import FinishApproveEditionOrderButton from './action-buttons/FinishApproveEditionOrderButton';
import FinishAssembleOrderButton from './action-buttons/FinishAssembleOrderButton';
import FinishEditOrderButton from './action-buttons/FinishEditOrderButton';
import FinishPackingOrderButton from './action-buttons/FinishPackingOrderButton';
import FinishPlotterOrderButton from './action-buttons/FinishPlotterOrderButton';
import FinishPrintOrderButton from './action-buttons/FinishPrintOrderButton';
import GenerateOrderControlPDFButton from './action-buttons/GenerateOrderControlPDFButton';
import GeneratePDFButton from './action-buttons/GeneratePDFButton';
import GeneratePDFClientButton from './action-buttons/GeneratePDFClientButton';
import GeneratePDFOrderFinishButton from './action-buttons/GeneratePDFinishOrderButton';
import ReceivedOrderUnitButton from './action-buttons/ReceivedOrderUnitButton';
import ReopenOrderButton from './action-buttons/ReopenOrderButton';
import SendDispatchOrderButton from './action-buttons/SendDispatchOrderButton';
import SendFinancialAnalysisButton from './action-buttons/SendFinancialAnalysisButton';
import SendReworkButton from './action-buttons/SendReworkButton';
import SendUnitButton from './action-buttons/SendUnitButton';
import ShelveOrderButton from './action-buttons/ShelveOrderButton';
import StartApproveEditionOrderButton from './action-buttons/StartApproveEditionOrderButton';
import StartAssembleOrderButton from './action-buttons/StartAssembleOrderButton';
import StartEditOrderButton from './action-buttons/StartEditOrderButton';
import StartFinancialAnalysisButton from './action-buttons/StartFinancialAnalysisButton';
import StartPackingOrderButton from './action-buttons/StartPackingOrderButton';
import StartPlotterOrderButton from './action-buttons/StartPlotterOrderButton';
import StartPrintOrderButton from './action-buttons/StartPrintOrderButton';
import AddProductModal from './AddProductModal';
import ApplyDescountModal from './ApplyDescountModal';
import EditedPhotos from './EditedPhotos';
import FilesOrder from './FilesOrder';
import FormData from './FormData';
import IconOrderStatus from './IconOrderStatus';
import ApproveEditionModal from './modals/ApproveEditionModal';
import AssembleModal from './modals/AssembleModal';
import EditModal from './modals/EditModal';
import FinancialAnalysisModal from './modals/FinancialAnalysisModal';
import PackingModal from './modals/PackingModal';
import PlotterModal from './modals/PlotterModal';
import PrintModal from './modals/PrintModal';
import OrderStatus from './OrderStatus';
import ProductsTable from './ProductsTable';
import ResumeOrder from './ResumeOrder';
import SendReworkModal from './SendReworkModal';

const EditOrder = ({
  order,
  setOrder,
  getOrder,
  loadPersonsOptions,
  unitsOptions,
}) => {
  const dispatch = useDispatch();
  const [hasPlotter, setHasPlotter] = useState(false);
  const [alert, setAlert] = useState(false);
  const [showOrder, setShowOrder] = useState(true);
  const { user, hasRole } = useContext(AuthContext);

  const verifyProducts = useCallback(async () => {
    order.products.forEach((product) => {
      if (product.type === 'Impressão plotter') setHasPlotter(true);
    });
  }, [order]);

  const verifyDeliveryDateOrder = useCallback(async () => {
    const today = new Date();

    if (
      new Date(order.delivery_date) <= today &&
      order.order_status !== 'Entregue'
    )
      setAlert(true);
  }, [order]);

  const fetchShowOrder = useCallback(() => {
    if (hasRole('editor') && order.editor_id && order.editor_id !== user.id) {
      setShowOrder(false);
    }
    // if (hasRole('editor')) {
    //   if (order.editor_id) {
    //     if (order.editor_id !== user.id) {
    //       setShowOrder(false);
    //     }
    //   }
    // }
  }, [user, order]);

  useEffect(() => {
    verifyProducts();

    verifyDeliveryDateOrder();

    fetchShowOrder();

    dispatch(
      setBreadcrumb([
        { page: 'pedidos', url: '/orders' },
        { page: 'editar', url: null },
      ])
    );
  }, [order]);

  return (
    <>
      {showOrder ? (
        <>
          <Container className="container ">
            <div>
              <OrderStatus order={order} has_plotter={hasPlotter} />

              {alert && (
                <Alert className="row">
                  <div
                    className="alert alert-danger alert-dismissible fade show text-center"
                    role="alert"
                  >
                    <GoAlert size={35} />
                    <strong>ATENÇÃO!</strong> Pedido com data de entrega
                    atrasada.
                    <button
                      type="button"
                      className="btn-close"
                      data-bs-dismiss="alert"
                      aria-label="Close"
                    />
                  </div>
                </Alert>
              )}

              <div className="title">
                <h6>PEDIDO {order.order_status?.toUpperCase()}</h6>
              </div>
            </div>

            <div className="row">
              <div className="row justify-content-end ">
                <div className="col-1 d-none d-md-block">
                  <IconOrderStatus order={order} />
                </div>
              </div>

              <FormData
                order={order}
                loadPersonsOptions={loadPersonsOptions}
                unitsOptions={unitsOptions}
                setorder={setOrder}
              />
            </div>

            <div className="row justify-content-center mt-3 p-2">
              <GeneratePDFClientButton order={order} />

              <AddSpcFilesButton order={order} setOrder={setOrder} />

              <GeneratePDFButton order={order} />

              <GenerateOrderControlPDFButton order={order} />

              <GeneratePDFOrderFinishButton order={order} />

              <SendFinancialAnalysisButton order={order} />

              <StartFinancialAnalysisButton order={order} />

              <ApproveFinAnalysisButton order={order} />

              <DisapproveFinAnalysisButton order={order} />

              <StartEditOrderButton order={order} />

              <AddPhotosButton order={order} setOrder={setOrder} />

              <ShelveOrderButton order={order} />

              <ReopenOrderButton order={order} />

              <FinishEditOrderButton order={order} />

              <StartApproveEditionOrderButton order={order} />

              <FinishApproveEditionOrderButton order={order} />

              <StartPrintOrderButton order={order} />

              <FinishPrintOrderButton order={order} />

              <StartPlotterOrderButton order={order} has_plotter={hasPlotter} />

              <FinishPlotterOrderButton order={order} />

              <StartAssembleOrderButton
                order={order}
                has_plotter={hasPlotter}
              />

              <FinishAssembleOrderButton order={order} />

              <StartPackingOrderButton order={order} />

              <FinishPackingOrderButton order={order} />

              <SendDispatchOrderButton order={order} />

              <SendUnitButton order={order} />

              <ReceivedOrderUnitButton order={order} />

              <DeliveredOrderButton order={order} />

              <SendReworkButton order={order} />

              <hr className="mt-2" />
            </div>
          </Container>

          <FilesOrder order={order} setOrder={setOrder} />

          <ProductsTable
            products={order.products || []}
            order={order}
            setOrder={setOrder}
          />

          <ResumeOrder order={order} />

          <EditedPhotos order={order} setOrder={setOrder} getOrder={getOrder} />

          <AddProductModal order={order} setOrder={setOrder} />

          <ApplyDescountModal order={order} setOrder={setOrder} />

          <SendReworkModal order={order} setOrder={setOrder} />

          <FinancialAnalysisModal order={order} setOrder={setOrder} />

          <EditModal order={order} setOrder={setOrder} />

          <ApproveEditionModal order={order} setOrder={setOrder} />

          <PrintModal order={order} setOrder={setOrder} />

          <PlotterModal order={order} setOrder={setOrder} />

          <AssembleModal order={order} setOrder={setOrder} />

          <PackingModal order={order} setOrder={setOrder} />
        </>
      ) : (
        <PageUnauthorized />
      )}
    </>
  );
};

EditOrder.propTypes = {
  unitsOptions: PropTypes.arrayOf(Object).isRequired,
  setOrder: PropTypes.func.isRequired,
  getOrder: PropTypes.func.isRequired,
  order: PropTypes.shape({
    id: PropTypes.string,
    person_id: PropTypes.string,
    editor_id: PropTypes.string,
    person: PropTypes.shape({
      full_name: PropTypes.string.isRequired,
    }),
    unit_id: PropTypes.string,
    unit: PropTypes.shape({
      name: PropTypes.string.isRequired,
    }),
    seller_id: PropTypes.string,
    seller: PropTypes.shape({
      full_name: PropTypes.string.isRequired,
    }),
    order_date: PropTypes.string,
    delivery_date: PropTypes.instanceOf(Date),
    payment_first_date: PropTypes.instanceOf(Date),
    products: PropTypes.arrayOf(Object),
    total_order: PropTypes.number,
    products_discount: PropTypes.number,
    discount: PropTypes.number,
    total_discounted: PropTypes.number,
    total_discount: PropTypes.number,
    spcfiles: PropTypes.arrayOf(Object),
    order_status: PropTypes.string,
    is_rework: PropTypes.bool,
    ms_number: PropTypes.number,
    photos: PropTypes.arrayOf(Object),
  }).isRequired,

  loadPersonsOptions: PropTypes.func.isRequired,
};

export default EditOrder;

export const Container = styled.div`
  .react-select__single-value {
    top: 50% !important;
  }

  .title {
    display: flex;
    flex-direction: row;
    justify-content: end;
    padding-bottom: 10px;

    h6 {
      margin-right: 50px;
      z-index: 2;
      position: absolute;
    }
  }
`;

export const InputFile = styled.button`
  position: relative;
  overflow: hidden;

  font-size: 16px;

  svg {
    margin-left: 10px;
    margin-bottom: 5px;
  }

  input[type='file'] {
    position: absolute;
    top: 0;
    right: 0;
    min-width: 100%;
    min-height: 100%;
    font-size: 100px;
    text-align: right;
    filter: alpha(opacity=0);
    opacity: 0;
    outline: none;
    cursor: inherit;
    display: block;
  }
`;

export const Alert = styled.div`
  font-size: 22px;

  svg {
    margin-right: 10px;
    margin-top: -10px;
  }
`;
