import { useContext, useCallback } from 'react';

import PropTypes from 'prop-types';

import { AuthContext } from '../../../../../actions/auth-context';

const StartAssembleOrderButton = ({ order, has_plotter }) => {
  const { hasPermissions } = useContext(AuthContext);

  const hasViewButton = useCallback(() => {
    if (has_plotter) {
      return (
        order.order_status === 'Plotado' && hasPermissions(['assemble_order'])
      );
    }

    return (
      order.order_status === 'Impresso' && hasPermissions(['assemble_order'])
    );
  }, [order, has_plotter]);

  return (
    <>
      {hasViewButton() && (
        <div className="col-sm col-md-4 col-xl-3 mt-2">
          <button
            className="btn btn-success btn-block col-12"
            type="button"
            data-bs-toggle="modal"
            data-bs-target="#modal-start-assemble-order"
          >
            iniciar montagem
          </button>
        </div>
      )}
    </>
  );
};

StartAssembleOrderButton.propTypes = {
  order: PropTypes.shape({
    id: PropTypes.string,
    order_status: PropTypes.string,
    products: PropTypes.arrayOf(Object),
  }).isRequired,
  has_plotter: PropTypes.bool.isRequired,
};

export default StartAssembleOrderButton;
