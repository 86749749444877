import api from './api';
import { handleError } from './handle-error';

export const getProductionPositionApi = async () => {
  const result = new Promise((resolve) => {
    api
      .get(`dash-admin/orders-position`)
      .then((response) => {
        resolve(response.data);
      })
      .catch(async (error) => {
        const response = await handleError(error);

        resolve(response);
      });
  });
  return result;
};

export const getOrdersByEditorApi = async () => {
  const result = new Promise((resolve) => {
    api
      .get(`dash-admin/orders-by-editor`)
      .then((response) => {
        resolve(response.data);
      })
      .catch(async (error) => {
        const response = await handleError(error);

        resolve(response);
      });
  });
  return result;
};

export const getProductionPositionDetailsApi = async () => {
  const result = new Promise((resolve) => {
    api
      .get(`dash-admin/orders-position-details`)
      .then((response) => {
        resolve(response.data);
      })
      .catch(async (error) => {
        const response = await handleError(error);

        resolve(response);
      });
  });
  return result;
};

export const getUsersProductionApi = async (params) => {
  const result = new Promise((resolve) => {
    api
      .get(`dash-admin/users-production`, { params })
      .then((response) => {
        resolve(response.data);
      })
      .catch(async (error) => {
        const response = await handleError(error);

        resolve(response);
      });
  });
  return result;
};

export const getUsersProductionByEditorApi = async (editor_id, params) => {
  const result = new Promise((resolve) => {
    api
      .get(`dash-admin/users-production/${editor_id}`, { params })
      .then((response) => {
        resolve(response.data);
      })
      .catch(async (error) => {
        const response = await handleError(error);

        resolve(response);
      });
  });
  return result;
};

export const getReworksByUsersApi = async (params) => {
  const result = new Promise((resolve) => {
    api
      .get(`dash-admin/reworks-to-users`, { params })
      .then((response) => {
        resolve(response.data);
      })
      .catch(async (error) => {
        const response = await handleError(error);

        resolve(response);
      });
  });
  return result;
};

export const getReworksBySectorsApi = async (params) => {
  const result = new Promise((resolve) => {
    api
      .get(`dash-admin/reworks-to-sectors`, { params })
      .then((response) => {
        resolve(response.data);
      })
      .catch(async (error) => {
        const response = await handleError(error);

        resolve(response);
      });
  });
  return result;
};

export const getReworksDetailsApi = async (params) => {
  const result = new Promise((resolve) => {
    api
      .get(`dash-admin/reworks-details`, { params })
      .then((response) => {
        resolve(response.data);
      })
      .catch(async (error) => {
        const response = await handleError(error);

        resolve(response);
      });
  });
  return result;
};
