import React, { useCallback, useEffect, useState, useContext } from 'react';
import { IoIosSearch } from 'react-icons/io';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';

import styled from 'styled-components';

import { AuthContext } from '../../../actions/auth-context';
import { index, remove } from '../../../actions/base-rest';
import DeleteModal from '../../../components/delete-modal';
import { setBreadcrumb } from '../../../reducers/breadcrumb';
import OrdersFilter from './OrdersFilter';
import OrdersTable from './OrdersTable';

const URL = 'orders';

const default_params = {
  current_page: 1,
  per_page: 30,
  sorts: 'delivery_date:ASC',
  order_location: 'Ativos',
};

const Orders = () => {
  const dispatch = useDispatch();

  const { hasPermissions, user, setLoading, hasRole } = useContext(AuthContext);

  const [page, setPage] = useState({});
  const [orders, setOrders] = useState([]);
  const [isFilter, setIsFilter] = useState(false);
  const [itemDelete, setItemDelete] = useState({});
  const [params, setParams] = useState(default_params);
  const [scrolling, setScrolling] = useState(false);
  const isEditor = hasRole('editor');

  const getOrders = useCallback(
    async (filter) => {
      setLoading(true);

      if (user?.city) filter.unit_city = user.city;
      if (isEditor) filter.editor_id = user.id;

      const response = await index(URL, filter);

      if (scrolling) {
        const new_orders = (data) => [...data, ...response.rows];

        setOrders(new_orders);

        setScrolling(false);
      } else {
        setOrders(response.rows);
      }

      setPage(response.page);

      setLoading(false);
    },
    [scrolling, user]
  );

  const deleteItem = useCallback(async () => {
    const response = await remove(URL, itemDelete.id);

    if (response) {
      setOrders([]);
      getOrders();
    }
  }, [itemDelete]);

  useEffect(() => {
    getOrders(params);
  }, [params]);

  useEffect(() => {
    dispatch(setBreadcrumb([{ page: 'pedidos', url: null }]));
  }, []);

  return (
    <Container className="container">
      <div className="row mt-3 justify-content-center">
        <div className="col-12 text-center my-3">
          <h4>PEDIDOS</h4>
        </div>

        {!isFilter && hasPermissions(['add_order']) && (
          <div className="col-6 col-md-4">
            <Link to={`/${URL}/new`}>
              <button
                type="button"
                className="btn btn-primary col-12 btn-order"
              >
                novo pedido
              </button>
            </Link>
          </div>
        )}

        <div className={isFilter ? 'col-12 col-md-10' : 'col-6 col-md-4'}>
          <button
            type="button"
            className="btn btn-secondary col-12 btn-order"
            onClick={() => setIsFilter(true)}
          >
            <IoIosSearch size={25} className="mb-1 icon-button" />
            localizar
          </button>

          <OrdersFilter
            isFilter={isFilter}
            setIsFilter={setIsFilter}
            setParams={setParams}
            params={params}
            default_params={default_params}
          />
        </div>
      </div>

      <OrdersTable
        orders={orders}
        page={page}
        setItemDelete={setItemDelete}
        setParams={setParams}
        params={params}
        setScrolling={setScrolling}
        getOrders={getOrders}
      />
      <DeleteModal
        id_modal="modal-order"
        item_delete={itemDelete}
        deleteItem={() => deleteItem()}
      />
    </Container>
  );
};

export default Orders;

export const Container = styled.div`
  .btn-order {
    height: 45px;
  }

  @media screen and (max-width: 360px) {
    .btn-order {
      height: 55px;
    }

    .icon-button {
      display: none;
    }
  }
`;
