import { useEffect, useState, useContext, useCallback } from 'react';
import { useLocation } from 'react-router-dom';

import { AuthContext } from '../../actions/auth-context';
import Footer from '../footer';

const FooterApp = () => {
  const { isLoading } = useContext(AuthContext);

  const location = useLocation();

  const [view, setView] = useState(true);

  const hasViewFooter = useCallback(() => {
    if (location.pathname === '/orders') return false;

    if (location.pathname === '/persons') return false;

    if (location.pathname === '/products') return false;

    return true;
  }, [location]);

  useEffect(() => {
    if (hasViewFooter()) {
      setView(true);
    } else {
      setView(false);
    }
  }, [location]);

  return <>{view && !isLoading && <Footer />}</>;
};

export default FooterApp;
