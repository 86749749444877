import { useCallback, useContext } from 'react';
import { BsPuzzleFill } from 'react-icons/bs';

import PropTypes from 'prop-types';

import { AuthContext } from '../../../../actions/auth-context';
import { startAssembleTheOrder } from '../../../../actions/order.service';

const StartAssembleOrderButton = ({ order, getOrders }) => {
  const { hasPermissions } = useContext(AuthContext);

  const getAssembleOrder = useCallback(async (data) => {
    startAssembleTheOrder(data.id, {
      action_note: null,
    }).then(() => {
      getOrders();
    });
  }, []);

  const hasViewButton = useCallback(
    () =>
      (!order.has_plotter &&
        hasPermissions(['edit_order', 'edit_order', 'plotter_photos']) &&
        order.order_status === 'Impresso') ||
      order.order_status === 'Plotado',
    [order]
  );

  return (
    <>
      {hasViewButton() && hasPermissions(['edit_order', 'assemble_order']) && (
        <BsPuzzleFill
          size={25}
          className="mb-1 action"
          data-tip="Montar Pedido"
          onClick={() => getAssembleOrder(order)}
        />
      )}
    </>
  );
};

StartAssembleOrderButton.propTypes = {
  order: PropTypes.shape({
    id: PropTypes.string,
    order_status: PropTypes.string,
    has_plotter: PropTypes.bool,
  }).isRequired,
  getOrders: PropTypes.func.isRequired,
};

export default StartAssembleOrderButton;
