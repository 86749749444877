import { useCallback, useContext } from 'react';

import PropTypes from 'prop-types';

import { AuthContext } from '../../../../../actions/auth-context';
import {
  startPackingTheOrder,
  finishPackingTheOrder,
  sendDispatchTheOrder,
  sendToUnit,
  receivedOrderToUnit,
  deliveredOrderToClient,
} from '../../../../../actions/order.service';
import ActionModal from './ActionModal';

const PackingModal = ({ order, setOrder }) => {
  const { setLoading } = useContext(AuthContext);

  const uploadOrder = useCallback((response) => {
    if (!response.error) {
      response.delivery_date = new Date(response.delivery_date);

      if (response.payment_first_date) {
        response.payment_first_date = new Date(
          response.payment_first_date.replace(/-/g, ',')
        );
      }

      setOrder(response);
    }
  }, []);

  const getPackingOrder = useCallback(async (data) => {
    setLoading(true);

    const response = await startPackingTheOrder(data.order_id, data);

    uploadOrder(response);

    setLoading(false);
  }, []);

  const finishPackingOrder = useCallback(async (data) => {
    setLoading(true);

    const response = await finishPackingTheOrder(data.order_id, data);

    uploadOrder(response);

    setLoading(false);
  }, []);

  const sendDispatchOrder = useCallback(async (data) => {
    setLoading(true);

    const response = await sendDispatchTheOrder(data.order_id, data);

    uploadOrder(response);

    setLoading(false);
  }, []);

  const sendUnit = useCallback(async (data) => {
    setLoading(true);

    const response = await sendToUnit(data.order_id, data);

    uploadOrder(response);

    setLoading(false);
  }, []);

  const receivedOrderUnit = useCallback(async (data) => {
    setLoading(true);

    const response = await receivedOrderToUnit(data.order_id, data);

    uploadOrder(response);

    setLoading(false);
  }, []);

  const deliveredOrder = useCallback(async (data) => {
    setLoading(true);

    const response = await deliveredOrderToClient(data.order_id, data);

    uploadOrder(response);

    setLoading(false);
  }, []);

  return (
    <>
      <ActionModal
        modal_id="modal-start-packing-order"
        title="iniciar embalagem do pedido?"
        action={getPackingOrder}
        order={order}
      />

      <ActionModal
        modal_id="modal-finish-packing-order"
        title="finalizar embalagem do pedido?"
        action={finishPackingOrder}
        order={order}
      />

      <ActionModal
        modal_id="modal-dispatch-order"
        title="enviar pedido para expedição?"
        action={sendDispatchOrder}
        order={order}
      />

      <ActionModal
        modal_id="modal-sent-order"
        title="enviar pedido para unidade?"
        action={sendUnit}
        order={order}
      />

      <ActionModal
        modal_id="modal-received-order"
        title="receber pedido na unidade?"
        action={receivedOrderUnit}
        order={order}
      />

      <ActionModal
        modal_id="modal-delivered-order"
        title="entregar pedido para cliente?"
        action={deliveredOrder}
        order={order}
      />
    </>
  );
};

PackingModal.propTypes = {
  order: PropTypes.shape({
    id: PropTypes.string,
    order_status: PropTypes.string,
  }).isRequired,
  setOrder: PropTypes.func.isRequired,
};

export default PackingModal;
