import { useContext, useCallback } from 'react';
import { AiFillPrinter } from 'react-icons/ai';

import PropTypes from 'prop-types';

import { AuthContext } from '../../../../../actions/auth-context';
import { getPdfOrder } from '../../../../../actions/order.service';

const GeneratePDFButton = ({ order }) => {
  const { hasPermissions } = useContext(AuthContext);

  const generatePdfFile = useCallback(async () => {
    const response = await getPdfOrder(order.id);

    window.open(response);
  }, [order]);

  return (
    <>
      {hasPermissions(['add_order']) &&
        order.products?.length > 0 &&
        order.spcfiles?.length > 0 &&
        order.person_id && (
          <div className="col-sm col-md-3 col-xl-2 mt-2">
            <button
              className="btn btn-primary btn-block col-12"
              type="button"
              onClick={generatePdfFile}
            >
              Contrato <AiFillPrinter size={25} className="mb-1" />
            </button>
          </div>
        )}
    </>
  );
};

GeneratePDFButton.propTypes = {
  order: PropTypes.shape({
    id: PropTypes.string,
    order_status: PropTypes.string,
    is_rework: PropTypes.bool,
    products: PropTypes.arrayOf(Object),
    spcfiles: PropTypes.arrayOf(Object),
    person_id: PropTypes.string,
  }).isRequired,
};

export default GeneratePDFButton;
