import React, { useRef, useCallback, useState } from 'react';
import { Link } from 'react-router-dom';

import { Form } from '@unform/web';
import styled from 'styled-components';
import * as Yup from 'yup';

import { forgotPassword } from '../../actions/authenticate';
import Input from '../../components/unform/input';

const ForgotPassword = () => {
  const formRef = useRef(null);
  const [resetSuccess, setResetSuccess] = useState(false);

  const submit = useCallback(async (data) => {
    try {
      formRef.current.setErrors({});
      const schema = Yup.object().shape({
        email: Yup.string()
          .email('Digite um e-mail válido')
          .required('E-mail é obrigatório'),
      });

      await schema.validate(data, { abortEarly: false });

      const response = await forgotPassword(data.email);
      if (response.message === 'email to reset password sended with success.')
        setResetSuccess(true);
    } catch (erro) {
      const errorMessages = {};
      if (erro instanceof Yup.ValidationError) {
        erro.inner.forEach((err) => {
          errorMessages[err.path] = err.message;
        });
        formRef.current.setErrors(errorMessages);
      }
    }
  }, []);

  return (
    <Container>
      {!resetSuccess && (
        <div className="form col col-md-6 col-lg-4 p-4 m-2">
          <h1 className="text-center">Recuperar senha</h1>

          <Form ref={formRef} onSubmit={submit} noValidate>
            <div className="mt-5">
              <p className="mb-2" htmlFor="email" type="email">
                insira seu e-mail
              </p>
              <Input name="email" placeholder="e-mail" />
            </div>

            <button
              className="btn btn-primary btn-block col-12 mt-5"
              type="submit"
            >
              enviar
            </button>
          </Form>
        </div>
      )}

      {resetSuccess && (
        <div className="card-success col col-md-6 col-lg-4 p-4 m-2">
          <h1 className="text-center">Sucesso!</h1>

          <h5 className="mt-5 text-center">
            Confira as informações para recuperar sua senha enviadas em seu
            e-mail
          </h5>

          <div className="col-sm mt-3 text-center mb-2">
            <Link to="/login">
              <button
                type="button"
                className="btn btn-primary btn-block col-12 mt-5"
              >
                Login
              </button>
            </Link>
          </div>
        </div>
      )}
    </Container>
  );
};

export default ForgotPassword;

export const Container = styled.div`
  margin-top: -80px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 100vh;
  text-transform: uppercase;
  color: #fff;

  h1 {
    font-size: 36px;
  }

  .form {
    background-color: #464646;
  }

  .card-success {
    background-color: #464646;
    color: #ffffff;
  }

  .btn-primary {
    height: 50px;
  }
`;
