import { useCallback, useContext } from 'react';

import PropTypes from 'prop-types';
import styled from 'styled-components';

import { AuthContext } from '../../../../../actions/auth-context';
import { addFileToOrder } from '../../../../../actions/order.service';

const AddSpcFilesButton = ({ order, setOrder }) => {
  const { setLoading, hasPermissions, hasRoles } = useContext(AuthContext);

  const viewButton = useCallback(() => {
    if (order.order_status === 'Em inclusão') {
      if (hasPermissions(['add_order'])) return true;

      if (hasRoles(['master', 'administrator'])) return true;
    }

    return false;
  }, []);

  const onChangeFile = useCallback(
    async (e) => {
      e.preventDefault();

      setLoading(true);

      let files;

      if (e.dataTransfer) {
        files = e.dataTransfer.files;
      } else if (e.target) {
        files = e.target.files;
      }

      const formData = new FormData();

      for (let i = 0; i < files.length; i++) {
        const file = files[i];
        formData.append('files', file);
      }

      const response = await addFileToOrder(order.id, formData);

      if (!response.error) {
        response.delivery_date = new Date(response.delivery_date);

        if (response.payment_first_date) {
          response.payment_first_date = new Date(
            response.payment_first_date.replace(/-/g, ',')
          );
        }

        setOrder(response);
      }

      setLoading(false);
    },
    [order]
  );

  return (
    <>
      {viewButton() && (
        <div className="col-sm-6 col-md-4 col-xl-3 col-xxl-2 mt-2">
          <InputFile
            type="button"
            className="btn btn-lg btn-secondary btn-block col-12"
          >
            add arquivos
            <input
              type="file"
              name="thumbnail"
              id="thumbnail"
              multiple="multiple"
              onChange={onChangeFile}
              accept="image/*,application/pdf"
            />
          </InputFile>
        </div>
      )}
    </>
  );
};

AddSpcFilesButton.propTypes = {
  order: PropTypes.shape({
    id: PropTypes.string,
    order_status: PropTypes.string,
  }).isRequired,
  setOrder: PropTypes.func.isRequired,
};

export default AddSpcFilesButton;

export const InputFile = styled.button`
  position: relative;
  overflow: hidden;

  font-size: 16px;

  svg {
    margin-left: 10px;
    margin-bottom: 5px;
  }

  input[type='file'] {
    position: absolute;
    top: 0;
    right: 0;
    min-width: 100%;
    min-height: 100%;
    font-size: 100px;
    text-align: right;
    filter: alpha(opacity=0);
    opacity: 0;
    outline: none;
    cursor: inherit;
    display: block;
  }
`;
