import React, {
  useEffect,
  useRef,
  useCallback,
  useState,
  useContext,
} from 'react';
import { FaUserEdit } from 'react-icons/fa';
import { useHistory } from 'react-router';
import { Link } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';

import { Form } from '@unform/web';
import PropTypes from 'prop-types';
import * as Yup from 'yup';

import { AuthContext } from '../../../../actions/auth-context';
import { update, index } from '../../../../actions/base-rest';
import {
  dateToStringWithTime,
  dateToString,
  dateToStringDDMMYYY,
} from '../../../../commons/date';
import InputDateLabel from '../../../../components/unform/input-date-label';
import InputLabel from '../../../../components/unform/input-label';
import InputTextAreaLabel from '../../../../components/unform/input-text-area-label';
import SelectLabel from '../../../../components/unform/select-label';
import SelectScrollingLabel from '../../../../components/unform/select-scrolling-label ';
import { GroupData } from '../../../../styles/GroupData';

const URL = 'orders';

const FormData = ({ order, loadPersonsOptions, unitsOptions, setOrder }) => {
  const { hasPermissions, hasRoles, setLoading } = useContext(AuthContext);
  const formRef = useRef(null);
  const history = useHistory();
  const [selectedEditor, setSelectedEditor] = useState({});
  const [sellersOptions, setSellersOptions] = useState([]);
  const [editorsOptions, setEditorsOptions] = useState([]);
  const [selectedPerson, setSelectedPerson] = useState({});
  const [changingPerson, setChangingPerson] = useState(false);
  const [selectedUnit, setSelectedUnit] = useState({});
  const [selectedSeller, setSelectedSeller] = useState({});

  const loadSellersOptions = useCallback(async (event) => {
    const sellers = await index(`units/${event.value}/sellers`);

    if (sellers) {
      setSelectedSeller({});

      const options = await sellers.map((item) => ({
        label: item.full_name,
        value: item.id,
      }));

      setSellersOptions(options);
    }
  }, []);

  const loadEditorsOptions = useCallback(async () => {
    const editors = await index(`users/get-users-by-role/editor`);

    if (editors) {
      const options = await editors.map((item) => ({
        label: item.full_name,
        value: item.id,
      }));

      setEditorsOptions(options);
    }
  }, []);

  const hasViewSigEdited = useCallback(
    () =>
      hasPermissions(['edit_photos', 'print_photos']) ||
      hasRoles(['master', 'administrator']),
    []
  );

  const hasViewSig = useCallback(
    () =>
      hasPermissions(['edit_photos', 'add_order', 'edit_order']) ||
      hasRoles(['master', 'administrator']),
    []
  );

  const hasViewMSNumber = useCallback(
    () =>
      hasPermissions(['print_photos', 'add_order']) ||
      hasRoles(['master', 'administrator']),

    []
  );

  const hasViewEditor = useCallback(
    () =>
      (order.order_status !== 'Em inclusão' &&
        order.order_status !== 'Incluído') ||
      order.is_rework,
    [order]
  );

  const hasHandleEditor = useCallback(
    () => !hasRoles(['master', 'financial', 'administrator']),
    [order]
  );

  const hasUpdateOrder = useCallback(() => {
    // if (hasRoles(['administrator', 'master'])) return true;
    if (hasPermissions(['edit_order', 'add_order'])) {
      const status = order.order_status;
      if (
        !hasRoles(['administrator', 'master', 'financial']) &&
        status !== 'Em inclusão' &&
        status !== 'Incluído'
      )
        return false;

      return true;
    }

    return false;
  }, [order]);

  const submit = useCallback(
    async (data) => {
      if (selectedPerson) data.person_id = selectedPerson.value;

      if (selectedUnit) data.unit_id = selectedUnit.value;

      if (selectedSeller) data.seller_id = selectedSeller.value;

      if (selectedEditor) data.editor_id = selectedEditor.value;

      if (data.note.length === 0) data.note = null;

      if (data.ms_number === '') data.ms_number = null;

      if (data.sig_number_edited === '') data.sig_number_edited = null;

      if (data.sig_number === '') data.sig_number = null;

      try {
        formRef.current.setErrors({});

        const schema = Yup.object().shape({
          unit_id: Yup.string().required('Unidade é obrigatório'),

          seller_id: Yup.string().required('Vendedor é obrigatório'),

          person_id: Yup.string().required('Cliente é obrigatório'),

          editor_id: Yup.string().nullable(),

          photographer: Yup.string(),

          order_date: Yup.string(),

          delivery_date: Yup.date(),

          sig_number: Yup.number().typeError('Valor inválido').nullable(),

          note: Yup.string().nullable(),

          ms_number: Yup.number().typeError('Valor inválido').nullable(),

          sig_number_edited: Yup.number()
            .typeError('Valor inválido')
            .nullable(),

          payment_option: Yup.string().required('Tipo Pgto é obrigatório'),

          payment_first_date: Yup.date().required('Data de Pgto é obrigatório'),
        });

        await schema.validate(data, { abortEarly: false });

        setLoading(true);

        data.delivery_date = await dateToStringWithTime(data.delivery_date);

        data.payment_first_date = await dateToString(data.payment_first_date);

        const response = await update(URL, data);

        if (!response.error) {
          response.delivery_date = new Date(response.delivery_date);

          response.order_date = new Date(
            response.order_date.replace(/-/g, ',')
          );

          response.payment_first_date = new Date(
            response.payment_first_date.replace(/-/g, ',')
          );

          setLoading(false);

          setOrder(response);
        } else {
          setLoading(false);
        }
      } catch (error) {
        const errorMessages = {};

        if (error instanceof Yup.ValidationError) {
          error.inner.forEach((err) => {
            errorMessages[err.path] = err.message;
          });

          formRef.current.setErrors(errorMessages);
        }
      }
    },
    [history, selectedPerson, selectedUnit, selectedSeller, selectedEditor]
  );

  useEffect(() => {
    if (hasRoles(['master', 'administrator', 'financial']))
      loadEditorsOptions();
  }, []);

  useEffect(() => {
    if (selectedPerson.value !== order.person_id) {
      setChangingPerson(true);
    } else setChangingPerson(false);
  }, [selectedPerson]);

  useEffect(() => {
    setSelectedPerson({
      label: order.person?.full_name,
      value: order.person_id,
    });

    setSelectedUnit({
      label: order.unit?.name,
      value: order.unit_id,
    });

    setSelectedSeller({
      label: order.seller?.full_name,
      value: order.seller_id,
    });

    setSelectedEditor({
      label: order.editor?.full_name,
      value: order.editor_id,
    });
  }, [order]);

  return (
    <Form ref={formRef} onSubmit={submit} initialData={order} noValidate>
      <GroupData>
        <span>DADOS PESSOAIS</span>

        <div className="col-1 d-none">
          <InputLabel name="id" label="ID" />
        </div>

        <div className="row">
          <div className="col-12 col-md-6 mt-2 px-1">
            <SelectScrollingLabel
              value={selectedPerson}
              loadOptions={loadPersonsOptions}
              name="person_id"
              label="cliente"
              placeholder="Pesquisar por nome"
              disabled={!hasPermissions(['edit_order'])}
              onChange={(e) =>
                setSelectedPerson({
                  label: e.label.slice(0, e.label.indexOf('-')),
                  value: e.value,
                })
              }
            />
          </div>

          {!changingPerson && (
            <>
              <div className="col-12 col-md-3 mt-2 px-1">
                <InputLabel name="person.cpf_cnpj" label="cpf/cnpj" disabled />
              </div>

              <div className="col-sm mt-2 px-1">
                <InputLabel
                  name="person.troll_code"
                  label="id troll"
                  disabled
                />
              </div>

              {hasPermissions(['edit_person']) && (
                <div className="col-12 col-md-1 mt-3 px-1">
                  <Link
                    to={`/persons/${order.person_id}`}
                    type="button"
                    className="btn btn-primary mt-1 col-12"
                    data-tip="Editar cliente"
                  >
                    <FaUserEdit />
                  </Link>
                  <ReactTooltip />
                </div>
              )}
            </>
          )}
        </div>
      </GroupData>

      <GroupData>
        <span>DETALHES</span>

        <div className="row justify-content-center">
          <div className="col-sm col-md-1 mt-2 px-1">
            <InputLabel
              name="order_date"
              label="data inclusão"
              defaultValue={dateToStringDDMMYYY(order.order_date)}
              disabled
            />
          </div>

          <div className="col-sm col-md-2 mt-2 px-1">
            <InputDateLabel
              name="delivery_date"
              label="data entrega prevista"
              defaultValue={order.delivery_date}
              disabled={!hasRoles(['master', 'administrator'])}
            />
          </div>

          <div className="col-sm col-md-3 mt-2 px-1">
            <SelectLabel
              name="unit_id"
              label="Unidade"
              placeholder=""
              options={unitsOptions}
              value={selectedUnit}
              onChange={(e) => loadSellersOptions(e)}
              disabled={!hasPermissions(['edit_order'])}
            />
          </div>

          <div className="col-sm col-md-3 mt-2 px-1">
            <SelectLabel
              name="seller_id"
              label="vendedor"
              placeholder=""
              options={sellersOptions}
              value={selectedSeller}
              onChange={(e) => setSelectedSeller(e)}
              disabled={!hasPermissions(['edit_order'])}
            />
          </div>

          <div className="col-sm mt-2 px-1">
            <InputLabel
              name="photographer"
              label="Fotógrafo"
              disabled={!hasPermissions(['edit_order'])}
            />
          </div>

          {hasViewEditor() && (
            <div className="col-sm col-md-23 mt-2 px-1">
              <SelectLabel
                name="editor_id"
                label="Editor"
                placeholder=""
                options={editorsOptions}
                value={selectedEditor}
                onChange={(e) => setSelectedEditor(e)}
                disabled={hasHandleEditor()}
              />
            </div>
          )}
        </div>

        <div className="row pt-2">
          {hasRoles(['administrator', 'master', 'seller', 'financial']) && (
            <>
              <div className="col-sm col-md-6 mt-2 px-1">
                <InputLabel
                  name="payment_option"
                  label="Forma de Pgto"
                  placeholder="Ex: Boleto 12x"
                  disabled={!hasPermissions(['edit_order'])}
                />
              </div>

              <div className="col-sm mt-2 px-1">
                <InputDateLabel
                  name="payment_first_date"
                  label="data 1º pgto"
                  defaultValue={order.payment_first_date}
                  disabled={!hasPermissions(['edit_order'])}
                />
              </div>
            </>
          )}

          {hasViewSig() && (
            <div className="col-sm col-md-2 mt-2 px-1">
              <InputLabel
                name="sig_number"
                label="Nº SIGI"
                disabled={!hasPermissions(['edit_order', 'add_order'])}
              />
            </div>
          )}

          {hasViewSigEdited() && (
            <div className="col-sm  col-md-2 mt-2 px-1">
              <InputLabel
                name="sig_number_edited"
                label="Nº SIGI edit."
                disabled={!hasPermissions(['edit_order'])}
              />
            </div>
          )}

          {hasViewMSNumber() && (
            <div className="col-sm col-md-1 mt-2 px-1">
              <InputLabel
                name="ms_number"
                label="Nº MS"
                disabled={!hasPermissions(['edit_order'])}
              />
            </div>
          )}
        </div>

        <div className="row justify-content-center">
          <div className="col-sm mt-2 px-1">
            <InputTextAreaLabel
              name="note"
              label="Observações"
              cols={20}
              rows={2}
              disabled={!hasPermissions(['edit_order'])}
            />
          </div>
        </div>
      </GroupData>

      <div className="row justify-content-end pt-2">
        <div className="col-6 col-md-3 col-xl-2 mt-2">
          <button
            className="btn btn-outline-secondary btn-block col-12"
            type="button"
            onClick={() => history.goBack()}
          >
            voltar
          </button>
        </div>

        {hasUpdateOrder() && (
          <div className="col-6 col-md-3 col-xl-2 mt-2">
            <button className="btn btn-primary btn-block col-12" type="submit">
              atualizar
            </button>
          </div>
        )}
      </div>
    </Form>
  );
};

FormData.propTypes = {
  unitsOptions: PropTypes.arrayOf(Object).isRequired,
  setOrder: PropTypes.func,
  order: PropTypes.shape({
    id: PropTypes.string,
    person_id: PropTypes.string,
    editor_id: PropTypes.string,
    person: PropTypes.shape({
      full_name: PropTypes.string.isRequired,
    }),
    editor: PropTypes.shape({
      // id: PropTypes.string.isRequired,
      full_name: PropTypes.string.isRequired,
    }),
    unit_id: PropTypes.string,
    unit: PropTypes.shape({
      name: PropTypes.string.isRequired,
    }),
    seller_id: PropTypes.string,
    seller: PropTypes.shape({
      full_name: PropTypes.string.isRequired,
    }),
    order_date: PropTypes.string,
    delivery_date: PropTypes.instanceOf(Date),
    payment_first_date: PropTypes.instanceOf(Date),
    products: PropTypes.arrayOf(Object),
    total_order: PropTypes.number,
    products_discount: PropTypes.number,
    discount: PropTypes.number,
    total_discounted: PropTypes.number,
    total_discount: PropTypes.number,
    spcfiles: PropTypes.arrayOf(Object),
    order_status: PropTypes.string,
    is_rework: PropTypes.bool,
    ms_number: PropTypes.number,
    photos: PropTypes.arrayOf(Object),
  }).isRequired,

  loadPersonsOptions: PropTypes.func.isRequired,
};

FormData.defaultProps = {
  setOrder: PropTypes.func,
};

export default FormData;
