import { useContext } from 'react';
import { BiRegistered } from 'react-icons/bi';
import { VscVersions } from 'react-icons/vsc';

import styled from 'styled-components';

import { AuthContext } from '../../actions/auth-context';

const Footer = () => {
  const { isLoading } = useContext(AuthContext);

  return (
    <>
      {!isLoading && (
        <Container>
          <div>
            <p>
              <BiRegistered size={15} /> Hyberica Softwares | Todos os direitos
              reservados
            </p>
            <p>
              <VscVersions size={15} /> Versão UI v 1.0.23 | API v 1.0.13
            </p>
          </div>
        </Container>
      )}
    </>
  );
};

export default Footer;

export const Container = styled.div`
  background-color: #464646;
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 30px;

  p {
    margin-top: 2px;
    margin-bottom: 2px;
    font-size: 10px;
    color: #fff;
  }
`;
