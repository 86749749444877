import React from 'react';
import { Link } from 'react-router-dom';

import styled from 'styled-components';

const PageUnauthorized = () => (
  <Container className="row p-2  mt-2 justify-content-center">
    <div className="col-12 col-md-9 card-error text-center">
      <h1 className="mt-5">401</h1>
      <h3 className=" mt-5">Acesso não autorizado</h3>

      <p>Parece que você não está autorizado a acessar essas informações.</p>
      <p>
        <b>Confira com o administrador do sistema!</b>
      </p>
      <Link to="/">
        <button className="btn btn-secondary m-5 col-5" type="button">
          Retorne a página inicial
        </button>
      </Link>
    </div>
  </Container>
);

export default PageUnauthorized;

export const Container = styled.div`
  height: 100vh;
  .card-error {
    /* background-color: #ffcf4c; */
  }

  h1 {
    font-size: 128px;
    text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.5);
    margin-bottom: 20px;
    color: #ffcf4c;
  }

  h3 {
    text-transform: uppercase;
    text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.5);
    font-size: 50px;
    margin-bottom: 40px;
    color: #ffcf4c;
  }

  .btn-secondary {
    height: 60px;
  }
`;
