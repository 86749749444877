import { useCallback, useContext } from 'react';
import { AiFillPrinter } from 'react-icons/ai';
import { useHistory } from 'react-router';

import PropTypes from 'prop-types';

import { AuthContext } from '../../../../actions/auth-context';
import { startPrintTheOrder } from '../../../../actions/order.service';

const PrintOrderButton = ({ order }) => {
  const { hasPermissions } = useContext(AuthContext);

  const history = useHistory();

  const getPrintOrder = useCallback(async (data) => {
    const response = await startPrintTheOrder(data.id, {
      action_note: null,
    });

    if (!response.error) {
      history.push(`/orders/${data.id}`);
    }
  }, []);

  return (
    <>
      {order.order_status === 'Edição aprovada' &&
        hasPermissions(['print_photos']) && (
          <AiFillPrinter
            size={25}
            className="mb-1 action"
            data-tip="Imprimir Pedido"
            onClick={() => getPrintOrder(order)}
          />
        )}
    </>
  );
};

PrintOrderButton.propTypes = {
  order: PropTypes.shape({
    order_status: PropTypes.string,
  }).isRequired,
};

export default PrintOrderButton;
