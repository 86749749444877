import { MdClose } from 'react-icons/md';

import PropTypes from 'prop-types';
import styled from 'styled-components';

import {
  dateToStringDDMMYYY,
  dateToStringTime,
} from '../../../../commons/date';

const ActionsOrderModal = ({ actions }) => (
  <ModalContainer>
    <div className="modal fade" tabIndex="-1" id="modal-view-actions-order">
      <div className="modal-dialog  modal-dialog-centered modal-xl">
        <div className="modal-content ">
          <div className="modal-header">
            <h5 className="modal-title col-11 text-center text-uppercase">
              Ações realizadas
            </h5>
            <MdClose
              id="close"
              size={30}
              data-bs-dismiss="modal"
              type="button"
              onClick={() => {}}
            />
          </div>
          <div className="bg-modal">
            <div className="modal-body">
              <div className="table-responsive">
                <table className="table table-hover text-center table-striped">
                  <thead>
                    <tr>
                      <th scope="col" className="text-start">
                        DATA
                      </th>
                      <th scope="col" className="text-start">
                        HORA
                      </th>
                      <th scope="col" className="text-start">
                        STATUS
                      </th>
                      <th scope="col" className="text-start">
                        USUÁRIO
                      </th>
                      <th scope="col" className="text-start">
                        TEMPO PROCESSO
                      </th>
                      <th scope="col" className="text-start">
                        TEMPO TOTAL
                      </th>
                      <th scope="col" className="text-start">
                        OBSERVAÇÕES
                      </th>
                    </tr>
                  </thead>

                  <tbody>
                    {actions.map((item) => (
                      <tr key={item.id}>
                        <td className="text-start">
                          {dateToStringDDMMYYY(item.created_at)}
                        </td>
                        <td className="text-start">
                          {dateToStringTime(item.created_at)}
                        </td>
                        <td className="text-start">{item.order_status}</td>
                        <td className="text-start">{item.user_full_name}</td>
                        {item.timeProcess.days ? (
                          <td className="text-start">{`${item.timeProcess.days_text} ${item.timeProcess.hours} ${item.timeProcess.minutes} ${item.timeProcess.seconds}`}</td>
                        ) : (
                          <td className="text-start">0</td>
                        )}

                        <td className="text-start">{`${item.timeStart.days_text} ${item.timeStart.hours} ${item.timeStart.minutes} ${item.timeStart.seconds}`}</td>
                        <td className="text-start">{item.note}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>

            <div className="row justify-content-end">
              <div className="col-6 col-md-2 m-3">
                <button
                  type="button"
                  data-bs-dismiss="modal"
                  className="btn btn-outline-secondary btn-block col-12"
                >
                  Cancelar
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ModalContainer>
);

ActionsOrderModal.propTypes = {
  actions: PropTypes.arrayOf(Object).isRequired,
};

export default ActionsOrderModal;

export const ModalContainer = styled.div`
  .bg-modal {
    background-color: #f5f5f5;
    margin: 7px;
  }

  .modal-header {
    border: none;
    background-color: #4f4f4f;
    color: #fff;
    justify-content: center !important;
  }

  .modal-body {
    text-transform: uppercase;
  }

  .modal-footer {
    justify-content: center !important;
    border: none;
  }

  svg {
    :hover {
      cursor: pointer;
      color: #ffba00;
    }
  }
`;
