export const dateToString = async (date) => {
  if (date) {
    const string_date = date
      .toISOString()
      .substr(0, 10)
      .split('/')
      .reverse()
      .join('-');

    return string_date;
  }

  return null;
};

export const dateToStringWithTime = async (date) => {
  if (date) {
    const string_date = date
      .toISOString()
      .substr(0, 10)
      .split('/')
      .reverse()
      .join('-');

    return `${string_date} 00:00:00`;
  }

  return null;
};

export const dateToStringDDMMYYY = (date) => {
  const new_date = new Date(date);

  let day = new_date.getDate();
  let month = new_date.getMonth() + 1;
  const year = new_date.getFullYear();

  day = day < 10 ? `0${day}` : day;
  month = month < 10 ? `0${month}` : month;

  return `${day}/${month}/${year}`;
};

export const dateToStringTime = (date) => {
  const new_date = new Date(date);

  let hour = new_date.getHours();
  let minuts = new_date.getMinutes() + 1;
  let seconds = new_date.getSeconds();

  hour = hour < 10 ? `0${hour}` : hour;
  minuts = minuts < 10 ? `0${minuts}` : minuts;
  seconds = seconds < 10 ? `0${seconds}` : seconds;

  return `${hour}:${minuts}:${seconds}`;
};

export const validateExpectedDelivaryDate = (value) => {
  const today = new Date();

  const expected_delivery_date = new Date(value);

  const diff = Math.abs(today.getTime() - expected_delivery_date.getTime());

  const days_difference = Math.ceil(diff / (1000 * 60 * 60 * 24));

  if (days_difference < 15) return false;

  return true;
};

export const validateIfDelivaryDateIsLatter = (delivery_date) => {
  let status = '';
  const today = new Date();

  const diff = Math.abs(today.getTime() - new Date(delivery_date).getTime());

  const days_difference = Math.ceil(diff / (1000 * 60 * 60 * 24));
  if (days_difference < 7) status = 'atrasado';

  if (new Date(delivery_date) <= today) status = 'expirado';

  return status;
};

export const formatDeltaTime = (deltaTime) => {
  if (deltaTime) {
    const d = deltaTime.split('D')[0].split('P')[1];

    const h = deltaTime.split('H')[0].split('T')[1];

    const m = deltaTime.split('H')[1].split('M')[0];

    const s = deltaTime.split('M')[1].split('.')[0];

    return {
      days: d,
      days_text: `${d} ${d > 1 ? ' dias' : ' dia'}`,
      hours: h,
      minutes: m,
      seconds: s,
    };
  }

  return 0;
};
