import React, {
  useEffect,
  useRef,
  useCallback,
  useState,
  useContext,
} from 'react';
import { AiOutlinePlus } from 'react-icons/ai';
import { useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router';

import { Form } from '@unform/web';
import styled from 'styled-components';
import * as Yup from 'yup';

import { AuthContext } from '../../../../actions/auth-context';
import { update, show } from '../../../../actions/base-rest';
import { removeSellerFromUnit } from '../../../../actions/unit.service';
import stateOptions from '../../../../commons/StateOptions';
import DeleteModal from '../../../../components/delete-modal';
import InputLabel from '../../../../components/unform/input-label';
import SelectLabel from '../../../../components/unform/select-label';
import { setBreadcrumb } from '../../../../reducers/breadcrumb';
import { GroupData } from '../../../../styles/GroupData';
import IncludeSellerModal from './IncludeSellerModal';
import SellersTable from './SellersTable';

const URL = 'units';

const EditUnit = () => {
  const { setLoading } = useContext(AuthContext);

  const dispatch = useDispatch();

  const history = useHistory();

  const params = useParams();

  const formRef = useRef(null);

  const [unit, setUnit] = useState({});

  const [itemDelete, setItemDelete] = useState({});

  const [selectedState, setSelectedState] = useState({});

  const getUnit = useCallback(
    async (id) => {
      setLoading(true);

      const response = await show(URL, id);

      setSelectedState(
        stateOptions.find((item) => item.value === response.state)
      );

      setUnit(response);

      setLoading(false);
    },
    [stateOptions]
  );

  const removeSeller = useCallback(async () => {
    const response = await removeSellerFromUnit(unit.id, itemDelete.id);

    if (!response.error) setUnit(response);
  }, [itemDelete, unit]);

  const submit = useCallback(
    async (data) => {
      data.state = selectedState.value;

      try {
        formRef.current.setErrors({});
        const schema = Yup.object().shape({
          name: Yup.string().min(3, 'Descrição mínimo 3 caracteres'),
          state: Yup.string().required('Estado é obrigatório'),
          city: Yup.string().required('Cidade é obrigatório'),
        });

        await schema.validate(data, { abortEarly: false });

        setLoading(true);

        const response = await update(URL, data);

        if (!response.error) setUnit(response);

        setLoading(false);
      } catch (error) {
        const errorMessages = {};
        if (error instanceof Yup.ValidationError) {
          error.inner.forEach((err) => {
            errorMessages[err.path] = err.message;
          });
          formRef.current.setErrors(errorMessages);
        }
      }
    },
    [getUnit, selectedState]
  );

  useEffect(() => {
    const { id } = params;
    if (id) getUnit(id);

    dispatch(
      setBreadcrumb([
        { page: 'unidades', url: '/units' },
        { page: 'editar', url: null },
      ])
    );
  }, [getUnit, dispatch, params]);

  return (
    <Container className="container ">
      <div className="row mt-3 px-1 justify-content-center">
        <div className="col-12 text-center my-3">
          <h4>{`EDITAR ${unit.name}`}</h4>
        </div>
      </div>

      <div className="row justify-content-center mb-5">
        <Form
          ref={formRef}
          onSubmit={submit}
          initialData={unit}
          noValidate
          className="col-12 col-lg-8 "
        >
          <GroupData>
            <div className="col-1 d-none">
              <InputLabel name="id" label="ID" />
            </div>

            <div className="row justify-content-center">
              <div className="col-12 col-md-8 mt-5 px-1">
                <InputLabel name="name" label="nome" />
              </div>
            </div>

            <div className="row justify-content-center">
              <div className="col-12 col-md-4 mt-3 px-1">
                <InputLabel name="city" label="cidade" />
              </div>

              <div className="col-6 col-md-4 mt-3 px-1">
                <SelectLabel
                  name="state"
                  label="estado"
                  placeholder="selecione"
                  options={stateOptions}
                  value={selectedState}
                  onChange={(e) => setSelectedState(e)}
                />
              </div>
            </div>
          </GroupData>

          <div className="row justify-content-center mt-4">
            <div className="col-6 col-md-4 col-xl-3">
              <button
                className="btn btn-outline-secondary btn-block col-12"
                type="button"
                onClick={() => history.push('/units')}
              >
                voltar
              </button>
            </div>

            <div className="col-12 col-md-4 col-xl-4 mb-3">
              <button
                className="btn btn-secondary btn-block col-12"
                type="button"
                data-bs-toggle="modal"
                data-bs-target="#modal-include-seller"
              >
                <AiOutlinePlus style={{ marginTop: '-2px' }} /> incluir vendedor
              </button>
            </div>

            <div className="col-6 col-md-4 col-xl-3">
              <button
                className="btn btn-primary btn-block col-12"
                type="submit"
              >
                atualizar
              </button>
            </div>
          </div>
        </Form>

        <SellersTable
          data={unit?.sellers || []}
          setItemDelete={setItemDelete}
          history={history}
        />

        <DeleteModal
          id_modal="modal-remove-seller"
          item_delete={itemDelete}
          deleteItem={() => removeSeller()}
        />

        <IncludeSellerModal
          id_modal="modal-include-seller"
          unit={unit}
          setUnit={setUnit}
        />
      </div>
    </Container>
  );
};

export default EditUnit;

export const Container = styled.div`
  text-transform: uppercase;

  height: 100vh;
`;
