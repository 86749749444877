import React, {
  useRef,
  useCallback,
  useState,
  useEffect,
  useContext,
} from 'react';
import { MdClose } from 'react-icons/md';
import ReactTooltip from 'react-tooltip';

import { Form } from '@unform/web';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import * as Yup from 'yup';

import { AuthContext } from '../../../actions/auth-context';
import { index } from '../../../actions/base-rest';
import { dateToStringWithTime } from '../../../commons/date';
import Input from '../../../components/unform/input';
import InputDateLabel from '../../../components/unform/input-date-label';
import InputMask from '../../../components/unform/input-mask-label';
import SelectLabel from '../../../components/unform/select-label';

const OrdersFilter = ({ isFilter, setIsFilter, setParams, default_params }) => {
  const { hasRoles } = useContext(AuthContext);

  const formRef = useRef(null);

  const [selectedLocation, setSelectedLocation] = useState({
    label: 'Ativos',
    value: 'Ativos',
  });

  const [selectedStatus, setSelectedStatus] = useState({
    label: 'Todos',
    value: null,
  });

  const [editorsOptions, setEditorsOptions] = useState({});

  const [selectedEditor, setSelectedEditor] = useState({});

  const [cpfCnpj, setCpfCnpj] = useState(null);

  const localOptions = [
    { label: 'Todos', value: 'Todos' },
    { label: 'Ativos', value: 'Ativos' },
    { label: 'Comercial', value: 'Comercial' },
    { label: 'Financeiro', value: 'Financeiro' },
    { label: 'Edição', value: 'Edição' },
    { label: 'Aprovação', value: 'Aprovação' },
    { label: 'Impressão', value: 'Impressão' },
    { label: 'Montagem', value: 'Montagem' },
    { label: 'Embalagem', value: 'Embalagem' },
    { label: 'Expedição', value: 'Expedição' },
    { label: 'Enviado', value: 'Enviado' },
    { label: 'Na Unidade', value: 'Na Unidade' },
    { label: 'Entregue', value: 'Entregue' },
    { label: 'Arquivado', value: 'Arquivado' },
  ];

  const statusOptions = [
    { label: ' Todos ', value: null },
    { label: 'Em inclusão', value: 'Em inclusão' },
    { label: 'Incluído', value: 'Incluído' },
    { label: 'Financeiro aprovado', value: 'Financeiro aprovado' },
    { label: 'Análise financeira', value: 'Análise financeira' },
    { label: 'Financeiro negado', value: 'Financeiro negado' },
    { label: 'Em edição', value: 'Em edição' },
    { label: 'Editado', value: 'Editado' },
    { label: 'Inspeção de edição', value: 'Inspeção de edição' },
    { label: 'Edição aprovada', value: 'Edição aprovada' },
    { label: 'Em impressão', value: 'Em impressão' },
    { label: 'Impresso', value: 'Impresso' },
    { label: 'Em plotter', value: 'Em plotter' },
    { label: 'Plotado', value: 'Plotado' },
    { label: 'Em montagem', value: 'Em montagem' },
    { label: 'Montado', value: 'Montado' },
    { label: 'Em embalagem', value: 'Em embalagem' },
    { label: 'Embalado', value: 'Embalado' },
    { label: 'Em expedição', value: 'Em expedição' },
    { label: 'Em trânsito', value: 'Enviado' },
    { label: 'Recebido na unidade', value: 'Recebido na unidade' },
    { label: 'Entregue', value: 'Entregue' },
  ];

  const clearFieldsSearch = useCallback(() => {
    formRef.current.setFieldValue('person_full_name', '');

    formRef.current.setFieldValue('unit_city', '');

    formRef.current.setFieldValue('sig_number', '');

    formRef.current.setFieldValue('cpf_cnpj', '');

    formRef.current.clearField('delivery_date_gte');

    formRef.current.clearField('delivery_date_lte');

    formRef.current.clearField('order_date_gte');

    formRef.current.clearField('order_date_lte');

    setSelectedLocation({ label: 'Ativos', value: 'Ativos' });

    setSelectedStatus({ label: 'Todos', value: null });

    setSelectedEditor({ label: '', value: null });

    setCpfCnpj(null);

    setParams(default_params);
  }, []);

  const filterUnitsByClient = useCallback(async (value) => {
    if (value.length >= 3) {
      setParams((prevState) => ({
        ...prevState,
        person_full_name: value,
      }));
    } else if (value.length === 0) {
      setParams(default_params);
    }
  }, []);

  const filterUnitsByUnitCity = useCallback(async (value) => {
    if (value.length >= 3) {
      setParams((prevState) => ({
        ...prevState,
        unit_city: value,
      }));
    } else if (value.length === 0) {
      setParams(default_params);
    }
  }, []);

  const submit = useCallback(
    async (data) => {
      try {
        formRef.current.setErrors({});

        const delivery_date_g = await dateToStringWithTime(
          data.delivery_date_gte
        );

        const delivery_date_l = await dateToStringWithTime(
          data.delivery_date_lte
        );

        const order_date_g = await dateToStringWithTime(data.order_date_gte);

        const order_date_l = await dateToStringWithTime(data.order_date_lte);

        setParams((prevState) => ({
          ...prevState,
          sig_number: data.sig_number || null,

          order_status: selectedStatus.value,

          order_location: selectedLocation.value,

          editor_id: selectedEditor.value,

          person_cpf_cnpj: cpfCnpj,

          delivery_date_gte: delivery_date_g,

          delivery_date_lte: delivery_date_l,

          order_date_gte: order_date_g,

          order_date_lte: order_date_l,
        }));
      } catch (error) {
        const errorMessages = {};
        if (error instanceof Yup.ValidationError) {
          error.inner.forEach((err) => {
            errorMessages[err.path] = err.message;
          });

          formRef.current.setErrors(errorMessages);
        }
      }
    },
    [selectedLocation, selectedStatus, cpfCnpj, selectedEditor]
  );

  const loadEditorsOptions = useCallback(async () => {
    const editors = await index(`users/get-users-by-role/editor`);

    if (editors) {
      const options = await editors.map((item) => ({
        label: item.full_name,
        value: item.id,
      }));

      setEditorsOptions(options);
    }
  }, []);

  useEffect(() => {
    if (hasRoles(['master', 'administrator', 'financial']))
      loadEditorsOptions();
  }, []);

  return (
    <>
      {isFilter && (
        <>
          <CloseSearch
            className="btn btn-outline-primary text-center"
            onClick={() => {
              setIsFilter(false);
              clearFieldsSearch();
            }}
          >
            <MdClose size={25} data-tip="Fechar" />
            <ReactTooltip />
          </CloseSearch>

          <Search>
            <Form
              ref={formRef}
              onSubmit={submit}
              noValidate
              className="col-sm col-lg-10 p-4"
            >
              <div className="row justify-content-center">
                <div className="col-sm">
                  <SelectLabel
                    name="order_local"
                    label="Setor"
                    placeholder=""
                    options={localOptions}
                    value={selectedLocation}
                    onChange={(e) => setSelectedLocation(e)}
                  />
                </div>

                <div className="col-sm">
                  <SelectLabel
                    name="order_status"
                    label="Status"
                    placeholder=""
                    options={statusOptions}
                    value={selectedStatus}
                    onChange={(e) => setSelectedStatus(e)}
                  />
                </div>
              </div>

              <div className="row justify-content-center">
                <div className="col-sm mt-2">
                  <Input
                    name="person_full_name"
                    placeholder="Pesquisa rápida por nome"
                    onChange={(e) => filterUnitsByClient(e.target.value)}
                  />
                </div>

                <div className="col-sm mt-2">
                  <Input
                    name="unit_city"
                    placeholder="Pesquisa rápida por cidade"
                    onChange={(e) => filterUnitsByUnitCity(e.target.value)}
                  />
                </div>
              </div>

              <div className="row justify-content-center">
                <div className="col mt-2 d-flex">
                  <InputMask
                    label=""
                    name="cpf_cnpj"
                    placeholder="Por CPF"
                    mask="999.999.999-99"
                    onChange={(e) => setCpfCnpj(e.target.value)}
                  />
                </div>

                <div className="col mt-2 d-flex">
                  <InputMask
                    label=""
                    name="cpf_cnpj"
                    placeholder="Por CNPJ"
                    mask="99.999.999/9999-99"
                    onChange={(e) => setCpfCnpj(e.target.value)}
                  />
                </div>
              </div>

              <div className="row mt-2">
                {hasRoles(['administrator', 'master', 'financial']) && (
                  <div className="col-sm col-md-6 ">
                    <SelectLabel
                      name="editor"
                      label="Editor"
                      placeholder=""
                      options={editorsOptions}
                      value={selectedEditor}
                      onChange={(e) => setSelectedEditor(e)}
                    />
                  </div>
                )}

                <div className="col-sm col-md-6" style={{ marginTop: '14px' }}>
                  <Input name="sig_number" placeholder="Nº SIGI" />
                </div>
              </div>

              <div className="row mt-2">
                <span className="mt-3 mb-3">Filtro por Período</span>
                <div className="col-sm col-md-6">
                  <InputDateLabel
                    name="delivery_date_gte"
                    label="entrega inicial"
                  />
                </div>

                <div className="col-sm col-md-6">
                  <InputDateLabel
                    name="delivery_date_lte"
                    label="entrega final"
                  />
                </div>
              </div>

              <div className="row mt-2">
                <div className="col-sm col-md-6">
                  <InputDateLabel
                    name="order_date_gte"
                    label="inclusão inicial"
                  />
                </div>

                <div className="col-sm col-md-6">
                  <InputDateLabel
                    name="order_date_lte"
                    label="inclusão final"
                  />
                </div>
              </div>

              <div className="row justify-content-center mt-3">
                <div className="col">
                  <button
                    className="btn btn-outline-secondary col-sm btn-block col-12"
                    type="button"
                    onClick={clearFieldsSearch}
                  >
                    Limpar campos
                  </button>
                </div>

                <div className="col">
                  <button
                    className="btn btn-primary col-sm btn-block col-12"
                    type="submit"
                  >
                    Pesquisar
                  </button>
                </div>
              </div>
            </Form>
          </Search>
        </>
      )}
    </>
  );
};

OrdersFilter.propTypes = {
  isFilter: PropTypes.bool.isRequired,
  setIsFilter: PropTypes.func.isRequired,
  setParams: PropTypes.func.isRequired,
  default_params: PropTypes.shape().isRequired,
};

export default OrdersFilter;

export const Search = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  background-color: #f5f5f5;

  span {
    font-size: 12px;
    text-transform: uppercase;
  }
`;
export const ClearSearch = styled.div`
  color: #d11515;

  svg {
    margin-top: 12px;
    margin-left: -8px;
  }

  :hover {
    cursor: pointer;
    color: #ec4646;
  }
`;
export const CloseSearch = styled.button`
  position: absolute;
  margin-left: -70px;
  margin-top: 6px;
  height: 35px !important;
`;
