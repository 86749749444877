/* eslint-disable no-unused-vars */
import { useContext, useCallback } from 'react';
import { RiFileEditFill } from 'react-icons/ri';
import { useHistory } from 'react-router';

import PropTypes from 'prop-types';

import { AuthContext } from '../../../../actions/auth-context';

const ViewOrderButton = ({ order }) => {
  const { hasRole } = useContext(AuthContext);

  const history = useHistory();

  const hasViewButton = useCallback(() => {
    const status = order.order_status;

    if (hasRole('administrator')) {
      if (status === 'Inspeção de edição') {
        return true;
      }
    }

    if (hasRole('seller')) {
      if (
        status === 'Financeiro aprovado' ||
        status === 'Financeiro negado' ||
        status === 'Arquivado' ||
        status === 'Em inclusão' ||
        status === 'Editado' ||
        status === 'Incluído' ||
        status === 'Financeiro aprovado' ||
        status === 'Análise financeira' ||
        status === 'Em edição' ||
        status === 'Em impressão' ||
        status === 'Montado' ||
        status === 'Em montagem' ||
        status === 'Impresso' ||
        status === 'Em plotter' ||
        status === 'Plotado' ||
        status === 'Embalado' ||
        status === 'Em expedição' ||
        status === 'Em embalagem'
      ) {
        return true;
      }
    }

    if (hasRole('financial')) {
      if (
        status === 'Análise financeira' ||
        status === 'Financeiro aprovado' ||
        status === 'Financeiro negado' ||
        status === 'Em inclusão' ||
        status === 'Editado' ||
        status === 'Arquivado' ||
        status === 'Financeiro aprovado' ||
        status === 'Em edição' ||
        status === 'Em impressão' ||
        status === 'Impresso' ||
        status === 'Montado' ||
        status === 'Em montagem' ||
        status === 'Em plotter' ||
        status === 'Plotado' ||
        status === 'Embalado' ||
        status === 'Em embalagem' ||
        status === 'Enviado' ||
        status === 'Em expedição' ||
        status === 'Recebido na unidade'
      ) {
        return true;
      }
    }

    if (hasRole('editor')) {
      if (
        status === 'Editado' ||
        status === 'Em edição' ||
        status === 'Em inclusão' ||
        status === 'Financeiro negado' ||
        status === 'Análise financeira' ||
        status === 'Arquivado' ||
        status === 'Incluído' ||
        status === 'Em impressão' ||
        status === 'Impresso' ||
        status === 'Montado' ||
        status === 'Em montagem' ||
        status === 'Em plotter' ||
        status === 'Plotado' ||
        status === 'Embalado' ||
        status === 'Em embalagem' ||
        status === 'Enviado' ||
        status === 'Em expedição' ||
        status === 'Recebido na unidade'
      ) {
        return true;
      }
    }

    if (hasRole('printer')) {
      if (
        status === 'Impresso' ||
        status === 'Em impressão' ||
        status === 'Financeiro negado' ||
        status === 'Financeiro aprovado' ||
        status === 'Análise financeira' ||
        status === 'Em inclusão' ||
        status === 'Arquivado' ||
        status === 'Incluído' ||
        status === 'Em edição' ||
        status === 'Montado' ||
        status === 'Em montagem' ||
        status === 'Em plotter' ||
        status === 'Plotado' ||
        status === 'Embalado' ||
        status === 'Em embalagem' ||
        status === 'Enviado' ||
        status === 'Em expedição' ||
        status === 'Recebido na unidade'
      ) {
        return true;
      }
    }

    if (hasRole('plotter')) {
      if (
        status === 'Em inclusão' ||
        status === 'Incluído' ||
        status === 'Financeiro negado' ||
        status === 'Financeiro aprovado' ||
        status === 'Análise financeira' ||
        status === 'Arquivado' ||
        status === 'Editado' ||
        status === 'Em impressão' ||
        status === 'Enviado' ||
        status === 'Recebido na unidade'
      ) {
        return true;
      }
    }

    if (hasRole('fitter')) {
      if (
        status === 'Enviado' ||
        status === 'Editado' ||
        status === 'Financeiro negado' ||
        status === 'Análise financeira' ||
        status === 'Em inclusão' ||
        status === 'Arquivado' ||
        status === 'Incluído' ||
        status === 'Recebido na unidade'
      ) {
        return true;
      }
    }

    // if (hasRole('packer') && status === 'Em embalagem') return true;

    return false;
  }, []);

  return (
    <>
      {hasViewButton() && (
        <RiFileEditFill
          size={23}
          className="mb-1 action"
          data-tip="Ver Detalhes Pedido"
          onClick={() => history.push(`/orders/${order.id}`)}
        />
      )}
    </>
  );
};

ViewOrderButton.propTypes = {
  order: PropTypes.shape({
    id: PropTypes.string,
    order_status: PropTypes.string,
  }).isRequired,
};

export default ViewOrderButton;
