const INITIAL_STATE = {
  data: [],
};
export function breadcrumb(state = INITIAL_STATE, action) {
  switch (action.type) {
    case 'SET_BREADCRUMB':
      return { data: action.data };
    default:
      return state;
  }
}

export const setBreadcrumb = (data) => ({
  type: 'SET_BREADCRUMB',
  data,
});
