/* eslint-disable no-unused-vars */
import React, { useEffect, useRef, useContext, useState } from 'react';
import { BsPersonFill } from 'react-icons/bs';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';

import { Form } from '@unform/web';
import styled from 'styled-components';

import { AuthContext } from '../../actions/auth-context';
import InputLabel from '../../components/unform/input-label';
import InputMaskLabel from '../../components/unform/input-mask-label';
import { setBreadcrumb } from '../../reducers/breadcrumb';
import { GroupData } from '../../styles/GroupData';
import CropThumbnail from './CropThumbnail';

const ProfileMe = () => {
  const { user, handleChangingPassword, thumbnailProfile } =
    useContext(AuthContext);
  const dispatch = useDispatch();
  const history = useHistory();
  const formRef = useRef(null);
  const [isCropping, setIsCropping] = useState(false);

  useEffect(() => {
    dispatch(
      setBreadcrumb([
        { page: 'meus dados', url: null },
        { page: user.username, url: null },
      ])
    );
  }, []);

  return (
    <Container className="container ">
      <div className="row mt-3 px-1 justify-content-center">
        <div className="col-12 text-center my-3">
          <h4>MEUS DADOS</h4>
        </div>
      </div>

      <div className="row justify-content-center mb-5">
        <Form
          ref={formRef}
          initialData={user}
          noValidate
          className="col-12 col-lg-8"
        >
          <GroupData>
            <div className="row">
              <div className="col-md-10">
                <div className="row justify-content-center">
                  <div className="col mt-3 px-2">
                    <InputLabel name="username" label="Username" disabled />
                  </div>
                </div>

                <div className="row justify-content-center">
                  <div className="col-12 col-md-7  mt-3 px-2">
                    <InputLabel name="email" label="E-mail" disabled />
                  </div>

                  <div className="col  mt-3 px-2">
                    <InputMaskLabel
                      name="cell_phone"
                      label="celular"
                      mask="(99) 99999-9999"
                      disabled
                    />
                  </div>
                </div>
              </div>

              <Thumbnail className="col-2 ">
                <div
                  className="row border mt-2 justify-content-center"
                  style={{ height: '100%' }}
                >
                  {thumbnailProfile && (
                    <img
                      src={thumbnailProfile}
                      alt="Profile thumbnail"
                      className="p-1"
                    />
                  )}

                  {!thumbnailProfile && <BsPersonFill size={100} />}
                  <div className="col text-center">
                    <button
                      type="button"
                      className="btn btn-primary btn-block col-12"
                      onClick={() => setIsCropping(true)}
                    >
                      alterar
                    </button>
                  </div>
                </div>
              </Thumbnail>
            </div>
          </GroupData>

          <div className="row justify-content-center mt-4">
            <div className="col-6 col-md-4">
              <button
                className="btn btn-outline-secondary btn-block col-12"
                type="button"
                onClick={() => history.push('/')}
              >
                cancelar
              </button>
            </div>
            <div className="col-6 col-md-4">
              <button
                className="btn btn-secondary btn-block col-12"
                type="button"
                data-bs-toggle="modal"
                data-bs-target="#modal-confirm-change-password"
                onClick={() => handleChangingPassword(true)}
              >
                alterar senha
              </button>
            </div>
          </div>
        </Form>
      </div>

      <CropThumbnail isCropping={isCropping} setIsCropping={setIsCropping} />
    </Container>
  );
};

export default ProfileMe;

export const Container = styled.div`
  text-transform: uppercase;
`;

export const Thumbnail = styled.div`
  .btn-primary {
    font-size: 12px;
    height: 28px;
  }

  svg {
    color: #b2b2b2;
  }

  img {
    width: 100px;
    object-fit: cover;
  }

  .border {
    border-color: #b2b2b2 !important;
    border-radius: 5px;
  }
`;
