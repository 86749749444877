import { useCallback, useContext } from 'react';

import PropTypes from 'prop-types';

import { AuthContext } from '../../../../../actions/auth-context';
import {
  startAssembleTheOrder,
  finishAssenbleTheOrder,
} from '../../../../../actions/order.service';
import ActionModal from './ActionModal';

const AssembleModal = ({ order, setOrder }) => {
  const { setLoading } = useContext(AuthContext);

  const uploadOrder = useCallback((response) => {
    if (!response.error) {
      response.delivery_date = new Date(response.delivery_date);

      if (response.payment_first_date) {
        response.payment_first_date = new Date(
          response.payment_first_date.replace(/-/g, ',')
        );
      }

      setOrder(response);
    }
  }, []);

  const getAssembleOrder = useCallback(async (data) => {
    setLoading(true);

    const response = await startAssembleTheOrder(data.order_id, data);

    uploadOrder(response);

    setLoading(false);
  }, []);

  const finishAssembleOrder = useCallback(async (data) => {
    setLoading(true);

    const response = await finishAssenbleTheOrder(data.order_id, data);

    uploadOrder(response);

    setLoading(false);
  }, []);

  return (
    <>
      <ActionModal
        modal_id="modal-start-assemble-order"
        title="iniciar montagem do pedido?"
        action={getAssembleOrder}
        order={order}
      />

      <ActionModal
        modal_id="modal-finish-assemble-order"
        title="finalizar montagem do pedido?"
        action={finishAssembleOrder}
        order={order}
      />
    </>
  );
};

AssembleModal.propTypes = {
  order: PropTypes.shape({
    id: PropTypes.string,
    order_status: PropTypes.string,
  }).isRequired,
  setOrder: PropTypes.func.isRequired,
};

export default AssembleModal;
