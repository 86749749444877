import { useContext, useCallback } from 'react';

import PropTypes from 'prop-types';

import { AuthContext } from '../../../../../actions/auth-context';

const StartPlotterOrderButton = ({ order, has_plotter }) => {
  const { hasPermissions } = useContext(AuthContext);

  const hasViewButton = useCallback(
    () => has_plotter && hasPermissions(['plotter_photos']),
    [has_plotter]
  );

  return (
    <>
      {hasViewButton() && order.order_status === 'Impresso' && (
        <div className="col-sm col-md-4 col-xl-3 mt-2">
          <button
            className="btn btn-success btn-block col-12"
            type="button"
            data-bs-toggle="modal"
            data-bs-target="#modal-start-plotter-order"
          >
            iniciar plotter
          </button>
        </div>
      )}
    </>
  );
};

StartPlotterOrderButton.propTypes = {
  order: PropTypes.shape({
    id: PropTypes.string,
    order_status: PropTypes.string,
    products: PropTypes.arrayOf(Object),
  }).isRequired,
  has_plotter: PropTypes.bool.isRequired,
};

export default StartPlotterOrderButton;
