import api from './api';
import { handleError } from './handle-error';
import { toastSuccess } from './toast';

export const addRole = async (data) => {
  const result = new Promise((resolve) => {
    api
      .post(`users/${data.id}/roles`, data)
      .then((response) => {
        toastSuccess('Novo perfil incluido com sucesso!');
        resolve(response.data);
      })
      .catch(async (error) => {
        const response = await handleError(error);
        resolve(response);
      });
  });
  return result;
};

export const removeRole = async (data) => {
  const result = new Promise((resolve) => {
    api
      .delete(`users/${data.id}/roles/${data.role}`)
      .then((response) => {
        toastSuccess('Perfil removido com sucesso!');
        resolve(response.data);
      })
      .catch(async (error) => {
        const response = await handleError(error);
        resolve(response);
      });
  });
  return result;
};

export const addPermission = async (data) => {
  const result = new Promise((resolve) => {
    api
      .post(`users/${data.id}/permissions`, data)
      .then((response) => {
        toastSuccess('Nova permisão incluida com sucesso!');
        resolve(response.data);
      })
      .catch(async (error) => {
        const response = await handleError(error);
        resolve(response);
      });
  });
  return result;
};

export const removePermission = async (data) => {
  const result = new Promise((resolve) => {
    api
      .delete(`users/${data.id}/permissions/${data.permission}`)
      .then((response) => {
        toastSuccess('Permissão removida com sucesso!');
        resolve(response.data);
      })
      .catch(async (error) => {
        const response = await handleError(error);
        resolve(response);
      });
  });
  return result;
};

export const getProfileMeFull = async () => {
  const result = new Promise((resolve) => {
    api
      .get('profile/me/full')
      .then((response) => {
        resolve(response.data);
      })
      .catch(async (error) => {
        const response = await handleError(error);
        resolve(response);
      });
  });
  return result;
};

export const prepareProfileMeThumbnail = async () => {
  const result = new Promise((resolve) => {
    api
      .get('profile/me/thumbnail', { responseType: 'arraybuffer' })
      .then((response) => {
        const buffer = Buffer.from(response.data, 'base64');
        const blob = new Blob([buffer]);
        const image_url = URL.createObjectURL(blob);
        resolve(image_url);
      })
      .catch(async () => {
        const response = {
          error: {
            title: 'Atenção! ',
            message: 'Erro ao obter thumbnail.',
          },
        };
        resolve(response);
      });
  });
  return result;
};

export const getThumbnailSW3Url = async () => {
  const result = new Promise((resolve) => {
    api
      .get(`profile/me/thumbnail/s3`)
      .then((response) => {
        resolve(response.data);
      })
      .catch(async (error) => {
        const response = await handleError(error);
        resolve(response);
      });
  });
  return result;
};

export const storeThumbnail = async (data) => {
  const result = new Promise((resolve, reject) => {
    api
      .post(`profile/upload-thumbnail`, data)
      .then((response) => {
        toastSuccess('Foto perfil alterarada com sucesso!');
        resolve(response.data);
      })
      .catch((error) => {
        handleError(error);
        reject(error);
      });
  });
  return result;
};
