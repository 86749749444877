/* eslint-disable react/require-default-props */
import { useCallback, useContext } from 'react';
import { BsPuzzleFill } from 'react-icons/bs';

import PropTypes from 'prop-types';

import { AuthContext } from '../../../../actions/auth-context';
import { finishAssenbleTheOrder } from '../../../../actions/order.service';

const FinishAssembleOrderButton = ({ order, getOrders }) => {
  const { hasPermissions } = useContext(AuthContext);

  const finishAssembleOrder = useCallback(async (data) => {
    finishAssenbleTheOrder(data.id, {
      action_note: null,
    }).then(() => {
      getOrders();
    });
  }, []);

  const hasViewButton = useCallback(
    () =>
      hasPermissions(['edit_order', 'assemble_order']) &&
      order.order_status === 'Em montagem',
    [order]
  );

  return (
    <>
      {hasViewButton() && (
        <BsPuzzleFill
          size={25}
          className="mb-1 action-finished"
          data-tip="Finalizar montagem"
          onClick={() => finishAssembleOrder(order)}
        />
      )}
    </>
  );
};

FinishAssembleOrderButton.propTypes = {
  order: PropTypes.shape({
    id: PropTypes.string,
    order_status: PropTypes.string,
  }).isRequired,
  getOrders: PropTypes.func,
};

export default FinishAssembleOrderButton;
