import { useEffect, useContext } from 'react';
import { useHistory } from 'react-router-dom';

import { AuthContext } from '../../actions/auth-context';

const Logout = () => {
  const history = useHistory();
  const { signOut } = useContext(AuthContext);

  useEffect(() => {
    signOut();
    history.push('/');
  }, []);

  return <div />;
};

export default Logout;
