import { useState, useCallback, useEffect, useContext } from 'react';
import { BsPersonCheckFill, BsSquareFill } from 'react-icons/bs';
import { useHistory } from 'react-router';
import { Link } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';

import styled from 'styled-components';

import { AuthContext } from '../../actions/auth-context';
import { index } from '../../actions/base-rest';
import {
  dateToStringDDMMYYY,
  validateIfDelivaryDateIsLatter,
} from '../../commons/date';
import ApproveEditionOrderButton from '../orders/list/action-buttons/ApproveEditionOrderButton';
import DeliveredOrderToClientButton from '../orders/list/action-buttons/DeliveredOrderToClientButton';
import DispatchOrderButton from '../orders/list/action-buttons/DispatchOrderButton';
import EditOrderButton from '../orders/list/action-buttons/EditOrderButton';
import FinancialAnalysisButton from '../orders/list/action-buttons/FinancialAnalysisButton';
import FinishAssembleOrderButton from '../orders/list/action-buttons/FinishAssembleOrderButton';
import FinishPackOrderButton from '../orders/list/action-buttons/FinishPackOrderButton';
import FinishPlotterOrderButton from '../orders/list/action-buttons/FinishPlotterOrderButton';
import PrintOrderButton from '../orders/list/action-buttons/PrintOrderButton';
import ReceivedOrderToUnitButton from '../orders/list/action-buttons/ReceivedOrderToUnitButton';
import SentToUnitButton from '../orders/list/action-buttons/SentToUnitButton';
import StartAssembleOrderButton from '../orders/list/action-buttons/StartAssembleOrderButton';
import StartPackOrderButton from '../orders/list/action-buttons/StartPackOrderButton';
import StartPlotterOrderButton from '../orders/list/action-buttons/StartPlotterOrderButton';
import ViewOrderButton from '../orders/list/action-buttons/ViewOrderButton';

const OrdersTableMe = () => {
  const { hasRole, user, setLoading, hasRoles } = useContext(AuthContext);

  const [orders, setOrders] = useState([]);

  const history = useHistory();

  const prepareParams = useCallback(() => {
    const filter = {
      page: 1,
      per_page: 100,
      unit_city: user.city,
      order_status: '',
      sorts: 'delivery_date:asc',
    };

    let status = '';

    if (hasRole('seller')) {
      status +=
        'Em inclusão,Incluído,Financeiro negado,Financeiro aprovado,Análise financeira,Enviado,Recebido na unidade,';

      filter.seller_full_name = user.full_name;
    }

    if (hasRole('financial')) status += 'Incluído,Análise financeira,';

    if (hasRole('editor')) {
      status += 'Financeiro aprovado,Em edição,Edição aprovada,';
      filter.sorts += ',editor_id:ASC';
    }

    if (hasRole('administrator')) status += 'Editado,Inspeção de edição,';

    if (hasRole('printer')) status += 'Edição aprovada,Em impressão,';

    if (hasRole('plotter')) status += 'Impresso,Em plotter,';

    if (hasRole('fitter')) status += 'Impresso,Plotado,Em montagem,';

    if (hasRole('packer'))
      status += 'Montado,Em embalagem,Embalado,Em expedição,';

    filter.order_status = status.substring(0, status.length - 1);

    return filter;
  }, [user]);

  const getOrders = useCallback(async () => {
    const params = await prepareParams();

    setLoading(true);

    const response = await index('orders', params);

    const prepared_orders = response.rows.map((item) => {
      item.delivery_date_string = dateToStringDDMMYYY(item.delivery_date);

      item.isOrderLatter = validateIfDelivaryDateIsLatter(item.delivery_date);
      return item;
    });

    setOrders(prepared_orders);

    setLoading(false);
  }, []);

  /*
   usado para filtrar os pedidos pelo id do editor logado e pedidos sem editor
   sem precisar criar outra tela só para o editor
  */
  const viewOrder = useCallback(
    (item) => {
      if (hasRole('editor')) {
        if (item.editor_id && item.editor_id !== user.id) return false;
      }

      return true;
    },
    [user]
  );

  useEffect(() => {
    getOrders();
  }, []);

  return (
    <Container>
      {hasRole('seller') && (
        <div className="row justify-content-center mt-5">
          <div className="col-6 col-md-4">
            <Link to="/orders/new">
              <button
                type="button"
                className="btn btn-primary col-12 btn-order"
              >
                novo pedido
              </button>
            </Link>
          </div>

          <div className="col-6 col-md-4">
            <Link to="/persons/new">
              <button
                type="button"
                className="btn btn-secondary col-12 btn-order"
              >
                nova pessoa
              </button>
            </Link>
          </div>
        </div>
      )}

      {hasRole('seller') && (
        <div className="row  justify-content-center legend mt-5">
          <div className="col text-center">
            <BsSquareFill color="#d11515" /> Entrega Atrasada
          </div>

          <div className="col text-center">
            <BsSquareFill color="#c7a11e" /> 1 semana entrega
          </div>

          <div className="col text-center">
            <BsSquareFill color="#E67E22" /> Retrabalho
          </div>

          <div className="col text-center">
            <BsSquareFill color="#00bfa5" /> Em Análise
          </div>

          <div className="col text-center">
            <BsSquareFill color="#000" /> Negado
          </div>

          <div className="col text-center">
            <BsSquareFill color="#457AB1" /> Enviado
          </div>

          <div className="col text-center">
            <BsSquareFill color="#3F9D2F" /> Recebido
          </div>
        </div>
      )}

      {hasRole('editor') && (
        <div className="row  justify-content-center legend mt-5">
          <div className="col text-center">
            <BsSquareFill color="#d11515" /> Entrega Atrasada
          </div>

          <div className="col text-center">
            <BsSquareFill color="#c7a11e" /> menos 7 dias entrega
          </div>

          <div className="col text-center">
            <BsSquareFill color="#E67E22" /> Retrabalho
          </div>

          <div className="col text-center">
            <BsSquareFill color="#00bfa5" /> Em edição
          </div>

          <div className="col text-center">
            <BsSquareFill color="#457AB1" /> Sem editor
          </div>
        </div>
      )}

      {hasRoles(['printer', 'plotter', 'fitter', 'packer']) && (
        <div className="row  justify-content-center legend mt-5">
          <div className="col text-center">
            <BsSquareFill color="#d11515" /> Entrega Atrasada
          </div>

          <div className="col text-center">
            <BsSquareFill color="#c7a11e" /> 1 semana entrega
          </div>

          <div className="col text-center">
            <BsSquareFill color="#E67E22" /> Retrabalho
          </div>
        </div>
      )}

      {hasRoles(['administrator']) && (
        <div className="row  justify-content-center legend mt-5">
          <div className="col text-center">
            <BsSquareFill color="#d11515" /> Entrega Atrasada
          </div>

          <div className="col text-center">
            <BsSquareFill color="#c7a11e" /> menos 7 dias entrega
          </div>

          <div className="col text-center">
            <BsSquareFill color="#E67E22" /> Retrabalho
          </div>
        </div>
      )}

      {orders.length > 0 && !hasRole('master') && (
        <div className="row mt-3">
          <div className="col">
            <div className="table-responsive">
              <table className="table table-hover text-center">
                <thead>
                  <tr>
                    <th scope="col" className="text-start">
                      D. ENTREGA
                    </th>
                    <th scope="col" className="text-start">
                      CLIENTE
                    </th>
                    {!hasRole('seller') && (
                      <th className="text-start" scope="col">
                        CIDADE
                      </th>
                    )}
                    <th className="text-start" scope="col">
                      STATUS
                    </th>
                    <th scope="col" className="text-end">
                      AÇÃO
                    </th>
                  </tr>
                </thead>

                <tbody>
                  {orders.map((item) => (
                    <OrderTr
                      order_status={item.order_status}
                      is_rework={item.is_rework}
                      has_editor={item.editor_id}
                      role={user.roles[0].slug}
                      order_later={item.is_order_later}
                      order_urgent={item.is_order_urgent}
                      key={item.id}
                      onClick={(e) => {
                        if (e.target.innerText !== undefined) {
                          history.push(`orders/${item.id}`);
                        }
                      }}
                    >
                      {viewOrder(item) && (
                        <>
                          <td className="text-start">
                            {item.delivery_date_string}
                          </td>

                          <td className="text-start">
                            {item.person_full_name}
                          </td>

                          {!hasRole('seller') && (
                            <td className="text-start">{item.unit_city}</td>
                          )}

                          <td className="text-start">{item.order_status}</td>

                          <td className="text-center">
                            <ViewOrderButton order={item} />

                            <FinancialAnalysisButton order={item} />

                            <EditOrderButton order={item} />

                            <ApproveEditionOrderButton order={item} />

                            <PrintOrderButton order={item} />

                            <StartPlotterOrderButton
                              order={item}
                              getOrders={() => getOrders()}
                            />

                            <FinishPlotterOrderButton
                              order={item}
                              getOrders={() => getOrders()}
                            />

                            <StartAssembleOrderButton
                              order={item}
                              getOrders={() => getOrders()}
                            />

                            <FinishAssembleOrderButton
                              order={item}
                              getOrders={() => getOrders()}
                            />

                            <StartPackOrderButton
                              order={item}
                              getOrders={() => getOrders()}
                            />

                            <FinishPackOrderButton
                              order={item}
                              getOrders={() => getOrders()}
                            />

                            <DispatchOrderButton
                              order={item}
                              getOrders={() => getOrders()}
                            />

                            <SentToUnitButton
                              order={item}
                              getOrders={() => getOrders()}
                            />

                            <ReceivedOrderToUnitButton
                              order={item}
                              getOrders={() => getOrders()}
                            />

                            <DeliveredOrderToClientButton
                              order={item}
                              getOrders={() => getOrders()}
                            />

                            {item.order_status === 'Entregue' && (
                              <BsPersonCheckFill size={25} color="#3F9D2F" />
                            )}

                            <ReactTooltip />
                          </td>
                        </>
                      )}
                    </OrderTr>
                  ))}
                </tbody>
              </table>

              {hasRoles(['seller', 'editor']) && (
                <div className="row justify-content-center mb-5">
                  <div className="col-md-4">
                    <Link to="/orders">
                      <button
                        type="button"
                        className="btn btn-secondary col-12"
                      >
                        ver todos os pedidos
                      </button>
                    </Link>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </Container>
  );
};

export default OrdersTableMe;

export const Container = styled.section`
  text-transform: uppercase;

  h6 {
    font-size: 12px;
  }

  thead {
    background: #464646;
    color: #fff;
  }
  .table-hover {
    cursor: pointer;
  }

  .legend {
    font-size: 12px;
    text-transform: capitalize;
  }

  @media screen and (max-width: 768px) {
    .table {
      width: 900px !important;
    }
  }
`;

export const OrderTr = styled.tr`
  color: ${(props) => {
    if (props.is_rework) return '#E67E22';

    if (props.order_later) return '#d11515';

    if (props.order_urgent) return '#c7a11e';

    if (
      props.order_status === 'Análise financeira' ||
      props.order_status === 'Em edição'
    )
      return '#00bfa5';

    if (!props.has_editor && props.role === 'editor') return '#457AB1';

    if (props.order_status === 'Enviado') return '#457AB1';

    if (props.order_status === 'Financeiro negado' && props.role === 'seller')
      return '#000';

    if (props.order_status === 'Recebido na unidade') return '#3F9D2F';

    return '#464646';
  }};

  font-weight: ${(props) => {
    if (props.order_status === 'Incluído' && props.role === 'financial')
      return '600';

    if (props.order_status === 'Financeiro negado') return '600';

    if (props.is_rework) return '600';

    if (props.order_latter === 'expirado') return '600';

    if (props.order_status === 'Editado' && props.role === 'printer')
      return '600';

    if (props.order_status === 'Impresso' && props.role === 'fitter')
      return '600';

    if (props.order_status === 'Montado' && props.role === 'packer')
      return '600';

    if (props.order_status === 'Enviado' && props.role === 'seller')
      return '600';

    if (props.order_status === 'Edição aprovada' && props.role === 'editor')
      return '600';

    return '';
  }};
`;

export const OrderField = styled.div`
  svg {
    margin-left: 8px;
    margin-top: -1px;
    :hover {
      color: #ffba00;
    }
  }
`;
