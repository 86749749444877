import { useEffect, useCallback, useState } from 'react';
import { AiFillPrinter, AiOutlineDollarCircle } from 'react-icons/ai';
import {
  BsCheck2Square,
  BsFillPuzzleFill,
  BsChevronCompactDown,
  BsPinMapFill,
} from 'react-icons/bs';
import { FaBoxOpen, FaBoxes, FaTruck, FaStore } from 'react-icons/fa';
import { MdOutlinePhotoCamera } from 'react-icons/md';
import { RiImageEditFill } from 'react-icons/ri';

import styled from 'styled-components';
import 'animate.css';

import {
  getProductionPositionApi,
  getProductionPositionDetailsApi,
} from '../../../actions/dashbords.service';
import { floatToCurrency } from '../../../commons/currency-formatter';
import OrdersInProductionLocationAndCityModal from './OrdersInProductionLocationAndCityModal';
import OrdersInProductionLocationModal from './OrdersInProductionLocationModal';

const ProductionLocation = () => {
  const [data, setData] = useState({});

  const [dataDetail, setDataDetail] = useState({});

  const [openDetail, setOpenDetail] = useState(false);

  const [orderLocation, setOrderLocation] = useState('');

  const [unitCity, setUnitCity] = useState('');

  const getProductionPosition = useCallback(async () => {
    const result = await getProductionPositionApi();

    setData(result);
  }, []);

  const getProductionPositionDetails = useCallback(async () => {
    const result = await getProductionPositionDetailsApi();

    setDataDetail(result);
  }, []);

  const getOrders = useCallback(async (city, location) => {
    setOrderLocation('');
    setOrderLocation(location);
    setUnitCity(city);
  }, []);

  useEffect(() => {
    getProductionPosition();

    getProductionPositionDetails();
  }, []);

  return (
    <>
      {data && (
        <Container>
          <h5 className="animate__animated animate__pulse">TOTAL DE PEDIDOS</h5>

          <ContainerProduction>
            <ul className="p-0">
              <li>
                <div className="card">
                  <div className="icon">
                    <MdOutlinePhotoCamera size={35} />
                  </div>

                  <p className="text">Comercial</p>

                  <p
                    className="count"
                    role="presentation"
                    data-bs-toggle="modal"
                    data-bs-target="#modal-orders-production-location"
                    onClick={() => setOrderLocation('Comercial')}
                  >
                    {data.commercial_count}
                  </p>

                  <p className="value">
                    {floatToCurrency(data.commercial_value)}
                  </p>
                </div>
              </li>
              <li>
                <div className="card">
                  <div className="icon">
                    <AiOutlineDollarCircle size={35} />
                  </div>

                  <p className="text">Financeiro</p>

                  <p
                    className="count"
                    role="presentation"
                    data-bs-toggle="modal"
                    data-bs-target="#modal-orders-production-location"
                    onClick={() => setOrderLocation('Financeiro')}
                  >
                    {data.financial_count}
                  </p>

                  <p className="value">
                    {floatToCurrency(data.financial_value)}
                  </p>
                </div>
              </li>
              <li>
                <div className="card">
                  <div className="icon">
                    <RiImageEditFill size={35} />
                  </div>

                  <p className="text">Edição</p>

                  <p
                    className="count"
                    role="presentation"
                    data-bs-toggle="modal"
                    data-bs-target="#modal-orders-production-location"
                    onClick={() => setOrderLocation('Edição')}
                  >
                    {data.edition_count}
                  </p>

                  <p className="value">{floatToCurrency(data.edition_value)}</p>
                </div>
              </li>
              <li>
                <div className="card">
                  <div className="icon">
                    <BsCheck2Square size={35} />
                  </div>

                  <p className="text">Aprovação</p>

                  <p
                    className="count"
                    role="presentation"
                    data-bs-toggle="modal"
                    data-bs-target="#modal-orders-production-location"
                    onClick={() => setOrderLocation('Aprovação')}
                  >
                    {data.edit_inspection_count}
                  </p>

                  <p className="value">
                    {floatToCurrency(data.edit_inspection_value)}
                  </p>
                </div>
              </li>
              <li>
                <div className="card">
                  <div className="icon">
                    <AiFillPrinter size={35} />
                  </div>

                  <p className="text">Impressão</p>

                  <p
                    className="count"
                    role="presentation"
                    data-bs-toggle="modal"
                    data-bs-target="#modal-orders-production-location"
                    onClick={() => setOrderLocation('Impressão')}
                  >
                    {data.print_count}
                  </p>

                  <p className="value">{floatToCurrency(data.print_value)}</p>
                </div>
              </li>
              <li>
                <div className="card">
                  <div className="icon">
                    <BsFillPuzzleFill size={35} />
                  </div>

                  <p className="text">Montagem</p>

                  <p
                    className="count"
                    role="presentation"
                    data-bs-toggle="modal"
                    data-bs-target="#modal-orders-production-location"
                    onClick={() => setOrderLocation('Montagem')}
                  >
                    {data.assembly_count}
                  </p>

                  <p className="value">
                    {floatToCurrency(data.assembly_value)}
                  </p>
                </div>
              </li>
              <li>
                <div className="card">
                  <div className="icon">
                    <FaBoxOpen size={35} />
                  </div>

                  <p className="text">Embalagem</p>

                  <p
                    className="count"
                    role="presentation"
                    data-bs-toggle="modal"
                    data-bs-target="#modal-orders-production-location"
                    onClick={() => setOrderLocation('Embalagem')}
                  >
                    {data.packing_count}
                  </p>

                  <p className="value">{floatToCurrency(data.packing_value)}</p>
                </div>
              </li>
              <li>
                <div className="card">
                  <div className="icon">
                    <FaBoxes size={35} />
                  </div>

                  <p className="text">Expedição</p>

                  <p
                    className="count"
                    role="presentation"
                    data-bs-toggle="modal"
                    data-bs-target="#modal-orders-production-location"
                    onClick={() => setOrderLocation('Expedição')}
                  >
                    {data.dispatch_count}
                  </p>

                  <p className="value">
                    {floatToCurrency(data.dispatch_value)}
                  </p>
                </div>
              </li>
              <li>
                <div className="card">
                  <div className="icon">
                    <FaTruck size={35} />
                  </div>

                  <p className="text">Enviado</p>

                  <p
                    className="count"
                    role="presentation"
                    data-bs-toggle="modal"
                    data-bs-target="#modal-orders-production-location"
                    onClick={() => setOrderLocation('Enviado')}
                  >
                    {data.sent_count}
                  </p>

                  <p className="value">{floatToCurrency(data.sent_value)}</p>
                </div>
              </li>
              <li>
                <div className="card">
                  <div className="icon">
                    <FaStore size={35} />
                  </div>

                  <p className="text">Na Unidade</p>

                  <p
                    className="count"
                    role="presentation"
                    data-bs-toggle="modal"
                    data-bs-target="#modal-orders-production-location"
                    onClick={() => setOrderLocation('Na Unidade')}
                  >
                    {data.received_count}
                  </p>

                  <p className="value">
                    {floatToCurrency(data.received_value)}
                  </p>
                </div>
              </li>
            </ul>
          </ContainerProduction>

          {!openDetail && (
            <div
              className="detalhes"
              role="button"
              tabIndex="0"
              onKeyPress={() => {}}
              onClick={() => setOpenDetail(true)}
            >
              <span>Relatório detalhado por cidade</span>

              <div>
                <BsChevronCompactDown />
              </div>
            </div>
          )}

          <>
            {openDetail && (
              <Detail>
                <div className="row justify-content-end">
                  <button
                    type="button"
                    className="btn btn-no-border-primary"
                    onClick={() => setOpenDetail(false)}
                  >
                    fechar relatório
                  </button>
                </div>

                <div className="table-responsive scrollme">
                  <table className="table">
                    <thead>
                      <tr>
                        <th scope="col" className="fixed-col text-center">
                          <BsPinMapFill size={35} />
                          <div className="detail-location">Cidade</div>
                        </th>
                        <th scope="col" className="bg-secondary" colSpan={2}>
                          <MdOutlinePhotoCamera size={35} />
                          <div className="detail-location">Comercial</div>
                        </th>
                        <th scope="col" colSpan={2}>
                          <AiOutlineDollarCircle size={35} />
                          <div className="detail-location">Financeiro</div>
                        </th>
                        <th scope="col" className="bg-secondary" colSpan={2}>
                          <RiImageEditFill size={35} />
                          <div className="detail-location">Edição</div>
                        </th>
                        <th scope="col" colSpan={2}>
                          <BsCheck2Square size={35} />
                          <div className="detail-location">Aprovação</div>
                        </th>
                        <th scope="col" className="bg-secondary" colSpan={2}>
                          <AiFillPrinter size={35} />
                          <div className="detail-location">Impressão</div>
                        </th>
                        <th scope="col" colSpan={2}>
                          <BsFillPuzzleFill size={35} />
                          <div className="detail-location">Montagem</div>
                        </th>
                        <th scope="col" className="bg-secondary" colSpan={2}>
                          <FaBoxOpen size={35} />
                          <div className="detail-location">Embalagem</div>
                        </th>
                        <th scope="col" colSpan={2}>
                          <FaBoxes size={35} />
                          <div className="detail-location">Expedição</div>
                        </th>
                        <th scope="col" className="bg-secondary" colSpan={2}>
                          <FaTruck size={35} />
                          <div className="detail-location">Enviado</div>
                        </th>
                        <th scope="col" colSpan={2}>
                          <FaStore size={35} />
                          <div className="detail-location">Na unidade</div>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <SubtitleTR>
                        <td className="fixed-col">#</td>

                        <td className="bg-secondary br-1">Qtde</td>
                        <td className="bg-secondary">Valor</td>

                        <td className="br-2">Qtde</td>
                        <td>Valor</td>

                        <td className="bg-secondary br-1">Qtde</td>
                        <td className="bg-secondary">Valor</td>

                        <td className="br-2">Qtde</td>
                        <td>Valor</td>

                        <td className="bg-secondary br-1">Qtde</td>
                        <td className="bg-secondary">Valor</td>

                        <td className="br-2">Qtde</td>
                        <td>Valor</td>

                        <td className="bg-secondary br-1">Qtde</td>
                        <td className="bg-secondary">Valor</td>

                        <td className="br-2">Qtde</td>
                        <td>Valor</td>

                        <td className="bg-secondary br-1">Qtde</td>
                        <td className="bg-secondary">Valor</td>

                        <td className="br-2">Qtde</td>
                        <td>Valor</td>
                      </SubtitleTR>
                      {dataDetail?.map((item) => (
                        <tr key={item.id}>
                          <td className="text-start fixed-col">
                            <div className="city">
                              <b>{item.city}</b>
                            </div>
                          </td>

                          <td
                            className="bg-secondary br-1 value-link"
                            role="presentation"
                            data-bs-toggle="modal"
                            data-bs-target="#modal-orders-production-location-city"
                            onClick={() => getOrders(item.city, 'Comercial')}
                          >
                            {item.commercial_count}
                          </td>
                          <td className="bg-secondary text-start">
                            {floatToCurrency(item.commercial_value)}
                          </td>

                          <td
                            className="br-2 value-link"
                            role="presentation"
                            data-bs-toggle="modal"
                            data-bs-target="#modal-orders-production-location-city"
                            onClick={() => getOrders(item.city, 'Financeiro')}
                          >
                            {item.financial_count}
                          </td>
                          <td className="text-start">
                            {floatToCurrency(item.financial_value)}
                          </td>

                          <td
                            className="bg-secondary br-1 value-link"
                            role="presentation"
                            data-bs-toggle="modal"
                            data-bs-target="#modal-orders-production-location-city"
                            onClick={() => getOrders(item.city, 'Edição')}
                          >
                            {item.edition_count}
                          </td>
                          <td className="bg-secondary text-start">
                            {floatToCurrency(item.edition_value)}
                          </td>

                          <td
                            className="br-2 value-link"
                            role="presentation"
                            data-bs-toggle="modal"
                            data-bs-target="#modal-orders-production-location-city"
                            onClick={() => getOrders(item.city, 'Aprovação')}
                          >
                            {item.edit_inspection_count}
                          </td>
                          <td className="text-start">
                            {floatToCurrency(item.edit_inspection_value)}
                          </td>

                          <td
                            className="bg-secondary br-1 value-link"
                            role="presentation"
                            data-bs-toggle="modal"
                            data-bs-target="#modal-orders-production-location-city"
                            onClick={() => getOrders(item.city, 'Impressão')}
                          >
                            {item.print_count}
                          </td>
                          <td className="bg-secondary text-start">
                            {floatToCurrency(item.print_value)}
                          </td>

                          <td
                            className="br-2 value-link"
                            role="presentation"
                            data-bs-toggle="modal"
                            data-bs-target="#modal-orders-production-location-city"
                            onClick={() => getOrders(item.city, 'Montagem')}
                          >
                            {item.assembly_count}
                          </td>
                          <td className="text-start">
                            {floatToCurrency(item.assembly_value)}
                          </td>

                          <td
                            className="bg-secondary br-1 value-link"
                            role="presentation"
                            data-bs-toggle="modal"
                            data-bs-target="#modal-orders-production-location-city"
                            onClick={() => getOrders(item.city, 'Embalagem')}
                          >
                            {item.packing_count}
                          </td>
                          <td className="bg-secondary text-start">
                            {floatToCurrency(item.packing_value)}
                          </td>

                          <td
                            className="br-2 value-link"
                            role="presentation"
                            data-bs-toggle="modal"
                            data-bs-target="#modal-orders-production-location-city"
                            onClick={() => getOrders(item.city, 'Expedição')}
                          >
                            {item.dispatch_count}
                          </td>
                          <td className="text-start">
                            {floatToCurrency(item.dispatch_value)}
                          </td>

                          <td
                            className="bg-secondary br-1 value-link"
                            role="presentation"
                            data-bs-toggle="modal"
                            data-bs-target="#modal-orders-production-location-city"
                            onClick={() => getOrders(item.city, 'Enviado')}
                          >
                            {item.sent_count}
                          </td>
                          <td className="bg-secondary text-start">
                            {floatToCurrency(item.sent_value)}
                          </td>

                          <td
                            className="br-2 value-link"
                            role="presentation"
                            data-bs-toggle="modal"
                            data-bs-target="#modal-orders-production-location-city"
                            onClick={() => getOrders(item.city, 'Na Unidade')}
                          >
                            {item.received_count}
                          </td>
                          <td className="text-start">
                            {floatToCurrency(item.received_value)}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </Detail>
            )}
          </>

          <OrdersInProductionLocationModal order_location={orderLocation} />

          <OrdersInProductionLocationAndCityModal
            order_location={orderLocation}
            unit_city={unitCity}
          />
        </Container>
      )}
    </>
  );
};

export default ProductionLocation;

export const Container = styled.div`
  text-align: center;
  color: #7d7d7d;

  .detalhes {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    font-size: 11px;

    :hover {
      color: #fab702;
      cursor: pointer;

      svg {
        animation: animate__pulse;
        animation-duration: 2s;
      }
    }
  }
`;

export const ContainerProduction = styled.div`
  background: #f5f5f5;
  overflow: auto;
  width: 100%;
  padding-top: 10px;

  ul {
    font-size: 8px;
    margin-top: 30px;
    counter-reset: step;
    display: flex;
    flex-direction: row;
    width: 100%;
  }

  li {
    list-style-type: none;
    position: relative;
    text-align: center;
    padding: 0 10px 0 10px;

    .icon {
      margin-top: -25px;
      color: #fff;
      z-index: 2;
      position: absolute;
      border-radius: 50%;
      width: 50px;
      height: 50px;
      background-color: #55b776;
      padding: 8px;
    }

    .card {
      border: 1px solid #55b776;
      width: 90px;
      height: 90px;
      z-index: 2;
      display: flex;
      align-items: center;
    }

    .text {
      background-color: #55b776;
      padding: 1px;
      width: 70px;
      border-radius: 3px;
      color: #fff;
      margin-top: 15px;
      z-index: 3;

      font-size: 10px;
    }

    .count {
      font-size: 22px;
      font-weight: bold;
      color: #7d7d7d;
      margin-top: -15px;

      :hover {
        cursor: pointer !important;
        color: #ffba00;
      }
    }

    .value {
      font-size: 10px;
      margin-top: -10px;
      color: #7d7d7d;
    }
  }

  li:after {
    width: 100px;
    height: 2px;
    content: '';
    position: absolute;
    background-color: #55b776;
    top: 42px;
    left: -20px;
    z-index: 1;
    transition: all 0.8s;
  }

  li:first-child:after {
    content: none;
  }

  @media screen and (max-width: 667px) {
    .ul {
      width: 800px;
    }
  }
`;

export const Detail = styled.div`
  margin-top: 10px;

  .btn-no-border-primary {
    width: 200px;
  }

  .bg-secondary {
    background-color: #e8e8e8 !important;
  }

  svg {
    color: #7d7d7d;
  }

  .city {
    width: 150px !important;
  }

  .fixed-col {
    position: sticky;
    left: 0 !important;
    background-color: #fff;
  }

  .br-1 {
    border-right: solid 1px #fff;
  }

  .br-2 {
    border-right: solid 1px #e8e8e8;
  }

  .detail-location {
    font-size: 10px;
    padding: 0;
    margin: 0;
    font-weight: 500;
  }

  .value-link {
    font-weight: bold;
    :hover {
      cursor: pointer;
      background-color: #fab702 !important;
    }
  }
`;

export const SubtitleTR = styled.tr`
  font-size: 12px;
  font-weight: 600;
`;
