import { useContext, useCallback } from 'react';
import { ImProfile } from 'react-icons/im';

import PropTypes from 'prop-types';

import { AuthContext } from '../../../../../actions/auth-context';
import { getPdfOrderControl } from '../../../../../actions/order.service';

const GenerateOrderControlPDFButton = ({ order }) => {
  const { hasPermissions } = useContext(AuthContext);

  const generateOrderControlPdfFile = useCallback(async () => {
    const response = await getPdfOrderControl(order.id);

    window.open(response);
  }, [order]);

  return (
    <>
      {hasPermissions(['add_order']) &&
        order.products?.length > 0 &&
        order.spcfiles?.length > 0 &&
        order.person_id && (
          <div className="col-sm col-md-4 col-xl-3 mt-2">
            <button
              className="btn btn-primary btn-block col-12"
              type="button"
              onClick={generateOrderControlPdfFile}
            >
              Ficha de Controle <ImProfile size={20} className="mb-1" />
            </button>
          </div>
        )}
    </>
  );
};

GenerateOrderControlPDFButton.propTypes = {
  order: PropTypes.shape({
    id: PropTypes.string,
    order_status: PropTypes.string,
    is_rework: PropTypes.bool,
    products: PropTypes.arrayOf(Object),
    spcfiles: PropTypes.arrayOf(Object),
    person_id: PropTypes.string,
  }).isRequired,
};

export default GenerateOrderControlPDFButton;
