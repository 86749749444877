import { useContext, useCallback } from 'react';
import { AiFillPrinter } from 'react-icons/ai';

import PropTypes from 'prop-types';

import { AuthContext } from '../../../../../actions/auth-context';
import { getPdfFinishOrder } from '../../../../../actions/order.service';

const GeneratePDFinishOrder = ({ order }) => {
  const { hasPermissions } = useContext(AuthContext);

  const generatePdfFinishOrder = useCallback(async () => {
    const response = await getPdfFinishOrder(order.id);

    window.open(response);
  }, [order]);

  const validPhotos =
    order.photos.filter((item) => item.is_cover_photo === true).length > 0;

  const viewButton =
    // (order.order_status === 'Montado' || order.order_status === 'Embalado') &&
    validPhotos && hasPermissions(['edit_order', 'pack_order']);

  return (
    <>
      {viewButton && (
        <div className="col-sm col-md-3 col-xl-2 mt-2">
          <button
            className="btn btn-primary btn-block col-12"
            type="button"
            onClick={generatePdfFinishOrder}
          >
            Ficha Cliente <AiFillPrinter size={25} className="mb-1" />
          </button>
        </div>
      )}
    </>
  );
};

GeneratePDFinishOrder.propTypes = {
  order: PropTypes.shape({
    id: PropTypes.string,
    order_status: PropTypes.string,
    is_rework: PropTypes.bool,
    products: PropTypes.arrayOf(Object),
    spcfiles: PropTypes.arrayOf(Object),
    photos: PropTypes.arrayOf(Object),
    person_id: PropTypes.string,
  }).isRequired,
};

export default GeneratePDFinishOrder;
