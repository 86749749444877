import React, { useEffect, useRef, useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';

import { Form } from '@unform/web';
import styled from 'styled-components';
import * as Yup from 'yup';

import { store, index } from '../../../../actions/base-rest';
import Checkbox from '../../../../components/unform/checkbox';
import InputLabel from '../../../../components/unform/input-label';
import { setBreadcrumb } from '../../../../reducers/breadcrumb';
import { GroupData } from '../../../../styles/GroupData';

const URL = 'users';

const NewUser = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const formRef = useRef(null);
  const [rolesOptions, setRolesOptions] = useState([]);
  const [roles, setRoles] = useState([]);
  const [noRoleError, setNoRoleError] = useState(false);

  const submit = useCallback(
    async (data) => {
      if (roles.length > 0) {
        data.roles = roles;
        setNoRoleError(false);
      } else {
        setNoRoleError(true);
      }

      try {
        formRef.current.setErrors({});
        const schema = Yup.object().shape({
          full_name: Yup.string().min(3, 'Nome mínimo 3 caracteres'),
          username: Yup.string().min(3, 'Username mínimo 3 caracteres'),
          email: Yup.string()
            .email('Digite um e-mail válido')
            .required('E-mail é obrigatório'),
          password: Yup.string()
            .min(8, 'Senha mínimo 8 caracteres')
            .required('Senha é obrigatória'),
          password_confirmation: Yup.string().oneOf(
            [Yup.ref('password'), null],
            'Senhas não conferem'
          ),
          roles: Yup.array().required('Perfil é obrigatório'),
        });

        await schema.validate(data, { abortEarly: false });

        const response = await store(URL, data);
        if (!response.error) history.push(`/users/${response.id}`);
      } catch (error) {
        const errorMessages = {};
        if (error instanceof Yup.ValidationError) {
          error.inner.forEach((err) => {
            errorMessages[err.path] = err.message;
          });
          formRef.current.setErrors(errorMessages);
        }
      }
    },
    [roles]
  );

  const changeRoles = useCallback(
    (value) => {
      const data = roles;
      if (roles.indexOf(value) > -1) {
        data.splice(data.indexOf('foo'), 1);
      } else {
        data.push(value);
      }
      setRoles(data);
    },
    [roles]
  );

  const loadRoles = useCallback(async () => {
    const response = await index('roles');
    response.sort((a, b) => (a.nome > b.nome ? 1 : b.nome > a.nome ? -1 : 0));
    setRolesOptions(response);
  }, []);

  useEffect(() => {
    dispatch(
      setBreadcrumb([
        { page: 'usuários', url: '/users' },
        { page: 'novo', url: null },
      ])
    );

    loadRoles();
  }, [dispatch, loadRoles]);

  return (
    <Container className="container ">
      <div className="row mt-3 px-1 justify-content-center">
        <div className="col-12 text-center my-3">
          <h4>NOVO USUÁRIO</h4>
        </div>
      </div>

      <div className="row justify-content-center mb-5">
        <Form
          ref={formRef}
          onSubmit={submit}
          noValidate
          className="col-12 col-lg-5"
        >
          <GroupData>
            <div className="row justify-content-center px-2">
              <div className="col-sm col-md-7 mt-3 px-1">
                <InputLabel name="full_name" label="Nome Completo" />
              </div>

              <div className="col-sm mt-3 px-1">
                <InputLabel name="username" label="Username" />
              </div>
            </div>

            <div className="row justify-content-center px-2">
              <div className="col-sm mt-3 px-1">
                <InputLabel name="email" label="E-mail" />
              </div>
            </div>

            <div className="row justify-content-center px-2">
              <div className="col-sm col-md-6 mt-3 px-1">
                <InputLabel name="password" label="Senha" type="password" />
              </div>

              <div className="col-sm mt-3 px-1">
                <InputLabel
                  name="password_confirmation"
                  label="Confirmar Senha"
                  type="password"
                />
              </div>
            </div>

            <div className="row px-1">
              <div className="col-12 mt-3">
                <label className="label" htmlFor="inu">
                  PERFIL
                  <input type="text" className="d-none" id="inu" />
                </label>
                {noRoleError && (
                  <p className="error">
                    * Usuário precisa de pelo menos um PERFIL
                  </p>
                )}
              </div>

              {rolesOptions.map((item) => (
                <div className="col-4 mt-2" key={item.id}>
                  <Checkbox
                    name={item.slug}
                    value={item.id}
                    label={item.name}
                    defaultChecked={false}
                    onChange={(e) => changeRoles(e.target.value)}
                  />
                </div>
              ))}
            </div>

            <div className="row justify-content-center" />
          </GroupData>

          <div className="row justify-content-center mt-4">
            <div className="col-6 col-md-4 ">
              <button
                className="btn btn-outline-secondary btn-block col-12"
                type="button"
                onClick={() => history.push('/users')}
              >
                cancelar
              </button>
            </div>
            <div className="col-6 col-md-4 ">
              <button
                className="btn btn-primary btn-block col-12"
                type="submit"
              >
                salvar
              </button>
            </div>
          </div>
        </Form>
      </div>
    </Container>
  );
};

export default NewUser;

export const Container = styled.div`
  text-transform: uppercase;
  height: 100vh;

  .label {
    font-style: normal;
    font-weight: 300;
    font-size: 10px;
    line-height: 14px;
    color: #4d4d4d;
    margin: 0;
    text-transform: uppercase;
  }

  .error {
    color: red;
    font-size: 12px;
    margin: 0;
    font-weight: 500;
  }
`;
