/* eslint-disable arrow-body-style */
/* eslint-disable prettier/prettier */
import { useContext, useCallback } from 'react';

import PropTypes from 'prop-types';

import { AuthContext } from '../../../../../actions/auth-context';

const ShelveOrderButton = ({ order }) => {
  const { hasRoles } = useContext(AuthContext);

  const viewButton = useCallback(() => {
    return (
      order.order_status !== 'Arquivado' &&
      hasRoles(['administrator', 'financial', 'master'])
    );
  }, [order]);

  return (
    <>
      {viewButton() && (
        <div className="col-sm col-md-3 col-xl-2 mt-2">
          <button
            className="btn btn-secondary btn-block col-12"
            type="button"
            data-bs-toggle="modal"
            data-bs-target="#modal-shelve-order"
          >
            arquivar
          </button>
        </div>
      )}
    </>
  );
};

ShelveOrderButton.propTypes = {
  order: PropTypes.shape({
    id: PropTypes.string,
    order_status: PropTypes.string,
    is_rework: PropTypes.bool,
  }).isRequired,
};

export default ShelveOrderButton;
