import React, {
  useRef,
  useCallback,
  useState,
  useEffect,
  useContext,
} from 'react';
import { useDispatch } from 'react-redux';
import { useParams, useHistory } from 'react-router';

import { Form } from '@unform/web';
import styled from 'styled-components';
import * as Yup from 'yup';

import { AuthContext } from '../../actions/auth-context';
import { resetPassword } from '../../actions/authenticate';
import Input from '../../components/unform/input';
import { setBreadcrumb } from '../../reducers/breadcrumb';

const ChangePassword = () => {
  const dispatch = useDispatch();
  const formRef = useRef(null);
  const { user } = useContext(AuthContext);
  const params = useParams();
  const history = useHistory();
  const [viewPassword, setviewPassword] = useState(false);

  const submit = useCallback(async (data) => {
    try {
      formRef.current.setErrors({});
      const schema = Yup.object().shape({
        password: Yup.string()
          .min(8, 'Senha mínimo 8 caracteres')
          .required('Senha é obrigatória'),
        password_confirmation: Yup.string().oneOf(
          [Yup.ref('password'), null],
          'Senhas não conferem'
        ),
      });

      await schema.validate(data, { abortEarly: false });

      data.token = params.token;

      const response = await resetPassword(data);
      if (!response.error) {
        history.push('/login');
      }
    } catch (erro) {
      const errorMessages = {};
      if (erro instanceof Yup.ValidationError) {
        erro.inner.forEach((err) => {
          errorMessages[err.path] = err.message;
        });
        formRef.current.setErrors(errorMessages);
      }
    }
  }, []);

  const changeViewPassword = useCallback(() => {
    const input1 = document.getElementById('new-password');
    const input2 = document.getElementById('password-confirmation');

    if (viewPassword) {
      input1.type = 'text';
      input2.type = 'text';
    } else {
      input1.type = 'password';
      input2.type = 'password';
    }
  }, [viewPassword]);

  useEffect(() => {
    changeViewPassword();

    if (user) {
      dispatch(
        setBreadcrumb([
          { page: 'profile', url: '/profile-me' },
          { page: user?.full_name, url: null },
          { page: 'alterar senha', url: null },
        ])
      );
    }
  }, [user]);

  return (
    <Container>
      <div className="form col col-md-6 col-lg-4 p-4 m-2">
        <h1 className="text-center">alterar senha</h1>

        <Form ref={formRef} onSubmit={submit}>
          <div className="mt-5">
            <Input
              id="new-password"
              type="password"
              name="password"
              placeholder="nova senha"
            />
          </div>

          <div className="mt-4">
            <Input
              id="password-confirmation"
              type="password"
              name="password_confirmation"
              placeholder="confirmar senha"
            />
          </div>

          <ViewPassword className="form-check">
            <input
              id="defaultCheck1"
              className="form-check-input mb-3"
              type="checkbox"
              checked={viewPassword}
              onChange={() => {
                setviewPassword(!viewPassword);
                changeViewPassword();
              }}
            />
            <p className="form-check-label " htmlFor="defaultCheck1">
              mostrar senha
            </p>
          </ViewPassword>

          <button
            className="btn btn-primary btn-block col-12 mt-5"
            type="submit"
          >
            enviar
          </button>
        </Form>
      </div>
    </Container>
  );
};

export default ChangePassword;

export const Container = styled.div`
  margin-top: -80px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 100vh;
  text-transform: uppercase;
  color: #464646;

  .form {
    border: 1px solid #464646;
  }
`;
export const ViewPassword = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  font-weight: 500;
  margin-top: 20px;

  p {
    font-size: 12px;
    margin-top: 5px;
    cursor: pointer;
    margin-left: 5px;
  }

  input {
    cursor: pointer;
    border-radius: 0 !important;
    width: 15px;
    height: 15px;
  }

  :hover {
    color: #ffcf4c;
  }

  .form-check-input:checked {
    background-color: #ffcf4c;
    border-color: #ffcf4c;
  }
`;
