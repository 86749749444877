import { useEffect, useCallback, useState } from 'react';

import styled from 'styled-components';
import 'animate.css';

import { getOrdersByEditorApi } from '../../../actions/dashbords.service';
import OrdersInProductionByEditorModal from './OrdersInProductionByEditorModal';

const OrdersByEditors = () => {
  const [data, setData] = useState([]);

  const [editor, setEditor] = useState({});

  const getOrdersByEditor = useCallback(async () => {
    const result = await getOrdersByEditorApi();

    setData(result);
  }, []);

  useEffect(() => {
    getOrdersByEditor();
  }, []);

  return (
    <>
      {data && (
        <Container>
          <h5 className="animate__animated animate__pulse">
            TOTAL DE PEDIDOS POR EDITOR
          </h5>

          <ContainerData>
            <ul className="">
              {data?.map((item) => (
                <li key={item.id}>
                  <div className="card">
                    <div className="icon">
                      <p className="total">{item.quantity_total}</p>
                    </div>

                    <p className="text">
                      {!item.full_name
                        ? 'Não definido'
                        : item.full_name?.length > 15
                        ? `${item.full_name.substr(0, 15)}...`
                        : item.full_name}
                    </p>

                    <p
                      className="edit-approved"
                      role="presentation"
                      data-bs-toggle="modal"
                      data-bs-target="#modal-orders-production-editor"
                      onClick={() => setEditor(item)}
                    >
                      {item.quantity_of_in_edit} /{' '}
                      {item.quantity_of_financial_approved}
                    </p>

                    <p className="legend">Editando / Aprovado</p>
                  </div>
                </li>
              ))}
            </ul>
          </ContainerData>

          <OrdersInProductionByEditorModal editor={editor} />
        </Container>
      )}
    </>
  );
};

export default OrdersByEditors;

export const Container = styled.div`
  text-align: center;
  margin-top: 20px;
  color: #7d7d7d;

  .detalhes {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    font-size: 11px;

    :hover {
      color: #fab702;
      cursor: pointer;

      svg {
        animation: animate__pulse;
        animation-duration: 2s;
      }
    }
  }
`;

export const ContainerData = styled.div`
  background: #f5f5f5;
  overflow: auto;
  width: 100%;
  padding-top: 10px;

  ul {
    font-size: 8px;
    margin-top: 30px;
    counter-reset: step;
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;

    @media screen and (min-width: 900px) {
      justify-content: center !important;
    }
  }

  li {
    list-style-type: none;
    position: relative;
    text-align: center;
    padding: 10px;

    .icon {
      margin-top: -25px;
      color: #fff;
      z-index: 2;
      position: absolute;
      border-radius: 50%;
      width: 50px;
      height: 50px;
      background-color: #7d7d7d;
      padding: 8px;
    }

    .card {
      border: 1px solid #7d7d7d;
      width: 130px;
      height: 90px;
      z-index: 2;
      display: flex;
      align-items: center;
    }

    .text {
      background-color: #7d7d7d;
      padding: 1px;
      width: 110px;
      border-radius: 3px;
      color: #fff;
      margin-top: 10px;
      z-index: 3;

      font-size: 12px;
    }

    .total {
      font-size: 22px;
      font-weight: bold;
      color: #fff;
    }

    .edit-approved {
      font-size: 22px;
      font-weight: bold;
      color: #7d7d7d;
      margin-top: -10px;

      :hover {
        cursor: pointer;
        color: #ffba00;
      }
    }

    .legend {
      font-size: 10px;
      margin-top: -12px;
      color: #7d7d7d;
    }
  }

  li:after {
    width: 100px;
    height: 2px;
    content: '';
    position: absolute;
    background-color: #7d7d7d;
    top: 42px;
    left: -20px;
    z-index: 1;
    transition: all 0.8s;
  }

  li:first-child:after {
    content: none;
  }

  @media screen and (max-width: 667px) {
    .ul {
      width: 800px;
    }
  }
`;

export const Detail = styled.div`
  margin-top: 10px;

  .btn-no-border-primary {
    width: 200px;
  }

  .bg-secondary {
    background-color: #e8e8e8 !important;
  }

  svg {
    color: #7d7d7d;
  }
`;
