import React, { useRef, useCallback, useEffect, useState } from 'react';
import { MdClose } from 'react-icons/md';

import { Form } from '@unform/web';
import { Modal } from 'bootstrap';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import * as Yup from 'yup';

import { sendOrderToRework } from '../../../../actions/order.service';
import { indexEnum } from '../../../../commons/enum';
import InputTextAreaLabel from '../../../../components/unform/input-text-area-label';
import SelectLabel from '../../../../components/unform/select-label';

function SendReworkModal({ order, setOrder }) {
  const formRefModal = useRef(null);

  const modalRef = useRef(null);

  const [modal, setModal] = useState(null);

  const [sectorsOptions, setSectorsOptions] = useState([]);

  const resetFormRefModal = useCallback(() => {
    formRefModal.current.reset();

    modal.hide();

    document.documentElement.style.overflow = 'auto';

    document.body.scroll = 'yes';
  }, [modal]);

  const loadSectorsOptions = useCallback(async () => {
    const response = await indexEnum('company-sections');
    setSectorsOptions(response);
  }, []);

  useEffect(() => {
    setModal(new Modal(modalRef.current));

    loadSectorsOptions();
  }, []);

  const submit = useCallback(
    async (data) => {
      try {
        formRefModal.current.setErrors({});
        const schema = Yup.object().shape({
          rework_to: Yup.string().required('Setor é obrigatório'),

          action_note: Yup.string(),
        });

        await schema.validate(data, { abortEarly: false });

        const response = await sendOrderToRework(order.id, data);

        if (!response.error) {
          response.delivery_date = new Date(response.delivery_date);

          if (response.payment_first_date) {
            response.payment_first_date = new Date(
              response.payment_first_date.replace(/-/g, ',')
            );
          }

          setOrder(response);
        }

        resetFormRefModal();
      } catch (error) {
        const errorMessages = {};
        if (error instanceof Yup.ValidationError) {
          error.inner.forEach((err) => {
            errorMessages[err.path] = err.message;
          });
          formRefModal.current.setErrors(errorMessages);
        }
      }
    },
    [order]
  );

  return (
    <ModalContainer>
      <div
        className="modal"
        tabIndex="-1"
        id="modal-rework-order"
        ref={modalRef}
        data-bs-backdrop="false"
      >
        <div className="modal-dialog  modal-dialog-centered">
          <div className="modal-content ">
            <div className="modal-header">
              <h5 className="modal-title col-11 text-center text-uppercase">
                enviar pedido para retrabalho?
              </h5>
              <MdClose
                id="close"
                size={30}
                data-bs-dismiss="modal"
                type="button"
                onClick={() => {}}
              />
            </div>
            <div className="bg-modal">
              <div className="modal-body">
                <Form ref={formRefModal} onSubmit={submit} noValidate>
                  <div className="row">
                    <div className="col-12 mt-2">
                      <SelectLabel
                        name="rework_to"
                        label="Retrabalho para"
                        placeholder=""
                        options={sectorsOptions}
                      />
                    </div>

                    <div className="col-sm mt-2">
                      <InputTextAreaLabel
                        name="action_note"
                        label="Observações"
                        cols={20}
                        rows={2}
                      />
                    </div>
                  </div>

                  <div className="row justify-content-center mt-4">
                    <button
                      type="button"
                      className="btn btn-secondary col-4 m-1"
                      onClick={() => resetFormRefModal()}
                    >
                      CANCELAR
                    </button>
                    <button type="submit" className="btn btn-primary col-4 m-1">
                      ENVIAR
                    </button>
                  </div>
                </Form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ModalContainer>
  );
}

SendReworkModal.propTypes = {
  order: PropTypes.shape({
    id: PropTypes.string,
  }).isRequired,
  setOrder: PropTypes.func.isRequired,
};

export default SendReworkModal;

export const ModalContainer = styled.div`
  .bg-modal {
    background-color: #f5f5f5;
    margin: 7px;
  }

  .modal-header {
    border: none;
    background-color: #4f4f4f;
    color: #fff;
    justify-content: center !important;
  }

  .modal-body {
    text-transform: uppercase;
  }

  .modal-footer {
    justify-content: center !important;
    border: none;
  }

  svg {
    :hover {
      cursor: pointer;
      color: #ffba00;
    }
  }
`;
