import api from './api';

export const deleteItem = async (data, id) => {
  const indexId = data.map((item) => item.id).indexOf(id);
  data.splice(indexId, 1);

  return data;
};

export const filterItems = async (data, query) => {
  const filter = await data.filter(
    (el) => el.name.toLowerCase().indexOf(query.toLowerCase()) > -1
  );

  return filter;
};

export const filterItemsById = async (data, query) => {
  const filter = await data.filter(
    (el) => el.id.toString().indexOf(query.toString()) > -1
  );

  return filter;
};

export const filterItemByCpfCnpj = async (query) => {
  let data = [];

  await api.get('persons').then((response) => {
    data = response.data.rows;
  });

  const filter = await data.filter(
    (el) => el.cpf_cnpj.toString().indexOf(query) > -1
  );

  return filter;
};
