/* eslint-disable react/require-default-props */
import { useCallback, useContext } from 'react';
import { FaTruck } from 'react-icons/fa';

import PropTypes from 'prop-types';

import { AuthContext } from '../../../../actions/auth-context';
import { sendToUnit } from '../../../../actions/order.service';

const SentToUnitButton = ({ order, getOrders }) => {
  const { hasPermissions } = useContext(AuthContext);

  const sendUnit = useCallback(async (data) => {
    sendToUnit(data.id, {
      action_note: null,
    }).then(() => {
      getOrders();
    });
  }, []);

  return (
    <>
      {order.order_status === 'Em expedição' &&
        hasPermissions(['edit_order', 'pack_order']) && (
          <FaTruck
            size={22}
            className="mb-1 action"
            data-tip="Enviar para unidade"
            onClick={() => sendUnit(order)}
          />
        )}
    </>
  );
};

SentToUnitButton.propTypes = {
  order: PropTypes.shape({
    order_status: PropTypes.string,
  }).isRequired,
  getOrders: PropTypes.func,
};

export default SentToUnitButton;
