import React, { useCallback, useEffect, useState } from 'react';
import { IoIosSearch } from 'react-icons/io';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';

import styled from 'styled-components';

import { index, remove } from '../../../actions/base-rest';
import DeleteModal from '../../../components/delete-modal';
import { setBreadcrumb } from '../../../reducers/breadcrumb';
import UnitsFilter from './UnitsFilter';
import UnitsTable from './UnitsTable';

const URL = 'units';

const default_params = {
  current_page: 1,
  per_page: 10,
  sorts: 'name:ASC',
};

const Units = () => {
  const dispatch = useDispatch();

  const [page, setPage] = useState({});

  const [units, setUnits] = useState([]);

  const [isFilter, setIsFilter] = useState(false);

  const [itemDelete, setItemDelete] = useState({});

  const [params, setParams] = useState(default_params);

  const [loading, setLoading] = useState(false);

  const [scrolling, setScrolling] = useState(false);

  const getUnits = useCallback(
    async (filter) => {
      setLoading(true);

      const response = await index(URL, filter);

      if (scrolling) {
        const new_units = (data) => [...data, ...response.rows];

        setUnits(new_units);

        setScrolling(false);
      } else {
        setUnits(response.rows);
      }

      setLoading(false);

      setPage(response.page);
    },
    [scrolling]
  );

  const deleteItem = useCallback(async () => {
    const response = await remove(URL, itemDelete.id);

    if (response) {
      setUnits([]);

      getUnits();
    }
  }, [itemDelete]);

  useEffect(() => {
    getUnits(params);
  }, [params]);

  useEffect(() => {
    dispatch(setBreadcrumb([{ page: 'unidades', url: null }]));
  }, []);

  return (
    <Container className="container">
      <div className="row mt-3 justify-content-center">
        <div className="col-12 text-center my-3">
          <h4>UNIDADES</h4>
        </div>

        {!isFilter && (
          <div className="col-6 col-md-4">
            <Link to={`/${URL}/new`}>
              <button type="button" className="btn btn-primary col-12 btn-unit">
                nova unidade
              </button>
            </Link>
          </div>
        )}

        <div className={isFilter ? 'col-12 col-md-10' : 'col-6 col-md-4'}>
          <button
            type="button"
            className="btn btn-secondary col-12 btn-unit"
            onClick={() => setIsFilter(true)}
          >
            <IoIosSearch size={25} className="mb-1 icon-button" />
            localizar
          </button>

          <UnitsFilter
            isFilter={isFilter}
            setIsFilter={setIsFilter}
            setParams={setParams}
            default_params={default_params}
          />
        </div>
      </div>

      <UnitsTable
        units={units}
        page={page}
        setItemDelete={setItemDelete}
        setParams={setParams}
        params={params}
        loading={loading}
        setScrolling={setScrolling}
      />

      <DeleteModal
        id_modal="modal-unit"
        item_delete={itemDelete}
        deleteItem={() => deleteItem()}
      />
    </Container>
  );
};

export default Units;

export const Container = styled.div`
  .btn-unit {
    height: 45px;
  }

  @media screen and (max-width: 360px) {
    .btn-unit {
      height: 55px;
    }

    .icon-button {
      display: none;
    }
  }
`;
