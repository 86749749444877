import { useState, useCallback } from 'react';
import { AiFillFileImage, AiFillDiff } from 'react-icons/ai';
import { BsPuzzleFill, BsCheck2Square } from 'react-icons/bs';
import {
  FaFileAlt,
  FaFileInvoiceDollar,
  FaBoxOpen,
  FaBox,
  FaBoxes,
  FaTruck,
} from 'react-icons/fa';
import { GiJigsawBox } from 'react-icons/gi';
import { GoChecklist } from 'react-icons/go';
import { IoBagCheck } from 'react-icons/io5';
import { MdMoneyOff, MdPhotoAlbum, MdPrint, MdStore } from 'react-icons/md';
import { RiArchiveDrawerFill, RiImageEditFill } from 'react-icons/ri';
import { TiPrinter } from 'react-icons/ti';
import { VscInspect } from 'react-icons/vsc';
import ReactTooltip from 'react-tooltip';

import PropTypes from 'prop-types';
import styled from 'styled-components';

import { getOrderActionsById } from '../../../../actions/order.service';
import { formatDeltaTime } from '../../../../commons/date';
import ActionsOrderModal from './ActionsOrderModal';

const IconOrderStatus = ({ order }) => {
  const tool_tip = `Pedido ${order.order_status} ${
    order.is_rework ? ' - retrabalho' : ''
  }`;

  const [actions, setActions] = useState([]);

  const getActionsOrder = useCallback(async () => {
    const response = await getOrderActionsById(order.id);

    if (!response?.error) {
      const data_format = response.map((item) => ({
        ...item,
        timeStart: formatDeltaTime(item.time_from_the_start),
        timeProcess: formatDeltaTime(item.time_of_process),
      }));

      setActions(data_format);
    }
  }, [order]);

  return (
    <Container order_status={order.order_status} is_rework={order.is_rework}>
      <div className="line">
        {order.order_status === 'Em inclusão' && (
          <>
            <AiFillDiff data-tip={tool_tip} />
            <ReactTooltip />
          </>
        )}

        {order.order_status === 'Incluído' && (
          <>
            <FaFileAlt data-tip={tool_tip} />
            <ReactTooltip />
          </>
        )}

        {order.order_status === 'Análise financeira' && (
          <>
            <FaFileInvoiceDollar data-tip={tool_tip} />
            <ReactTooltip />
          </>
        )}

        {order.order_status === 'Financeiro negado' && (
          <>
            <MdMoneyOff data-tip={tool_tip} />
            <ReactTooltip />
          </>
        )}

        {order.order_status === 'Arquivado' && (
          <>
            <RiArchiveDrawerFill data-tip={tool_tip} />
            <ReactTooltip />
          </>
        )}

        {order.order_status === 'Financeiro aprovado' && (
          <>
            <GoChecklist data-tip={tool_tip} />
            <ReactTooltip />
          </>
        )}

        {order.order_status === 'Em edição' && (
          <>
            <RiImageEditFill data-tip={tool_tip} />
            <ReactTooltip />
          </>
        )}

        {order.order_status === 'Editado' && (
          <>
            <MdPhotoAlbum data-tip={tool_tip} />
            <ReactTooltip />
          </>
        )}

        {order.order_status === 'Inspeção de edição' && (
          <>
            <VscInspect data-tip={tool_tip} />
            <ReactTooltip />
          </>
        )}

        {order.order_status === 'Edição aprovada' && (
          <>
            <BsCheck2Square data-tip={tool_tip} />
            <ReactTooltip />
          </>
        )}

        {order.order_status === 'Em impressão' && (
          <>
            <MdPrint data-tip={tool_tip} />
            <ReactTooltip />
          </>
        )}

        {order.order_status === 'Impresso' && (
          <>
            <AiFillFileImage data-tip={tool_tip} />
            <ReactTooltip />
          </>
        )}

        {order.order_status === 'Em plotter' && (
          <>
            <TiPrinter data-tip={tool_tip} />
            <ReactTooltip />
          </>
        )}

        {order.order_status === 'Plotado' && (
          <>
            <AiFillFileImage data-tip={tool_tip} />
            <ReactTooltip />
          </>
        )}

        {order.order_status === 'Em montagem' && (
          <>
            <BsPuzzleFill data-tip={tool_tip} />
            <ReactTooltip />
          </>
        )}

        {order.order_status === 'Montado' && (
          <>
            <GiJigsawBox data-tip={tool_tip} />
            <ReactTooltip />
          </>
        )}

        {order.order_status === 'Em embalagem' && (
          <>
            <FaBoxOpen data-tip={tool_tip} />
            <ReactTooltip />
          </>
        )}

        {order.order_status === 'Embalado' && (
          <>
            <FaBox data-tip={tool_tip} />
            <ReactTooltip />
          </>
        )}

        {order.order_status === 'Em expedição' && (
          <>
            <FaBoxes data-tip={tool_tip} />
            <ReactTooltip />
          </>
        )}

        {order.order_status === 'Enviado' && (
          <>
            <FaTruck data-tip={tool_tip} />
            <ReactTooltip />
          </>
        )}

        {order.order_status === 'Recebido na unidade' && (
          <>
            <MdStore data-tip={tool_tip} />
            <ReactTooltip />
          </>
        )}

        {order.order_status === 'Entregue' && (
          <>
            <IoBagCheck data-tip={tool_tip} />
            <ReactTooltip />
          </>
        )}
      </div>

      <Title
        data-bs-toggle="modal"
        data-bs-target="#modal-view-actions-order"
        onClick={() => getActionsOrder()}
        is_rework={order.is_rework}
        data-tip="Ver ações do pedido"
      >
        {order.is_rework ? 'RETRABALHO' : 'VER AÇÕES'}
      </Title>

      <ActionsOrderModal actions={actions} />
    </Container>
  );
};

IconOrderStatus.propTypes = {
  order: PropTypes.shape({
    id: PropTypes.string,
    order_status: PropTypes.string,
    is_rework: PropTypes.bool,
  }).isRequired,
};
export default IconOrderStatus;

export const Container = styled.div`
  border-radius: 50%;
  border: ${(props) => {
    if (props.is_rework) return '3px solid #d11515';

    if (props.order_status === 'Entregue') return '3px solid #4FC43B';

    return '2px solid #FFBA00';
  }};
  width: 60px;
  height: 60px;
  display: flex;
  align-items: center !important;
  justify-content: center;
  position: absolute;
  margin-top: -20px;
  margin-left: 60px;

  .line {
    border-radius: 50%;
    width: 48px;
    height: 48px;
    display: flex;
    align-items: center !important;
    justify-content: center;

    background: ${(props) => {
      if (props.order_status === 'Financeiro negado') return '#d11515';

      if (props.order_status === 'Arquivado') return '#1F1F1F';

      if (props.order_status === 'Entregue') return '#4FC43B';

      if (props.is_rework) return '#FFBA00';

      return '#6B6B6B';
    }};
  }

  svg {
    font-size: 30px;
    color: #fff;
  }

  @media screen and (max-width: 786px) {
    margin-top: -20px;
    margin-left: 10px;
  }
`;

export const Title = styled.div`
  position: absolute;
  padding: 2px;
  width: 85px;
  font-size: 11px;
  margin-top: 50px;
  border: ${(props) => {
    if (props.is_rework) return '1px solid #d11515';

    return '1px solid #ffba00';
  }};
  color: ${(props) => {
    if (props.is_rework) return '#d11515';

    return '6b6b6b';
  }};
  background: #fff;
  display: flex;
  flex-direction: row;
  justify-content: center;

  :hover {
    background: #ffba00;
    color: #fff;

    :hover {
      cursor: pointer;
    }
  }
`;
