import React, { useEffect, useContext } from 'react';
import { useDispatch } from 'react-redux';

import { AuthContext } from '../../actions/auth-context';
import { setBreadcrumb } from '../../reducers/breadcrumb';
import Dashboard from './dashboard';
import EditorProductionById from './EditorProductionById';
import MenuItems from './MenuItems';
import OrdersTableMe from './OrdersTableMe';

const Home = () => {
  const { hasRole, user } = useContext(AuthContext);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setBreadcrumb([]));
  }, [dispatch]);

  return (
    <div className="container">
      <div className="row justify-content-center" style={{ marginTop: '3%' }}>
        <div className="col col-md-10">
          <div className="row justify-content-center">
            <MenuItems />
          </div>

          {hasRole('editor') && <EditorProductionById user={user} />}

          <div className="row">
            <OrdersTableMe />
          </div>

          <div className="row">
            <Dashboard />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
