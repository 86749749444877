import React, { useRef, useCallback, useEffect, useState } from 'react';
import { MdClose } from 'react-icons/md';

import { Form } from '@unform/web';
import { Modal } from 'bootstrap';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import * as Yup from 'yup';

import { index } from '../../../../actions/base-rest';
import { addSellerToUnit } from '../../../../actions/unit.service';
import SelectLabel from '../../../../components/unform/select-label';

function IncludeSellerModal({ id_modal, unit, setUnit }) {
  const formRefModal = useRef(null);
  const modalRef = useRef(null);
  const [modal, setModal] = useState(null);
  const [sellersOptions, setSellerOptions] = useState([]);

  const getSellersOptions = useCallback(async () => {
    const sellers = await index('users/get-users-by-role/seller');

    if (sellers) {
      const options = await sellers.map((item) => ({
        label: item.full_name,
        value: item.id,
      }));

      setSellerOptions(options);
    }
  }, []);

  useEffect(() => {
    getSellersOptions();
  }, []);

  useEffect(() => {
    setModal(new Modal(modalRef.current));
  }, []);

  const submit = useCallback(
    async (data) => {
      try {
        formRefModal.current.setErrors({});
        const schema = Yup.object().shape({
          seller_id: Yup.string().required('Vendedor é obrigatório'),
        });

        await schema.validate(data, { abortEarly: false });

        const response = await addSellerToUnit(unit.id, data.seller_id);

        if (!response.error) setUnit(response);

        modal.hide();
      } catch (error) {
        const errorMessages = {};
        if (error instanceof Yup.ValidationError) {
          error.inner.forEach((err) => {
            errorMessages[err.path] = err.message;
          });
          formRefModal.current.setErrors(errorMessages);
        }
      }
    },
    [unit]
  );

  return (
    <ModalContainer>
      <div
        className="modal"
        tabIndex="-1"
        id={id_modal}
        ref={modalRef}
        data-bs-backdrop="false"
      >
        <div className="modal-dialog  modal-dialog-centered">
          <div className="modal-content ">
            <div className="modal-header">
              <h5 className="modal-title col-11 text-center">
                INCLUIR VENDEDOR
              </h5>
              <MdClose
                id="close"
                size={30}
                data-bs-dismiss="modal"
                type="button"
                onClick={() => {}}
              />
            </div>
            <div className="bg-modal">
              <div className="modal-body">
                <Form ref={formRefModal} onSubmit={submit} noValidate>
                  <div className="col-12 mt-3 px-1">
                    <SelectLabel
                      name="seller_id"
                      label="vendedor"
                      placeholder="selecione"
                      options={sellersOptions}
                    />
                  </div>

                  <div className="row justify-content-center mt-3">
                    <button
                      type="button"
                      className="btn btn-secondary col-4 m-1"
                      data-bs-dismiss="modal"
                    >
                      CANCELAR
                    </button>
                    <button type="submit" className="btn btn-primary col-4 m-1">
                      incluir
                    </button>
                  </div>
                </Form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ModalContainer>
  );
}

IncludeSellerModal.propTypes = {
  id_modal: PropTypes.string.isRequired,
  unit: PropTypes.shape({
    id: PropTypes.string,
  }).isRequired,
  setUnit: PropTypes.func.isRequired,
};

export default IncludeSellerModal;

export const ModalContainer = styled.div`
  .bg-modal {
    background-color: #f5f5f5;
    margin: 7px;
  }

  .modal-header {
    border: none;
    background-color: #4f4f4f;
    color: #fff;
    justify-content: center !important;
  }

  .modal-body {
    text-transform: uppercase;
  }

  .modal-footer {
    justify-content: center !important;
    border: none;
  }

  svg {
    :hover {
      cursor: pointer;
      color: #ffba00;
    }
  }
`;
