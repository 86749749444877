/* eslint-disable react/no-array-index-key */
import { useCallback, useContext } from 'react';
import { BsPersonCheckFill, BsSquareFill } from 'react-icons/bs';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useHistory } from 'react-router';
import SyncLoader from 'react-spinners/SyncLoader';
import ReactTooltip from 'react-tooltip';

import PropTypes from 'prop-types';
import styled from 'styled-components';

import { AuthContext } from '../../../actions/auth-context';
import Footer from '../../../components/footer-for-scrooling';
import SortButton from '../../../components/sort-desc/SortButton';
import DeliveredOrderToClientButton from './action-buttons/DeliveredOrderToClientButton';
import DispatchOrderButton from './action-buttons/DispatchOrderButton';
import EditOrderButton from './action-buttons/EditOrderButton';
import FinancialAnalysisButton from './action-buttons/FinancialAnalysisButton';
import FinishAssembleOrderButton from './action-buttons/FinishAssembleOrderButton';
import FinishPackOrderButton from './action-buttons/FinishPackOrderButton';
import FinishPlotterOrderButton from './action-buttons/FinishPlotterOrderButton';
import PrintOrderButton from './action-buttons/PrintOrderButton';
import ReceivedOrderToUnitButton from './action-buttons/ReceivedOrderToUnitButton';
import RemoveOrderButton from './action-buttons/RemoveOrderButton';
import SentToUnitButton from './action-buttons/SentToUnitButton';
import StartAssembleOrderButton from './action-buttons/StartAssembleOrderButton';
import StartPackOrderButton from './action-buttons/StartPackOrderButton';
import StartPlotterOrderButton from './action-buttons/StartPlotterOrderButton';
import ViewOrderButton from './action-buttons/ViewOrderButton';

const OrdersTable = ({
  orders,
  page,
  setItemDelete,
  params,
  setParams,
  setScrolling,
  getOrders,
}) => {
  const { hasRole, loading } = useContext(AuthContext);

  const history = useHistory();

  const changePage = useCallback(() => {
    setScrolling(true);
    setParams((prevState) => ({
      ...prevState,
      current_page: page.current_page + 1,
    }));
  }, [page]);

  const sortOrders = useCallback(
    (field, sort) => {
      setParams((prevState) => ({
        ...prevState,
        sorts: `${field}:${sort}`,
        per_page: orders.length,
      }));
    },
    [orders]
  );

  return (
    <Container>
      <div className="col-12 text-center mt-5">
        <h6>
          TOTAL DE REGISTROS - {orders.length} de <b>{page?.total}</b>
        </h6>
      </div>
      <Legend className="row  justify-content-center mt-4">
        <div className="col text-center">
          <BsSquareFill color="#d11515" /> Entrega Atrasada
        </div>

        <div className="col text-center">
          <BsSquareFill color="#c7a11e" /> menos 7 dias entrega
        </div>

        <div className="col text-center">
          <BsSquareFill color="#E67E22" /> Retrabalho
        </div>
      </Legend>
      <div className="row mt-3">
        <div className="col mb-5">
          <InfiniteScroll
            dataLength={orders.length}
            next={changePage}
            hasMore={page.has_next_page}
            loader={
              <div className="col text-center">
                {loading && (
                  <SyncLoader color="#F7931E" size={15} margin={10} />
                )}
              </div>
            }
            endMessage={
              <div className="col text-center">
                <h6>
                  TOTAL DE REGISTROS - {orders.length} de <b>{page?.total}</b>
                </h6>
              </div>
            }
          >
            <div className="table-responsive">
              <table className="table table-hover text-center">
                <thead>
                  <tr>
                    <th scope="col" className="text-start">
                      <SortButton
                        label="ENTREGA"
                        params={params}
                        ascFunction={() => sortOrders('delivery_date', 'ASC')}
                        descFunction={() => sortOrders('delivery_date', 'DESC')}
                      />
                    </th>

                    <th scope="col" className="text-start">
                      <SortButton
                        label="INCLUSÃO"
                        params={params}
                        ascFunction={() => sortOrders('order_date', 'ASC')}
                        descFunction={() => sortOrders('order_date', 'DESC')}
                      />
                    </th>

                    <th scope="col" className="text-start">
                      <SortButton
                        label="CLIENTE"
                        params={params}
                        ascFunction={() =>
                          sortOrders('person_full_name', 'ASC')
                        }
                        descFunction={() =>
                          sortOrders('person_full_name', 'DESC')
                        }
                      />
                    </th>

                    <th scope="col" className="text-start">
                      <SortButton
                        label="CIDADE"
                        params={params}
                        ascFunction={() => sortOrders('unit_city', 'ASC')}
                        descFunction={() => sortOrders('unit_city', 'DESC')}
                      />
                    </th>

                    <th scope="col" className="text-start">
                      EDITOR
                    </th>

                    <th scope="col" className="text-start">
                      <SortButton
                        label="STATUS"
                        params={params}
                        ascFunction={() => sortOrders('order_status', 'ASC')}
                        descFunction={() => sortOrders('order_status', 'DESC')}
                      />
                    </th>

                    <th scope="col" className="text-end">
                      AÇÃO
                    </th>
                  </tr>
                </thead>

                <tbody>
                  {orders.map((item, i) => (
                    <OrderTr
                      order_later={item.is_order_later}
                      order_urgent={item.is_order_urgent}
                      order_status={item.order_status}
                      is_rework={item.is_rework}
                      has_editor={item.editor_id}
                      key={i}
                      onClick={(e) => {
                        if (e.target.innerText !== undefined) {
                          history.push(`orders/${item.id}`);
                        }
                      }}
                    >
                      <td className="text-start">
                        {item.delivery_date_format}
                      </td>
                      <td className="text-start">{item.order_date_format}</td>
                      <td className="text-start">{item.person_full_name}</td>
                      <td className="text-start">{item.unit_city}</td>
                      {/* <td className="text-start">{item.sig_number}</td> */}
                      <td className="text-start">
                        {item.editor_full_name || 'Não definido'}
                      </td>
                      {/* <td>{item.is_rework ? 'Sim' : 'não'}</td> */}
                      <td className="text-start">{item.order_status}</td>
                      <td className="text-end">
                        <RemoveOrderButton
                          order={item}
                          setItemDelete={setItemDelete}
                        />

                        <ViewOrderButton order={item} />

                        <FinancialAnalysisButton order={item} />

                        <EditOrderButton order={item} />

                        <PrintOrderButton order={item} />

                        <StartPlotterOrderButton
                          order={item}
                          getOrders={() => getOrders(params)}
                        />

                        <FinishPlotterOrderButton
                          order={item}
                          getOrders={() => getOrders(params)}
                        />

                        <StartAssembleOrderButton
                          order={item}
                          getOrders={() => getOrders(params)}
                        />

                        <FinishAssembleOrderButton
                          order={item}
                          getOrders={() => getOrders(params)}
                        />

                        <StartPackOrderButton
                          order={item}
                          getOrders={() => getOrders()}
                        />

                        <FinishPackOrderButton
                          order={item}
                          getOrders={() => getOrders()}
                        />

                        <DispatchOrderButton
                          order={item}
                          getOrders={() => getOrders()}
                        />

                        <SentToUnitButton
                          order={item}
                          getOrders={() => getOrders()}
                        />

                        <ReceivedOrderToUnitButton
                          order={item}
                          getOrders={() => getOrders()}
                        />

                        <DeliveredOrderToClientButton
                          order={item}
                          getOrders={() => getOrders()}
                        />

                        {item.order_status === 'Entregue' &&
                          !hasRole('master') &&
                          !hasRole('administrator') && (
                            <BsPersonCheckFill size={25} color="#3F9D2F" />
                          )}

                        <ReactTooltip />
                      </td>
                    </OrderTr>
                  ))}
                </tbody>
              </table>
            </div>
          </InfiniteScroll>
        </div>
      </div>

      <Footer />
    </Container>
  );
};

OrdersTable.propTypes = {
  orders: PropTypes.arrayOf(Object).isRequired,
  page: PropTypes.shape().isRequired,
  setItemDelete: PropTypes.func.isRequired,
  setParams: PropTypes.func.isRequired,
  params: PropTypes.shape().isRequired,
  setScrolling: PropTypes.func.isRequired,
  getOrders: PropTypes.func,
};

OrdersTable.defaultProps = {
  getOrders: PropTypes.func,
};

export default OrdersTable;

export const Container = styled.section`
  /* text-transform: uppercase; */

  margin-bottom: 100px;

  height: 100vh;

  h6 {
    font-size: 12px;
  }

  .table-hover {
    cursor: pointer;
  }

  .action {
    color: #464646;

    :hover {
      cursor: pointer;
      color: #ffba00;
    }
  }

  .action-finished {
    color: #4fc43b;

    :hover {
      cursor: pointer;
      color: #ffba00;
    }
  }

  .remove {
    color: #d11515;

    :hover {
      cursor: pointer;
      color: #ec4646;
    }
  }

  @media screen and (max-width: 768px) {
    .table {
      width: 900px !important;
    }
  }
`;

export const OrderField = styled.div`
  svg {
    margin-left: 8px;
    margin-top: -1px;
    :hover {
      color: #ffba00;
    }
  }
`;

export const OrderTr = styled.tr`
  color: ${(props) => {
    if (props.order_later) return '#d11515';

    if (props.order_urgent) return '#c7a11e';

    if (props.is_rework) return '#E67E22';
    return '#464646';
  }} !important;

  :hover {
    cursor: pointer;
  }
`;

export const Legend = styled.div`
  font-size: 12px;
  text-transform: capitalize;
`;
