/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable no-unused-vars */
import React, {
  useRef,
  useEffect,
  useState,
  useCallback,
  useContext,
} from 'react';
import { BsChevronCompactLeft, BsChevronCompactRight } from 'react-icons/bs';
import { MdClose } from 'react-icons/md';

import { Form } from '@unform/web';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import * as Yup from 'yup';

import { AuthContext } from '../../../../actions/auth-context';
import {
  getLocalBlobPhotoApi,
  updatePhotoStatusApi,
  setCoverPhotoApi,
} from '../../../../actions/order.service';
import InputTextAreaLabel from '../../../../components/unform/input-text-area-label';

function ViewImageModal({ order, setOrder, getOrder, first_photo_to_view }) {
  const { setLoading, isLoading, hasPermissions } = useContext(AuthContext);

  const photos = [];

  const modalRef = useRef(null);

  const formRef = useRef(null);

  const [selectedPhoto, setSelectedPhoto] = useState({});

  const [openFormReprove, setOpenFormReprove] = useState(false);

  const [index, setIndex] = useState(null);

  const getLocalBlobPhoto = useCallback(
    async (photo) => {
      const result = await getLocalBlobPhotoApi(order.id, photo.id);

      return {
        id: photo.id,
        blob: result,
        photo_status: photo.photo_status,
        is_cover_photo: photo.is_cover_photo,
        note: photo.note,
      };
    },
    [order]
  );

  const preparePhoto = useCallback(
    async (photo, i) => {
      setLoading(true);

      if (!photos[i]) {
        const result = await getLocalBlobPhoto(photo);

        photos[i] = result;

        setSelectedPhoto(result);
      } else {
        setSelectedPhoto(photos[i]);
      }

      setLoading(false);
    },
    [order]
  );

  const previusPhoto = useCallback(
    async (i) => {
      if (i >= 0) {
        order.photos.sort((a, b) => {
          const fa = a.id;
          const fb = b.id;

          if (fa < fb) {
            return -1;
          }
          if (fa > fb) {
            return 1;
          }
          return 0;
        });
        const photo = await order.photos.filter((item, x) => x === i);

        preparePhoto(photo[0], i);

        setIndex(i);
      }
    },
    [order]
  );

  const nextPhoto = useCallback(
    async (i) => {
      if (i < order.photos.length) {
        order.photos.sort((a, b) => {
          const fa = a.id;
          const fb = b.id;

          if (fa < fb) {
            return -1;
          }
          if (fa > fb) {
            return 1;
          }
          return 0;
        });

        const photo = await order.photos.filter((item, x) => x === i);

        preparePhoto(photo[0], i);

        setIndex(i);
      }
    },
    [order]
  );

  const disapprovePhoto = useCallback(
    async (data) => {
      try {
        formRef.current.setErrors({});

        const schema = Yup.object().shape({
          note: Yup.string().required('Descrição é obrigatório.'),
        });

        await schema.validate(data, { abortEarly: false });

        data.id = selectedPhoto.id;
        data.photo_status = 'Reprovada';

        const result = await updatePhotoStatusApi(data);

        if (result.id) {
          nextPhoto(index + 1);

          setOpenFormReprove(false);

          getOrder(order.id);
        }

        setLoading(true);
      } catch (error) {
        const errorMessages = {};

        if (error instanceof Yup.ValidationError) {
          error.inner.forEach((err) => {
            errorMessages[err.path] = err.message;
          });

          formRef.current.setErrors(errorMessages);
        }
      }
    },
    [selectedPhoto, index]
  );

  const approvePhoto = useCallback(
    async (photo) => {
      photo.photo_status = 'Aprovada';

      setLoading(true);

      const result = await updatePhotoStatusApi(photo);

      if (result.id) {
        nextPhoto(index + 1);

        getOrder(order.id);
      }

      setLoading(false);
    },
    [index, order]
  );

  const setCoverPhoto = useCallback(
    async (photo) => {
      setLoading(true);

      const result = await setCoverPhotoApi(photo);

      if (result) {
        result.blob = photo.blob;
        setSelectedPhoto(result);
        getOrder(order.id);
      }

      setLoading(false);
    },
    [index, order]
  );

  useEffect(() => {
    if (first_photo_to_view?.id) {
      preparePhoto(first_photo_to_view, first_photo_to_view.index);

      setIndex(first_photo_to_view.index);
    }
  }, [first_photo_to_view]);

  return (
    <ModalContainer>
      <div
        className="modal fade"
        tabIndex="-1"
        id="modal-view-image"
        ref={modalRef}
      >
        <div className="modal-dialog modal-xl ">
          <div className="modal-content ">
            <div className="modal-header">
              <h5 className="modal-title col-11 text-center">
                Foto - {index + 1} | {selectedPhoto.photo_status}
              </h5>
              <MdClose
                id="close"
                size={30}
                data-bs-dismiss="modal"
                type="button"
                onClick={() => {}}
              />
            </div>
            <div className="bg-modal">
              <div className="modal-body border">
                <Photo className="container-fluid">
                  {index > 0 && !isLoading && (
                    <BsChevronCompactLeft
                      className="previus"
                      onClick={() => previusPhoto(index - 1)}
                    />
                  )}

                  {!isLoading && (
                    <img
                      className="img-fluid"
                      src={selectedPhoto?.blob}
                      alt="foto editada"
                    />
                  )}

                  {index < order?.photos.length - 1 && !isLoading && (
                    <BsChevronCompactRight
                      className="next"
                      onClick={() => nextPhoto(index + 1)}
                    />
                  )}
                </Photo>
              </div>

              <div className="modal-footer justify-content-center">
                {hasPermissions(['edit_photos', 'edit_inspection']) &&
                  selectedPhoto.photo_status === 'Reprovada' && (
                    <div className="description">
                      <span>Descrição:</span>
                      <p>{selectedPhoto.note}</p>
                    </div>
                  )}

                {!openFormReprove &&
                  hasPermissions(['edit_order', 'edit_photos']) && (
                    <div className="col-sm col-md-5 text-center">
                      {!selectedPhoto.is_cover_photo && (
                        <button
                          type="button"
                          className="btn btn-primary m-2"
                          onClick={() => setCoverPhoto(selectedPhoto)}
                        >
                          Usar como foto principal
                        </button>
                      )}

                      {selectedPhoto.is_cover_photo && (
                        <h5>Foto usada como principal</h5>
                      )}
                    </div>
                  )}

                {!openFormReprove && hasPermissions(['edit_inspection']) && (
                  <div className="col-sm col-md-5 text-center">
                    {selectedPhoto.photo_status !== 'Reprovada' && (
                      <button
                        type="button"
                        className="btn btn-secondary m-2"
                        onClick={() => setOpenFormReprove(true)}
                      >
                        Reprovar
                      </button>
                    )}

                    {selectedPhoto.photo_status !== 'Aprovada' && (
                      <button
                        type="button"
                        className="btn btn-primary m-2"
                        onClick={() => approvePhoto(selectedPhoto)}
                      >
                        Aprovar
                      </button>
                    )}
                  </div>
                )}

                {openFormReprove && (
                  <div className="col-sm col-md-5">
                    <Form ref={formRef} onSubmit={disapprovePhoto} noValidate>
                      <div>
                        <InputTextAreaLabel
                          name="note"
                          label="descrição"
                          cols={2}
                          rows={2}
                        />
                      </div>

                      <div className="row">
                        <div className="col text-center">
                          <button
                            type="button"
                            className="btn btn-danger m-2"
                            onClick={() => setOpenFormReprove(false)}
                          >
                            Cancelar
                          </button>

                          <button type="submit" className="btn btn-primary m-2">
                            Salvar
                          </button>
                        </div>
                      </div>
                    </Form>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </ModalContainer>
  );
}

ViewImageModal.propTypes = {
  order: PropTypes.shape({
    id: PropTypes.string,
    photos: PropTypes.arrayOf(Object).isRequired,
  }).isRequired,
  setOrder: PropTypes.func.isRequired,
  getOrder: PropTypes.func.isRequired,
  first_photo_to_view: PropTypes.shape({
    id: PropTypes.string,
    index: PropTypes.number,
  }).isRequired,
};

export default ViewImageModal;

export const ModalContainer = styled.div`
  .bg-modal {
    background-color: #f5f5f5;
  }

  .modal-header {
    border: none;
    background-color: #4f4f4f;
    color: #fff;
    justify-content: center !important;
  }

  .modal-body {
    text-transform: uppercase;
    min-height: 600px;
    padding: 0;
  }

  .description {
    span {
      font-size: 12px;
      font-weight: bold;
    }
  }

  svg {
    :hover {
      cursor: pointer;
      color: #ffba00;
    }
  }
`;

export const Photo = styled.div`
  padding: 0;

  svg {
    z-index: 99;
    position: fixed;
    top: 45%;
    height: 60px;
    width: 60px;
    opacity: 0.6;
    padding: 10px;
    background-color: rgba(255, 255, 255, 0.2);
    border-radius: 50%;
    color: #464646;
    cursor: pointer;

    :hover {
      background-color: rgba(255, 255, 255, 0.9);
    }
  }

  .previus {
    margin-left: 5px;
  }

  .next {
    margin-left: -65px;
  }

  img {
    width: 100%;
    z-index: 98;
    position: relative;
  }

  span {
    font-size: 12px;
    font-weight: bold;
  }
`;
