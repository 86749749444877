import axios from 'axios';

import { handleError } from './handle-error';
import { toastSuccess } from './toast';

export const authenticate = async (username, password) => {
  const result = new Promise((resolve) => {
    const formData = new FormData();
    formData.append('username', username);
    formData.append('password', password);

    axios({
      method: 'post',
      url: `${process.env.REACT_APP_API_BASE_URL}/auth/login/token`,
      data: formData,
      headers: { 'Content-Type': 'multipart/form-data' },
    })
      .then((response) => {
        resolve(response.data);
      })
      .catch(async (error) => {
        const response = await handleError(error);
        resolve(response);
      });
  });
  return result;
};

export const forgotPassword = async (email) => {
  const result = new Promise((resolve) => {
    const formData = new FormData();
    formData.append('email', email);

    axios({
      method: 'post',
      url: `${process.env.REACT_APP_API_BASE_URL}/profile/forgot-password`,
      data: formData,
      headers: { 'Content-Type': 'multipart/form-data' },
    })
      .then((response) => {
        resolve(response.data);
      })
      .catch(async (error) => {
        const response = await handleError(error);

        resolve(response);
      });
  });
  return result;
};

export const resetPassword = async (data) => {
  const result = new Promise((resolve) => {
    axios
      .post(
        `${process.env.REACT_APP_API_BASE_URL}/profile/reset-password/${data.token}`,
        data
      )
      .then((response) => {
        toastSuccess(
          'Senha Alterada!',
          'Sua senha foi alterada com sucesso, faça o Login novamente.'
        );
        resolve(response.data);
      })
      .catch(async (error) => {
        const response = await handleError(error);
        resolve(response);
      });
  });
  return result;
};
