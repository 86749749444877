/* eslint-disable react/require-default-props */
import { useCallback, useContext } from 'react';
import { FaStoreAlt } from 'react-icons/fa';

import PropTypes from 'prop-types';

import { AuthContext } from '../../../../actions/auth-context';
import { receivedOrderToUnit } from '../../../../actions/order.service';

const ReceivedOrderToUnitButton = ({ order, getOrders }) => {
  const { hasPermissions } = useContext(AuthContext);

  const receivedOrderUnit = useCallback(async (data) => {
    receivedOrderToUnit(data.id, {
      action_note: null,
    }).then(() => {
      getOrders();
    });
  }, []);

  return (
    <>
      {order.order_status === 'Enviado' &&
        hasPermissions(['edit_order', 'add_order']) && (
          <FaStoreAlt
            size={22}
            className="mb-1 action"
            data-tip="Receber pedido na unidade"
            onClick={() => receivedOrderUnit(order)}
          />
        )}
    </>
  );
};

ReceivedOrderToUnitButton.propTypes = {
  order: PropTypes.shape({
    order_status: PropTypes.string,
  }).isRequired,
  getOrders: PropTypes.func,
};

export default ReceivedOrderToUnitButton;
