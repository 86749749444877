import React, { useRef, useCallback, useEffect, useState } from 'react';
import { MdClose } from 'react-icons/md';

import { Form } from '@unform/web';
import { Modal } from 'bootstrap';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import * as Yup from 'yup';

import { applyDescountToOrder } from '../../../../actions/order.service';
import {
  floatToCurrency,
  currencyToFloat,
} from '../../../../commons/currency-formatter';
import InputCurrencyLabel from '../../../../components/unform/input-currency-label';

function ApplyDecountModal({ order, setOrder }) {
  const formRefModal = useRef(null);

  const modalRef = useRef(null);

  const [modal, setModal] = useState(null);

  const resetFormRefModal = useCallback(() => {
    formRefModal.current.reset();

    modal.hide();

    document.documentElement.style.overflow = 'auto';

    document.body.scroll = 'yes';
  }, [modal]);

  useEffect(() => {
    setModal(new Modal(modalRef.current));
  }, []);

  useEffect(() => {
    if (order.discount) {
      formRefModal.current.setFieldValue(
        'discount',
        floatToCurrency(order.discount)
      );
    }
  }, [order]);

  const submit = useCallback(
    async (data) => {
      if (data.discount) data.discount = await currencyToFloat(data.discount);

      try {
        formRefModal.current.setErrors({});
        const schema = Yup.object().shape({
          discount: Yup.number().nullable(),
        });

        await schema.validate(data, { abortEarly: false });

        const response = await applyDescountToOrder(order.id, data);

        if (!response.error) {
          response.delivery_date = new Date(response.delivery_date);

          if (response.payment_first_date) {
            response.payment_first_date = new Date(
              response.payment_first_date.replace(/-/g, ',')
            );
          }

          setOrder(response);
        }

        resetFormRefModal();
      } catch (error) {
        const errorMessages = {};
        if (error instanceof Yup.ValidationError) {
          error.inner.forEach((err) => {
            errorMessages[err.path] = err.message;
          });
          formRefModal.current.setErrors(errorMessages);
        }
      }
    },
    [order]
  );

  return (
    <ModalContainer>
      <div
        className="modal"
        tabIndex="-1"
        id="modal-apply-discount"
        ref={modalRef}
        data-bs-backdrop="false"
      >
        <div className="modal-dialog  modal-dialog-centered">
          <div className="modal-content ">
            <div className="modal-header">
              <h5 className="modal-title col-11 text-center">
                ADICIONAR DESCONTO PEDIDO
              </h5>
              <MdClose
                id="close"
                size={30}
                data-bs-dismiss="modal"
                type="button"
                onClick={() => {}}
              />
            </div>
            <div className="bg-modal">
              <div className="modal-body">
                <Form
                  ref={formRefModal}
                  onSubmit={submit}
                  initialData={order}
                  noValidate
                >
                  <div className="row">
                    <div className="col">
                      <table className="table text-center table-borderless">
                        <tbody>
                          <tr>
                            <td className="text-start p-0">TOTAL PRODUTOS</td>
                            <td className="text-end p-1">
                              {floatToCurrency(order.total_order)}
                            </td>
                          </tr>

                          <tr>
                            <td className="text-start p-0">
                              TOTAL DESCONTO PRODUTOS
                            </td>
                            <td className="text-end p-1">
                              {floatToCurrency(order.products_discount)}
                            </td>
                          </tr>

                          <tr>
                            <td className="text-start p-0">
                              TOTAL DESCONTO PEDIDO
                            </td>
                            <td className="text-end p-1">
                              {floatToCurrency(order.discount)}
                            </td>
                          </tr>

                          <tr>
                            <td className="text-start p-0">TOTAL DESCONTOS</td>
                            <td className="text-end p-1">
                              {floatToCurrency(order.total_discount)}
                            </td>
                          </tr>

                          <tr>
                            <td className="text-start p-0 fw-bold">
                              TOTAL PEDIDO
                            </td>
                            <td className="text-end p-1 fw-bold">
                              {floatToCurrency(order.total_discounted)}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>

                  <div className="row justify-content-end">
                    <div className="col-sm col-md-6 mt-2">
                      <InputCurrencyLabel
                        name="discount"
                        label="aplicar desconto ao pedido"
                      />
                    </div>
                  </div>

                  <div className="row justify-content-center mt-4">
                    <button
                      type="button"
                      className="btn btn-secondary col-4 m-1"
                      data-bs-dismiss="modal"
                    >
                      CANCELAR
                    </button>
                    <button type="submit" className="btn btn-primary col-4 m-1">
                      salvar
                    </button>
                  </div>
                </Form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ModalContainer>
  );
}

ApplyDecountModal.propTypes = {
  order: PropTypes.shape({
    id: PropTypes.string,
    total_order: PropTypes.number,
    total_discounted: PropTypes.number,
    total_discount: PropTypes.number,
    discount: PropTypes.number,
    products_discount: PropTypes.number,
  }).isRequired,
  setOrder: PropTypes.func.isRequired,
};

export default ApplyDecountModal;

export const ModalContainer = styled.div`
  .bg-modal {
    background-color: #f5f5f5;
    margin: 7px;
  }

  .modal-header {
    border: none;
    background-color: #4f4f4f;
    color: #fff;
    justify-content: center !important;
  }

  .modal-body {
    text-transform: uppercase;
  }

  .modal-footer {
    justify-content: center !important;
    border: none;
  }

  svg {
    :hover {
      cursor: pointer;
      color: #ffba00;
    }
  }
`;
