import React, {
  useEffect,
  useRef,
  useCallback,
  useState,
  useContext,
} from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';

import { Form } from '@unform/web';
import PropTypes from 'prop-types';
import * as Yup from 'yup';

import { AuthContext } from '../../../../actions/auth-context';
import { store, index } from '../../../../actions/base-rest';
import {
  validateExpectedDelivaryDate,
  dateToStringWithTime,
  dateToString,
} from '../../../../commons/date';
import InputDateLabel from '../../../../components/unform/input-date-label';
import InputLabel from '../../../../components/unform/input-label';
import InputTextAreaLabel from '../../../../components/unform/input-text-area-label';
import SelectLabel from '../../../../components/unform/select-label';
import SelectScrollingLabel from '../../../../components/unform/select-scrolling-label ';
import { setBreadcrumb } from '../../../../reducers/breadcrumb';
import { GroupData } from '../../../../styles/GroupData';

const URL = 'orders';
const NewOrder = ({ unitsOptions, setOrder, loadPersonsOptions }) => {
  const { setLoading, user } = useContext(AuthContext);

  const dispatch = useDispatch();

  const formRef = useRef(null);

  const history = useHistory();

  const [selectedPerson, setSelectedPerson] = useState({});

  const [selectedSeller, setSelectedSeller] = useState({});

  const [selectedUnit, setSelectedUnit] = useState({});

  const [sellersOptions, setSellersOptions] = useState([]);

  const expectedDeliveryDate = useCallback(() => {
    const date = new Date();

    date.setDate(date.getDate() + 30);

    return date;
  }, []);

  const submit = useCallback(
    async (data) => {
      if (selectedPerson) data.person_id = selectedPerson.value;

      if (selectedUnit) data.unit_id = selectedUnit.value;

      if (selectedSeller) data.seller_id = selectedSeller.value;

      if (data.note.length === 0) data.note = null;

      if (data.sig_number === '') data.sig_number = null;

      try {
        formRef.current.setErrors({});

        const schema = Yup.object().shape({
          unit_id: Yup.string().required('Unidade é obrigatório'),

          seller_id: Yup.string().required('Vendedor é obrigatório'),

          person_id: Yup.string().required('Cliente é obrigatório'),

          photographer: Yup.string().required('Fotógrafo é obrigatório'),

          delivery_date: Yup.date()
            .test(
              'validateDate',
              'Data precisa ser superior a 15 dias',
              (value) => validateExpectedDelivaryDate(value)
            )
            .required('Data de entrega é obrigatório')
            .nullable(),

          sig_number: Yup.number().typeError('Valor inválido').nullable(),

          note: Yup.string().nullable(),

          payment_option: Yup.string().required('Tipo Pgto é obrigatório'),

          payment_first_date: Yup.date()
            .required('Data de Pgto é obrigatório')
            .nullable(),
        });

        await schema.validate(data, { abortEarly: false });

        setLoading(true);

        data.delivery_date = await dateToStringWithTime(data.delivery_date);

        data.payment_first_date = await dateToString(data.payment_first_date);

        const response = await store(URL, data);

        if (!response.error) {
          history.push(`/orders/${response.id}`);

          response.delivery_date = new Date(response.delivery_date);

          response.payment_first_date = new Date(
            response.payment_first_date.replace(/-/g, ',')
          );

          setOrder(response);

          setLoading(false);
        }

        setLoading(false);
      } catch (error) {
        const errorMessages = {};

        if (error instanceof Yup.ValidationError) {
          error.inner.forEach((err) => {
            errorMessages[err.path] = err.message;
          });

          formRef.current.setErrors(errorMessages);
        }
      }
    },
    [history, selectedPerson, selectedSeller]
  );

  const loadSellersOptions = useCallback(async (unit_id) => {
    const sellers = await index(`units/${unit_id}/sellers`);

    if (sellers) {
      setSelectedSeller({});

      const options = await sellers.map((item) => ({
        label: item.full_name,
        value: item.id,
      }));

      setSellersOptions(options);
    }
  }, []);

  const loadSellersByUnit = useCallback(
    async (unit) => {
      const sellers = await index(`units/${unit.id}/sellers`);

      for (let x = 0; x < sellers?.length; x++) {
        const seller = sellers[x];

        if (seller.username === user.username) {
          setSelectedUnit({
            label: unit.name,
            value: unit.id,
          });

          setSelectedSeller({
            label: seller.full_name,
            value: seller.id,
          });
        }
      }
    },
    [user]
  );

  const loadUnits = useCallback(async () => {
    const response_units = await index('units', { per_page: 500 });

    for (let i = 0; i < response_units.rows.length; i++) {
      const unit = response_units.rows[i];

      loadSellersByUnit(unit);
    }
  }, []);

  useEffect(() => {
    dispatch(
      setBreadcrumb([
        { page: 'pedidos', url: '/orders' },
        { page: 'novo', url: null },
      ])
    );
  }, []);

  useEffect(() => {
    loadUnits();
  }, []);

  return (
    <div className="container" style={{ height: '100vh' }}>
      <div className="row mt-3 justify-content-center">
        <div className="col-12 text-center my-3">
          <h4>NOVO PEDIDO </h4>
        </div>
      </div>

      <Form ref={formRef} onSubmit={submit} noValidate>
        <GroupData>
          <span>CLIENTE</span>

          <div className="row">
            <div className="col-sm col-md-6 mt-2 px-1">
              <SelectScrollingLabel
                value={selectedPerson}
                loadOptions={loadPersonsOptions}
                name="person_id"
                label="Nome"
                placeholder="Pesquisar por nome"
                onChange={(e) =>
                  setSelectedPerson({
                    label: e.label.slice(0, e.label.indexOf('-')),
                    value: e.value,
                  })
                }
              />
            </div>
          </div>
        </GroupData>

        <GroupData>
          <span>DETALHES</span>

          <div className="row justify-content-center">
            <div className="col-sm col-md-2 mt-2 px-1">
              <InputDateLabel
                name="delivery_date"
                label="data entrega prevista"
                defaultValue={expectedDeliveryDate()}
              />
            </div>

            <div className="col-sm col-md-3 mt-2 px-1">
              <SelectLabel
                name="unit_id"
                label="Unidade"
                placeholder=""
                className="select"
                options={unitsOptions}
                onChange={(e) => {
                  setSelectedUnit(e);
                  loadSellersOptions(e.value);
                }}
                value={selectedUnit}
              />
            </div>

            <div className="col-sm col-md-3 mt-2 px-1">
              <SelectLabel
                name="seller_id"
                label="vendedor"
                placeholder=""
                options={sellersOptions}
                value={selectedSeller}
                onChange={(e) => setSelectedSeller(e)}
              />
            </div>

            <div className="col-sm mt-2 px-1">
              <InputLabel name="photographer" label="Fotógrafo" />
            </div>
          </div>

          <div className="row">
            <div className="col-sm col-md-6 mt-2 px-1">
              <InputLabel
                name="payment_option"
                label="Forma de Pgto"
                placeholder="Ex: Boleto 12x"
              />
            </div>

            <div className="col-sm col-md-3 mt-2 px-1">
              <InputDateLabel
                name="payment_first_date"
                label="data 1º pgto"
                defaultValue={null}
              />
            </div>

            <div className="col-sm  mt-2 px-1">
              <InputLabel name="sig_number" label="Nº SIGI" />
            </div>
          </div>

          <div className="row justify-content-center">
            <div className="col-sm mt-2 px-1">
              <InputTextAreaLabel
                name="note"
                label="Observações"
                cols={20}
                rows={2}
              />
            </div>
          </div>
        </GroupData>

        <div className="row justify-content-center mt-4">
          <div className="col-6 col-md-4 col-xl-3">
            <button
              className="btn btn-outline-secondary btn-block col-12"
              type="button"
              onClick={() => history.goBack()}
            >
              cancelar
            </button>
          </div>
          <div className="col-6 col-md-4 col-xl-3">
            <button className="btn btn-primary btn-block col-12" type="submit">
              salvar
            </button>
          </div>
        </div>
      </Form>
    </div>
  );
};

NewOrder.propTypes = {
  unitsOptions: PropTypes.arrayOf(Object).isRequired,
  setOrder: PropTypes.func.isRequired,
  loadPersonsOptions: PropTypes.func.isRequired,
};

export default NewOrder;
