import React from 'react';
import { MdClose } from 'react-icons/md';

import PropTypes from 'prop-types';
import styled from 'styled-components';

function DeleteModal({ id_modal, item_delete, deleteItem }) {
  return (
    <Modal>
      <div className="modal" tabIndex="-1" id={id_modal}>
        <div className="modal-dialog">
          <div className="modal-content text-center">
            <div className="modal-header">
              <h5 className="modal-title col-11">EXCLUIR REGISTRO</h5>
              <MdClose size={30} data-bs-dismiss="modal" type="button" />
            </div>
            <div className="bg-modal">
              <div className="modal-body">
                <p>
                  EXCLUIR REGISTRO DE <b>{item_delete?.description}</b>
                </p>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary col-4"
                  data-bs-dismiss="modal"
                >
                  {' '}
                  NÃO{' '}
                </button>
                <button
                  type="button"
                  className="btn btn-primary col-4"
                  data-bs-dismiss="modal"
                  onClick={() => deleteItem()}
                >
                  SIM
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
}

DeleteModal.propTypes = {
  id_modal: PropTypes.string.isRequired,
  item_delete: PropTypes.shape().isRequired,
  deleteItem: PropTypes.func.isRequired,
};

export default DeleteModal;

export const Modal = styled.div`
  .bg-modal {
    background-color: #f5f5f5;
    margin: 7px;
  }

  .modal-header {
    border: none;
    background-color: #4f4f4f;
    color: #fff;
    justify-content: center !important;
  }

  .modal-body {
    text-transform: uppercase;
  }

  .modal-footer {
    justify-content: center !important;
    border: none;
  }

  svg {
    :hover {
      cursor: pointer;
      color: #ffba00;
    }
  }
`;
