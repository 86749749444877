import { useContext } from 'react';
import { IoIosTrash } from 'react-icons/io';

import PropTypes from 'prop-types';

import { AuthContext } from '../../../../actions/auth-context';

const RemoveOrderButton = ({ order, setItemDelete }) => {
  const { hasPermissions } = useContext(AuthContext);

  return (
    <>
      {hasPermissions(['delete_order']) && (
        <IoIosTrash
          size={25}
          className="mb-1 remove"
          data-tip="Excluir"
          onClick={() =>
            setItemDelete({
              id: order.id,
              description: order.person_full_name,
            })
          }
          data-bs-toggle="modal"
          data-bs-target="#modal-order"
        />
      )}
    </>
  );
};

RemoveOrderButton.propTypes = {
  order: PropTypes.shape({
    id: PropTypes.string,
    order_status: PropTypes.string,
    person_full_name: PropTypes.string,
    products: PropTypes.arrayOf(Object),
  }).isRequired,
  setItemDelete: PropTypes.func.isRequired,
};

export default RemoveOrderButton;
