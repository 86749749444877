import React, { useContext } from 'react';

import styled, { keyframes } from 'styled-components';

import { AuthContext } from '../../actions/auth-context';

const Loading = () => {
  const { isLoading } = useContext(AuthContext);
  return (
    <>
      {isLoading && (
        <Container>
          <Spinner />
        </Container>
      )}
    </>
  );
};

export default Loading;

const rotate360 = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 99999;
  background-color: rgba(0, 0, 0, 0.3);
  -webkit-transition: opacity 400ms ease-in;
  -moz-transition: opacity 400ms ease-in;
  transition: opacity 400ms ease-in;
  pointer-events: none;
`;

const Spinner = styled.div`
  margin: 16px;
  animation: ${rotate360} 1s linear infinite;
  transform: translateZ(0);
  border-top: 6px solid #464646;
  border-right: 6px solid #464646;
  border-bottom: 6px solid #464646;
  border-left: 6px solid #ffba00;
  background: transparent;
  width: 90px;
  height: 90px;
  border-radius: 50%;
`;
