/* eslint-disable prettier/prettier */
import { useEffect, useState, useCallback, useContext } from 'react';
import { useParams } from 'react-router';

import { AuthContext } from '../../../actions/auth-context';
import { index, show } from '../../../actions/base-rest';
import EditOrder from './edit/EditOrder';
import NewOrder from './new/NewOrder';

const URL = 'orders';

const Order = () => {
  const paramsUrl = useParams();
  const { hasPermissions, setLoading } = useContext(AuthContext);
  const [unitsOptions, setUnitsOptions] = useState([]);
  const [order, setOrder] = useState({});
  const [persons, setPersons] = useState([]);
  const [page, setPage] = useState({});
  const [params, setParams] = useState({
    current_page: 1,
    per_page: 10,
    sorts: 'full_name:ASC',
  });

  const getPersons = useCallback(async () => {
    if (hasPermissions(['edit_person'])) {
      const response = await index('persons', params);

      const options = await response.rows.map((item) => ({
        label: `${item.full_name} - (${item.cpf_cnpj})`,
        value: item.id,
      }));

      setPersons(options);

      setPage(response.page);
    }
  }, [params]);

  const loadUnitsOptions = useCallback(async () => {
    if (hasPermissions(['add_order'])) {
      const response = await index('units', { per_page: 500 });

      const options = await response.rows.map((item) => ({
        label: item.name,
        value: item.id,
      }));

      setUnitsOptions([options]);
    }
  }, []);

  const loadPersonsOptions = useCallback(
    (search) => {
      if (!search) {
        getPersons();
      } else if (search.length >= 3) {
        setParams((prevState) => ({
          ...prevState,
          full_name: search,
        }));
      }

      setParams((prevState) => ({
        ...prevState,
        current_page: page.current_page + 1,
      }));

      return {
        options: persons,
        hasMore: page.has_next_page,
      };
    },
    [page, persons]
  );

  const getOrder = useCallback(async (id) => {
    setLoading(true);

    const response = await show(URL, id);

    response.delivery_date = new Date(response.delivery_date.slice(0, 25));

    if (response.payment_first_date) {
      response.payment_first_date = new Date(
        response.payment_first_date.replace(/-/g, ',')
      );
    }

    setOrder(response);

    setLoading(false);
  }, []);

  useEffect(() => {
    loadUnitsOptions();
  }, []);

  useEffect(() => {
    const { id } = paramsUrl;

    if (id) getOrder(id);
  }, []);

  useEffect(() => {
    getPersons();
  }, [params]);

  return (
    <>
      {!paramsUrl.id && (
        <NewOrder
          unitsOptions={unitsOptions}
          setOrder={setOrder}
          loadPersonsOptions={loadPersonsOptions}
        />
      )}

      {paramsUrl.id && order.id && (
        <EditOrder
          order={order}
          setOrder={setOrder}
          getOrder={getOrder}
          loadPersonsOptions={loadPersonsOptions}
          unitsOptions={unitsOptions}
        />
      )}
    </>
  );
};

export default Order;
