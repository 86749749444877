import { useCallback, useContext } from 'react';
import { RiPhoneFindFill } from 'react-icons/ri';
import { useHistory } from 'react-router';

import PropTypes from 'prop-types';

import { AuthContext } from '../../../../actions/auth-context';
import { getOrderToFinanceAnalysis } from '../../../../actions/order.service';

const FinancialAnalysisButton = ({ order }) => {
  const { hasPermissions } = useContext(AuthContext);

  const history = useHistory();

  const getFinancialAnalysis = useCallback(async (data) => {
    const response = await getOrderToFinanceAnalysis(data.id, {
      action_note: null,
    });

    if (!response.error) history.push(`/orders/${data.id}`);
  }, []);

  return (
    <>
      {order.order_status === 'Incluído' &&
        hasPermissions(['financial_analysis']) && (
          <RiPhoneFindFill
            size={25}
            className="mb-1 action"
            data-tip="Analizar Pedido"
            onClick={() => getFinancialAnalysis(order)}
          />
        )}
    </>
  );
};

FinancialAnalysisButton.propTypes = {
  order: PropTypes.shape({
    order_status: PropTypes.string,
  }).isRequired,
};

export default FinancialAnalysisButton;
