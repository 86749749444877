import { useContext } from 'react';

import PropTypes from 'prop-types';

import { AuthContext } from '../../../../../actions/auth-context';

const SendUnitButton = ({ order }) => {
  const { hasPermissions } = useContext(AuthContext);

  return (
    <>
      {order.order_status === 'Em expedição' && hasPermissions(['pack_order']) && (
        <div className="col-sm col-md-6 col-xl-4 mt-2">
          <button
            className="btn btn-success btn-block col-12"
            type="button"
            data-bs-toggle="modal"
            data-bs-target="#modal-sent-order"
          >
            enviar pedido para unidade
          </button>
        </div>
      )}
    </>
  );
};

SendUnitButton.propTypes = {
  order: PropTypes.shape({
    id: PropTypes.string,
    order_status: PropTypes.string,
  }).isRequired,
};

export default SendUnitButton;
