import { useContext } from 'react';

import PropTypes from 'prop-types';

import { AuthContext } from '../../../../../actions/auth-context';
import {} from '../../../../../actions/order.service';

const FinishAssembleOrderButton = ({ order }) => {
  const { hasPermissions } = useContext(AuthContext);

  return (
    <>
      {order.order_status === 'Em montagem' &&
        hasPermissions(['assemble_order']) && (
          <div className="col-sm col-md-5 col-xl-3 mt-2">
            <button
              className="btn btn-success btn-block col-12"
              type="button"
              data-bs-toggle="modal"
              data-bs-target="#modal-finish-assemble-order"
            >
              finalizar montagem
            </button>
          </div>
        )}
    </>
  );
};

FinishAssembleOrderButton.propTypes = {
  order: PropTypes.shape({
    id: PropTypes.string,
    order_status: PropTypes.string,
  }).isRequired,
};

export default FinishAssembleOrderButton;
