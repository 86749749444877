import api from './api';
import { handleError } from './handle-error';
import { toastSuccess } from './toast';

export const addProductToOrder = async (order_id, data) => {
  const result = new Promise((resolve) => {
    api
      .post(`orders/${order_id}/products`, data)
      .then((response) => {
        toastSuccess('Novo produto incluido com sucesso!');

        resolve(response.data);
      })
      .catch(async (error) => {
        const response = await handleError(error);

        resolve(response);
      });
  });
  return result;
};

export const deleteProductFromOrder = async (order_id, product_id) => {
  const result = new Promise((resolve) => {
    api
      .delete(`orders/${order_id}/products/${product_id}`)
      .then((response) => {
        toastSuccess('Produto removido com sucesso!');

        resolve(response.data);
      })
      .catch(async (error) => {
        const response = await handleError(error);

        resolve(response);
      });
  });
  return result;
};

export const deletePhotoOfOrder = async (order_id, product_id) => {
  const result = new Promise((resolve) => {
    api
      .delete(`orders/${order_id}/photos/${product_id}`)
      .then((response) => {
        toastSuccess('Foto removida com sucesso!');

        resolve(response.data);
      })
      .catch(async (error) => {
        const response = await handleError(error);

        resolve(response);
      });
  });
  return result;
};

export const deletePhotosOfOrder = async (order_id, data) => {
  const result = new Promise((resolve) => {
    api
      .delete(`orders/${order_id}/photos`, { data })
      .then((response) => {
        toastSuccess('As fotos removidas com sucesso!');

        resolve(response.data);
      })
      .catch(async (error) => {
        const response = await handleError(error);

        resolve(response);
      });
  });
  return result;
};

export const applyDescountToOrder = async (order_id, data) => {
  const result = new Promise((resolve) => {
    api
      .put(`orders/${order_id}/apply-discount`, data)
      .then((response) => {
        toastSuccess('Desconto incluido com sucesso!');

        resolve(response.data);
      })
      .catch(async (error) => {
        const response = await handleError(error);

        resolve(response);
      });
  });
  return result;
};

export const addFileToOrder = async (order_id, data) => {
  const result = new Promise((resolve) => {
    api
      .post(`orders/${order_id}/spcfiles`, data)
      .then((response) => {
        toastSuccess('Novo arquivo incluido com sucesso!');

        resolve(response.data);
      })
      .catch(async (error) => {
        const response = await handleError(error);

        resolve(response);
      });
  });
  return result;
};

export const addPhotosToOrder = async (order_id, data) => {
  const result = new Promise((resolve) => {
    api
      .post(`orders/${order_id}/photos`, data)
      .then((response) => {
        toastSuccess('Fotos incluídas com sucesso!');

        toastSuccess('Carregando imagens editadas!');

        resolve(response.data);
      })
      .catch(async (error) => {
        const response = await handleError(error);

        resolve(response);
      });
  });
  return result;
};

export const updatePhotoStatusApi = async (data) => {
  const result = new Promise((resolve) => {
    api
      .put(`orders-photos/${data.id}`, data)
      .then((response) => {
        toastSuccess(`Foto ${response.data.photo_status}`);
        resolve(response.data);
      })
      .catch(async (error) => {
        const response = await handleError(error);

        resolve(response);
      });
  });
  return result;
};

export const setCoverPhotoApi = async (data) => {
  const result = new Promise((resolve) => {
    api
      .post(`orders-photos/${data.id}/cover-photo`)
      .then((response) => {
        toastSuccess(`Foto selecionada como principal`);
        resolve(response.data);
      })
      .catch(async (error) => {
        const response = await handleError(error);

        resolve(response);
      });
  });
  return result;
};

export const deleteFileFromOrder = async (order_id, file_id) => {
  const result = new Promise((resolve) => {
    api
      .delete(`orders/${order_id}/spcfiles/${file_id}`)
      .then((response) => {
        toastSuccess('Arquivo removido com sucesso!');

        resolve(response.data);
      })
      .catch(async (error) => {
        const response = await handleError(error);

        resolve(response);
      });
  });
  return result;
};

export const sendOrderToFinanceAnalysis = async (order_id, data) => {
  const result = new Promise((resolve) => {
    api
      .put(`orders/${order_id}/send-to-finance`, data)
      .then((response) => {
        toastSuccess('Pedido enviado para Análise Financeira!');

        resolve(response.data);
      })
      .catch(async (error) => {
        const response = await handleError(error);

        resolve(response);
      });
  });
  return result;
};

export const getOrderToFinanceAnalysis = async (order_id, data) => {
  const result = new Promise((resolve) => {
    api
      .put(`orders/${order_id}/financial-analysis`, data)
      .then((response) => {
        toastSuccess('Pedido em análise!');

        resolve(response.data);
      })
      .catch(async (error) => {
        const response = await handleError(error);

        resolve(response);
      });
  });
  return result;
};

export const orderFinancialDisapproved = async (order_id, data) => {
  const result = new Promise((resolve) => {
    api
      .put(`orders/${order_id}/financial-disapproved`, data)
      .then((response) => {
        toastSuccess('Pedido reprovado!');

        resolve(response.data);
      })
      .catch(async (error) => {
        const response = await handleError(error);

        resolve(response);
      });
  });
  return result;
};

export const orderFinancialApproved = async (order_id, data) => {
  const result = new Promise((resolve) => {
    api
      .put(`orders/${order_id}/financial-approved`, data)
      .then((response) => {
        toastSuccess('Pedido aprovado!');

        resolve(response.data);
      })
      .catch(async (error) => {
        const response = await handleError(error);

        resolve(response);
      });
  });
  return result;
};

export const shelveOrderById = async (order_id, data) => {
  const result = new Promise((resolve) => {
    api
      .put(`orders/${order_id}/shelve-order`, data)
      .then((response) => {
        toastSuccess('Pedido arquivo!');

        resolve(response.data);
      })
      .catch(async (error) => {
        const response = await handleError(error);

        resolve(response);
      });
  });
  return result;
};

export const reopenOrderById = async (order_id, data) => {
  const result = new Promise((resolve) => {
    api
      .put(`orders/${order_id}/reopen-order`, data)
      .then((response) => {
        toastSuccess('Pedido reaberto!');

        resolve(response.data);
      })
      .catch(async (error) => {
        const response = await handleError(error);

        resolve(response);
      });
  });
  return result;
};

export const getOrderToEdit = async (order_id, data) => {
  const result = new Promise((resolve) => {
    api
      .put(`orders/${order_id}/start-editing`, data)
      .then((response) => {
        toastSuccess('Pedido em edição!');

        resolve(response.data);
      })
      .catch(async (error) => {
        const response = await handleError(error);

        resolve(response);
      });
  });
  return result;
};

export const finishEditingTheOrder = async (order_id, data) => {
  const result = new Promise((resolve) => {
    api
      .put(`orders/${order_id}/finish-editing`, data)
      .then((response) => {
        toastSuccess('Edição finalizada!');

        resolve(response.data);
      })
      .catch(async (error) => {
        const response = await handleError(error);

        resolve(response);
      });
  });
  return result;
};

export const startApproveEditionTheOrder = async (order_id, data) => {
  const result = new Promise((resolve) => {
    api
      .put(`orders/${order_id}/edit-inspection`, data)
      .then((response) => {
        toastSuccess('Pedido em análise!');

        resolve(response.data);
      })
      .catch(async (error) => {
        const response = await handleError(error);

        resolve(response);
      });
  });
  return result;
};

export const finishApproveEditionTheOrder = async (order_id, data) => {
  const result = new Promise((resolve) => {
    api
      .put(`orders/${order_id}/approve-edit`, data)
      .then((response) => {
        toastSuccess('Análise finalizada!');

        resolve(response.data);
      })
      .catch(async (error) => {
        const response = await handleError(error);

        resolve(response);
      });
  });
  return result;
};

export const startPrintTheOrder = async (order_id, data) => {
  const result = new Promise((resolve) => {
    api
      .put(`orders/${order_id}/start-print`, data)
      .then((response) => {
        toastSuccess('Pedido em impressão!');

        resolve(response.data);
      })
      .catch(async (error) => {
        const response = await handleError(error);

        resolve(response);
      });
  });
  return result;
};

export const finishPrintTheOrder = async (order_id, data) => {
  const result = new Promise((resolve) => {
    api
      .put(`orders/${order_id}/finish-print`, data)
      .then((response) => {
        toastSuccess('Impressão finalizada!');

        resolve(response.data);
      })
      .catch(async (error) => {
        const response = await handleError(error);

        resolve(response);
      });
  });
  return result;
};

export const startAssembleTheOrder = async (order_id, data) => {
  const result = new Promise((resolve) => {
    api
      .put(`orders/${order_id}/start-assemble`, data)
      .then((response) => {
        toastSuccess('Pedido em processo de montagem!');

        resolve(response.data);
      })
      .catch(async (error) => {
        const response = await handleError(error);

        resolve(response);
      });
  });
  return result;
};

export const finishAssenbleTheOrder = async (order_id, data) => {
  const result = new Promise((resolve) => {
    api
      .put(`orders/${order_id}/finish-assemble`, data)
      .then((response) => {
        toastSuccess('Montagem finalizada!');

        resolve(response.data);
      })
      .catch(async (error) => {
        const response = await handleError(error);

        resolve(response);
      });
  });
  return result;
};

export const startPackingTheOrder = async (order_id, data) => {
  const result = new Promise((resolve) => {
    api
      .put(`orders/${order_id}/start-packing`, data)
      .then((response) => {
        toastSuccess('Pedido em processo de embalagem!');

        resolve(response.data);
      })
      .catch(async (error) => {
        const response = await handleError(error);

        resolve(response);
      });
  });
  return result;
};

export const finishPackingTheOrder = async (order_id, data) => {
  const result = new Promise((resolve) => {
    api
      .put(`orders/${order_id}/finish-packing`, data)
      .then((response) => {
        toastSuccess('Embalagem finalizada!');

        resolve(response.data);
      })
      .catch(async (error) => {
        const response = await handleError(error);

        resolve(response);
      });
  });
  return result;
};

export const sendDispatchTheOrder = async (order_id, data) => {
  const result = new Promise((resolve) => {
    api
      .put(`orders/${order_id}/start-dispatch`, data)
      .then((response) => {
        toastSuccess('Pedido enviado para expedição!');

        resolve(response.data);
      })
      .catch(async (error) => {
        const response = await handleError(error);

        resolve(response);
      });
  });
  return result;
};

export const sendToUnit = async (order_id, data) => {
  const result = new Promise((resolve) => {
    api
      .put(`orders/${order_id}/sent`, data)
      .then((response) => {
        toastSuccess('Pedido enviado para unidade!');

        resolve(response.data);
      })
      .catch(async (error) => {
        const response = await handleError(error);

        resolve(response);
      });
  });
  return result;
};

export const receivedOrderToUnit = async (order_id, data) => {
  const result = new Promise((resolve) => {
    api
      .put(`orders/${order_id}/received-in-the-unit`, data)
      .then((response) => {
        toastSuccess('Pedido recebido na unidade!');

        resolve(response.data);
      })
      .catch(async (error) => {
        const response = await handleError(error);

        resolve(response);
      });
  });
  return result;
};

export const deliveredOrderToClient = async (order_id, data) => {
  const result = new Promise((resolve) => {
    api
      .put(`orders/${order_id}/delivered`, data)
      .then((response) => {
        toastSuccess('Pedido recebido pelo cliente!');

        resolve(response.data);
      })
      .catch(async (error) => {
        const response = await handleError(error);

        resolve(response);
      });
  });
  return result;
};

export const sendOrderToRework = async (order_id, data) => {
  const result = new Promise((resolve) => {
    api
      .put(`orders/${order_id}/request-rework`, data)
      .then((response) => {
        toastSuccess('Retralho enviado!');

        resolve(response.data);
      })
      .catch(async (error) => {
        const response = await handleError(error);

        resolve(response);
      });
  });
  return result;
};

export const getOrderActionsById = async (order_id) => {
  const result = new Promise((resolve) => {
    api
      .get(`orders/${order_id}/orders-actions`)
      .then((response) => {
        resolve(response.data);
      })
      .catch(async (error) => {
        const response = await handleError(error);
        resolve(response);
      });
  });
  return result;
};

export const getOrderPositionOfOrderById = async (order_id) => {
  const result = new Promise((resolve) => {
    api
      .get(`orders/${order_id}/order-positions`)
      .then((response) => {
        resolve(response.data);
      })
      .catch(async (error) => {
        const response = await handleError(error);

        resolve(response);
      });
  });
  return result;
};

export const getLocalBlobThumbnailApi = async (order_id, photo_id) => {
  const result = new Promise((resolve) => {
    api
      .get(`orders/${order_id}/thumbnail-photos/${photo_id}`, {
        responseType: 'arraybuffer',
      })
      .then((response) => {
        const buffer = Buffer.from(response.data, 'base64');

        const blob = new Blob([buffer]);

        const imageUrl = URL.createObjectURL(blob);

        resolve(imageUrl);
      })
      .catch(async (error) => {
        const response = await handleError(error);

        resolve(response);
      });
  });
  return result;
};

export const getLocalBlobPhotoApi = async (order_id, photo_id) => {
  const result = new Promise((resolve) => {
    api
      .get(`orders/${order_id}/photos/${photo_id}`, {
        responseType: 'arraybuffer',
      })
      .then((response) => {
        const buffer = Buffer.from(response.data, 'base64');

        const blob = new Blob([buffer]);

        const imageUrl = URL.createObjectURL(blob);

        resolve(imageUrl);
      })
      .catch(async (error) => {
        const response = await handleError(error);

        resolve(response);
      });
  });
  return result;
};

export const getSW3ThumbnailUrl = async (order_id, photo_id) => {
  const result = new Promise((resolve) => {
    api
      .get(`orders/${order_id}/thumbnail-s3-url-photos/${photo_id}`)
      .then((response) => {
        resolve(response.data);
      })
      .catch(async (error) => {
        const response = await handleError(error);
        resolve(response);
      });
  });
  return result;
};

export const getSW3PhotoUrl = async (order_id, photo_id) => {
  const result = new Promise((resolve) => {
    api
      .get(`orders/${order_id}/order-s3-url-photos/${photo_id}`)
      .then((response) => {
        resolve(response.data);
      })
      .catch(async (error) => {
        const response = await handleError(error);

        resolve(response);
      });
  });
  return result;
};

export const getSW3UrlThumbnailPhotos = async (order_id, photo_id) => {
  const result = new Promise((resolve) => {
    api
      .get(`orders/${order_id}/order-thumbnail-s3-url-photos/${photo_id}`)
      .then((response) => {
        resolve(response.data);
      })
      .catch(async (error) => {
        const response = await handleError(error);
        resolve(response);
      });
  });
  return result;
};

export const downloadSpcFileFromOrder = async (order_id, file_id) => {
  const result = new Promise((resolve) => {
    api
      .get(`orders/${order_id}/spcfiles/${file_id}`, {
        responseType: 'arraybuffer',
      })
      .then((response) => {
        const buffer = Buffer.from(response.data, 'base64');

        const blob = new Blob([buffer]);

        const file_url = URL.createObjectURL(blob);

        resolve(file_url);
      })
      .catch(async (error) => {
        const response = await handleError(error);
        resolve(response);
      });
  });
  return result;
};

export const downloadPhotoFromOrder = async (order_id, photo_id) => {
  const result = new Promise((resolve) => {
    api
      .get(`orders/${order_id}/order-s3-url-photos/${photo_id}`)
      .then((response) => {
        resolve(response.data);
      })
      .catch(async (error) => {
        const response = await handleError(error);
        resolve(response);
      });
  });
  return result;
};

export const downloadAllPhotosFromOrder = async (
  order_id,
  order_person_name
) => {
  const result = new Promise((resolve) => {
    api
      .get(`orders/${order_id}/photos`, {
        responseType: 'arraybuffer',
      })
      .then((response) => {
        const blob = new Blob([response.data]);

        const link = document.createElement('a');

        const url = URL.createObjectURL(blob);

        link.href = url;

        link.download = `${order_person_name}.zip`;

        link.click();

        resolve(response.data);
      })
      .catch(async (error) => {
        const response = await handleError(error);
        resolve(response);
      });
  });
  return result;
};

export const getPdfOrder = async (order_id) => {
  const result = new Promise((resolve) => {
    api
      .get(`orders/${order_id}/pdf-order`, {
        responseType: 'application/pdf',
      })
      .then((response) => {
        const file = new Blob([response.data], { type: 'application/pdf' });

        const file_url = URL.createObjectURL(file);

        resolve(file_url);
      })
      .catch(async (error) => {
        const response = await handleError(error);

        resolve(response);
      });
  });
  return result;
};

export const getPdfOrderControl = async (order_id) => {
  const result = new Promise((resolve) => {
    api
      .get(`orders/${order_id}/pdf-client-order-full`, {
        responseType: 'application/pdf',
      })
      .then((response) => {
        const file = new Blob([response.data], { type: 'application/pdf' });

        const file_url = URL.createObjectURL(file);

        resolve(file_url);
      })
      .catch(async (error) => {
        const response = await handleError(error);

        resolve(response);
      });
  });
  return result;
};

export const getPdfFinishOrder = async (order_id) => {
  const result = new Promise((resolve) => {
    api
      .get(`orders/${order_id}/pdf-finish-order`, {
        responseType: 'application/pdf',
      })
      .then((response) => {
        const file = new Blob([response.data], { type: 'application/pdf' });

        const file_url = URL.createObjectURL(file);

        resolve(file_url);
      })
      .catch(async (error) => {
        const response = await handleError(error);

        resolve(response);
      });
  });
  return result;
};

export const getPdfClientOrder = async (order_id) => {
  const result = new Promise((resolve) => {
    api
      .get(`orders/${order_id}/pdf-client-order`, {
        responseType: 'application/pdf',
      })
      .then((response) => {
        const file = new Blob([response.data], { type: 'application/pdf' });

        const file_url = URL.createObjectURL(file);

        resolve(file_url);
      })
      .catch(async (error) => {
        const response = await handleError(error);

        resolve(response);
      });
  });
  return result;
};

export const getOrderbyToken = async (token) => {
  const result = new Promise((resolve) => {
    api
      .get(`orders/access-order/${token}`)
      .then((response) => {
        resolve(response.data);
      })
      .catch(async (error) => {
        const response = await handleError(error);

        resolve(response);
      });
  });
  return result;
};

export const startPlotterTheOrder = async (order_id, data) => {
  const result = new Promise((resolve) => {
    api
      .put(`orders/${order_id}/start-plotter`, data)
      .then((response) => {
        toastSuccess('Pedido em plotter!');
        resolve(response.data);
      })
      .catch(async (error) => {
        const response = await handleError(error);
        resolve(response);
      });
  });
  return result;
};

export const finishPlotterTheOrder = async (order_id, data) => {
  const result = new Promise((resolve) => {
    api
      .put(`orders/${order_id}/finish-plotter`, data)
      .then((response) => {
        toastSuccess('Plotter finalizada!');
        resolve(response.data);
      })
      .catch(async (error) => {
        const response = await handleError(error);
        resolve(response);
      });
  });
  return result;
};
