import { useRef, useContext, useCallback } from 'react';
import { FaUserCheck, FaUserAltSlash } from 'react-icons/fa';
import { useHistory } from 'react-router';
import ReactTooltip from 'react-tooltip';

import { Form } from '@unform/web';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import * as Yup from 'yup';

import { AuthContext } from '../../../../actions/auth-context';
import { update } from '../../../../actions/base-rest';
import InputLabel from '../../../../components/unform/input-label';
import InputMaskLabel from '../../../../components/unform/input-mask-label-user-phone';
import { GroupData } from '../../../../styles/GroupData';

const URL = 'users';

const UserData = ({ user, setUser }) => {
  const { handleChangingPassword, setLoading } = useContext(AuthContext);

  const history = useHistory();

  const formRef = useRef(null);

  const submit = useCallback(
    async (data) => {
      data.id = user.id;

      try {
        formRef.current.setErrors({});
        const schema = Yup.object().shape({
          full_name: Yup.string().min(3, 'Nome mínimo 3 caracteres'),

          username: Yup.string().min(3, 'Username mínimo 3 caracteres'),

          email: Yup.string()
            .email('Digite um e-mail válido')
            .required('E-mail é obrigatório'),

          cell_phone: Yup.string()
            .test('validPhone', 'Celular inválido', (value) => {
              if (value.includes('_')) return false;
              return true;
            })
            .nullable(),
        });

        await schema.validate(data, { abortEarly: false });

        setLoading(true);

        const response = await update(URL, data);

        if (!response.error) {
          setUser({});

          setUser(response);
        } else {
          setUser({});

          setUser(user);
        }

        setLoading(false);
      } catch (error) {
        const errorMessages = {};
        if (error instanceof Yup.ValidationError) {
          error.inner.forEach((err) => {
            errorMessages[err.path] = err.message;
          });
          formRef.current.setErrors(errorMessages);
        }
      }
    },
    [user]
  );

  const setStatusUser = useCallback(
    async (status) => {
      setLoading(true);

      let data = {};

      data = user;

      data.is_active = status;

      const response = await update(URL, data);

      if (!response.error) {
        setUser({});

        setUser(response);
      } else {
        setUser({});

        setUser(user);
      }

      setLoading(false);
    },
    [user]
  );

  return (
    <Form
      ref={formRef}
      onSubmit={submit}
      initialData={user}
      noValidate
      className="col-12 col-lg-10"
    >
      <GroupData>
        <div className="row">
          <div className="col">
            <div className="row px-2">
              <UserIcon className="mt-3">
                {user.is_active && (
                  <FaUserCheck color="#3F9D2F" data-tip="Usuário Ativo" />
                )}

                {!user.is_active && (
                  <FaUserAltSlash color="#DC4E4E" data-tip="Usuário Inativo" />
                )}

                <ReactTooltip />
              </UserIcon>
            </div>

            <div className="row justify-content-center px-2">
              <div className="col-sm col-md-7 mt-3 px-1">
                <InputLabel name="full_name" label="Nome Completo" />
              </div>

              <div className="col-sm mt-3 px-1">
                <InputLabel name="username" label="Username" />
              </div>
            </div>

            <div className="row justify-content-center px-2">
              <div className="col-sm col-md-7 mt-3 px-1">
                <InputLabel name="email" label="E-mail" />
              </div>

              <div className="col-sm mt-3 px-1">
                <InputMaskLabel
                  name="cell_phone"
                  label="celular"
                  mask="(99) 99999-9999"
                />
              </div>
            </div>
          </div>
        </div>
      </GroupData>

      <div className="row justify-content-center mt-4 mb-4">
        <div className="col-6 col-md-2 ">
          <button
            className="btn btn-outline-secondary btn-block col-12"
            type="button"
            onClick={() => history.push('/users')}
          >
            voltar
          </button>
        </div>

        <div className="col-6 col-md-3">
          <button
            className="btn btn-secondary btn-block col-12"
            type="button"
            data-bs-toggle="modal"
            data-bs-target="#modal-confirm-reset-password"
            onClick={() => handleChangingPassword(true)}
          >
            resetar senha
          </button>
        </div>

        {user.is_active && (
          <div className="col-6 col-md-3">
            <button
              className="btn btn-danger btn-block col-12"
              type="button"
              onClick={() => setStatusUser(false)}
            >
              Inativar Usuário
            </button>
          </div>
        )}

        {!user.is_active && (
          <div className="col-6 col-md-3">
            <button
              className="btn btn-success btn-block col-12"
              type="button"
              onClick={() => setStatusUser(true)}
            >
              Ativar Usuário
            </button>
          </div>
        )}

        <div className="col-6 col-md-2">
          <button className="btn btn-primary btn-block col-12" type="submit">
            salvar
          </button>
        </div>
      </div>
    </Form>
  );
};

UserData.propTypes = {
  user: PropTypes.shape({
    id: PropTypes.string,
    thumbnail: PropTypes.string,
    is_active: PropTypes.bool,
    cell_phone: PropTypes.string,
  }).isRequired,
  setUser: PropTypes.func.isRequired,
};

export default UserData;

export const UserIcon = styled.div`
  border-radius: 50%;
  width: 50px;
  height: 50px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0;
  border: 1px solid #4d4d4d;

  svg {
    width: 50px;
  }
`;
