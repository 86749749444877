import React, { useRef, useCallback, useState } from 'react';
import { MdClose } from 'react-icons/md';
import ReactTooltip from 'react-tooltip';

import { Form } from '@unform/web';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import Input from '../../../components/unform/input';
import InputMask from '../../../components/unform/input-mask-label';

const PersonsFilter = ({
  isFilter,
  setIsFilter,
  setParams,
  default_params,
}) => {
  const formRef = useRef(null);
  const [trollCode, setTrollCode] = useState(null);
  const [cpfCnpj, setCpfCnpj] = useState(null);

  const clearFieldsSearch = useCallback(() => {
    formRef.current.setFieldValue('id', '');

    formRef.current.setFieldValue('name', '');

    formRef.current.setFieldValue('cpf_cnpj', '');

    setParams(default_params);

    setTrollCode(null);

    setCpfCnpj(null);
  }, []);

  const filterPersonsByFullName = useCallback(async (value) => {
    if (value.length >= 3) {
      setParams((prevState) => ({
        ...prevState,
        full_name: value,
      }));
    } else if (value.length === 0) {
      setParams(default_params);
    }
  }, []);

  const filter = useCallback(() => {
    setParams((prevState) => ({
      ...prevState,
      cpf_cnpj: cpfCnpj !== '' ? cpfCnpj : null,
      troll_code: trollCode !== '' ? trollCode : null,
    }));
  }, [cpfCnpj, trollCode]);

  return (
    <>
      {isFilter && (
        <>
          <CloseSearch
            className="btn btn-outline-primary text-center"
            onClick={() => {
              setIsFilter(false);
              clearFieldsSearch();
            }}
          >
            <MdClose size={25} data-tip="Fechar" />
            <ReactTooltip />
          </CloseSearch>

          <Search>
            <Form
              ref={formRef}
              onSubmit={filter}
              noValidate
              className="col col-lg-10 p-4"
            >
              <div className="row justify-content-center">
                <div className="col mt-2 d-flex">
                  <Input
                    name="name"
                    placeholder="Pesquisa rápida por nome"
                    onChange={(e) => filterPersonsByFullName(e.target.value)}
                  />
                </div>
              </div>

              <div className="row justify-content-center">
                <div className="col-4 col-md-3 mt-2">
                  <Input
                    name="id"
                    placeholder="Por id TROLL"
                    onChange={(e) => setTrollCode(e.target.value)}
                  />
                </div>

                <div className="col mt-2 d-flex">
                  <InputMask
                    label=""
                    name="cpf_cnpj"
                    placeholder="Por CPF"
                    mask="999.999.999-99"
                    onChange={(e) => setCpfCnpj(e.target.value)}
                  />
                </div>

                <div className="col mt-2 d-flex">
                  <InputMask
                    label=""
                    name="cpf_cnpj"
                    placeholder="Por CNPJ"
                    mask="99.999.999/9999-99"
                    onChange={(e) => setCpfCnpj(e.target.value)}
                  />
                </div>
              </div>

              <div className="row justify-content-center mt-3">
                <div className="col">
                  <button
                    className="btn btn-outline-secondary col-sm btn-block col-12"
                    type="button"
                    onClick={clearFieldsSearch}
                  >
                    Limpar campos
                  </button>
                </div>

                <div className="col">
                  <button
                    className="btn btn-primary col-sm btn-block col-12"
                    type="submit"
                    onClick={filter}
                  >
                    Pesquisar
                  </button>
                </div>
              </div>
            </Form>
          </Search>
        </>
      )}
    </>
  );
};

PersonsFilter.propTypes = {
  isFilter: PropTypes.bool.isRequired,
  setIsFilter: PropTypes.func.isRequired,
  setParams: PropTypes.func.isRequired,
  default_params: PropTypes.shape().isRequired,
};

export default PersonsFilter;

export const Search = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  background-color: #f5f5f5;
`;
export const ClearSearch = styled.div`
  color: #d11515;

  svg {
    margin-top: 12px;
    margin-left: -8px;
  }

  :hover {
    cursor: pointer;
    color: #ec4646;
  }
`;
export const CloseSearch = styled.button`
  position: absolute;
  margin-left: -70px;
  margin-top: 6px;
  height: 35px !important;
`;
