import { useContext, useCallback } from 'react';

import PropTypes from 'prop-types';

import { AuthContext } from '../../../../../actions/auth-context';

const SendReworkButton = ({ order }) => {
  const { hasPermissions, hasRoles } = useContext(AuthContext);

  const hasViewButton = useCallback(() => {
    if (
      hasPermissions(['add_order']) &&
      /Recebido na unidade|Entregue/.test(order.order_status)
    ) {
      return true;
    }
    if (
      hasPermissions(['print_photos']) &&
      /Em impressão|Em plotter/.test(order.order_status)
    ) {
      return true;
    }
    if (
      hasPermissions(['assemble_order']) &&
      /Em montagem/.test(order.order_status)
    ) {
      return true;
    }

    if (
      hasPermissions(['pack_order']) &&
      /Em embalagem|Embalado|Em expedição/.test(order.order_status)
    ) {
      return true;
    }
    if (hasRoles(['administrator', 'master'])) return true;

    return false;
  }, [order]);

  return (
    <>
      {hasViewButton() && (
        <div className="col-sm col-md-4 col-xl-3 mt-2">
          <button
            className="btn btn-danger btn-block col-12"
            type="button"
            data-bs-toggle="modal"
            data-bs-target="#modal-rework-order"
          >
            enviar retrabalho
          </button>
        </div>
      )}
    </>
  );
};

SendReworkButton.propTypes = {
  order: PropTypes.shape({
    id: PropTypes.string,
    order_status: PropTypes.string,
    is_rework: PropTypes.bool,
  }).isRequired,
};

export default SendReworkButton;
