import { useContext, useState, useRef, useCallback } from 'react';
import { BsChevronCompactDown } from 'react-icons/bs';
import { FaFilter } from 'react-icons/fa';
import ReactTooltip from 'react-tooltip';

import { Form } from '@unform/web';
import styled from 'styled-components';
import * as Yup from 'yup';

import { AuthContext } from '../../../actions/auth-context';
import { dateToStringWithTime } from '../../../commons/date';
import InputDateLabel from '../../../components/unform/input-date-label';
import OrdersByEditors from './OrdersByEditors';
import ProductionLocation from './ProductionLocation';
import Reworks from './Rewoks';
import UsersProduction from './UsersProduction';

const Dashboard = () => {
  const { hasRoles } = useContext(AuthContext);

  const formRef = useRef(null);

  const [params, setParams] = useState({});

  const [openDateForm, setOpenDateForm] = useState(false);

  const submit = useCallback(
    async (data) => {
      try {
        formRef.current.setErrors({});
        const schema = Yup.object().shape({
          start_date: Yup.date().nullable(),
          end_date: Yup.date().nullable(),
        });

        await schema.validate(data, { abortEarly: false });

        data.start_date = await dateToStringWithTime(data.start_date);

        data.end_date = await dateToStringWithTime(data.end_date);

        setParams(data);
      } catch (error) {
        const errorMessages = {};
        if (error instanceof Yup.ValidationError) {
          error.inner.forEach((err) => {
            errorMessages[err.path] = err.message;
          });
          formRef.current.setErrors(errorMessages);
        }
      }
    },
    [formRef]
  );

  return (
    <div className="p-0 mt-3 mb-5">
      {hasRoles(['administrator', 'master', 'financial']) && (
        <>
          <ProductionLocation />

          <OrdersByEditors />
        </>
      )}

      {hasRoles(['administrator', 'master']) && (
        <>
          {!openDateForm && (
            <Container>
              <h4 className="text-center">DADOS DOS ÚLTIMOS 30 DIAS</h4>

              <div
                className="detalhes"
                role="button"
                tabIndex="0"
                onKeyPress={() => {}}
                onClick={() => setOpenDateForm(true)}
              >
                <span>Filtrar por data</span>

                <div>
                  <BsChevronCompactDown />
                </div>
              </div>
            </Container>
          )}

          {openDateForm && (
            <Form ref={formRef} onSubmit={submit} noValidate>
              <ContainerForm>
                <Close
                  onClick={() => {
                    setParams({});

                    setOpenDateForm(false);
                  }}
                >
                  X
                </Close>

                <div className="row justify-content-center">
                  <div className="col text-center mb-2">
                    <span>FILTRAR POR DATA</span>
                  </div>
                </div>

                <div className="row justify-content-center">
                  <div className="col-sm-6 col-md-2">
                    <InputDateLabel name="start_date" label="data inicial" />
                  </div>

                  <div className="col-sm-6 col-md-2">
                    <InputDateLabel name="end_date" label="data final" />
                  </div>

                  <Button type="submit" className="col-1 d-none d-md-block">
                    <FaFilter data-tip="Filtrar" size={20} />
                    <ReactTooltip />
                  </Button>

                  <div className="row d-sm-block d-md-none mt-2">
                    <div className="col-sm p-0">
                      <button
                        type="submit"
                        className="btn btn-primary btn-block col-12"
                      >
                        Filtrar
                      </button>
                    </div>
                  </div>
                </div>
              </ContainerForm>
            </Form>
          )}

          <UsersProduction params={params} />

          <Reworks params={params} />
        </>
      )}
    </div>
  );
};

export default Dashboard;

export const Container = styled.div`
  h4 {
    font-weight: bold;
    color: #7d7d7d;
    padding-top: 10px;
  }

  margin-top: 30px;

  background-color: #f5f5f5;

  .detalhes {
    color: #7d7d7d;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    font-size: 11px;
    margin-top: -10px;

    :hover {
      color: #fab702;
      cursor: pointer;

      svg {
        animation: animate__pulse;
        animation-duration: 2s;
      }
    }
  }
`;

export const ContainerForm = styled.div`
  margin-top: 10px;
  background-color: #f5f5f5;
  padding: 5px 20px 20px 20px;
  align-items: center;
  font-weight: bold;

  span {
    font-size: 10px;
    font-weight: bold;
  }

  .close {
    width: 40px;
    border: 1px solid #fab702;
  }
`;

export const Button = styled.button`
  background-color: #fab702;
  padding: 10px;
  border-radius: 3px;
  color: #fff;
  height: 40px;
  margin-top: 14px;
  width: 50px;
  border: 0;

  :hover {
    cursor: pointer;
    background-color: #ffcf4c;
  }
`;

export const Close = styled.button`
  background-color: #d11515;
  border-color: #d11515;
  border-radius: 3px;
  color: #fff;
  height: 30px;
  margin-top: 5px;
  width: 30px;
  border: 0;
  float: right;

  :hover {
    cursor: pointer;
    background: #ec4646 !important;
    border-color: #d11515 !important;
  }
`;
