import React, { useEffect, useRef, useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { Link } from 'react-router-dom';

import { Form } from '@unform/web';
import * as Yup from 'yup';

import { store, index } from '../../../../actions/base-rest';
import { filterItemByCpfCnpj } from '../../../../actions/serve-json';
import { isValidCpf, isValidCnpj } from '../../../../commons/validate-cpf-cnpj';
import InputLabel from '../../../../components/unform/input-label';
import InputMaskLabel from '../../../../components/unform/input-mask-label';
import RadioButtonLabel from '../../../../components/unform/radiobutton-label-string';
import { setBreadcrumb } from '../../../../reducers/breadcrumb';
import { GroupData } from '../../../../styles/GroupData';

const URL = 'persons';
const NewPerson = () => {
  const dispatch = useDispatch();

  const formRef = useRef(null);

  const history = useHistory();

  const [typePerson, setTypePerson] = useState('Física');

  const [registeredPerson, setRegisteredPerson] = useState({});

  const validateCpfCnpj = useCallback(
    async (value) => {
      const val = value.replace('_', '');

      let result = false;

      if (val.length > 0) {
        if (val.length === 14) {
          result = await isValidCpf(val);
        } else {
          result = await isValidCnpj(val);
        }

        if (!result) {
          formRef.current.setFieldError(
            'cpf_cnpj',
            `${typePerson === 'Física' ? 'CPF' : 'CNPJ'} inválido`
          );
        } else {
          formRef.current.setFieldError('cpf_cnpj', ``);
          const params = {
            cpf_cnpj: value,
          };
          const person_already_registered = await index('persons', params);
          if (person_already_registered.rows.length > 0) {
            setRegisteredPerson(person_already_registered.rows[0]);
            formRef.current.setFieldError('cpf_cnpj', `PESSOA já cadastrada`);
          } else {
            setRegisteredPerson({});
          }
        }
      } else {
        formRef.current.setFieldError(
          'cpf_cnpj',
          `${typePerson === 'Física' ? 'CPF' : 'CNPJ'} inválido`
        );
      }
    },
    [typePerson]
  );

  const submit = useCallback(
    async (data) => {
      try {
        formRef.current.setErrors({});
        const schema = Yup.object().shape({
          type: Yup.string().required('Tipo pessoa é obrigatório'),
          full_name: Yup.string().min(3, 'Nome mínimo 3 caracteres'),
          email: Yup.string().email('Digite um e-mail válido'),
          cpf_cnpj: Yup.string()
            .test(
              'personAlreadyRegistered',
              `PESSOA já cadastrada!`,
              async (value) => {
                if (value) {
                  const response = await filterItemByCpfCnpj(value);
                  if (response.length > 0) {
                    setRegisteredPerson(response[0]);
                    return false;
                  }
                  setRegisteredPerson({});
                }
                return true;
              }
            )
            .test(
              'validateCpfCnpj',
              `${typePerson === 'Física' ? 'CPF' : 'CNPJ'} inválido`,
              (value) => {
                let result = false;

                if (value.length > 0) {
                  if (value.length === 14) {
                    result = isValidCpf(value);
                  } else {
                    result = isValidCnpj(value);
                  }
                }

                return result;
              }
            )
            .required(
              `${typePerson === 'Física' ? 'CPF' : 'CNPJ'} é obrigatório`
            ),
          naturalness: Yup.string(),
        });

        await schema.validate(data, { abortEarly: false });

        const response = await store(URL, data);

        if (!response.error) history.push(`/persons/${response.id}`);
      } catch (error) {
        const errorMessages = {};

        if (error instanceof Yup.ValidationError) {
          error.inner.forEach((err) => {
            errorMessages[err.path] = err.message;
          });
          formRef.current.setErrors(errorMessages);
        }
      }
    },
    [typePerson, history]
  );

  useEffect(() => {
    dispatch(
      setBreadcrumb([
        { page: 'pessoas', url: '/persons' },
        { page: 'novo', url: null },
      ])
    );
  }, [dispatch]);

  return (
    <div className="container">
      <div className="row mt-3 justify-content-center">
        <div className="col-12 text-center my-3">
          <h4>NOVA PESSOA</h4>
        </div>
      </div>

      <Form ref={formRef} onSubmit={submit} noValidate>
        <GroupData>
          <span>DADOS PESSOAIS</span>

          <div className="mt-3">
            <RadioButtonLabel
              name="type"
              label="Tipo pessoa"
              defaultValue="Física"
              onChange={(e) => setTypePerson(e.target.value)}
              options={[
                { label: 'física', value: 'Física' },
                { label: 'jurídica', value: 'Jurídica' },
              ]}
            />
          </div>

          <div className="row mt-4">
            <div className="col-12 col-md-4">
              <InputMaskLabel
                name="cpf_cnpj"
                label={typePerson === 'Física' ? 'cpf' : 'cnpj'}
                mask={
                  typePerson === 'Física'
                    ? '999.999.999-99'
                    : '99.999.999/9999-99'
                }
                onBlur={(e) => validateCpfCnpj(e.target.value)}
              />
            </div>
            {registeredPerson?.id && (
              <div className="col-12 col-md-2 p-0">
                <Link to={`/persons/${registeredPerson.id}`}>
                  <button
                    type="button"
                    className="btn btn-outline-primary col-12"
                    style={{ marginTop: '12px' }}
                  >
                    editar pessoa
                  </button>
                </Link>
              </div>
            )}
          </div>

          <div className="row mt-3">
            <div className="col-12 col-md-8">
              <InputLabel
                name="full_name"
                label="nome"
                disabled={registeredPerson.id}
              />
            </div>

            <div className="col-12 col-md-4">
              <InputLabel
                name="naturalness"
                label="naturalidade"
                disabled={registeredPerson.id}
              />
            </div>
          </div>
        </GroupData>

        <div className="row justify-content-center mt-4">
          <div className="col-6 col-md-4 col-xl-3">
            <button
              className="btn btn-outline-secondary btn-block col-12"
              type="button"
              onClick={() => history.goBack()}
            >
              voltar
            </button>
          </div>
          <div className="col-6 col-md-4 col-xl-3">
            <button
              className="btn btn-primary btn-block col-12"
              type="submit"
              disabled={registeredPerson.id}
            >
              salvar
            </button>
          </div>
        </div>
      </Form>
    </div>
  );
};

export default NewPerson;
