import { useCallback, useContext } from 'react';
import { FaBoxes } from 'react-icons/fa';

import PropTypes from 'prop-types';

import { AuthContext } from '../../../../actions/auth-context';
import { sendDispatchTheOrder } from '../../../../actions/order.service';

const DispatchOrderButton = ({ order, getOrders }) => {
  const { hasPermissions } = useContext(AuthContext);

  const sendDispatchOrder = useCallback(async (data) => {
    sendDispatchTheOrder(data.id, { action_note: null }).then(() => {
      getOrders();
    });
  }, []);

  return (
    <>
      {order.order_status === 'Embalado' &&
        hasPermissions(['edit_order', 'pack_order']) && (
          <FaBoxes
            size={22}
            className="mb-1 action"
            data-tip="Mandar para expedição"
            onClick={() => sendDispatchOrder(order)}
          />
        )}
    </>
  );
};

DispatchOrderButton.propTypes = {
  order: PropTypes.shape({
    order_status: PropTypes.string,
  }).isRequired,
  getOrders: PropTypes.func,
};

DispatchOrderButton.defaultProps = {
  getOrders: PropTypes.func,
};

export default DispatchOrderButton;
