export const currencyToFloat = async (value) => {
  let val = value.toString();

  val = await val.replace(/[^0-9,-]+/g, '').replace(',', '.');

  return parseFloat(val);
};

export const { format: floatToCurrency } = new Intl.NumberFormat('pt-BR', {
  style: 'currency',
  currency: 'BRL',
});
